import React, { useEffect } from 'react'
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from "@mui/material/styles";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { loadUserApi } from '../../state/actions/authActions';
import { useDispatch } from 'react-redux';
import { paymentDetails, paymentStatus } from '../../state/actions/paymentActions'
import BackdropComponent from '../../Components/Backdrop/Backdrop';
import { sendToAnalytics } from '../../state/actions/AnalyticsAction';


const StyledLogin = styled(Button)(() => ({
    backgroundColor: "#007aff",
    color: "white",
    fontSize: "14px",
    padding: "6px 16px",
    "&:hover": {
        backgroundColor: "#0957DD",
        color: "white",
    },
}));
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#48484800",
    borderColor: "white",
    boxShadow:
        "0px 2px 1px -4px white,0px 0px 1px -1px rgb(221 221 221 / 90%), 0px 1px 5px 6px rgb(255 255 255 / 12%)",
    borderRadius: "10px",
    paddingTop: "1rem",
    ...theme.typography.body2,
    overflow: "hidden",
    // textAlign: "center",
    color: "white",
    Height: "100%",
}));


const Confirmation = () => {
    const navigate = useNavigate()
    const [cardData, setCardData] = React.useState(null)
    const [userData, setUserData] = React.useState(null)
    const { state } = useLocation()
    const [response, setResponse] = React.useState(null)
    const [paymentStatusCheck, setPaymentStatus] = React.useState("")
    const [sessionId, setSessionId] = React.useState(null)
    const [openBackdrop, setOpenBreakdrop] = React.useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (state && state?.cardData) {
            setCardData(state.cardData)
        }
        if (state && state?.orderId) {
            getPaymentDetails(state?.orderId)
        }
    }, [state])

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        if (sessionId) {
            setSessionId(sessionId)
        } else {
            if (state && state?.orderData) {
                getPaymentDetails(state?.orderId)
            }
        }
        setOpenBreakdrop(true);

        getPaymentStatus(sessionId, state?.orderData)
    }, []);


    const getPaymentStatus = async (sessionId, data) => {
        let selectQry = `?sessionId=${sessionId}`
        const response = await paymentStatus(selectQry, data)

        if (response && response?.status == "complete") {
            getPaymentDetails(response?.orderDetail?.id)
            setCardData(response?.cardLast4)
            setPaymentStatus(response.status)
        } else {
            setOpenBreakdrop(false);
            setResponse(true);

        }
    }

    let addressString = [];
    const getPaymentDetails = async (id) => {
        let selectQry = `?orderId=${id}`

        const response = await paymentDetails(selectQry)
        if (response && response?.status) {
            await sendToAnalytics({ ecommerce: null });
            const payload = {
                TotalAmount: response?.data?.orderDetail?.planAmount,
                Discount: response?.data?.orderDetail?.discountAmount,
                CouponCode: response?.data?.coupon,
                quantity: 1,
                planName: response?.data?.planDetail?.planKey,
                price: response?.data?.planDetail?.finalPrice,

            }
            await sendToAnalytics(payload);
            setUserData(response?.data)
            setOpenBreakdrop(false);
            setResponse(true);
            dispatch(loadUserApi());
            addressString = [
                response?.data.userAddress?.address,
                response?.data.userAddress?.city,
                response?.data.userAddress?.stateName,
                response?.data.userAddress?.countryName,
                response?.data.userAddress?.zipcode
            ].filter(Boolean).join(', ');

        }
        else {
            setOpenBreakdrop(false);
            setResponse(true);

            console.log(response?.message)
        }
    }

    useEffect(() => {
        dispatch(loadUserApi());
    }, []);

    return (
        <>
            {!openBackdrop && response == true && <Grid
                container
                sx={{
                    backgroundColor: "#0c0e2e",
                    marginTop: { xs: "5rem", md: "6rem" },
                    marginBottom: { xs: "5rem", sm: "5rem", md: "5rem" },
                    width: { xs: "100%", md: "550px" },
                    margin: "auto",
                }}
            >
                <Item>
                    <Grid item sm={12} xs={12}>
                        <Stack paddingTop={2} paddingBottom={1} gap={1} flexDirection={'row'} justifyContent={'center'}>
                            <FaCheckCircle style={{ fontSize: { xs: '35px', sm: '50px' }, color: '#16d916' }} />
                        </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Stack gap={1} paddingBottom={1} flexDirection={'row'} justifyContent={'center'}>
                            <Typography fontWeight={'500'} fontSize={{ xs: '12px', sm: '22px' }} sx={{ color: '#fff' }}>
                                {paymentStatusCheck == "complete" ?
                                    "Payment Success!" : "Payment Failed"}</Typography>
                        </Stack>
                    </Grid>

                    <Grid item sm={12} xs={12} px={{ xs: 2, sm: 3 }}>
                        <Grid container paddingBottom={2} spacing={{ xs: 0, sm: 1 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff' }}>Price :</Typography>
                            </Grid>

                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff', textAlign: 'end' }}>$ {userData?.planDetail?.finalPrice || '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} px={{ xs: 2, sm: 3 }}>
                        <Grid container paddingBottom={2} spacing={{ xs: 0, sm: 1 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff' }}>Discount :</Typography>
                            </Grid>

                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff', textAlign: 'end' }}>$ {userData?.orderDetail?.discountAmount || '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} px={{ xs: 2, sm: 3 }}>
                        <Grid container paddingBottom={2} spacing={{ xs: 0, sm: 1 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff' }}>Coupon Code :</Typography>
                            </Grid>

                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff', textAlign: 'end' }}>{userData?.coupon || '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} px={{ xs: 2, sm: 3 }}>
                        <Grid container paddingBottom={2} spacing={{ xs: 0, sm: 1 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff' }}>Purchase Amount Total :</Typography>
                            </Grid>

                            <Grid item xs={5.5}>
                                <Typography fontWeight={'500'} fontSize={{ xs: '11px', sm: '16px' }} sx={{ color: '#fff', textAlign: 'end' }}>$ {userData?.orderDetail?.planAmount || '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                    </Grid>

                    <Grid item sm={12} xs={12} paddingY={3} paddingX={1.5}>
                        <Grid container spacing={1.5}>
                            <Grid item sm={6} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Full Name </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{userData?.userDetail?.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Phone Number </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{userData?.userDetail?.mobile}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Customer Email </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{userData?.userDetail?.email}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Last 4 CC numbers </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{cardData || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Plan Details </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{userData?.planDetail?.planKey}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Grid container sx={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'14px'} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Customer Address </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={'15px'} sx={{ color: '#fff' }} fontWeight={'600'}>{userData?.userAddress?.address}, {userData?.userAddress?.city}, {userData?.userAddress?.stateName}, {userData?.userAddress?.countryName}, {userData?.userAddress?.zipcode}</Typography>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                    </Grid>
                    <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <StyledLogin sx={{ my: 2, mr: 2 }} onClick={() => navigate("/welcome")}>
                            <p>Go to Dashboard</p>
                        </StyledLogin>
                    </Grid>
                </Item >
            </Grid >}
            <BackdropComponent open={openBackdrop} />

        </>
    )
}

export default Confirmation