import React from "react";
import { Button, styled, IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { TextField, Tooltip } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { changePassword } from "../../state/actions/profile";

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const initialValues = {
  oldPassword: "",
  newPassword: "",
};
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#0957DD",
  borderRadius: "10px",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;

export default function ResetPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open, setNotify, handleClose } = props;

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmNewPassword] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmNewPassword(!showConfirmPassword);
  };

  const validatePassword = (fieldValues, temp, key, value) => {
    if (value !== "") {
      if (value.length < 8) {
        temp[key] = "Password  must be 8 character";
      } else {
        if (value.length > 128) {
          temp[key] = "Password  must be less than 128 character";
        } else {
          if (!/[0-9]/.test(value)) {
            temp[key] = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(value)) {
              temp[key] = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(value)) {
                temp[key] = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp[key] = "";
              }
            }
          }
        }
      }
    } else {
      temp[key] = "This field is required.";
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("oldPassword" in fieldValues) {
      validatePassword(
        fieldValues,
        temp,
        "oldPassword",
        fieldValues.oldPassword
      );
    }
    if ("newPassword" in fieldValues) {
      validatePassword(
        fieldValues,
        temp,
        "newPassword",
        fieldValues.newPassword
      );
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setOpenBreakdrop(true);
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const response = await changePassword(payload);
      if (response && response.status === true) {
        setOpenBreakdrop(false);
        handleClose();
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
      } else {
        setOpenBreakdrop(false);
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
      }

      setOpenBreakdrop(false);
    }
  };
  useEffect(() => {
    resetForm();
  }, [handleClose]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Box
        sx={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
        className="fontLight"
      >
        Change Password
        <IconButton
          onClick={() => {
            setValues({
              oldpassword: "",
              newPassword: "",
            });
            handleClose();
          }}
        >
          <Close className="fontLight" />
        </IconButton>
      </Box>

      <Form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ mt: 2 }}>
          <StyledLabel className="fontLight">Current Password </StyledLabel>
          <WhiteBorderTextField
            name="oldPassword"
            value={values.oldPassword}
            onChange={handleInputChange}
            type={showNewPassword ? "text" : "password"}
            // sx={{ mt: 1, borderRadius: "8px" }}
            sx={{
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  borderColor: "white",
                },
              },

              mt: 1,
              mr: 3,
              borderRadius: "8px",
            }}
            size="small"
            fullWidth
            error={Boolean(errors.oldPassword)}
            helperText={errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ m: 0 }}>
                  <Tooltip
                    title={showNewPassword ? "Hide Password" : "Show Password"}
                  >
                    <IconButton onClick={handleClickShowNewPassword}>
                      {showNewPassword ? (
                        <Visibility className="fontLight" />
                      ) : (
                        <VisibilityOff className="fontLight" />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <StyledLabel className="fontLight">New Password</StyledLabel>
          <WhiteBorderTextField
            name="newPassword"
            value={values.newPassword}
            onChange={handleInputChange}
            type={showConfirmPassword ? "text" : "password"}
            sx={{ mt: 1, borderRadius: "8px" }}
            size="small"
            fullWidth
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ m: 0 }}>
                  <Tooltip
                    title={
                      showConfirmPassword ? "Hide Password" : "Show Password"
                    }
                  >
                    <IconButton onClick={handleClickShowConfirmPassword}>
                      {showConfirmPassword ? (
                        <Visibility className="fontLight" />
                      ) : (
                        <VisibilityOff className="fontLight" />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          <StyledLogin sx={{ mt: 3, mr: 3 }} type="submit">
            Save
          </StyledLogin>
          <StyledLogin
            sx={{ mt: 3 }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </StyledLogin>
        </Box>
      </Form>
    </>
  );
}
