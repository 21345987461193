import React, { useState, useEffect } from "react";
import ResetPassword from "./ResetPassword";
import {
  Grid,
  Box,
  TextField,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Controls from "../../Components/controls/Controls";
import Modal from "@mui/material/Modal";
import ToastNotification from "../../Utils/toastNotification";
import { useForm } from "../../Utils/useForm";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { store } from "../../state/store";
import { profileDetails, updateProfile } from "../../state/actions/profile";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const initialValues = {
  name: "",
  profile: "",
  email: "",
};

export default function Profile() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [ResetPasswordOpen, setResetPasswordOpen] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && isEdit) {
      const res = await updateProfile(values);
      if (res && res.status === true) {
        setOpenBreakdrop(false);
        setNotify({
          isOpen: true,
          message: res.message || "Profile updated successfully.",
          type: "success",
        });
        navigate("/profile");
        window.location.reload();
      } else {
        setNotify({
          isOpen: true,
          message: res.message || "Something went wrong.",
          type: "error",
        });
        setOpenBreakdrop(false);
      }
    } else {
      setIsEdit(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userPlanData, setUserPlanData] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { auth } = store.getState();

  const getProfile = async () => {
    setOpenBreakdrop(true);
    const res = await profileDetails();
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      setValues({
        ...values,
        name: res.data.name,
        email: res.data.email,
        profile: res.data.profile,
      });
      setUserPlanData(res.data.planDetail);
    } else {
      setOpenBreakdrop(false);
    }
  };
  useEffect(() => {
    if (auth && auth.token) {
      getProfile();
    }
    if (auth && auth?.user && auth?.user.role == 1) {
      setIsAdmin(true);
    }
  }, [auth]);
  const ResetPasswordClose = () => {
    setResetPasswordOpen(false);
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6} xs={6}>
            <h2 className="title">Profile</h2>
          </Grid>
          <Grid sm={6} xs={6}>
            <Box
              style={{
                float: "right",
              }}
            >
              <Controls.Button
                text={isEdit ? "Save" : "Edit"}
                onClick={(e) => {
                  setIsEdit(!isEdit);
                  handleSubmit(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            container
            spacing={1}
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                paddingLeft: { xs: "10px", md: "20px" },
                paddingRight: { xs: "0px", md: "0px" },
              }}
              style={{
                border: "1px solid rgba(15, 15, 15, 0.15)",
                textAlign: "center",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow key={"name"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        width: "15%",
                        color: "white",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Name
                    </TableCell>
                    {isEdit ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.name}
                          inputProps={{ maxLength: 100 }}
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          pl: 2,
                          color: "white",
                          fontSize: { xs: "14px", md: "16px", lg: "16px" },
                        }}
                      >
                        {values.name || ""}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow key={"email"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "white",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Email
                    </TableCell>

                    <TableCell
                      sx={{
                        pl: 2,
                        color: "white",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      {values.email}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "white",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "white",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      ***********
                      {!isEdit ? (
                        <Button
                          variant="contained"
                          sx={{
                            color: "white",
                            backgroundColor: "#0957DD",
                            cursor: "pointer",
                            ml: 2,
                            minWidth: "56px!important",
                          }}
                          onClick={() => {
                            setResetPasswordOpen(true);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </form>
        {isAdmin ? null : (
          <div
            sx={{
              marginLeft: { xs: "10px", md: "20px" },
              marginTop: { xs: "20px", md: "20px" },
            }}
            style={{ paddingBottom: "20px" }}
          >
            <h3 className="fontLight" style={{ paddingLeft: "17px" }}>
              Subscription Details
            </h3>
            <Table>
              <TableBody>
                <TableRow key={"name"}>
                  <TableCell
                    sx={{
                      pl: 2,
                      width: "15%",
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    Plan Type
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 2,
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    {userPlanData?.planName}
                  </TableCell>
                </TableRow>
                <TableRow key={"email"}>
                  <TableCell
                    sx={{
                      pl: 2,
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    Duration
                  </TableCell>

                  <TableCell
                    sx={{
                      pl: 2,
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    {userPlanData?.planExpirationDuration == 99 ? "Unlimited" : userPlanData?.planExpirationDuration}{" "}
                    {/* {userPlanData?.planExpirationDuration}{" "} */}
                    {userPlanData?.planExpirationDuration == 99 ? "" : userPlanData?.planDurationType == 3 ? "Days" : "Months"}
                    {/* {userPlanData?.planDurationType == 3 ? "Day" : "Month"} */}
                  </TableCell>
                </TableRow>

                <TableRow key={"email"}>
                  <TableCell
                    sx={{
                      pl: 2,
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 2,
                      color: "white",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                    }}
                  >
                    {userPlanData?.planDurationType == 3
                      && "Free"
                    }
                    {
                      userPlanData?.planExpirationDuration == 99 ? "$0.00/Month" : `$${userPlanData?.planPrice}/Month`
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {userPlanData?.planDurationType == 3 ? (
              <Controls.Button
                sx={{ marginTop: "20px", marginLeft: "17px" }}
                text="Extend Subscription"
                onClick={(e) => {
                  navigate("/buy-now");
                }}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <Modal
        keepMounted
        open={ResetPasswordOpen}
        handleClose={ResetPasswordClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <ResetPassword
            open={ResetPasswordOpen}
            handleClose={ResetPasswordClose}
            setNotify={setNotify}
          />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
