import React, { useEffect, useState } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { addFaq, updateFaq } from "../../state/actions/faqAction";

import { styled } from "@mui/material/styles";
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
const initialValues = {
    question: "",
    answer: "",
}


const Faqadd = (props) => {

    const [isEdit, setIsEdit] = useState(false);
    const [descriptionData, setDescriptionData] = useState([]);
    const { handleClose, setNotify, faqListData, editId, page } = props;
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("question" in fieldValues) {
            temp.question = fieldValues.question ? "" : "This field is required.";
        }

        if ("answer" in fieldValues) {
            temp.answer = fieldValues.answer ? "" : "This field is required.";
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const payload = {
                question: values.question,
                answer: values.answer,
            };
            if (isEdit) {
                const res = await updateFaq(editId.id, payload);
                if (res && res.status === true) {
                    setNotify({
                        isOpen: true,
                        message: res.message,
                        type: "success",
                    });
                    faqListData(page);
                    handleClose();
                    resetForm();
                } else {
                    setNotify({
                        isOpen: true,
                        message: res.message,
                        type: "error",
                    });
                }
            } else {
                const res = await addFaq(payload);
                if (res && res.status === true) {
                    handleClose();
                    faqListData(page);
                    resetForm();
                    setNotify({
                        isOpen: true,
                        message: res.message || "Added Successfully.",
                        type: "success",
                    });
                } else {
                    setNotify({
                        isOpen: true,
                        message: res.message || "Something went wrong.",
                        type: "error",
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (editId) {
            console.log(editId);
            setIsEdit(true);
            setValues({
                ...values,
                question: editId.title,
                answer: editId.content,
            });
            setDescriptionData(editId.planDescriptions);
        }
    }, [editId]);

    return (
        <>
            <Grid container>
                <Grid item xs={9}>
                    <p className="fontLight"> {isEdit ? "Save" : "Add"} FAQ</p>
                </Grid>
                <Grid item xs={3} className="text-end">
                    <ClearIcon
                        onClick={() => handleClose()}
                        style={{ cursor: "pointer", color: "#efefef" }}
                    />
                </Grid>
            </Grid>
            <Divider
                sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
                variant="fullWidth"
                style={{
                    marginTop: "0.5rem",

                    marginBottom: "1.5rem",
                }}
            />
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="question"
                            className="planExpirationDuration"
                            onChange={handleInputChange}
                            value={values.question}
                            error={errors.question}
                            label="Question"
                            rows={2}
                            multiline
                            inputProps={{
                                maxLength: 200,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="answer"
                            className="planExpirationDuration"
                            onChange={handleInputChange}
                            value={values.answer}
                            error={errors.answer}
                            helperText={errors.answer}
                            label="Answer"
                        />
                    </Grid>

                    <Grid container style={{ marginTop: "22px" }}>
                        <Grid item xs={9}></Grid>
                        <Grid item xs={3} className="text-end">
                            <Controls.Button
                                text="Cancel"
                                className="cancel-btn"
                                onClick={() => {
                                    handleClose();
                                    resetForm();
                                }}
                            />
                            <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};

export default Faqadd;
