import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import ToastNotification from "../../Utils/toastNotification";
import VideoLibraryList from "./VideoLibraryList";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import AddVideoLibrary from "./AddVideoLibrary";
import { videoLibraryList } from "../../state/actions/videoLibraryActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
export default function VideoLibrary() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [videolist, setVideolist] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [editId, setEditId] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const VideoLibraryListData = async (pageNum) => {
    setOpenBreakdrop(true);
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}`;
    const res = await videoLibraryList(queryString);
    if (res && res.status === true) {
      setVideolist(res.data);
      setTotalRecords(res.totalRecord);
      setPage(pageNum);
      setTimeout(() => {
        setOpenBreakdrop(false);
      }, 2000);
    } else {
      setVideolist([]);
      setOpenBreakdrop(false);
    }
  };

  useEffect(() => {
    VideoLibraryListData(1);
  }, [rowsPerPage, search]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Video Library</h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                startIcon={<AddIcon />}
                text="Video"
                onClick={handleOpen}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <VideoLibraryList
          videolist={videolist}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          VideoLibraryListData={VideoLibraryListData}
          setNotify={setNotify}
          open={open}
          setOpen={setOpen}
          editId={editId}
          setEditId={setEditId}
        />
      </div>
      <Modal
        keepMounted
        open={open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <AddVideoLibrary
            open={open}
            setOpen={setOpen}
            setNotify={setNotify}
            VideoLibraryListData={VideoLibraryListData}
            editId={editId}
            setEditId={setEditId}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
