import * as React from "react";
import { Box, FormHelperText } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const useStyles = styled({
  select: {
    "&.MuiOutlinedInput-root": {
      width: "200px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      "&:hover": {
        borderColor: "green",
      },
    },
  },
});
export default function SelectBox1(props) {
  const {
    name,
    value,
    onChange,
    maxRows = 0,
    options,
    disabled,
    ClassName,
    error = null,
    label,
    style,
    ...other
  } = props;
  return (
    <Box sx={{ minWidth: 160 }}>
      <FormControl fullWidth size="small" {...(error && { error: true })}>
        <InputLabel
          sx={{
            "&.MuiInputLabel-root": {
              color: "#fff ",
            },

            "&.MuiInputLabel-root.Mui-error": {
              color: "#fc9494",
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          name={name}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            ".MuiInputLabel-root": {
              color: "white",
            },
            ".MuiSelect-select": {
              color: "white",
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fc9494",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
          id="demo-select-small"
          value={value}
          label={label}
          disabled={disabled}
          onChange={onChange}
          {...other}
          {...(error && { error: true, helperText: error })}
        >
          {options.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name || item.planKey}
              </MenuItem>
            );
          })}
          <MenuItem value="Other">Other</MenuItem>
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
