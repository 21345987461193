// import React from "react"
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
// const options = {
//   responsive: true,
//   interaction: {
//     mode: 'index',
//     intersect: false,
//   },
//   stacked: false,
//   scales: {
//     x: {
//       grid: {
//         drawOnChartArea: false,
//       },
//     },
//     y: {
//       type: 'linear',
//       display: true,
//       position: 'left',
//       grid: {
//         drawOnChartArea: false,
//       },
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// export default function Bar({ graphData, graphMonthData }) {
//   let finalData = [];
//   if (graphData) {
//     labels.forEach((x) => {
//       const item = graphData.find((y) => y.months === x);
//       if (item) {
//         finalData.push(item.usercount)
//       } else {
//         finalData.push(0)
//       }
//     })
//   }

//   let finalMonthData = [];
//   if (graphMonthData) {
//     labels.forEach((x) => {
//       const item = graphMonthData.find((y) => y.months === x);
//       if (item) {
//         finalMonthData.push(item.usermonthcount)
//       } else {
//         finalMonthData.push(0)
//       }
//     })
//   }

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: 'Per User Bookings',
//         data: finalData,
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         yAxisID: 'y',
//         tension: 0.3,
//       },
//       {
//         label: 'Per Month Bookings',
//         data: finalMonthData,
//         borderColor: 'rgb(53, 162, 235)',
//         backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         yAxisID: 'y',
//         tension: 0.3,
//       },
//     ],
//   };

//   return (
//     <>
//       <Line options={options} data={data} width='500px' height='300px' />
//     </>
//   )
// }

// src/LineChart.js
// src/LineChart.js
// src/LineChart.js
// import React from "react";
// import Chart from "react-apexcharts";
// import "../styles/ColumnChart.css";

// class LineChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       options: {
//         chart: {
//           id: "line",
//           type: "line",
//           toolbar: {
//             show: false,
//           },
//         },
//         dataLabels: {
//           enabled: false,
//         },
//         xaxis: {
//           categories: ["week-1", "week-2", "week-3", "week-4"],
//           axisBorder: {
//             show: true,
//             color: "#fff",
//           },
//           axisTicks: {
//             show: false,
//             color: "#fff",
//           },
//           labels: {
//             style: {
//               colors: "#fff",
//             },
//           },
//         },
//         yaxis: {
//           axisBorder: {
//             show: true,
//             color: "#fff",
//           },
//           axisTicks: {
//             show: false,
//             color: "#fff",
//           },
//           labels: {
//             style: {
//               colors: "#fff",
//             },
//           },
//         },
//         legend: {
//           position: "top",
//         },
//         stroke: {
//           curve: "straight",
//         },
//       },
//       series: [
//         {
//           name: "New User",
//           data: [1, 5, 4, 2],
//           color: "#ac5ac3",
//         },
//         {
//           name: "Active User",
//           data: [12, 10, 32, 21],
//           color: "#00cadc",
//         },
//       ],
//     };
//   }

//   render() {
//     return (
//       <div className="chart-container">
//         <div className="chart">
//           <Chart
//             options={this.state.options}
//             series={this.state.series}
//             type="line"
//             width="100%"
//             height="100%"
//           />
//         </div>
//       </div>
//     );
//   }
// }

// export default LineChart;


import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "../styles/ColumnChart.css";

const LineChart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "line",
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data?.dateLabels,
      axisBorder: {
        show: true,
        color: "#fff",
      },
      axisTicks: {
        show: false,
        color: "#fff",
      },
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#fff",
      },
      axisTicks: {
        show: false,
        color: "#fff",
      },
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    legend: {
      position: "top",
    },
    stroke: {
      curve: "straight",
    },
  });

  const [series, setSeries] = useState([
    {
      name: "New User",
      data: data?.allUserCounts,
      color: "#ac5ac3",
    },
    {
      name: "Active User",
      data: data?.newUserCounts,
      color: "#00cadc",
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "New User",
        data: data?.allUserCounts,
        color: "#ac5ac3",
      },
      {
        name: "Active User",
        data: data?.newUserCounts,
        color: "#00cadc",
      },
    ]);

    setOptions({
      chart: {
        id: "line",
        type: "line",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data?.dateLabels,
        axisBorder: {
          show: true,
          color: "#fff",
        },
        axisTicks: {
          show: false,
          color: "#fff",
        },
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#fff",
        },
        axisTicks: {
          show: false,
          color: "#fff",
        },
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      legend: {
        position: "top",
      },
      stroke: {
        curve: "straight",
      },
    });
  }, [data]);

  return (
    <div className="chart-container">
      <div className="chart">
        <Chart
          options={options}
          series={series}
          type="line"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default LineChart;
