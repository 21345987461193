import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider, Box } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { addCoupon, updateCoupon } from "../../state/actions/couponActions";
import dayjs from "dayjs";
const initialValues = {
    title: "",
    code: "",
    Type: "",
    value: "",
    startDate: null,
    endDate: null,
    maximumUsed: "",
    minAmount: "",
    expiry: 0,
    shortDesc: "",
};

const AddCoupon = (props) => {
    const { handleClose, setNotify, couponListsData, editId, page } = props;
    const [isEdit, setIsEdit] = useState(false);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("title" in fieldValues) {
            temp.title = fieldValues.title ? "" : "This field is required.";
        }

        if ("shortDesc" in fieldValues) {
            temp.shortDesc = fieldValues.shortDesc ? "" : "This field is required.";
        }

        if ("code" in fieldValues) {
            temp.code = fieldValues.code ? "" : "This field is required.";
        }

        if ("Type" in fieldValues) {
            if (fieldValues.Type === "") {
                temp.Type = "This field is required.";
            }
            else {
                temp.Type = "";
            }
        }

        if ("value" in fieldValues) {

            //this value in decimal point after 2 digit
            let regex = /^[0-9]+(\.[0-9]{1,2})?$/;

            if (fieldValues.value == "") {
                temp.value = "This field is required.";
            } else if (fieldValues.value && !regex.test(fieldValues.value)) {
                temp.value = "This field should be numeric.";

            } else {
                temp.value = "";
            }
        }

        if ("startDate" in fieldValues) {
            temp.startDate = fieldValues.startDate ? "" : "This field is required.";
        }

        if (fieldValues.expiry !== 1) {
            if ("endDate" in fieldValues) {
                temp.endDate = fieldValues.endDate ? "" : "This field is required.";
            }
        }
        else {
            temp.endDate = "";
        }
        if ("maximumUsed" in fieldValues) {
            let regex = /^[0-9]+(\.[0-9]{1,2})?$/;

            if (fieldValues.maximumUsed == "") {
                temp.maximumUsed = "This field is required.";
            } else if (fieldValues.maximumUsed && !regex.test(fieldValues.maximumUsed)) {
                temp.maximumUsed = "This field should be numeric.";
            } else {
                temp.maximumUsed = "";
            }
        }

        if ("minAmount" in fieldValues) {

            //minimum amount should be numeric
            let regex = /^[0-9]+(\.[0-9]{1,2})?$/;
            if (fieldValues.minAmount == "") {
                temp.minAmount = "This field is required.";
            }
            else if (fieldValues.minAmount && !regex.test(fieldValues.minAmount)) {
                temp.minAmount = "This field should be numeric.";
            }
            else {
                temp.minAmount = "";
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const payload = {
                title: values.title,
                couponCode: values.code,
                discountType: values.Type,
                discountAmount: values.value,
                startDate: values.startDate,
                endDate: values.expiry === 1 ? "null" : values.endDate,
                expiry: values.expiry,
                maximumUsed: values.maximumUsed,
                minAmount: values.minAmount,
                shortDescription: values.shortDesc
            };
            if (isEdit) {
                const res = await updateCoupon(editId.id, payload);
                if (res && res.status === true) {
                    setNotify({
                        isOpen: true,
                        message: res?.message,
                        type: "success",
                    });
                    couponListsData(page);
                    handleClose();
                    resetForm();
                } else {
                    setNotify({
                        isOpen: true,
                        message: res?.message,
                        type: "error",
                    });
                }
            } else {
                const res = await addCoupon(payload);
                if (res && res.status === true) {
                    handleClose();
                    couponListsData(1);
                    setNotify({
                        isOpen: true,
                        message: res?.message || "Added Successfully.",
                        type: "success",
                    });
                    resetForm();
                } else {
                    setNotify({
                        isOpen: true,
                        message: "Failed to add coupon.",
                        type: "error",
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (editId) {
            setIsEdit(true);
            setValues({
                ...values,
                title: editId.title,
                code: editId.couponCode,
                Type: editId.discountType,
                value: editId.discountAmount,
                startDate: editId.startDate,
                endDate: editId.endDate,
                expiry: editId.expiry,
                maximumUsed: editId.maximumUsed,
                minAmount: editId.minAmount,
                shortDesc: editId.shortDescription,
            });
        }
    }, [editId]);

    const DiscountType = [
        { id: "1", name: "Percentage" },
        { id: "0", name: "Flat" },
    ];
    return (
        <>
            <Grid container>
                <Grid item xs={9}>
                    <p className="fontLight"> {isEdit ? "Save" : "Add"} Coupon</p>
                </Grid>
                <Grid item xs={3} className="text-end">
                    <ClearIcon
                        onClick={() => {
                            handleClose();
                            resetForm();
                        }}
                        style={{ cursor: "pointer", color: "#efefef" }}
                    />
                </Grid>
            </Grid>
            <Divider
                sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
                variant="fullWidth"
                style={{
                    marginTop: "0.5rem",
                    borderColor: "white",
                    marginBottom: "1.5rem",
                }}
            />
            <Form onSubmit={handleSubmit}>
                <Controls.Input
                    name="title"
                    onChange={handleInputChange}
                    value={values.title}
                    error={errors.title}
                    label="Title"
                    inputProps={{ maxLength: 100 }}
                />
                <Controls.Input
                    name="shortDesc"
                    onChange={handleInputChange}
                    value={values.shortDesc}
                    error={errors.shortDesc}
                    label="Short Description"
                    inputProps={{ maxLength: 100 }}
                    rows="3"
                />
                <Box sx={{ marginTop: "1rem" }}>
                    <Controls.Input
                        name="code"
                        onChange={handleInputChange}
                        value={values.code}
                        error={errors.code}
                        label="Coupon Code"
                    // inputProps={{ maxLength: 100 }}
                    />
                </Box>

                <Box sx={{ marginTop: "1rem" }}>
                    <Controls.SelectBox
                        name="Type"
                        onChange={handleInputChange}
                        value={values.Type}
                        options={DiscountType}
                        error={errors.Type}
                        label="Select Discount Type"
                    />
                </Box>
                <Box sx={{ marginTop: "1rem" }}>

                    <Controls.Input
                        name="value"
                        onChange={handleInputChange}
                        value={values.value}
                        error={errors.value}
                        label="Discount Value"
                        className="seq-input"
                        i
                        nputProps={{ maxLength: 4 }}
                    />
                </Box>
                <Box sx={{ marginTop: "1rem" }}>
                    <Controls.Date
                        placeholder="Select Start Date"
                        fullWidth
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        value={values.startDate}
                        color={errors.startDate && "#fc9494"}
                        onChange={(e) => {
                            setValues({
                                ...values,
                                endDate: null,
                                startDate: dayjs(e).format("YYYY-MM-DD"),
                            });
                            setErrors({
                                ...errors,
                                startDate: "",
                            });
                        }}
                    />
                    {errors.startDate && <p style={{ color: "#fc9494" }} className="error">{errors.startDate}</p>}
                </Box>

                {values && values.expiry !== 1 && (<Box sx={{ marginTop: "1rem" }}>
                    <Controls.Date
                        placeholder="Select End Date"
                        fullWidth
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        value={values.endDate}
                        color={errors.endDate && "#fc9494"}
                        onChange={(e) => {
                            setValues({
                                ...values,
                                endDate: dayjs(e).format("YYYY-MM-DD"),
                            });
                            setErrors({
                                ...errors,
                                endDate: "",
                            });
                        }}
                        currentDate={values.startDate}

                    />
                    {errors.endDate && <p style={{ color: "#fc9494" }} className="error">{errors.endDate}</p>}

                </Box>)}

                <Box sx={{ marginTop: "1rem" }}>
                    <Controls.Checkbox
                        name="expiry"
                        onChange={(e) => {
                            handleInputChange({
                                target: {
                                    name: "expiry",
                                    value: e?.target?.checked ? 1 : 0,
                                },
                            });
                        }}
                        value={values.expiry}
                        error={errors.expiry}
                        checked={values.expiry === 1 ? true : false}
                        label={
                            <p style={{ color: "white", marginTop: "2px" }}>
                                No Expiration
                            </p>
                        }
                    />
                </Box>

                <Controls.Input
                    name="maximumUsed"
                    onChange={handleInputChange}
                    value={values.maximumUsed}
                    error={errors.maximumUsed}
                    label="Maximum Used"
                    className="seq-input"
                    inputProps={{ maxLength: 4 }}
                />

                <Box sx={{ marginTop: "1rem" }}>

                    <Controls.Input
                        name="minAmount"
                        onChange={handleInputChange}
                        value={values.minAmount}
                        error={errors.minAmount}
                        label="Minimum Amount"
                        inputProps={{ maxLength: 5 }}
                    />
                </Box>
                <Grid container style={{ marginTop: "1rem" }}>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={7} className="text-end">
                        <Controls.Button
                            text="Cancel"
                            className="cancel-btn"
                            onClick={() => {
                                handleClose();
                                resetForm();
                            }}
                        />
                        <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};

export default AddCoupon;
