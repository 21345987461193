import React, { useEffect, useState, useMemo } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider, Box, Typography } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  addVideoLibrary,
  videoLibraryDetails,
  updateVideoLibrary,
} from "../../state/actions/videoLibraryActions";
import { getUnitDropDownList } from "../../state/actions/unitActions";
import { topicDropdown } from "../../state/actions/topicActions";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledImageUploadWrapper = styled(Box)(() => ({
  border: "1px solid #fff",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid #fff",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",
  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});
const initialValues = {
  unitId: "",
  topicId: "",
  thumbnail: "",
  title: "",
  videoLink: "",
};

const WhiteBorderCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiFormControlLabel-label {
    color: white;
  }
`;

const AddVideoLibrary = (props) => {
  const {
    handleClose,
    open,
    setOpen,
    setNotify,
    VideoLibraryListData,
    editId,
    page,
    setEditId,
  } = props;
  const [image, setImage] = useState("");
  const [thumbImg, setThumbImg] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [allowInTest, setAllowInTest] = useState(0);
  const [demo, setDemo] = useState(false);
  const [unitDropDownList, setUnitDropDownList] = useState([]);
  const [topicDropDownList, setTopicDropDownList] = useState([]);
  const [topicName, setTopicName] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const label = {
    inputProps: { "aria-label": "Allow this video for test mode?" },
  };
  useEffect(() => {
    if (editId) {
      setIsEdit(true);
      getVideoLibraryData(editId.id);
    }
  }, [editId]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("unitId" in fieldValues) {
      temp.unitId = fieldValues.unitId ? "" : "This field is required.";
    }

    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "This field is required.";
    }
    if ("videoLink" in fieldValues) {
      if (fieldValues.videoLink !== "") {
        if (
          /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(
            fieldValues.videoLink
          )
        ) {
          temp.videoLink = "Invalid Vimeo Link";
        } else {
          temp.videoLink = "";
        }
      } else {
        temp.videoLink = "This field is required.";
      }
    }
    if ("videoLink" in fieldValues) {
      temp.videoLink = fieldValues.videoLink ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  console.log("1111111111111111", topicName);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTopicName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const getVideoLibraryData = async (id) => {
    const res = await videoLibraryDetails(id);
    if (res && res.status === true) {
      const body = {
        unitId: res.data.unitId,
        title: res.data.title,
        videoLink: res.data.videoLink,
      };
      setValues(body);
      setAllowInTest(res.data.allowInTest);
      setTopicName(res.data.topicId.split(","));
      setThumbImg(res.data.thumbnail);
      setImage(res.data.thumbnail);
    } else {
      setValues({});
    }
  };

  const getDropDownList = async () => {
    const res = await getUnitDropDownList();
    if (res && res.status === true) {
      setUnitDropDownList(res.data);
    } else {
      setUnitDropDownList([]);
    }
  };
  const topicDropDownListByUnitId = async () => {
    const res = await topicDropdown(values.unitId);
    if (res && res.status === true) {
      // setTopicName([]);
      setTopicDropDownList(res.data);
    } else {
      setTopicDropDownList([]);
      setTopicName([]);
    }
  };
  const resetImage = () => {
    setImage("");
    setThumbImg({});
  };
  const handelCancel = () => {
    resetImage();
    resetForm();
    setOpen(false);
    setIsEdit(false);
    setEditId(null);
    setAllowInTest(0);
    setTopicDropDownList([]);
    setTopicName([]);
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setOpenBreakdrop(true);

      var formdata = new FormData();

      formdata.append("unitId", values.unitId);
      formdata.append("topicId", topicName.toString());
      formdata.append("title", values.title);
      formdata.append("videoLink", values.videoLink);
      formdata.append("allowInTest", allowInTest ? 1 : 0);
      if (thumbImg && thumbImg.name) {
        formdata.append("thumbnail", thumbImg);
      }

      if (isEdit) {
        const res = await updateVideoLibrary(editId.id, formdata);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setOpenBreakdrop(false);
          setAllowInTest(0);
          VideoLibraryListData(1);
          handleClose();
          setIsEdit(false);
          setEditId(null);
          setTopicName([]);
          resetForm();
          resetImage();
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      } else {
        if (thumbImg && thumbImg.name) {
          const res = await addVideoLibrary(formdata);
          if (res && res.status === true) {
            handleClose();
            VideoLibraryListData(1);
            setAllowInTest(0);
            resetForm();
            setTopicName([]);
            resetImage();
            setNotify({
              isOpen: true,
              message: res.message || "Added Successfully.",
              type: "success",
            });
            setOpenBreakdrop(false);
          } else {
            setNotify({
              isOpen: true,
              message: res.message || "Something went wrong.",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Please select image.",
            type: "error",
          });
          setOpenBreakdrop(false);
        }
      }
    }
  };

  useEffect(() => {
    getDropDownList();
    // getDropDownList();
  }, []);
  useMemo(() => {
    if (values.unitId) {
      topicDropDownListByUnitId();
    }
  }, [values.unitId]);
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["thumbnail"];
      return newValues;
    });
  };
  const handelModelClose = () => {
    handleClose();
    resetForm();
    setImage("");
    setIsEdit(false);
    setEditId(null);
    setTopicName([]);
    setTopicDropDownList([]);
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setThumbImg(selected);
      setImage(base64String);
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <Grid container>
        <Grid item xs={9}>
          <p className="fontLight"> {isEdit ? "Save" : "Add"} Video</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <ClearIcon
            onClick={() => handelModelClose()}
            style={{ cursor: "pointer", color: "#efefef" }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
        variant="fullWidth"
        style={{
          marginTop: "0.5rem",

          marginBottom: "1.5rem",
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controls.SelectBox
              name="unitId"
              onChange={handleInputChange}
              value={values.unitId}
              options={unitDropDownList}
              error={errors.unitId}
              label="Select Unit"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel
                sx={{
                  "&.MuiInputLabel-root": {
                    color: "#fff ",
                  },

                  "&.MuiInputLabel-root.Mui-error": {
                    color: "#fc9494",
                  },
                }}
                id="demo-multiple-checkbox-label"
              >
                Select Topic
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="topicId"
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  ".MuiInputLabel-root": {
                    color: "white",
                  },
                  ".MuiSelect-select": {
                    color: "white",
                  },

                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#fc9494",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }}
                multiple
                value={topicName}
                onChange={handleChange}
                input={<OutlinedInput label="Select Topic" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {topicDropDownList.map((name) => (
                  <MenuItem key={name.id} value={name.name}>
                    <Checkbox checked={topicName.indexOf(name.name) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="title"
              className="planExpirationDuration"
              onChange={handleInputChange}
              value={values.title}
              error={errors.title}
              helperText={errors.title}
              label="Video Description"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledImageUploadWrapper>
              <label>
                <StyledInput
                  accept="image/*"
                  id="image-upload"
                  type="file"
                  onChange={handleImageItem}
                  onClick={(event) => {
                    event.currentTarget.value = null;
                  }}
                />
                <StyledIconWrapper
                  sx={
                    image === ""
                      ? {}
                      : {
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }
                  }
                >
                  {image === "" && (
                    <PhotoCameraOutlinedIcon
                      sx={{
                        color: "white",
                        marginTop: "27px",
                        fontSize: "25px",
                      }}
                    />
                  )}
                </StyledIconWrapper>
              </label>
              <Typography sx={{ color: "white", fontSize: "14px" }}>
                Add Photo
              </Typography>
              <Typography
                sx={{
                  color: "whitesmoke",
                  mx: 2,
                  marginBottom: "5px",
                  fontSize: "12px",
                }}
              >
                Allowed *.jpeg, *.jpg, *.png, max size of 2 MB
              </Typography>
              {image != "" && (
                <StyledRemoveButton
                  onClick={(e) => {
                    e.preventDefault();
                    removeImage();
                  }}
                  sx={{
                    color: "#fc9494",
                  }}
                >
                  Remove
                </StyledRemoveButton>
              )}
            </StyledImageUploadWrapper>
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="videoLink"
              className="planExpirationDuration"
              onChange={handleInputChange}
              value={values.videoLink}
              error={errors.videoLink}
              helperText={errors.videoLink}
              label="Video Link"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <WhiteBorderCheckbox
                  checked={Number(allowInTest) == 1 ? true : false}
                  onChange={(e) => {
                    setAllowInTest(e.target.checked);
                  }}
                  name="allowInTest"
                />
              }
              style={{ color: "white" }}
              label="Allow this video on trial mode?"
            />
          </Grid>
          <Grid container style={{ marginTop: "22px" }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} className="text-end">
              <Controls.Button
                text="Cancel"
                className="cancel-btn"
                onClick={() => handelCancel()}
              />
              <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AddVideoLibrary;
