import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Stack } from "@mui/material";
import { FaRegCalendar } from "react-icons/fa6";
import "./datetime.css"

export default function DateRange({ filterDate, setFilterDate }) {

    const range = {
        Today: [moment(), moment()],
        // Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        // "Last 7 Days": [moment().subtract(6, "days"), moment()],
        // "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment()
                .subtract(1, "month")
                .startOf("month"),
            moment()
                .subtract(1, "month")
                .endOf("month")
        ],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        "Last Year": [
            moment()
                .subtract(1, "year")
                .startOf("year"),
            moment()
                .subtract(1, "year")
                .endOf("year")
        ]
    };

    const handleEvent = (event, picker) => {
        if (event.type === "apply") {
            setFilterDate({
                fromDate: picker.startDate.toDate(),
                toDate: moment(picker.endDate.toDate()).format("YYYY-MM-DD") == moment(picker.startDate.toDate()).format("YYYY-MM-DD") ? moment(picker.endDate.toDate()).add(1, 'days').toDate() : picker.endDate.toDate()
            });
        }

    };

    return (
        <div className="date_range">
            <DateRangePicker
                initialSettings={{
                    ranges: range,
                    startDate: range['This Year'][0],
                    endDate: range['This Year'][1],
                }}
                alwaysShowCalendars={true}
                onApply={handleEvent}
            >
                <button style={{ backgroundColor: "transparent", padding: "10px", borderRadius: "10px", color: "white", border: "1px solid white", width: "220px" }} >
                    <Stack flexDirection={'row'} gap={1} alignItems={'center'} >
                        <div className="date_range_icon" style={{ borderRadius: '5px' }}><FaRegCalendar size={24} /></div>
                        <div style={{ lineHeight: 'normal', whiteSpace: 'nowrap' }}>
                            {!filterDate.fromDate ? `Select Date` :
                                `${moment(filterDate.fromDate).format("L")} to ${moment(filterDate.toDate).format("L")}`}
                        </div>
                    </Stack>
                </button>
            </DateRangePicker>
        </div>
    );
}
