import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import Chart from "react-apexcharts";

function PerformenceShelf(props) {
  const { totalresultData } = props;

  var Top5ProductsOptions = {
    series: [0, 0, 0, 100],
    chart: {
      width: 480,
      type: "donut",
      colors: ["red", "green"],
    },
    dataLabels: {
      enabled: false,
      distributed: false,
      style: {
        fontSize: "10px !important",
      },
    },
    options: {
      labels: ["Correct", "Omitted", "Incorrect", "No data"],
      colors: ["#2ecc71", "#FEB019", "#C21E56", "#C21E56"],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Roboto",
                color: "white",
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "13px",
                fontFamily: "Roboto",
                color: "white",
                offsetY: 36,

                formatter: function (val) {
                  console.log("dsdsdsdsdsd", val);

                  if (typeof val === 'number') {
                    return val.toFixed(0) + "%";
                  } else {
                    // Handle the case where val is not a number
                    console.error("val is not a number");
                    return val;
                  }

                },
              },
            },
          },
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },

    series1: [20, 60, 20],
    chart: {
      width: 480,
      type: "donut",
      colors: ["red", "green"],
    },
    dataLabels: {
      enabled: true,
      distributed: false,
      style: {
        fontSize: "10px !important",
      },
    },
    options1: {
      labels: ["Total", "Used", "Unused"],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Roboto",
                color: "white",
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "13px",
                fontFamily: "Roboto",
                color: "white",
                offsetY: 36,

                formatter: function (val) {
                  if (typeof val === 'number') {
                    return val.toFixed(0) + "%";
                  } else {
                    // Handle the case where val is not a number
                    console.error("val is not a number");
                    return val;
                  }
                },
              },
            },
          },
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  const [chartValue, setChartValue] = useState(Top5ProductsOptions);
  const [chartValueQbank, setChartValueQbank] = useState(Top5ProductsOptions);
  useEffect(() => {
    if (totalresultData.totalTest > 0) {
      setChartValue((previousValues) => {
        let newValues = { ...previousValues };
        newValues.series = [
          totalresultData.totalRightAnswerTest,
          totalresultData.totalOmiteQues,
          totalresultData.totalWrongAnswerTest,
        ];
        return newValues;
      });
    }
    setChartValueQbank((previousValues) => {
      let newValues = { ...previousValues };
      newValues.series1 = [
        totalresultData.totalQuestions,
        totalresultData.usedQuestion,
        totalresultData.unUsedQuestion,
      ];
      return newValues;
    });
  }, [totalresultData]);
  return (
    <div
      style={{
        marginTop: "40px",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          lg={5}
          sx={{
            display: { xs: "flex", sm: "block" },
            justifyContent: { xs: "center" },
          }}
        >
          <Chart
            options={{
              ...chartValue.options,
              // theme: { mode: "light" },
            }}
            series={chartValue.series}
            type="donut"
            height="500"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={1}
          sx={{
            display: { xs: "block", sm: "none", md: "none", lg: "block" },
          }}
        ></Grid>
        <Grid item xs={12} sm={4} lg={5}>
          <Typography className="resultHeader">Your Score</Typography>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <p className="fontLights analysisHeadding">Total Correct</p>
            <p className="fontLights ">
              {totalresultData.totalRightAnswerTest}
            </p>
          </Grid>
          <Divider className="divider" />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="fontLights analysisHeadding">Total Incorrect</p>
            <p className="fontLights ">
              {totalresultData.totalWrongAnswerTest}
            </p>
          </Grid>
          <Divider className="divider" />

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="fontLights analysisHeadding">Total Omitted</p>
            <p className="fontLights ">{totalresultData.totalOmiteQues}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          lg={5}
          sx={{
            display: { xs: "flex", sm: "block" },
            justifyContent: { xs: "center" },
          }}
        >
          <Chart
            options={{
              ...chartValueQbank.options1,
              // theme: { mode: "light" },
            }}
            series={chartValueQbank.series1}
            type="donut"
            height="500"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={1}
          sx={{
            display: { xs: "block", sm: "none", md: "none", lg: "block" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          lg={5}
          sx={{ paddingLeft: "20px", paddingRight: "10px" }}
        >
          <Typography className="resultHeader">QBank Usage</Typography>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <p className="fontLights analysisHeadding">Used Questions</p>
            <p className="fontLights ">{totalresultData.usedQuestion}</p>
          </Grid>
          <Divider className="divider" />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="fontLights analysisHeadding">Unused Questions</p>
            <p className="fontLights "> {totalresultData.unUsedQuestion}</p>
          </Grid>
          <Divider className="divider" />

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <p className="fontLights analysisHeadding">Total Questions</p>
            <p className="fontLights ">{totalresultData.totalQuestions}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>
    </div>
  );
}

export default PerformenceShelf;
