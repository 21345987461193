import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Coupon.css";
import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import CouponList from "./CouponList";
import Modal from "@mui/material/Modal";
import AddCoupon from "./AddCoupon";
import ToastNotification from "../../Utils/toastNotification";
import { couponList } from "../../state/actions/couponActions";
import InputAdornment from "@mui/material/InputAdornment";
import { Search as SearchIcon } from "@mui/icons-material";
import { useForm, Form } from "../../Utils/useForm";
import { FiFilter } from "react-icons/fi";
import { Stack, Typography, FormLabel, Divider, Drawer } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import dayjs from "dayjs";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "var(--main-bg)",
    borderRadius: "10px",
    boxShadow: 24,
    minHeight: "50px",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    maxHeight: "100%",
    p: 2,
    overflow: "auto",
};

const initialValues = {
    startDate: "",
    endDate: "",
    status: "",
};
const Coupons = () => {
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const statusOptions = [
        { id: "1", name: "Active" },
        { id: "0", name: "Inactive" },
        { id: "3", name: "Delete" },
    ];
    const [open, setOpen] = useState(false);
    const [couponLists, setCouponLists] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [filterData, setFilterData] = useState(true);
    const [filter, setFilter] = useState(false);
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues);

    const couponListsData = async (pageNum) => {
        setOpenBreakdrop(true);

        let string = "";
        let i = 0;
        for (let [key, value] of Object.entries(values)) {
            string += `&${key}=${value}`;
            i++;
        }
        const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}${string}`;
        const res = await couponList(queryString);
        if (res && res.status === true) {
            setCouponLists(res.data);
            setTotalRecords(res.totalRecord);
            setPage(pageNum);
            setOpenBreakdrop(false);
            setFilter(false);
        } else {
            setCouponLists([]);
            setOpenBreakdrop(false);
        }
    };

    const openFilterDrawer = () => {
        setFilter(true);
    };
    const closeFilterDrawer = () => {
        setFilter(false);
        resetForm();
        setFilterData(!filterData);
    };
    const closeFilterDrawer1 = () => {
        setFilter(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFilterData(!filterData);
    };

    useEffect(() => {
        couponListsData(1);
    }, [search, filterData]);

    return (
        <>
            <BackdropComponent open={openBackdrop} />
            <Grid container>
                <Grid item container xs={12}>
                    <Grid sm={6}>
                        <h2 className="title">Coupons</h2>
                    </Grid>
                    <Grid sm={6}>
                        <Box style={{ float: "right" }}>

                            <Controls.Button
                                startIcon={<AddIcon />}
                                text="Coupon"
                                onClick={handleOpen}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <div
                style={{
                    backgroundColor: "var(--main-bg)",
                    borderRadius: "10px",
                    color: "black",
                }}
            >
                <Grid container xs={12}>
                    <Grid item xs={12} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                        <div className="searchBar">
                            <Controls.Input
                                name="search"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                icon={<SearchIcon />}
                                placeholder="Search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className="borderLight" position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div>
                                <h1> </h1>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        style={{
                            marginTop: "10px",
                        }}
                    >
                        <div>
                            <Controls.Button
                                text="Filter"
                                variant="outlined"
                                startIcon={<FiFilter />}
                                onClick={openFilterDrawer}
                                className="filter-button"
                            />
                        </div>
                    </Grid>
                </Grid>
                <CouponList
                    CouponLists={couponLists}
                    page={page}
                    setPage={setPage}
                    totalRecords={totalRecords}
                    rowsPerPage={rowsPerPage}
                    couponListsData={couponListsData}
                    setNotify={setNotify}
                />
            </div>

            <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
                <form
                    onSubmit={handleSubmit}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        backgroundColor: "var(--main-bg)",
                    }}
                >
                    <Box
                        sx={{
                            width: 300,
                            padding: "10px 20px",
                        }}
                    >
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography className="fontLight" sx={{ fontWeight: "bold" }}>
                                Filter
                            </Typography>

                            <AiOutlineCloseCircle
                                className="close-button fontLight"
                                onClick={closeFilterDrawer1}
                            />
                        </Stack>

                        <Box sx={{ margin: "8px auto" }} style={{}}>
                            <Divider sx={{ mt: 2, color: "white" }} />

                            <Controls.Date

                                placeholder="Select End Date"
                                fullWidth
                                id="startDate"
                                name="startDate"
                                label="Start Date"
                                value={values.startDate}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        startDate: dayjs(e).format("YYYY-MM-DD"),
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={{ margin: "22px auto" }}>
                            <Controls.Date

                                placeholder="Select End Date"
                                fullWidth
                                id="endDate"
                                name="endDate"
                                label="End Date"
                                value={values.endDate}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        endDate: dayjs(e).format("YYYY-MM-DD"),
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={{ margin: "22px auto" }}>
                            <Controls.SelectBox
                                className=""
                                placeholder="Select Status"
                                fullWidth
                                id="status"
                                name="status"
                                label="Status"
                                value={values.status}
                                onChange={handleInputChange}
                                options={statusOptions}
                            />
                        </Box>
                    </Box>
                    <Stack
                        sx={{ margin: "5px 20px 20px 20px" }}
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Controls.Button
                            text="Reset"
                            variant="outlined"
                            onClick={closeFilterDrawer}
                            className="cancel-filter"
                        />

                        <Controls.Button
                            text="Filter"
                            variant="contained"
                            type="submit"
                            className="order-filter-button"
                        />
                    </Stack>
                </form>
            </Drawer>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <AddCoupon
                        handleClose={handleClose}
                        setNotify={setNotify}
                        couponListsData={couponListsData}
                        page={page}
                    />
                </Box>
            </Modal>
            <ToastNotification notify={notify} setNotify={setNotify} />
        </>
    );
}


export default Coupons;
