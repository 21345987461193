import React, { useState } from "react";
import { Box, Paper, Grid, Checkbox, FormControlLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddFaq from "./FaqAdd";
import Moment from "moment";
import { styled } from "@mui/system";
import {
  deleteFaq,
  changeStatus,
  changeLocation,
} from "../../state/actions/faqAction";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const WhiteBorderCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",

  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const FaqList = (props) => {
  const {
    faqList,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    faqListData,
    setNotify,
  } = props;
  const [open, setOpen] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openFaqModel, setOpenFaqModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [editId, setEditId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [faqId, setFaqId] = useState(null);
  const [faqLocation, setFaqLocation] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [status, setStatus] = useState();
  const [faqType, setFaqType] = useState("");

  const handleOpen = () => setOpen(true);
  const handleFaqModel = (name) => {
    setFaqType(name);
    setOpenFaqModel(true);
  };
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseFaq = () => setOpenFaqModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const columns = [
    {
      label: "Question",
    },
    {
      label: "Answer",
    },
    {
      label: "Location",
    },
    {
      label: "Status",
    },
    {
      label: "Actions",
    },
  ];

  const handleChangeLocation = async (e) => {
    const body = {
      faqType: faqType,
    };
    const res = await changeLocation(faqId, body);
    if (res && res.status === true) {
      setFaqLocation(Number(res.data.listType));
      handleCloseFaq();
      faqListData(page);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const handleChangeStatus = async (e) => {
    const payload = {
      status: checked == true ? 1 : 2,
    };
    const res = await changeStatus(switchId, payload);
    if (res && res.status === true) {
      handleCloseSwitch();
      faqListData(page);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteFaq(deleteId);
    if (res && res.status === true) {
      handleCloseDelete();
      if (faqList.length === 1) {
        faqListData(page - 1);
      } else {
        faqListData(page);
      }

      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{ backgroundColor: "var(--main-bg)", color: "#FFFFFF" }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {faqList && faqList.length > 0
              ? faqList.map((faq) => (
                  <StyledTableRow>
                    <TableCell>{faq.title}</TableCell>
                    <TableCell>{faq.content}</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <WhiteBorderCheckbox
                            checked={Number(faq.userFlag) == 1 ? true : false}
                            onChange={(e) => {
                              handleFaqModel("user");
                              setFaqId(faq.id);
                              setChecked(e.target.checked);
                            }}
                            name="user"
                          />
                        }
                        label="User"
                      />
                      <FormControlLabel
                        control={
                          <WhiteBorderCheckbox
                            checked={Number(faq.webFlag) == 1 ? true : false}
                            onChange={(e) => {
                              handleFaqModel("web");
                              setFaqId(faq.id);
                              setChecked(e.target.checked);
                            }}
                            name="web"
                          />
                        }
                        label="Web"
                      />
                    </TableCell>

                    <TableCell>
                      <Switch
                        checked={faq.isActive == 1 ? true : false}
                        onChange={(e) => {
                          handleSwitchModel();
                          setSwitchId(faq.id);
                          setChecked(e.target.checked);
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container gap={2}>
                        <Button
                          style={{
                            minWidth: "40px",
                            backgroundColor: "#1976d2",
                          }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleOpen();
                            setEditId(faq);
                          }}
                        >
                          <EditIcon
                            style={{ fontSize: "20px", color: "#FFF" }}
                          />
                        </Button>
                        <Button
                          style={{
                            minWidth: "40px",
                            backgroundColor: "#e91e63",
                          }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleDeleteModel();
                            setDeleteId(faq.id);
                          }}
                        >
                          <DeleteIcon
                            style={{ fontSize: "20px", color: "#FFF" }}
                          />
                        </Button>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {faqList && faqList.length == 0 ? (
          <div className="noDataFound"> No Data Found</div>
        ) : (
          <TablePagination
            label={
              "Showing " + faqList.length + " of " + totalRecords + " Records"
            }
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              faqListData(newPage);
              setPage(newPage);
            }}
            page={page}
            setPage={setPage}
          />
        )}

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <AddFaq
              handleClose={handleClose}
              faqListData={faqListData}
              page={page}
              editId={editId}
              setNotify={setNotify}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openFaqModel}
          onClose={handleCloseFaq}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style1}>
            <label className="fontLight">
              Are you sure to change FAQ type?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseFaq} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeLocation(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style1}>
            <label className="fontLight">
              Are you sure change status to {status}?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style1}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "#F05454" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default FaqList;
