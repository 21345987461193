import { applyMiddleware, compose, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers/index";
import authReducer from "../state/reducers/authReducer";
import testReducer from "../state/reducers/exam";
import orderReducer from "../state/reducers/order";
import cartReducer from "../state/reducers/cart";
import userReducer from "../state/reducers/userAddress";
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: {
    auth: authReducer,
    test: testReducer,
    order: orderReducer,
    userAddress: userReducer,
    cart: cartReducer,
  },
});
