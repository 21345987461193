import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";

import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import ToastNotification from "../../Utils/toastNotification";
import FaqList from "./FaqList";
import InputAdornment from "@mui/material/InputAdornment";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { Search as SearchIcon } from "@mui/icons-material";
import Faqadd from "./FaqAdd";
import { faqListsAdmin } from "../../state/actions/faqAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
export default function Faq() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [faqList, serFaqList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const faqListData = async (pageNum) => {
    setOpenBreakdrop(true);
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}`;
    const res = await faqListsAdmin(queryString);
    if (res && res.status === true) {
      serFaqList(res.data);

      setTotalRecords(res.totalRecord);
      setPage(pageNum);
      setOpenBreakdrop(false);
    } else {
      serFaqList([]);
      setOpenBreakdrop(false);
    }
  };

  useEffect(() => {
    faqListData(1);
  }, [rowsPerPage, search]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">FAQ List</h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                startIcon={<AddIcon />}
                text="FAQ"
                onClick={handleOpen}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <div className="searchBar">
          <Controls.Input
            name="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            icon={<SearchIcon />}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment className="borderLight" position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <h1> </h1>
          </div>
        </div>
        <FaqList
          faqList={faqList}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          faqListData={faqListData}
          setNotify={setNotify}
        />
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Faqadd
            handleClose={handleClose}
            setNotify={setNotify}
            faqListData={faqListData}
            page={page}
          />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
