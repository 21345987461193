import { useState, useEffect } from "react";

import {
  Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
  Drawer,
} from "@mui/material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Controls from "../../Components/controls/Controls";
import { useForm, Form } from "../../Utils/useForm";
import PriviousTestShelf from "./PriviousTestShelf";
import { Search as SearchIcon } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { previousTestList } from "../../state/actions/previousTestActions";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { FiFilter } from "react-icons/fi";
import Slider from "@mui/material/Slider";
import { useLocation } from "react-router-dom";
const initialValues = {
  mode: "",
  startDate: "",
  endDate: "",
};
const PriviousTest = () => {
  const [search, setSearch] = useState("");
  const { state } = useLocation();
  const [previousTestData, setPreviousTestData] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [value, setValue] = useState([0, 99]);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const openUserModal = () => {
    setAddUser(true);
  };
  const closeUserModal = () => {
    setAddUser(false);
  };

  const TestData = async (pageNum, name = "") => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    const queryString = `?page=${pageNum}&size=${rowsPerPage}${string}&startRange=${value[0]}&endRange=${value[1]}&userId=${state?.userId || ""}`;
    const res = await previousTestList(queryString);
    if (res && res.status === true) {
      setPreviousTestData(res.data);

      setTotalRecords(res.totalRecord);
      setOpenBreakdrop(false);
    } else {
      setPreviousTestData([]);
      setOpenBreakdrop(false);
    }
    setFilter(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);

    resetForm();
    setValue([0, 99]);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };
  useEffect(() => {
    TestData(1, "", "");
  }, [filterData]);

  const statusOptions = [
    { id: "2", name: "Tutor" },
    { id: "1", name: "Timed" },
  ];
  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          className="fontLight"
          sx={{ fontSize: "30px", fontWeight: "bold" }}
        >
          Previous Tests
        </Typography>
      </Stack>

      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: "40px",
        }}
      >
        <Grid
          container
          xs={12}
          style={{
            marginTop: "20px",
            justifyContent: "end",
          }}
        >
          <Grid
            item
            xs={12}
            sm={2.5}
            md={2}
            lg={1}
            xl={1}
            style={{
              marginRight: "1rem",
            }}
          >
            <div>
              <Controls.Button
                text="Filter"
                variant="outlined"
                startIcon={<FiFilter />}
                onClick={openFilterDrawer}
                className="filter-button"
              />
            </div>
          </Grid>
          <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
            <form
              onSubmit={handleSubmit}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "var(--main-bg)",
              }}
            >
              <Box
                sx={{
                  width: 300,
                  padding: "10px 20px",
                }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography className="fontLight" sx={{ fontWeight: "bold" }}>
                    Filter
                  </Typography>

                  <AiOutlineCloseCircle
                    className="close-button fontLight"
                    onClick={closeFilterDrawer1}
                  />
                </Stack>

                <Box sx={{ margin: "22px auto" }}>
                  <Controls.SelectBox
                    className=""
                    placeholder="Select Status"
                    fullWidth
                    id="mode"
                    name="mode"
                    label="Test Mode"
                    value={values.mode}
                    onChange={handleInputChange}
                    options={statusOptions}
                  />
                </Box>
                <Box sx={{ margin: "22px auto" }}>
                  <Controls.Date
                    className=""
                    placeholder="Select From Date"
                    fullWidth
                    id="date"
                    name="startDate"
                    label="From Date"
                    format="YYYY-MM-DD"
                    value={values.startDate}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        startDate: dayjs(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Box>

                <Box sx={{ margin: "22px auto" }}>
                  <Controls.Date
                    className=""
                    placeholder="Select To Date"
                    fullWidth
                    id="date"
                    name="endDate"
                    label="To Date"
                    minDate={values.startDate}
                    value={values.endDate}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        endDate: dayjs(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Box>
                <Box
                  sx={{ margin: "8px auto" }}
                  style={{
                    backgroundColor: "var(--main-bg)",
                  }}
                >
                  <Typography
                    className="fontLight"
                    sx={{ marginBottom: "20px" }}
                  >
                    Score Range
                  </Typography>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Box>
              <Stack
                sx={{ margin: "5px 20px 20px 20px" }}
                flexDirection="row"
                justifyContent="space-between"
              >
                <Controls.Button
                  text="Reset"
                  variant="outlined"
                  onClick={closeFilterDrawer}
                  className="cancel-filter"
                />

                <Controls.Button
                  text="Filter"
                  variant="contained"
                  type="submit"
                  className="order-filter-button"
                />

              </Stack>
            </form>
          </Drawer>
        </Grid>
        <PriviousTestShelf
          previousTestData={previousTestData}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          TestData={TestData}
        />
      </div>
    </>
  );
};
export default PriviousTest;
