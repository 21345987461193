import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "white",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "green",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white",
//     },
//   },
// });
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
    border-color: white;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const Input = (props) => {
  const {
    name,
    value,
    onChange,
    maxRows = 1,
    error = null,
    label,
    style,
    ...other
  } = props;

  const [ClassName, setClassName] = useState("");

  useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <WhiteBorderTextField
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
      multiline
      minRows={maxRows}
      className={ClassName}

      sx={{
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "white",
          },
        },
        "&.Mui-focused .MuiOutlinedInput-root": {
          borderColor: "white",
        },

        mt: 1,
        color: "white",
        borderRadius: "8px",
      }}
      size="small"
      variant="outlined"
      fullWidth
      label={label}
    />
  );
};

export default Input;
