import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";
import { Grid, Card, Box, Button } from "@mui/material";
import { borderRadius } from "@mui/system";
import "./globalTime.css";
import Modal from "@mui/material/Modal";
import {
  GlobalTimeperQue,
  GlobalTimeperDetails,
} from "../../state/actions/globalTimeAction";
import ToastNotification from "../../Utils/toastNotification";
import { useForm } from "../../Utils/useForm";
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  boxShadow: 24,
  p: 2,
};

const initialValues = {
  time: "",
};

function GlobalTime() {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let regex = /^[0-9\b]+$/;

    if ("time" in fieldValues)
      if (fieldValues.time.length > 4) {
        temp.time = "Time per question should be less than 4 digits";
      } else if (fieldValues.time == 0) {
        temp.time = "Time per question is required";
      } else if (!regex.test(fieldValues.time)) {
        temp.time = "Time per question should be numeric";
      } else {
        temp.time = "";
      }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleCloseUpdate = () => setOpenUpdateModel(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    globalTimeperQueDetails();
  }, []);

  const globalTimeperQueDetails = async () => {
    const res = await GlobalTimeperDetails();
    if (res.status === true) {
      setValues({ ...values, time: res.data.value });
    }
  };

  const handelUpdate = async () => {
    const data = {
      value: values.time,
    };
    if (validate()) {
      const res = await GlobalTimeperQue(data);

      if (res.status === true) {
        setEdit(false);
        setNotify({
          isOpen: true,
          message: res.message,
          type: "success",
        });
        setOpenUpdateModel(false);
      } else {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "error",
        });
      }
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          height: "100px",
          backgroundColor: "var(--main-bg)",
          borderRadius: "12px",
        }}
      >
        <Grid item xs={0.2}>
          {" "}
        </Grid>
        <Grid
          item
          xs={2}
          lg={2.7}
          style={{
            display: "flex",
            justifyContent: "left",
            // marginTop: "20px"
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <label className="fontLight">Global Time Per Question</label>
        </Grid>

        <Grid
          item
          xs={3}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            justifyContent: "left",
          }}
        >
          {edit ? (
            <Controls.Input
              name="time"
              className="timePerQuestions"
              onChange={handleInputChange}
              value={values.time}
              // label="Time per question"
              icon="timer"
              inputProps={{ maxLength: 4 }}
              errors={errors.time}
              helperText={errors.time}
            />
          ) : (
            <label
              className="fontLight"
              style={{
                //add underline
                borderBottom: "1px solid #fff",
                paddingBottom: "5px",
                color: "#fff",
                width: "300px",
              }}
            >
              {values.time} Seconds
            </label>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {!edit ? (
            <Button
              style={{ minWidth: "40px", backgroundColor: "#1976d2" }}
              size="small"
              variant="outlined"
              text="Updated"
              className="timePerQuestion updateButton"
              onClick={() => {
                // if (validate()) {
                //     setOpenUpdateModel(true)
                // }
                setEdit(true);
              }}
            >
              <EditIcon style={{ fontSize: "20px", color: "#FFF" }} />
            </Button>
          ) : (
            <Controls.Button
              text="Update"
              type="submit"
              className="timePerQuestion updateButton"
              onClick={() => {
                if (validate()) {
                  setOpenUpdateModel(true);
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={1.7}></Grid>
      </Grid>

      <Modal
        keepMounted
        open={openUpdateModel}
        onClose={handleCloseUpdate}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Are you sure to want to update time per question?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button
                text="Cancel"
                onClick={() => {
                  handleCloseUpdate();
                  setEdit(false);
                }}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Update"
                // style={{ backgroundColor: "red" }}
                onClick={() => handelUpdate()}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default GlobalTime;
