import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import "./infobutton.css";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const Infobutton = ({ text }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#7e80e7",
      color: "#fff",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: "10px",
    },
  }));
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <LightTooltip title={text}>
          {/* <Button variant="contained" {...bindTrigger(popupState)}>
          Open Popover
        </Button> */}
          <InfoIcon
            variant="contained"
            {...bindTrigger(popupState)}
            className="infobutton"
          />
          {/* <Popover
            {...bindPopover(popupState)}
            anchorReference="anchorPosition"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography
              sx={{ p: 2 }}
              style={{
                backgroundColor: "#7e80e7",
                fontSize: "12px",
                fontWeight: "600px",
              }}
            >
              {text}
            </Typography>
          </Popover> */}
        </LightTooltip>
      )}
    </PopupState>
  );
};

export default Infobutton;
