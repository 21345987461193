import React, { useState } from "react";
import '../styles/VideoTable.css';

const data = [
  {
    unit: "Unit 1: Chemistry of Life",
    totalVideoViews: "88",
    totalVideoCompleted: "77",
    totalVideoWatchTime: "12:38:01",
  },
  {
    unit: "Unit 2: Cell Structure & Function",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 3: Cellular Energetics",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 4: Cell Communication & Cell Cycle",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 5: Heredity",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 6: Gene Expression & Regulation",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 7: Natural Selection",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
  {
    unit: "Unit 8: Ecology",
    totalVideoViews: "NN",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "HH:MM:SS",
  },
];

const subdata = [
  {
    unit: "1.1 Structure of water and Hydrogen Bonding",
    totalVideoViews: "2",
    totalVideoCompleted: "0",
    totalVideoWatchTime: "00:40:30",
  },
  {
    unit: "1.2 Elements of Life",
    totalVideoViews: "5",
    totalVideoCompleted: "5",
    totalVideoWatchTime: "00:40:30",
  },
  {
    unit: "1.3 Introduction to Biological Macromolecules",
    totalVideoViews: "1",
    totalVideoCompleted: "0",
    totalVideoWatchTime: "00:00:00",
  },
  {
    unit: "1.4 Properties of Biological Macromolecules",
    totalVideoViews: "1",
    totalVideoCompleted: "0",
    totalVideoWatchTime: "00:00:00",
  },
  {
    unit: "1.5 Structure and Function of Biological Macromolecules",
    totalVideoViews: "1",
    totalVideoCompleted: "0",
    totalVideoWatchTime: "00:00:00",
  },
  {
    unit: "1.6 Nucleic Acids",
    totalVideoViews: "3",
    totalVideoCompleted: "0",
    totalVideoWatchTime: "00:00:00",
  },
];

const Testtable = ({ data }) => {
  const [selectedUnit, setSelectedUnit] = useState(null);
  function formatSecondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600).toFixed(0);
    const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
    const remainingSeconds = Math.floor(seconds % 60).toFixed(0);

    const hoursStr = String(hours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  const handleRowClick = (unitName) => {
    setSelectedUnit(selectedUnit === unitName ? null : unitName);
  };

  return (
    <div className="table-container" style={{ padding: "0px" }}>
      <table className="newtable">
        <thead>
          <tr>
            <th className="tableheader">Unit & Topic</th>
            <th className="tableheader">Total Video Views</th>
            <th className="tableheader">Total Completed Video</th>
            <th className="tableheader">Total Video Watch Time</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 && data.map((item, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => handleRowClick(item.unitName)}
                style={{ backgroundColor: selectedUnit === item.unitName ? "" : "#191e26" }}
                className={selectedUnit === item.unitName ? "selected" : ""}
              >
                <td style={{ textAlign: "left" }} className="tablebody">{item.unitName}</td>
                <td className="tablebody">{item.totalViews}</td>
                <td className="tablebody">{item.totalCompletedVideo}</td>
                <td className="tablebody">{formatSecondsToTime(item.totalWatchTime)}</td>
              </tr>
              {selectedUnit === item.unitName &&
                item && item.topics.map((subItem, subIndex) => (
                  <tr
                    key={`${index}-${subIndex}`}
                    className="subdata"
                    style={{ backgroundColor: "#0d1216" }}
                  >
                    <td style={{ textAlign: "left" }} className="tablebody">{subItem.topicName}</td>
                    <td className="tablebody">{subItem.totalViews}</td>
                    <td className="tablebody">{subItem.totalCompletedVideo}</td>
                    <td className="tablebody">{formatSecondsToTime(subItem.totalWatchTime)}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Testtable;
