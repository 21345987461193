import axiosRequest from "../axios/axios";

import { ORDER_DATA, ORDER_DATA_REMOVE } from "../action-types/order";
export const placeOrder = async () => {
  try {
    const response = await axiosRequest.post(`/api/v1/orderCreate`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const orderCancel = async (id) => {
  try {
    const response = await axiosRequest.post(`/api/v1/cancelOrder/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const orderDispatch = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_DATA,
      payload: payload,
    });
  } catch (e) {}
};
export const orderRemoveDispatch = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_DATA_REMOVE,
      payload: payload,
    });
    return true;
  } catch (e) {}
};

//order list
export const OrderLists = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/orderList${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
