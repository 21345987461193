import React, { useState, useEffect } from "react";
import "./styles/usertable.css";
import Infobutton from "../infobutton/Infobutton";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TopUser from "./TopUser";
import { Checkbox, FormControlLabel } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const TopuserTable = ({ data, getDashboardData, setNotify, users, setUsers, sortConfigUser, setSortConfigUser, mode, setMode }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "var(--main-bg)",
    boxShadow: 24,
    p: 2,
  };

  const handleChange = (e) => {
    if (e.target.value == "") {
      setUsers(0);
      return;
    } else {
      if (e.target.value && e.target.value[0] == "0") {
        setUsers(e.target.value.slice(1));
      } else {
        setUsers(e.target.value);
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addUsers, setAddUsers] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [viewUser, setViewUser] = useState(false);

  const closeModal = () => {
    getDashboardData();
    handleClose();
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 20,
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          Top
          <input
            type="text"
            value={!users ? "10" : users}
            onChange={handleChange}
            style={{
              width: "26px",
              fontWeight: 600,
              fontSize: 20,
              border: "none",
              backgroundColor: "transparent",
              color: "white",
            }}
          />
          Users
          <Infobutton text="This table lists the top 10 users, including their total video views, total completed videos, total tests started, and total completed tests." />
        </div>
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          <FormControlLabel
            control={<Checkbox
              checked={mode.includes(1)}
              style={{ color: "white" }}
              onChange={(e) => {
                if (e.target.checked) {
                  setMode([...mode, 1])
                } else {
                  setMode(mode.filter((item) => item !== 1))
                }
              }}
            />}
            label="Timed Mode Generated Test"
          />
          <FormControlLabel
            control={<Checkbox style={{ color: "white" }}
              checked={mode.includes(2)}
              onChange={(e) => {
                if (e.target.checked) {
                  setMode([...mode, 2])
                } else {
                  setMode(mode.filter((item) => item !== 2))
                }
              }}
            />}
            label="Tutor Mode Generated Test"
          />
        </div>
      </div>
      <div className="user-table-container">
        <table className="userTable">
          <thead>
            <tr>
              <th className="userheader">No</th>
              <th className="userheader" onClick={() => setSortConfigUser({ key: "userName", direction: sortConfigUser && sortConfigUser.key === "userName" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                User Name {sortConfigUser && sortConfigUser.key === "userName" && sortConfigUser.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
              </th>
              <th className="userheader" onClick={() => setSortConfigUser({ key: "totalVideoView", direction: sortConfigUser && sortConfigUser.key === "totalVideoView" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                Total Video Views
                {sortConfigUser && sortConfigUser.key === "totalVideoView" && sortConfigUser.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
              </th>
              <th className="userheader" onClick={() => setSortConfigUser({ key: "totalCompletedVideo", direction: sortConfigUser && sortConfigUser.key === "totalCompletedVideo" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                Total Completed Videos
                {sortConfigUser && sortConfigUser.key === "totalCompletedVideo" && sortConfigUser.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
              </th>
              <th className="userheader" onClick={() => setSortConfigUser({ key: "totaltestStarted", direction: sortConfigUser && sortConfigUser.key === "totaltestStarted" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                Total Tests Started
                {sortConfigUser && sortConfigUser.key === "totaltestStarted" && sortConfigUser.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
              </th>
              <th className="userheader" onClick={() => setSortConfigUser({ key: "totalCompletedTest", direction: sortConfigUser && sortConfigUser.key === "totalCompletedTest" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                Total Completed Tests
                {sortConfigUser && sortConfigUser.key === "totalCompletedTest" && sortConfigUser.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
              </th>
            </tr>
          </thead>
          <tbody>
            {data && data?.map((user, index) => (
              <tr key={user.id} style={{ backgroundColor: "#191e26" }}>
                <td className="userbody"> {index + 1}</td>
                <td
                  style={{ textAlign: "left" }}
                  className="userbody"
                  onClick={(e) => {
                    handleOpen(e);
                    setSelectedId(user.id);
                    setViewUser(true);
                  }}
                >
                  {user.userName}
                </td>
                <td className="userbody " style={{ cursor: "default" }}>{user.totalVideoView}</td>
                <td className="userbody" style={{ cursor: "default" }}>{user.totalCompletedVideo}</td>
                <td className="userbody" style={{ cursor: "default" }}>{user.totaltestStarted}</td>
                <td className="userbody" style={{ cursor: "default" }}>{user.totalCompletedTest}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          open={open}
          keepMounted
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TopUser
              handleClose={handleClose}
              userListData={getDashboardData}
              addUsers={addUsers}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              idEditable={false}
              handleOpen={handleOpen}
              viewUser={viewUser}
              setViewUser={setViewUser}
              setNotify={setNotify}
            />
          </Box>
        </Modal>
        <Link to="/users">
          <button className="see-all-users">See All Users</button>
        </Link>
      </div>
    </div>
  );
};

export default TopuserTable;
