import axiosRequest from "../axios/axios";

export const OverallPerfomance = async (id) => {
  try {
    const res = await axiosRequest.get(`/api/v1/dashboarApiDataUser?userId=${id}`);
    return res.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, message: e.response.data.message };
  }
};
