import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Controls from "../../Components/controls/Controls";
import { Grid, Box, Divider, Typography } from "@mui/material";
import "./test.css";
import {
  getUnitTopicList,
  questionCount,
} from "../../state/actions/testAction";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "../../Utils/useForm";
import { addTest } from "../../state/actions/testAction";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../Utils/toastNotification";
import { updateExpiredSession } from "../../state/actions/sessionActions";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { loadUserApi } from "../../state/actions/authActions";
import Modal from "@mui/material/Modal";
import { testRunning } from "../../state/actions/exam";
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  width: "22%",

  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    border: "1px solid #ffffff",
    height: "40px",
  },
  ".MuiOutlinedInput-root:hover": {
    border: "1px solid #ffffff",
  },
  ".MuiOutlinedInput-root.Mui-focused": {},
}));
const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const initialValues = { noOfQuestions: "", password: "" };

function Test() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("noOfQuestions" in fieldValues) {
      let regex = /^[0-9\b]+$/;
      if (fieldValues.noOfQuestions == "") {
        temp.noOfQuestions = "*required.";
      } else if (!regex.test(fieldValues.noOfQuestions)) {
        temp.noOfQuestions = "Please enter only number.";
      } else {
        temp.noOfQuestions = "";
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [unitTopicList, setUnitTopicList] = useState([]);
  const [unitTopicArray, setUnitTopicArray] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [mode, setMode] = useState("tutor");
  const [planData, setPlanData] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [unitStatus, setUnitStatus] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const { auth } = useSelector((state) => state);
  const [perQuestionTime, setperQuestionTime] = useState(0);

  const getUnitAndTopicList = async () => {
    setOpenBreakdrop(true);
    const res = await getUnitTopicList();
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      const data = res.data.filter((item) => item != null);
      data.map((item) => {
        item.checked = false;
        item.topics.map((topicItem) => {
          topicItem.checked = false;
        });
      });
      setUnitTopicList(data);
    }
  };

  useEffect(() => {
    getUnitAndTopicList();
  }, []);

  const handelModeChange = (e, mode) => {
    if (e.target.checked) {
      setMode(mode);
      setModeStatus(false);
    } else {
      setMode("");
    }
  };

  const payload = [];
  const handleUnitChange = (e, id, i) => {
    setUnitStatus(false);
    if (e.target.checked) {
      setUnitArray([...unitArray, id]);

      setUnitTopicList(
        unitTopicList[i] &&
        unitTopicList[i].topics &&
        unitTopicList[i].topics.map((item) => {
          item.checked = true;
        })
      );
      unitTopicList[i].topics.forEach((item) => {
        payload.push({
          unit: id,
          topic: item.id,
        });
      });

      setUnitTopicArray([...unitTopicArray, ...payload]);
    } else {
      const index = unitArray.findIndex((item) => item === id);
      unitArray.splice(index, 1);
      setUnitArray([...unitArray]);
      setUnitTopicArray(unitTopicArray.filter((item) => item.unit !== id));
      setUnitTopicList(
        unitTopicList[i] &&
        unitTopicList[i].topics &&
        unitTopicList[i].topics.map((item) => {
          item.checked = false;
        })
      );
    }
    const index = unitTopicList.findIndex((item) => item.id === id);
    unitTopicList[index].checked = e.target.checked;

    setUnitTopicList([...unitTopicList]);
  };

  const handelTopicChange = (e, topic, unitId, i) => {
    // if (unitArray.includes(unitId)) {
    if (e.target.checked) {
      setUnitTopicArray([
        ...unitTopicArray,
        {
          unit: unitId,
          topic: topic,
        },
      ]);
      const temps =
        unitTopicList &&
        unitTopicList.map((item) => {
          if (item.id === unitId) {
            item.checked = true;
            item.topics.forEach((topicItem, index) => {
              if (topicItem.id == topic) {
                topicItem.checked = true;
              }
            });
          }
          return item;
        });
      unitArray.push(unitId);
      setUnitArray(unitArray);
      setUnitTopicList(temps);
    } else {
      const index = unitTopicArray.findIndex((item) => item.topic == topic);
      unitTopicArray.splice(index, 1);
      setUnitTopicArray([...unitTopicArray]);

      const temps =
        unitTopicList &&
        unitTopicList.map((item) => {
          if (item.id === unitId) {
            item.topics.forEach((item, index) => {
              if (item.id === topic) {
                item.checked = false;
              }
            });
          }
        });
      //Checking number of remaining checked topics in the unit
      let noMoreTopics = true;
      unitTopicList.forEach((unit) => {
        if (unit.id === unitId) {
          unit.topics.forEach((topic) => {
            if (topic.checked) {
              noMoreTopics = false;
            }
          });
        }
      });
      //Deselecting the unit if no more topics are checked
      if (noMoreTopics) {
        const unitIndex = unitArray.indexOf(unitId);
        const index = unitArray.indexOf(unitId);
        if (index > -1) {
          // only splice array when item is found
          unitArray.splice(index, 1); // 2nd parameter means remove one item only
          const neItems = [...unitArray];
          setUnitArray(neItems);
        } else {
          unitArray.splice(unitIndex, 1);
          setUnitArray([...unitArray]);
        }

        const tempss =
          unitTopicList &&
          unitTopicList.map((keyss) => {
            if (keyss.id === unitId) {
              keyss.checked = false;
            }
            return keyss;
          });
        setUnitTopicList(tempss);
      }
    }
  };

  const modeVaidation = () => {
    if (mode == "") {
      setModeStatus(true);
      unitVaidation();
    }
    if (mode != "") {
      return true;
    }
  };

  const unitVaidation = () => {
    if (unitTopicArray.length == 0) {
      setUnitStatus(true);
    }
    if (unitTopicArray.length > 0) {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modeVaidation() && unitVaidation()) {
      if (validate()) {
        const data = {
          testMode: mode === "tutor" ? 2 : 1,
          unitTopic: unitTopicArray,
          maxQue: values.noOfQuestions
        };
        if (auth?.user?.planDetail?.planPrice == "0.00") {
          const res = await addTest(data);
          if (res && res.status == true) {
            localStorage.setItem('mode', mode);
            localStorage.setItem('queTiming', perQuestionTime);
            dispatch(testRunning(true));
            Navigate("/addTest", {
              state: { mode: mode, queTiming: perQuestionTime },
            });
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "error",
            });
          }
        } else {
          const result = await questionCount(data);
          if (result && result.status == true) {
            const res = await addTest(data);
            if (res && res.status == true) {
              localStorage.setItem('mode', mode);
              localStorage.setItem('queTiming', perQuestionTime);
              dispatch(testRunning(true));
              Navigate("/addTest", {
                state: { mode: mode, queTiming: perQuestionTime },
              });
            } else {
              setNotify({
                isOpen: true,
                message: res.message,
                type: "error",
              });
            }
          } else {
            setNotify({
              isOpen: true,
              message: result.message,
              type: "error",
            });
          }
        }
      }
    }
  };

  // Restrict user if plan is expired
  useEffect(() => {
    if (auth.user === null) {
      dispatch(loadUserApi());
    }
  }, [auth.user, dispatch]);
  const { expirationLeftPeriod = 0 } = auth?.user || {};

  useEffect(() => {
    if (auth.user) {
      if (expirationLeftPeriod <= 0) {
        dispatch(updateExpiredSession(true));
        setPlanData(auth?.user?.planDetail);
        setOpenModel(true);
      } else {
        dispatch(updateExpiredSession(false));
      }
    }
  }, [expirationLeftPeriod, auth.user, dispatch, unitTopicList]);
  // End  Restrict user if plan is expired
  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <Typography
              className="fontLight"
              sx={{ fontSize: "30px", fontWeight: "bold" }}
            >
              Create Test
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div
        className="card"
        style={{
          marginTop: "40px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div className="card-body">
          <Grid container spacing={2} xs={12} sm={12}>
            <Grid item xs={12} sm={4} md={4} lg={6}>
              <h3 className="card-title fontLight">Test Mode</h3>
              <Controls.Switch
                name="tutor"
                inputProps={{ "aria-label": "secondary checkbox" }}
                label={<p className="fontLight"> Tutor</p>}
                onChange={(e) => {
                  handelModeChange(e, "tutor");
                }}
                checked={mode === "tutor"}
              />
              &nbsp; &nbsp;
              <Controls.Switch
                name="timed"
                inputProps={{ "aria-label": "secondary checkbox" }}
                label={<p className="fontLight"> Timed</p>}
                onChange={(e) => {
                  handelModeChange(e, "timed");
                }}
                checked={mode === "timed"}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={6}>
              {unitStatus && (
                <Typography className="fontLight" sx={{ color: "#fc9494" }}>
                  Please select at least one unit and topic
                </Typography>
              )}

              <h3 className="fontLight">Number of Questions</h3>
              <Grid container xs={12} spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: "flex",
                      spacBetween: "10px",
                    }}
                  >
                    <StyledTextField
                      name="noOfQuestions"
                      id="noOfQuestions"
                      inputProps={{ style: { color: "white" } }}
                      onChange={handleInputChange}
                      value={values.noOfQuestions}
                      error={errors.noOfQuestions}
                      helperText={errors.noOfQuestions}
                      required
                    />

                    <Typography
                      className="fontLight"
                      sx={{
                        marginTop: { xs: "15px", sm: "5px", md: "20px", lg: "15px" },
                        marginLeft: "10px",
                      }}
                    >
                      Max allow per test
                    </Typography>
                    <p
                      className="questionCount"
                      style={{
                        marginTop: "16px",
                        marginLeft: "8px",
                        color: "white",
                      }}
                    >
                      {auth?.user?.planDetail?.finalPrice === "0.00" ? "10" : "50"}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Button sx={{
                    fontSize: {
                      sm: "0.60em",
                      md: "0.875em",
                    },
                    minWidth: {
                      sm: "120px",
                      md: "90px"
                    }
                  }} text="Generate Test" onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item xs={12} sm={6}>
            <h3 className="card-title fontLight">Units And Topics</h3>
          </Grid>
          &nbsp;
          <Grid container spacing={2} xs={12} sm={12}>
            {unitTopicList != "" &&
              unitTopicList &&
              unitTopicList.map((item, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          display: "flex",
                        }}
                      >
                        <Controls.Checkbox
                          name="unit"
                          label={
                            <p
                              variant="h6"
                              style={{ color: "white", fontWeight: 900 }}
                            >
                              {item.name}
                            </p>
                          }
                          onChange={(e) => {
                            handleUnitChange(e, item.id, index);
                          }}
                          checked={item.checked}
                          value={item.id}
                          id={item.id}
                        />
                        {/* <p className="questionCount">1</p> */}
                      </Grid>
                      {item.topics &&
                        item.topics.map((topic, i) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{
                                marginLeft: "30px",
                                display: "flex",
                              }}
                            >
                              <Controls.Checkbox
                                name="topic"
                                label={
                                  <p
                                    variant="h6"
                                    style={{
                                      color: "white",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {topic.name} ({topic.questionCount})
                                  </p>
                                }
                                onChange={(e) => {
                                  handelTopicChange(
                                    e,
                                    topic.id,
                                    item.id,
                                    index
                                  );
                                }}
                                checked={
                                  unitArray.includes(item.id)
                                    ? topic.checked
                                    : false
                                }
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {unitStatus && (
            <Typography className="fontLight" sx={{ color: "#fc9494" }}>
              Please select at least one unit and topic
            </Typography>
          )}
          {/* <Divider sx={{ my: 2 }} />
          <h3 className="fontLight">Number of Questions</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  spacBetween: "10px",
                }}
              >
                <StyledTextField
                  name="noOfQuestions"
                  id="noOfQuestions"
                  inputProps={{ style: { color: "white" } }}
                  onChange={handleInputChange}
                  value={values.noOfQuestions}
                  error={errors.noOfQuestions}
                  helperText={errors.noOfQuestions}
                  required
                />

                <p
                  className="fontLight"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                >
                  Max allow per test
                </p>
                <p
                  className="questionCount"
                  style={{
                    marginTop: "16px",
                    marginLeft: "8px",
                    color: "white",
                  }}
                >
                  {auth?.user?.planDetail?.finalPrice === "0.00" ? "10" : "50"}
                </p>
              </Grid>
            </Grid>
          </Grid> */}
          <Divider sx={{ my: 2 }} />
          <Controls.Button sx={{
            fontSize: {
              sm: "0.60em",
              md: "0.875em",
            },
            minWidth: {
              sm: "120px",
              md: "90px"
            }
          }} text="Generate Test" onClick={handleSubmit} />
          {modeStatus && (
            <Typography
              className="fontLight"
              sx={{ color: "#fc9494", marginTop: "5px" }}
            >
              Please select test mode.
            </Typography>
          )}
        </div>
        <ToastNotification notify={notify} setNotify={setNotify} />
      </div>
      <Modal
        keepMounted
        open={openModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div style={{ marginBottom: "1rem" }}>
            {/* <img src={expired} /> */}
          </div>

          <label style={{ fontSize: "25px" }}>
            Your plan has expired, please upgrade.
          </label>

          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} className="">
              {/* <Controls.Button
                text="Buy now"
                onClick={() => Navigate("/buy-now")}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default connect(null, { updateExpiredSession, loadUserApi })(Test);
