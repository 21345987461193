import axiosRequest from "../axios/axios";

export const profileDetails = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/profileDetail`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateProfile = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/updateProfile`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/changePassword`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
