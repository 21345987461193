import React, { useState } from "react";
import "./styles/VideoTable.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// const data = [
//   {
//     unit: "Unit 1: Chemistry of Life",
//     totalVideoViews: "12",
//     totalVideoCompleted: "7",
//     totalVideoWatchTime: "01:38:01",
//     avgVideoWatchTime: "00:06:02",
//   },
//   {
//     unit: "Unit 2: Cell Structure & Function",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 3: Cellular Energetics",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 4: Cell Communication & Cell Cycle",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 5: Heredity",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 6: Gene Expression & Regulation",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 7: Natural Selection",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
//   {
//     unit: "Unit 8: Ecology",
//     totalVideoViews: "NN",
//     totalVideoCompleted: "NN",
//     totalVideoWatchTime: "HH:MM:SS",
//     avgVideoWatchTime: "%",
//   },
// ];

// const subdata = [
//   {
//     unit: "1.2 Elements of Life",
//     totalVideoViews: "5",
//     totalVideoCompleted: "5",
//     totalVideoWatchTime: "00:40:30",
//     avgVideoWatchTime: "00:08:06",
//   },
//   {
//     unit: "1.6 Nucleic Acids",
//     totalVideoViews: "3",
//     totalVideoCompleted: "0",
//     totalVideoWatchTime: "00:00:00",
//     avgVideoWatchTime: "00:00:00",
//   },
//   {
//     unit: "1.1 Structure of water and Hydrogen Bonding",
//     totalVideoViews: "2",
//     totalVideoCompleted: "0",
//     totalVideoWatchTime: "00:40:30",
//     avgVideoWatchTime: "00:00:00",
//   },
//   {
//     unit: "1.3 Introduction to Biological Macromolecules",
//     totalVideoViews: "1",
//     totalVideoCompleted: "0",
//     totalVideoWatchTime: "00:00:00",
//     avgVideoWatchTime: "00:00:00",
//   },
//   {
//     unit: "1.4 Properties of Biological Macromolecules",
//     totalVideoViews: "1",
//     totalVideoCompleted: "0",
//     totalVideoWatchTime: "00:00:00",
//     avgVideoWatchTime: "00:00:00",
//   },
// ];

const Testtable = ({ data, sortConfigTest, setSortConfigTest }) => {
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleRowClick = (unitName) => {
    setSelectedUnit(selectedUnit === unitName ? null : unitName);
  };

  return (
    <div className="">
      <table className="newtable">
        <thead>
          <tr>
            <th className="tableheader" onClick={() => setSortConfigTest({ key: "unitName", direction: sortConfigTest && sortConfigTest.key === "unitName" && sortConfigTest.direction === "asc" ? "desc" : "asc" })}>
              Unit/Topic {sortConfigTest && sortConfigTest.key === "unitName" && sortConfigTest.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader" onClick={() => setSortConfigTest({ key: "totalQuestions", direction: sortConfigTest && sortConfigTest.key === "totalQuestions" && sortConfigTest.direction === "asc" ? "desc" : "asc" })}>
              Total Questions
              {sortConfigTest && sortConfigTest.key === "totalQuestions" && sortConfigTest.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader" onClick={() => setSortConfigTest({ key: "totalCorrect", direction: sortConfigTest && sortConfigTest.key === "totalCorrect" && sortConfigTest.direction === "asc" ? "desc" : "asc" })}>
              Total Correct
              {sortConfigTest && sortConfigTest.key === "totalCorrect" && sortConfigTest.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader" onClick={() => setSortConfigTest({ key: "totalInCorrect", direction: sortConfigTest && sortConfigTest.key === "totalInCorrect" && sortConfigTest.direction === "asc" ? "desc" : "asc" })}>
              Total Incorrect
              {sortConfigTest && sortConfigTest.key === "totalInCorrect" && sortConfigTest.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
            <th className="tableheader" onClick={() => setSortConfigTest({ key: "score", direction: sortConfigTest && sortConfigTest.key === "score" && sortConfigTest.direction === "asc" ? "desc" : "asc" })}>
              Score%
              {sortConfigTest && sortConfigTest.key === "score" && sortConfigTest.direction === "asc" ? <ArrowUpwardIcon className="iconstyle" /> : <ArrowDownwardIcon className="iconstyle" />}
            </th>
          </tr>
        </thead>
        <tbody>
          {data && data?.map((item, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => handleRowClick(item.unitName)}
                style={{ backgroundColor: "#191e26" }}
                className={selectedUnit === item.unitName ? "selected" : ""}
              >
                <td style={{ textAlign: "left" }} className="tablebody">
                  {item.unitName}
                </td>
                <td className="tablebody">{item.totalQuestions}</td>
                <td className="tablebody">{item.totalCorrect}</td>
                <td className="tablebody">{item.totalInCorrect}</td>
                <td className="tablebody">{item.score}</td>
              </tr>
              {selectedUnit === item.unitName &&
                item && item.topics.map((subItem, subIndex) => (
                  <tr
                    key={`${index}-${subIndex}`}
                    className="subdata selected"
                    style={{ backgroundColor: "#0d1216" }}
                  >
                    <td style={{ textAlign: "left" }} className="tablebody">
                      {subItem.topicName}
                    </td>
                    <td className="tablebody">{subItem.totalQuestions}</td>
                    <td className="tablebody">{subItem.totalCorrect}</td>
                    <td className="tablebody">{subItem.totalIncorrect}</td>
                    <td className="tablebody">{subItem.score}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Testtable;
