import React from "react";
import "./Userstatuscard.css";
import Infobutton from "../../infobutton/Infobutton";
import profile_img from "../../../assets/images/profile_img.png";
import { Avatar } from "@mui/material";
import videoview from "../../../assets/images/sidebar_icon/videoView.png";
import complateview from "../../../assets/images/sidebar_icon/Complatevideo.png";
import watchtime from "../../../assets/images/sidebar_icon/watchvideo.png";
import teststart from "../../../assets/images/sidebar_icon/test.png";
import complatedtest from "../../../assets/images/sidebar_icon/complatetest.png";
import coorectans from "../../../assets/images/sidebar_icon/coorectans.png";

const StatusCard = ({ counts, plan, userData }) => {
  function formatSecondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600).toFixed(0);
    const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
    const remainingSeconds = Math.floor(seconds % 60).toFixed(0);

    const hoursStr = String(hours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
  const statusreport = [
    {
      imgsrc: videoview,
      title: "Total Video Views",
      number: counts?.totalVideoViews ?? 0,
    },
    {
      imgsrc: complateview,
      title: "Total Completed Videos",
      number: counts?.totalVideoCompleted ?? 0,
    },
    {
      imgsrc: watchtime,
      title: "Total Video Watch Time",
      number: counts?.totalVideoWatchTime
        ? formatSecondsToTime(counts.totalVideoWatchTime)
        : "00:00:00",
    },
    {
      imgsrc: teststart,
      title: "Total Tests Started",
      number: counts?.totalCreatedTest ?? 0,
    },
    {
      imgsrc: complatedtest,
      title: "Total Completed Tests",
      number: counts?.totalCompletedTest ?? 0,
    },
    {
      imgsrc: coorectans,
      title: "Total Correct Answers",
      number: counts?.rightAnswerCount ?? 0,
    },
  ];

  return (
    <div className="user-status-first-card">
      <div className="user-details-card">
        <div className="user-header">
          {/* <Avatar
            alt="Remy Sharp"
            src={profile_img}
            sx={{ width: 65, height: 65 }}
            className="user-avatar"
          /> */}
          <div className="user-info">
            <div className="user-role">{userData && userData.name}</div>
          </div>
        </div>
        {/* <div className="user-actions">
          <button className="Button">Top Selected Units</button>
          <button className="Button">Top Selected Topics</button>
          <button className="Button">Highest Score %</button>
        </div> */}
        <div className="subscription-details">
          <div className="subscription-title">Subscription Details: </div>
          <div className="subscription-info">
            <div>
              <span className="bold-text">Plan Type: </span>
              {plan?.planName}
            </div>
            <div>
              <span className="bold-text" style={{ marginRight: "9px" }}>Duration: </span>{""}
              {plan?.planExpirationDuration == 99
                ? "Unlimited"
                : plan?.planExpirationDuration}{" "}
              {plan?.planExpirationDuration == 99
                ? ""
                : plan?.planDurationType == 3
                  ? "Days"
                  : "Months"}
            </div>
            <div>
              <span className="bold-text" style={{ marginRight: "40px" }}>Price: </span>{" "}
              {plan?.planExpirationDuration == 99
                ? "$0.00/Month"
                : `$${plan?.planPrice}/Month`}
            </div>
          </div>
        </div>
      </div>
      <div className="user-status-card">
        {statusreport.map((user, index) => (
          <div key={index} className="status-item">
            <div className="status-item-inner">
              <div className="user-status-icon">
                <img
                  src={user.imgsrc}
                  alt=""
                  style={{ height: "25px", width: "25px" }}
                />
              </div>
              <div className="status-info">
                <div className="status-title">
                  {user.title}
                  {/* <Infobutton className="user-status-infoicon" /> */}
                </div>
                <div className="status-number">{user.number}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusCard;
