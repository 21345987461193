import axiosRequest from "../axios/axios";
import axios from "axios";
import {
  UPDATETESTRESULT,
  STARTUPDATING,
  CLEARTESTARRAY,
} from "../action-types/exam";
import { store } from "../store";
import { addTest } from "./testAction";

export const addExam = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/createExam`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const examList = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/questionListByUser`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const AddTest = (payload) => async (dispatch) => {
  const { test } = store.getState();
  let reduxData = test?.test;
  let newindex = [...reduxData];


  let index = newindex && newindex?.findIndex((item) => item.questionId == payload.questionId);
  if (index > -1) {
    newindex[index] = payload;

  } else {
    newindex = [...newindex, payload];
  }

  console.log("reduxData", newindex);

  // const neItems = reduxData;

  dispatch({
    type: STARTUPDATING,
  });
  dispatch({
    type: UPDATETESTRESULT,
    payload: newindex,
  });
};

export const testSubmit = async (payload) => {
  try {
    // payload.testId = id;

    const response = await axiosRequest.post(`/api/v1/testSubmit`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const suspendTest = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/suspendTest/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

//cleare reduc data
export const clearTestArray = () => async (dispatch) => {
  dispatch({
    type: CLEARTESTARRAY,
  });
};

export const testRunning = (payload) => async (dispatch) => {
  dispatch({
    type: "TEST_RUNNING",
    payload: payload,
  });
};

