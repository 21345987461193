import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  console.log("token", token);

  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
