import axiosRequest from "../axios/axios";

export const payment = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/payment`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

//payment details
export const paymentDetails = async (qry) => {
  try {
    const response = await axiosRequest.get(`/api/v1/paymentDetail/${qry}`);
    return response.data;
  } catch (e) {
    // if (e.request.status === 401) {
    //   window.location.href = "/login";
    // }
    return e.response.data;
  }
};


export const paymentStatus = async (qry, data) => {
  try {
    const response = await axiosRequest.post(`/api/v1/session-status/${qry}`, data);
    return response?.data;
  } catch (e) {
    // if (e.request.status === 401) {
    //   window.location.href = "/login";
    // }
    return e?.response?.data;
  }
}


export const checkoutSession = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/create-checkout-session`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
}
