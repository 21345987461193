import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../action-types/authActionTypes";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
// const BASE_URL = 'http://localhost:4000';
const BASE_URL = process.env.REACT_APP_API_URL;

export const loadUserApi = () => async (dispatch) => {
  if (localStorage.token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.token}`;
  }
  try {
    const res = await axios.get(`${BASE_URL}/api/v1/userDetailsByToken`);
    if (res.data && res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    return { success: false, message: err.response.data.message };
  }
};

export const loginUserApi = (payload, rememberMe) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/v1/login`, payload);
    const body = JSON.stringify(payload);

    if (res.data && res.data.status === true) {
      if (rememberMe === true) {
        cookies.set("token", res.data.data.token, { path: "/" });
        cookies.set("email", payload.email, { path: "/" });
        cookies.set("password", payload.password, { path: "/" });
      } else {
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const logoutApi = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const forgotPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/forgotPassword`,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
export const resetPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/resetPassword `,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
