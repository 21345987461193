import axiosRequest from "../axios/axios";

export const createSubscriber = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/createSubscriber`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
