import axiosRequest from "../axios/axios";
import { USER_DATA, USER_DATA_REMOVE } from "../action-types/userAddress";
export const userList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/userlist${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusUser/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const addUserData = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/addUserByAdmin`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const updateUser = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/updateUser`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteuser = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteuser/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const getCountryDropDownList = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/countrylist`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const stateDropdown = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/statelist/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const userDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/userDetailsProfile`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const updateUserByAdmin = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(
      `/api/v1/updateByAdmin/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const planDropdown = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/plans`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const getUserDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/getDetailByAdmin/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const userDispatch = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DATA,
      payload: payload,
    });
  } catch (e) {}
};
