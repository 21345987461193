import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import {
  Grid,
  Avatar,
  Tooltip,
} from "@mui/material";
import PieChart from "./Charts/Piechart";
import { NewdashboardData } from "../../state/actions/dashBoardAction";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import DateTimepicker from "../../pages/date-time/DateTimepicker";
import BarChart from "./Charts/BarChart";
import VideoTable from "./VideoTable";
import LineChart from "./Charts/LineChart";
import TopuserTable from "./TopuserTable";
import profile_img from "../../assets/images/profile_img.png";
import Infobutton from "../infobutton/Infobutton";
import ColumnChart from "./Charts/ColumnChart";
import Testtable from "./Testtable";
import users_icon from "../../assets/images/sidebar_icon/user.svg";
import notebook from "../../assets/images/sidebar_icon/notebook.png";
import playicon from "../../assets/images/sidebar_icon/playicon.png";
import profile from "../../assets/images/sidebar_icon/profile.png";
import taskcompleted from "../../assets/images/sidebar_icon/task_completed.png";
import UnitChart from "./Charts/UnitChart";
import TypeChart from "./Charts/TypeChart";
import moment from "moment";
import html2canvas from "html2canvas";
import ToastNotification from "../../Utils/toastNotification";


export default function Dashboard() {

  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [active, setActive] = useState("allUsers");
  const [filterDate, setFilterDate] = useState({ fromDate: moment().startOf('year'), toDate: moment().endOf('year'), });
  const [dashboardData, setDashboardData] = useState({});
  const [sortConfigTest, setSortConfigTest] = useState({ key: "", direction: "" });
  const [sortConfigUser, setSortConfigUser] = useState({ key: "", direction: "" });
  const [mode, setMode] = useState([]);
  const [users, setUsers] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const userData = JSON.parse(localStorage.getItem("authUser"));

  const handleToggle = (value) => {
    setActive(value);
  };

  const getDashboardData = async () => {
    setOpenBreakdrop(true);

    let queryString = `?fromDate=${moment(filterDate.fromDate).format("YYYY-MM-DD")}&toDate=${moment(filterDate.toDate).format("YYYY-MM-DD")}&sortFlagForLibrary=${sortConfig.direction}&sortByForLibrary=${sortConfig.key}&sortByForTest=${sortConfigTest.key}&sortFlagForTest=${sortConfigTest.direction}&users=${users}&sortFlagUser=${sortConfigUser.direction}&sortByUser=${sortConfigUser.key}&mode=${JSON.stringify(mode)}`;

    const res = await NewdashboardData(queryString);
    if (res.status == true) {
      setDashboardData(res.data);
      setOpenBreakdrop(false);
    } else {
      setOpenBreakdrop(false);
    }
  }

  useEffect(() => {
    getDashboardData();
  }, [filterDate, sortConfig, users, sortConfigTest, sortConfigUser, mode]);

  const statusreport = [
    {
      title: "Total New Users",
      number: dashboardData && dashboardData.count && dashboardData.count.totalUserCount,
      img: users_icon,
    },
    {
      title: "Active Users",
      number: dashboardData && dashboardData.count && dashboardData.count.totalActiveUserCount,
      img: profile,
    },
    {
      title: "Total Tests Generated",
      number: dashboardData && dashboardData.count && dashboardData.count.testStarted,
      img: notebook,
    },
    {
      title: "Total Tests Completed",
      number: dashboardData && dashboardData.count && dashboardData.count.testComplted,
      img: taskcompleted,
    },
    {
      title: "Total Questions Answered",
      number: dashboardData && dashboardData.count && dashboardData.count.totalQuestionCount,
      img: playicon,
    },
  ];

  const downloadImage = () => {
    const element = document.getElementById('capture');
    element.style.backgroundColor = "#1A1A1A";
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'dashboard-report.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <>
      {/* <BackdropComponent open={openBackdrop} /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Avatar
            alt="Remy Sharp"
            src={profile_img}
            sx={{ width: 65, height: 65 }}
          />
          <div style={{ fontWeight: 700, color: "white", fontSize: "18px" }}>
            Hello, {userData && userData.name}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {/* toggle button */}
          <div className="toggle-container">
            <div
              className={`toggle-button ${active === "allUsers" ? "active" : ""
                }`}
              onClick={() => handleToggle("allUsers")}
            >
              All Users
            </div>
            <div
              className={`toggle-button ${active === "topUsers" ? "active" : ""
                }`}
              onClick={() => handleToggle("topUsers")}
            >
              Top Users
            </div>
          </div>

          <DateTimepicker
            filterDate={filterDate}
            setFilterDate={setFilterDate}
          />
        </div>
      </div>
      <div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              color: "white",
              fontSize: "34px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            BioBuddy Admin Analytics Dashboard
          </div>
          <Tooltip
            title="Download Report"
            style={{
              backgroundColor: "#7e80e7",
              fontSize: "12px",
              fontWeight: "600px",
            }}
          >
            <div
              style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
              onClick={downloadImage}
            >
              Download Report
            </div>
          </Tooltip>
        </div>
        <div id="capture">
          {active === "allUsers" && (
            <div>
              <div className="dashboard-status-card">
                {statusreport.map((user, index) => (
                  <div key={index} className="dashboard-status-item">
                    <div className="dashboard-status-item-inner">
                      <div className="status-icon">
                        <img
                          src={user.img}
                          style={{
                            height: "60px",
                            width: "60px",
                            padding: "10px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div className="dashboard-status-info">
                        <div className="dashboard-status-title">
                          {user.title}
                        </div>
                        <div className="dashboard-status-number">{user.number}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* 31-05-2024 changes */}
              <div>
                {/* user summary */}
                <div>
                  <div
                    style={{
                      height: "400px",
                      padding: "24px",
                      backgroundColor: `var(--main-bg)`,
                      borderRadius: `var(--border-radius)`,
                      boxshadow: `var(--box-shadow)`,
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 20,
                          display: "flex",
                          gap: "10px",
                          color: "white",
                        }}
                      >
                        User Summary
                        <Infobutton text="This chart summarizes user activity over the selected duration, displaying the number of new users and active users within that timeframe." />
                      </div>
                      {/* <DownloadIcon style={{ color: "white", cursor: "pointer" }} /> */}
                    </div>
                    <LineChart data={dashboardData && dashboardData.userChart} />
                  </div>
                </div>
                {/* video lib card */}
                <div>
                  <div
                    style={{
                      height: "100%",
                      padding: "24px",
                      backgroundColor: `var(--main-bg)`,
                      borderRadius: `var(--border-radius)`,
                      boxshadow: `var(--box-shadow)`,
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "1rem",
                          fontWeight: "800",
                          marginBottom: "10px",
                          color: "white",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        Video Library <Infobutton text="This table provides a summary of video library usage, including total video views, total videos completed, total video watch time, and average video watch time for each unit and topic over the selected duration." />
                      </p>
                      {/* <DownloadIcon style={{ color: "white", cursor: "pointer" }} /> */}
                    </div>
                    <div
                      style={{
                        height: "400px",
                      }}
                    >
                      <BarChart data={dashboardData && dashboardData.videoLibraryChart} />
                    </div>
                    {/* tables */}
                    <div
                      style={{
                        // backgroundColor: "#2e4777",
                        borderRadius: "10px",
                        color: "#efefef",
                      }}
                    >
                      <VideoTable setSortConfig={setSortConfig} sortConfig={sortConfig} data={dashboardData && dashboardData.videoLibrarySummary} />
                    </div>
                  </div>
                </div>
              </div>

              {/* create test */}
              <div
                style={{
                  backgroundColor: "#2e4777",
                  borderRadius: "10px",
                  color: "#efefef",
                  marginBottom: "30px",
                }}
              >
                <div style={{ padding: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 20,
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      Create Tests
                      <Infobutton text="This table provides a summary of created tests, including the unit and topic, total questions, total correct answers, total incorrect answers, and the score percentage for each topic." />
                    </div>
                    {/* <DownloadIcon style={{ cursor: "pointer" }} /> */}
                  </div>
                  <div
                    style={{
                      gap: "10px",
                      padding: "24px",
                      display: "flex",
                    }}
                    className="test-chart"
                  >
                    <div
                      style={{ color: "black", width: "100%" }}
                      className="New-columnchart"
                    >
                      <ColumnChart data={dashboardData && dashboardData.creteTestChart} />
                    </div>
                    <div style={{ color: "black", width: "100%" }}>
                      <PieChart tutorModeCount={dashboardData.tutorModeCount} timedModeCount={dashboardData.timedModeCount} />
                      {/* <TypeChart data={dashboardData && dashboardData.topicChart} /> */}

                    </div>
                  </div>
                  <div>
                    <Testtable sortConfigTest={sortConfigTest} setSortConfigTest={setSortConfigTest} data={dashboardData && dashboardData.unitAggregatedData} />
                  </div>
                </div>
              </div>
              {/* top user table */}

              {/* Cards */}
              <Grid container spacing={3} mb={4}></Grid>

            </div>

          )}
          {active === "topUsers" && (
            <div>
              <div
                style={{
                  backgroundColor: "#2e4777",
                  borderRadius: "10px",
                  color: "#efefef",
                  marginBottom: "30px",
                  padding: "20px",
                }}
              >
                <TopuserTable getDashboardData={getDashboardData} data={dashboardData && dashboardData.topTenUsers} users={users} setUsers={setUsers} sortConfigUser={sortConfigUser} setSortConfigUser={setSortConfigUser} mode={mode} setMode={setMode} />

                {/* <div
                  style={{
                    borderRadius: "10px",
                    color: "#efefef",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "1.3rem",
                        fontWeight: "800",
                        marginTop: "20px",
                        marginBottom: "10px",
                        color: "white",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      Unit/Topic <Infobutton text="This chart displays the top 5 units and topics, showing the percentage distribution within the selected duration." />
                    </p>
                  </div>
                  <div
                    style={{
                      gap: "10px",
                      padding: "24px",
                      display: "flex",
                    }}
                    className="test-chart"
                  >
                    <div
                      style={{ color: "black", width: "100%" }}
                      className="New-columnchart"
                    >
                      <UnitChart data={dashboardData && dashboardData.unitChart} />
                    </div>
                    <div style={{ color: "black", width: "100%" }}>
                      <TypeChart data={dashboardData && dashboardData.topicChart} />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
