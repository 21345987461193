import { Box, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Pagination, PaginationItem } from "@mui/material";

export default function TablePaginationBox(props) {
  const { label, numOfPages, onChangePage, page } = props;
  return (
    <Box
      className="pagination-box-container"
      style={{
        paddingLeft: "16px",
        fontSize: "15px",
        fontWeight: "200",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Grid container>
        <Grid item sm={4} xs={6} style={{ marginTop: "7px" }}>
          <label style={{ color: "#bbbbbb" }}>{label}</label>
        </Grid>
        <Grid item sm={8} xs={6}>
          <Pagination
            count={numOfPages}
            page={page}
            shape="rounded"
            color="primary"
            onChange={(event, newPage) => onChangePage(event, newPage)}
            renderItem={(item) => (
              <PaginationItem
                style={{ color: "#fff" }}
                components={{
                  previous: ArrowBackIosNewIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
