import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { addUnit, editUnit } from "../../state/actions/unitActions";

const initialValues = { name: "", sequence: "" };

const AddUnit = (props) => {
  const { handleClose, setNotify, unitListData, editId, page } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("sequence" in fieldValues) {
      let regex = /^[0-9\b]+$/;

      if (fieldValues.sequence != "" && !regex.test(fieldValues.sequence)) {
        temp.sequence = "Only numbers are allowed.";
      } else {
        temp.sequence = "";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        name: values.name,
        sequence: values.sequence,
      };
      if (isEdit) {
        const res = await editUnit(editId.id, payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          unitListData(page);
          handleClose();
          resetForm();
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      } else {
        const res = await addUnit(payload);
        if (res && res.status === true) {
          handleClose();
          unitListData(1);
          setNotify({
            isOpen: true,
            message: res.message || "Added Successfully.",
            type: "success",
          });
          resetForm();
        } else {
          setNotify({
            isOpen: true,
            message: res.message || "Something went wrong.",
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (editId) {
      setIsEdit(true);
      setValues({
        ...values,
        name: editId.name,
        sequence: editId.sequence,
      });
    }
  }, [editId]);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="fontLight"> {isEdit ? "Save" : "Add"} Unit</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <ClearIcon
            onClick={() => {
              handleClose();
              resetForm();
            }}
            style={{ cursor: "pointer", color: "#efefef" }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
        variant="fullWidth"
        style={{
          marginTop: "0.5rem",
          borderColor: "white",
          marginBottom: "1.5rem",
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Controls.Input
          name="name"
          onChange={handleInputChange}
          value={values.name}
          error={errors.name}
          label="Name"
          inputProps={{ maxLength: 100 }}
        />
        <Controls.Input
          name="sequence"
          onChange={handleInputChange}
          value={values.sequence}
          error={errors.sequence}
          label="Sequence"
          className="seq-input"
          inputProps={{ maxLength: 4 }}
        />
        <Grid container style={{ marginTop: "1rem" }}>
          <Grid item xs={5}></Grid>
          <Grid item xs={7} className="text-end">
            <Controls.Button
              text="Cancel"
              className="cancel-btn"
              onClick={() => {
                handleClose();
                resetForm();
              }}
            />
            <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AddUnit;
