import React, { useState } from "react";
import { Box, Paper, Grid, Chip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import moment from "moment";
import AddUser from "../users/AddUser";

const StyledChip = styled(Chip)(() => ({
  height: "25px",
  borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "var(--main-bg)",
  boxShadow: 24,
  p: 2,
};

function OrderLists(props) {
  const {
    orderList,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    OrderListData,
    setNotify,
  } = props;
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  const columns = [
    { id: "planPrice", label: "Order ID" },
    { id: "planName", label: "User Name" },
    { id: "planExpirationDuration", label: "Coupon Code" },
    { id: "planExpirationDuration", label: "Plan Price" },
    { id: "planExpirationDuration", label: "Discount Amount" },
    // { id: "planExpirationDuration", label: "Discount Percentage" },
    { id: "planExpirationDuration", label: "Total" },
    { id: "planExpirationDuration", label: "Payment Status" },
    { id: "planExpirationDuration", label: "Order Status" },
    { id: "finalPrice", label: "Date" },
  ];
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getOrderStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgb(6 165 91)",
        color: "#FFF",
      },

      0: {
        backgroundColor: "#f05454cf",
        color: "#FFF;",
      },
      2: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
      3: {
        backgroundColor: "#F05454",
        color: "#fc9494;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status == 1 ? "Confirmed" : "Failed"}
      />
    );
  };

  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgb(6 165 91)",
        color: "#FFF",
      },

      0: {
        backgroundColor: "#f05454cf",
        color: "#FFF;",
      },
      2: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
      3: {
        backgroundColor: "#f05454cf",
        color: "#FFF;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={
          status == 1
            ? "Paid"
            : status == 2
              ? "Pending"
              : status == 3
                ? "Failed"
                : "Unpaid"
        }
      />
    );
  };

  return (
    <>
      <div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              style={{ backgroundColor: "var(--main-bg)", color: "#FFFFFF" }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    style={{
                      fontWeight: "700",
                      fontSize: "16px",
                      color: "#efefef",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList && orderList.length > 0
                ? orderList.map((order) => (
                  <StyledTableRow>
                    <TableCell>{order.id}</TableCell>
                    <TableCell
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setViewUser(true);
                        setSelectedId(order.userId);
                        handleOpen();
                      }}
                    >
                      {order.name}
                    </TableCell>
                    <TableCell> {order && order.couponCode ? order.couponCode : "-"}</TableCell>
                    <TableCell> {(parseFloat(order.planAmount) + parseFloat(order.discountAmount)).toFixed(2)}</TableCell>
                    <TableCell> {order && order.discountType ? order.discountAmount : "-"}</TableCell>
                    {/* <TableCell> {order && order.discountType == 1 ? order.discountAmount : "-"}</TableCell> */}
                    {/* <TableCell>{order && order.couponDiscount ? "$" : ""}{order && order.couponDiscount ? order.couponDiscount : "-"}</TableCell>
                    <TableCell>{order && order.couponDiscount ? "$" : ""}{order && order.couponDiscount ? order.couponDiscount : "-"}</TableCell> */}
                    <TableCell>${order.planAmount}</TableCell>
                    <TableCell>{getStatus(order.paymentStatus)}</TableCell>
                    <TableCell>{getOrderStatus(order.status)}</TableCell>
                    <TableCell>
                      {moment(order.createdAt).format("MM-DD-YYYY LT")}
                    </TableCell>
                  </StyledTableRow>
                ))
                : null}
            </TableBody>
          </Table>
          {orderList && orderList.length == 0 ? (
            <div className="noDataFound"> No Data Found</div>
          ) : (
            <TablePagination
              label={
                "Showing " +
                orderList.length +
                " of " +
                totalRecords +
                " Records"
              }
              numOfPages={numOfPages}
              onChangePage={(event, newPage) => {
                OrderListData(newPage);
                setPage(newPage);
              }}
              page={page}
              setPage={setPage}
            />
          )}
        </TableContainer>
      </div>

      <Modal
        keepMounted
        open={open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <AddUser
            handleClose={handleClose}
            setNotify={setNotify}
            viewUser={viewUser}
            // userListData={userListData}
            idEditable={false}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        </Box>
      </Modal>
    </>
  );
}

export default OrderLists;
