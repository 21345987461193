import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import moment from "moment";

import {
  Chip,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  Avatar,
} from "@mui/material";
import Select from "@mui/material/Select";
import PieChart from "./Charts/Piechart";

import { styled } from "@mui/system";
import eye from "../../assets/images/eye.svg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import StatusCard from "../statusCard/StatusCard";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Chart from "react-apexcharts";
import { store } from "../../state/store";
import { DashboardData } from "../../state/actions/dashBoardAction";
import Controls from "../controls/Controls";
import BackdropComponent from "../../Components/Backdrop/Backdrop";

import { ExpandMore, ExpandLess } from "@mui/icons-material";
import VideoTable from "./VideoTable";
import TopuserTable from "./TopuserTable";
import profile_img from "../../assets/images/profile_img.png";
import Infobutton from "../infobutton/Infobutton";
import ColumnChart from "./Charts/ColumnChart";
import Testtable from "./Testtable";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#efefef",
  backgroundColor: "#2e4777",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#041d496b",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  " td, th": {
    color: "#efefef",
  },
}));

const data = [
  {
    id: 102,
    fname: "John",
    lname: "Doe",
    email: "john@gmail.com",
    performance: "30 %",
  },
  {
    id: 76,
    fname: "Tom",
    lname: "",
    email: "tom@gmail.com",
    mobile: "9754986336",
    role: 1,
    gender: 1,

    availableStatus: null,
    performance: "60 %",

    isActive: 2,
    isNotificationSend: 1,
  },
  {
    id: 72,
    fname: "Bob",
    lname: "",
    email: "bob@gmail.com",
    mobile: "9824507120",
    role: 1,
    gender: 1,
    availableStatus: null,
    isActive: 1,
    isNotificationSend: 1,
    performance: "80 %",
  },
  {
    id: 58,
    fname: "Mike",
    lname: "",
    email: "mike@outlook.com",
    mobile: "9980846426",
    role: 1,
    gender: 1,
    availableStatus: null,
    isActive: 1,
    isNotificationSend: 1,
    performance: "50 %",
  },
  {
    id: 51,
    fname: "David",
    lname: "",
    email: "mailto:david@gmail.com",
    mobile: "3197349659",
    role: 1,
    gender: 1,
    availableStatus: null,
    isActive: 1,
    isNotificationSend: 1,
    performance: "80 %",
  },
];

const data1 = [
  {
    id: 1,
    name: "Unit 1",
    count: 5,
  },
  {
    id: 2,
    name: "Unit 2",
    count: 5,
  },
  {
    id: 3,
    name: "Unit 3",
    count: 5,
  },
  {
    id: 4,
    name: "Unit 4",
    count: 5,
  },
  {
    id: 5,
    name: "Unit 5",
    count: 5,
  },
];

const data2 = [
  {
    id: 1,
    name: "Topic 1",
    count: 5,
  },
  {
    id: 2,
    name: "Topic 2",
    count: 5,
  },
  {
    id: 3,
    name: "Topic 3",
    count: 5,
  },
  {
    id: 4,
    name: "Topic 4",
    count: 5,
  },
  {
    id: 5,
    name: "Topic 5",
    count: 5,
  },
];

const filterList = [
  {
    id: 1,
    name: "This Week",
  },
  {
    id: 2,
    name: "Previous Week",
  },
  {
    id: 3,
    name: "This Month",
  },
  {
    id: 4,
    name: "Previous Month",
  },
  {
    id: 5,

    name: "This Quarter",
  },
  {
    id: 6,
    name: "Previous Quarter",
  },
  {
    id: 7,
    name: "This Year",
  },
  {
    id: 8,
    name: "Previous Year",
  },
];

const Top5Topics = {
  series: [1, 2, 3, 4, 5],

  chart: {
    width: 380,
    type: "donut",
    foreColor: "red",
    background: "transparent",
  },
  dataLabels: {
    enabled: true,
  },
  options: {
    colors: ["#AE30EC", "#01D5AD", "#6D43EF", "#8A69F4", "#4959E2"],
    labels: ["UNIT-1", "UNIT-2", "UNIT-3", "UNIT-4", "UNIT-5"],
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "Gradient Donut with custom Start-angle",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const Top5Units = {
  series: [1, 2, 3, 4, 5],

  chart: {
    width: 380,
    type: "donut",
    foreColor: "red",
    background: "transparent",
  },
  dataLabels: {
    enabled: true,
  },
  options: {
    colors: ["#AE30EC", "#01D5AD", "#6D43EF", "#8A69F4", "#4959E2"],
    labels: ["UNIT-1", "UNIT-2", "UNIT-3", "UNIT-4", "UNIT-5"],
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: "Gradient Donut with custom Start-angle",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const chartOptions = {
  series: [
    {
      name: "SubScriptions",
      data: [40, 70, 20, 90, 36, 80, 30, 91, 60],
    },
    {
      name: "Free",
      data: [40, 30, 70, 80, 40, 16, 40, 20, 51],
    },
  ],
  options: {
    colors: ["#007aff", "#282D26"],

    chart: {
      toolbar: {
        show: true,
      },
      foreColor: "#ffffff",
      type: "line",
      zoom: {
        enabled: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    labels: {
      fontColor: "white",
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [],
    },

    legend: {
      position: "top",
      align: "left",
      labels: {
        fontColor: "white",
      },
    },
    grid: {
      show: true,
    },
  },
};

const chartOptions1 = {
  series: [],
  options: {
    colors: ["#007aff"],
    chart: {
      toolbar: {
        show: true,
      },
      type: "bar",
      foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          // return "$ " + val + " thousands";
          return val;
        },
      },
    },
  },
};
const chartOptions2 = {
  series: [],
  options: {
    colors: ["#007aff"],
    chart: {
      toolbar: {
        show: true,
      },
      type: "bar",
      foreColor: "#ffffff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          // return "$ " + val + " thousands";
          return val;
        },
      },
    },
  },
};

const ArealChartoptions = {
  chart: {
    height: 280,
    type: "area",
    foreColor: "#ffffff",
    toolbar: {
      show: true,
    },
  },
  colors: ["#007aff"],
  dataLabels: {
    enabled: false,
  },
  series: [
    {
      name: "Series 1",
      data: [45, 52, 38, 45, 19, 23, 2],
    },
  ],
  fill: {
    type: "gradient",

    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100],
    },
  },
  xaxis: {
    categories: [],
  },
};
export default function Dashboard() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [nurseAnchorEl, setNurseAnchorEl] = useState(null);
  const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [users, setUsers] = useState(data);
  const [units, setUnits] = useState(data1);
  const [topic, setTopic] = useState(data2);
  const [userView, setUserView] = useState(false);
  const totalMenus = 6;
  const [menu, setMenus] = useState(3);
  const [top5units, setTop5units] = useState(Top5Units);
  const [top5topics, setTop5topics] = useState(Top5Topics);
  const [unitChartVisible, setunitChartVisible] = useState(true);
  const [BarChartVisible, setBarChartVisible] = useState(true);
  const [LineChartVisible, setLineChartVisible] = useState(true);
  const [topicChartVisible, setTopicChartVisible] = useState(true);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeuser, setactiveuser] = useState(0);
  const [totaltestcreated, settotaltestcreated] = useState(0);
  const [totalqueans, settotalqueans] = useState(0);

  const [totalquestions, setTotalquestions] = useState(0);
  const [totalTests, setTotalTests] = useState(0);
  const [usersData, setUsersData] = useState(chartOptions);
  const [examData, setExamData] = useState(chartOptions2);
  const [topUnitTopic, setTopUnitTopic] = useState([]);
  const [topTopicQuestion, setTopTopicQuestion] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [subscription, setSubscription] = useState(ArealChartoptions);
  const [orderDataChart, setOrderDataChart] = useState(chartOptions1);

  const [currentMenuIndex, setCurrentMenuIndex] = useState(-1);
  const resetCurrentMenuIndex = () => {
    setCurrentMenuIndex(-1);
  };
  let queryString = `?filterUser=this_month`;
  const userData = JSON.parse(localStorage.getItem("authUser"));

  const { auth } = store.getState();

  const handleMenuChange = (e, value) => {
    setMenus(e.target.value);
    if (e.target.value === 1) {
      queryString = `?filterUser=this_week`;
      getDashboardData(1, queryString);
    }
    if (e.target.value === 2) {
      queryString = `?filterUser=previous_week`;
      getDashboardData(2, queryString);
    }
    if (e.target.value === 3) {
      queryString = `?filterUser=this_month`;
      getDashboardData(3, queryString);
    }
    if (e.target.value === 4) {
      queryString = `?filterUser=previous_month`;
      getDashboardData(4, queryString);
    }
    if (e.target.value === 5) {
      queryString = `?filterUser=this_quarter`;
      getDashboardData(5, queryString);
    }
    if (e.target.value === 6) {
      queryString = `?filterUser=previous_quarter`;
      getDashboardData(6, queryString);
    }
    if (e.target.value === 7) {
      queryString = `?filterUser=this_year`;
      getDashboardData(7, queryString);
    }
    if (e.target.value === 8) {
      queryString = `?filterUser=previous_year`;
      getDashboardData(8, queryString);
    }
  };

  const open = Boolean(anchorEl);

  const handleUserView = () => {
    setUserView(true);
  };

  //handel page is reload then navigate to order page

  const getDashboardData = async (value, queryString) => {
    console.log(value);
    setOpenBreakdrop(true);
    let counts1 = [];
    let counts2 = [];
    const res = await DashboardData(queryString);
    if (res.status == true) {
      setTotalActiveUsers(res.data.totalActiveUser);
      setTotalUsers(res.data.totalUser);
      setTotalquestions(res.data.totalQuestion);
      setTotalTests(res.data.totalTest);
      setTopUnitTopic(res.data.topUnitGraph);
      setTopTopicQuestion(res.data.topTopicGraph);
      setUsers(res.data.userTopCount);

      setTop5units((previousValues) => {
        var newValues = { ...previousValues };
        var seriesArray = [];
        var labels = [];
        var counts = [];
        if (
          res &&
          res.data &&
          res.data.UnitGraph &&
          res.data.UnitGraph.length > 0
        ) {
          res?.data?.UnitGraph?.map((value, index) => {
            labels.push(value.unitName.substring(0, 10) + "...");
            counts.push(value.countUnit);
          });

          setunitChartVisible(true);
        } else {
          seriesArray.push({
            name: "Units",
            data: [0, 0, 0, 0, 0],
          });
          console.log("else");
          setunitChartVisible(false);
        }

        newValues.series = counts;
        newValues.options.labels = labels;
        return newValues;
      });

      setTop5topics((previousValues) => {
        var newValues = { ...previousValues };
        var seriesArray = [];
        var labels = [];
        var counts = [];
        if (
          res &&
          res.data &&
          res.data.topicGraph &&
          res.data.topicGraph.length > 0
        ) {
          res?.data?.topicGraph?.map((value, index) => {
            labels.push(value.topicName.substring(0, 10) + "...");
            counts.push(Number(value.countTopic));
          });
          setTopicChartVisible(true);
        } else {
          seriesArray.push({
            name: "Units",
            data: [0, 0, 0, 0, 0],
          });
          setTopicChartVisible(false);
          console.log("elsesas");
        }

        newValues.series = counts;
        newValues.options.labels = labels;
        return newValues;
      });

      if (value == 1 || value == 2) {
        setUsersData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts1 = [];
          let counts2 = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          if (
            res &&
            res.data &&
            res.data.UserGraph &&
            res.data.UserGraph.length > 0
          ) {
            var date;
            date = moment(res.data.UserGraph[0].weekDates);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            var weekDay = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
              weekDay[startingWeekDate + index] = 0;
            });

            res?.data?.UserGraph?.map((value, index) => {
              const currentDate = moment(value.weekDates).date();
              weekDays[currentDate] = value.subscribed;
              weekDay[currentDate] = value.free;
            });

            labels = weeks.map((value) => value.substring(0, 3));
            counts1 = Object.values(weekDays);
            counts2 = Object.values(weekDay);

            console.log("object Subscribed", counts1);

            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
          } else {
            seriesArray.push(
              {
                name: "Subscribed",
                data: [0, 0, 0, 0, 0, 0, 0],
              },
              {
                name: "Free",
                data: [0, 0, 0, 0, 0, 0, 0],
              }
            );
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setSubscription((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          if (
            res &&
            res.data &&
            res.data.subscriptionGraph &&
            res.data.subscriptionGraph.length > 0
          ) {
            var date;
            date = moment(res.data.subscriptionGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.subscriptionGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countSub);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", labels);
            let counts = Object.values(weekDays);

            seriesArray.push({
              name: "Subscriptions",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Subscriptions",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          newValues.series = seriesArray;
          newValues.xaxis.categories = labels;
          return newValues;
        });

        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var date;
            date = moment(res.data.orderGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.orderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countOrder);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", seriesArray);
            let counts = Object.values(weekDays);

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Orders",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setExamData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let labels = [];
          let counts = [];
          const weeks = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          labels = weeks.map((value) => value.substring(0, 3));

          if (
            res &&
            res.data &&
            res.data.examDataGraph &&
            res.data.examDataGraph.length > 0
          ) {
            var date;
            date = moment(res.data.examDataGraph[0].Date);
            var startingWeekDate = date.date() - date.weekday();
            var weekDays = {};
            new Array(7).fill(0).map((value, index) => {
              weekDays[startingWeekDate + index] = 0;
            });

            res?.data?.examDataGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              weekDays[currentDate] = Number(value.countExam);
            });

            labels = weeks.map((value) => value.substring(0, 3));
            console.log("labels", seriesArray);
            counts = Object.values(weekDays);

            seriesArray.push({
              name: "Exam",
              data: counts,
            });
          } else {
            seriesArray.push({
              name: "Exam",
              data: [0, 0, 0, 0, 0, 0, 0],
            });
          }
          console.log("labels", labels);
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }

      if (value == 3 || value == 4) {
        setUsersData((previousValues) => {
          let newValues = { ...previousValues };

          let seriesArray = [];
          let labels = [];
          if (
            res &&
            res.data &&
            res.data.UserGraph &&
            res.data.UserGraph.length > 0
          ) {
            // if (res.data.length == 0) {

            var dateElement = moment(res.data.UserGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            res?.data?.UserGraph?.map((value, index) => {
              const currentDate = moment(value.months).date();
              counts1[currentDate - 1] = Number(value.subscribed);
              counts2[currentDate - 1] = Number(value.free);

              console.log("subscribedsubscribedsubscribedsubscribed", counts1);
              console.log("freefreefreefreefreefreefreefreefreefree", counts2);
            });

            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
            console.log("lables", labels);
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
            console.log("lables", labels);
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setSubscription((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.subscriptionGraph &&
            res.data.subscriptionGraph.length > 0
          ) {
            var dateElement = moment(res.data.subscriptionGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.subscriptionGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countSub);
            });

            seriesArray.push({
              name: "Subscriptions",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Subscriptions",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          }
          newValues.series = seriesArray;
          newValues.xaxis.categories = labels;
          return newValues;
        });

        setOrderDataChart((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var dateElement = moment(res.data.orderGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.orderGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countOrder);
            });

            seriesArray.push({
              name: "Orders",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Orders",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
        setExamData((previousValues) => {
          var newValues = { ...previousValues };

          var seriesArray = [];
          var labels = [];
          var counts = [];
          if (
            res &&
            res.data &&
            res.data.examDataGraph &&
            res.data.examDataGraph.length > 0
          ) {
            var dateElement = moment(res.data.examDataGraph[0].Date);
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            //  counts array
            counts = new Array(NO_OF_DAYS).fill(0);
            res?.data?.examDataGraph?.map((value, index) => {
              const currentDate = moment(value.Date).date();
              counts[currentDate - 1] = Number(value.countExam);
            });

            seriesArray.push({
              name: "Exam",
              data: counts,
            });
            //  labels array
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          } else {
            var dateElement = moment();
            var month = dateElement.month() + 1;
            var MONTH_SHORT_FORM = dateElement.format("MMM");
            var NO_OF_DAYS = dateElement.daysInMonth();
            counts1 = new Array(NO_OF_DAYS).fill(0);
            counts2 = new Array(NO_OF_DAYS).fill(0);
            seriesArray.push({
              name: "Exam",
              data: counts1,
            });
            new Array(NO_OF_DAYS).fill(0).map((value, index) => {
              index = index + 1;
              index = index < 10 ? "0" + index : index;
              labels.push(`${index}-${month}`);
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }

      if (value == 5 || value == 6) {
        let labels = [];
        let seriesArray = [];
        let counts3 = [];
        let labels2 = [];
        let quarters = {
          1: {
            January: 0,
            February: 0,
            March: 0,
          },
          2: {
            April: 0,
            May: 0,
            June: 0,
          },
          3: { July: 0, August: 0, September: 0 },
          4: {
            October: 0,
            November: 0,
            December: 0,
          },
        };

        setUsersData((previousValues) => {
          let newValues = { ...previousValues };

          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];
          let quarters1 = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };
          let quarters2 = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };

          if (
            res &&
            res.data &&
            res.data.UserGraph &&
            res.data.UserGraph.length > 0
          ) {
            var month = res.data.UserGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.UserGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.subscribed;
              quarters1[quarterNo][value.Date] = value.free;
            });

            labels = Object.keys(quarters[quarterNo]);
            counts1 = Object.values(quarters[quarterNo]);
            counts2 = Object.values(quarters1[quarterNo]);
            console.log("counts1", counts1, "counts2", counts2);
            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
          } else {
            labels = Object.keys(quarters[1]);
            counts1 = Object.values(quarters[1]);
            counts2 = Object.values(quarters1[1]);
            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setSubscription((previousValues) => {
          var newValues = { ...previousValues };

          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];
          let quarters = {
            1: {
              January: 0,
              February: 0,
              March: 0,
            },
            2: {
              April: 0,
              May: 0,
              June: 0,
            },
            3: { July: 0, August: 0, September: 0 },
            4: {
              October: 0,
              November: 0,
              December: 0,
            },
          };

          if (
            res &&
            res.data &&
            res.data.subscriptionGraph &&
            res.data.subscriptionGraph.length > 0
          ) {
            var month = res.data.subscriptionGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.subscriptionGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countSub;
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            seriesArray.push({
              name: "Subscriptions",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[1]);
            counts3 = Object.values(quarters[1]);
            seriesArray.push({
              name: "Subscriptions",
              data: counts3,
            });
          }

          newValues.series = seriesArray;
          newValues.xaxis.categories = labels2;
          return newValues;
        });

        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            var month = res.data.orderGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.orderGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countOrder;
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[3]);
            counts3 = Object.values(quarters[3]);
            seriesArray.push({
              name: "Orders",
              data: counts3,
            });
          }

          newValues.series = seriesArray;
          newValues.options.labels = labels2;
          return newValues;
        });

        setExamData((previousValues) => {
          let newValues = { ...previousValues };
          let labels = [];
          let seriesArray = [];
          let counts3 = [];
          let labels2 = [];

          if (
            res &&
            res.data &&
            res.data.examDataGraph &&
            res.data.examDataGraph.length > 0
          ) {
            var month = res.data.examDataGraph[0].Date;
            var quarterNo;
            Object.keys(quarters).map((quarter_no) => {
              if (month in quarters[quarter_no]) {
                quarterNo = quarter_no;
              }
            });

            res?.data?.examDataGraph?.map((value, index) => {
              quarters[quarterNo][value.Date] = value.countExam;
            });

            labels2 = Object.keys(quarters[quarterNo]);
            counts3 = Object.values(quarters[quarterNo]);
            seriesArray.push({
              name: "Exam",
              data: counts3,
            });
          } else {
            labels2 = Object.keys(quarters[3]);
            counts3 = Object.values(quarters[3]);
            seriesArray.push({
              name: "Exam",
              data: counts3,
            });
          }

          newValues.series = seriesArray;
          newValues.options.labels = labels2;
          return newValues;
        });
      }

      if (value == 7 || value == 8) {
        var months1 = {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        };
        var months2 = {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        };
        let labels = [];
        let seriesArray = [];

        setUsersData((previousValues) => {
          var newValues = { ...previousValues };
          let seriesArray = [];

          if (
            res &&
            res.data &&
            res.data.UserGraph &&
            res.data.UserGraph.length > 0
          ) {
            res?.data?.UserGraph?.map((value, index) => {
              months1[value.YearDates] = value.subscribed;
              months2[value.YearDates] = value.free;
            });
            labels = Object.keys(months1);
            counts1 = Object.values(months1);
            counts2 = Object.values(months2);
            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
          } else {
            labels = Object.keys(months1);
            counts1 = Object.values(months1);
            counts2 = Object.values(months2);
            seriesArray.push(
              {
                name: "Subscribed",
                data: counts1,
              },
              {
                name: "Free",
                data: counts2,
              }
            );
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setOrderDataChart((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.orderGraph &&
            res.data.orderGraph.length > 0
          ) {
            res?.data?.orderGraph?.map((value, index) => {
              months[value.Date] = value.countOrder;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Orders",
              data: counts,
            });
          }
          console.log("seriesArray", seriesArray);
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });

        setSubscription((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.subscriptionGraph &&
            res.data.subscriptionGraph.length > 0
          ) {
            res?.data?.subscriptionGraph?.map((value, index) => {
              months[value.Date] = value.countSub;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Subscriptions",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Subscriptions",
              data: counts,
            });
          }
          newValues.series = seriesArray;
          newValues.xaxis.categories = labels;
          return newValues;
        });
        setExamData((previousValues) => {
          let newValues = { ...previousValues };
          let seriesArray = [];
          let months = {
            January: 0,
            February: 0,
            March: 0,
            April: 0,
            May: 0,
            June: 0,
            July: 0,
            August: 0,
            September: 0,
            October: 0,
            November: 0,
            December: 0,
          };
          let counts = [];
          let labels = [];

          if (
            res &&
            res.data &&
            res.data.examDataGraph &&
            res.data.examDataGraph.length > 0
          ) {
            res?.data?.examDataGraph?.map((value, index) => {
              months[value.Date] = value.countExam;
            });
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Exam",
              data: counts,
            });
          } else {
            labels = Object.keys(months);
            counts = Object.values(months);
            seriesArray.push({
              name: "Exam",
              data: counts,
            });
          }
          newValues.series = seriesArray;
          newValues.options.labels = labels;
          return newValues;
        });
      }
    }
    setOpenBreakdrop(false);
  };

  useEffect(() => {
    getDashboardData(3, queryString);
    setMenus(3);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setNurseAnchorEl(null);
    setEquipmentAnchorEl(null);
  };

  const handleClick = (event, currentUser) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(currentUser);
  };

  const userGraph = [
    { usermonthcount: 13, months: "August" },
    { usermonthcount: 66, months: "September" },
  ];

  const series = [1, 2, 3, 4, 5];
  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Avatar
            alt="Remy Sharp"
            src={profile_img}
            sx={{ width: 65, height: 65 }}
          />
          <div style={{ fontWeight: 700, color: "white", fontSize: "18px" }}>
            Hello, {userData && userData.name}
          </div>
        </div>
        <Grid item xs={1.8} style={{ width: "fit-content" }}>
          <Select
            name="graphFilter"
            style={{
              width: "100%",
              borderRadius: "10px",
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiInputLabel-root": {
                color: "white",
              },
              ".MuiSelect-select": {
                color: "white",
              },

              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },

              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#fc9494",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
            }}
            id="demo-select-small"
            value={Number(menu)}
            onChange={(e, value) => {
              handleMenuChange(e, value);
            }}
          >
            {filterList.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </div>
      <div
        style={{
          fontWeight: 700,
          color: "white",
          fontSize: "34px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        BioBuddy Admin Analytics Dashboard
      </div>
      <Grid container spacing={3} rowSpacing={1}>
        <Grid item xs={9.6}></Grid>
        <Grid item xs={0.6}></Grid>
        <Grid item xs={3}>
          <StatusCard icon={GroupIcon} count={totalUsers} title="Total Users" />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={PersonOutlineIcon}
            count={totalActiveUsers}
            title="Total Active Users"
          />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={QuestionMarkIcon}
            count={totalquestions}
            title="Total Questions"
          />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={PendingActionsIcon}
            count={totalTests}
            title="Total Tests"
          />
        </Grid>
      </Grid>
      {/*  */}
      {/* <Grid container spacing={3} rowSpacing={1}>
        <Grid item xs={9.6}></Grid>
        <Grid item xs={0.6}></Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={GroupIcon}
            count={totalUsers}
            title="Total New Users"
          />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={PersonIcon}
            count={activeuser}
            title="Active Users"
          />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={PersonIcon}
            count={totaltestcreated}
            title="Total Tests Created"
          />
        </Grid>
        <Grid item xs={3}>
          <StatusCard
            icon={PlayCircleFilledIcon}
            count={totalqueans}
            title="Total Questions Answered"
          />
        </Grid>
      </Grid> */}

      {/* Cards */}
      <Grid container spacing={3} mb={4}>
        {/* unit card */}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight">Units</p>

            {unitChartVisible ? (
              <Chart
                options={{
                  ...top5units.options,
                  // theme: { mode: "light" },
                  yaxis: {
                    labels: {
                      formatter: (value) => {
                        return value && value.toFixed(1);
                      },
                    },
                  },
                }}
                series={top5units.series}
                type="donut"
                height="300"
              />
            ) : (
              <div
                className="no-data fontLight"
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontSize: "1.5rem",
                }}
              >
                <p>No data available to view</p>
              </div>
            )}
          </div>
        </Grid>

        {/* topic card */}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight">Topics</p>

            {topicChartVisible ? (
              <Chart
                options={{
                  ...top5topics.options,
                  // theme: { mode: "light" },
                }}
                series={top5topics.series}
                type="donut"
                height="300"
              />
            ) : (
              <div
                className="no-data fontLight"
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontSize: "1.5rem",
                }}
              >
                <p>No data available to view</p>
              </div>
            )}
          </div>
        </Grid>
        {/* total user Card*/}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight">Total Users</p>
            <Chart
              options={{
                ...usersData.options,
                colors: ["#b84644", "#007aff"],
                theme: { mode: "light", foreColor: "white" },
                yaxis: {
                  labels: {
                    formatter: (value) => {
                      return Number(value);
                    },
                  },
                },
              }}
              series={usersData.series}
              type="line"
              height="360"
            />
          </div>
        </Grid>
        {/*exam card  */}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight"> Exam</p>

            <Chart
              options={{
                ...examData.options,
                // theme: { mode: "light" },
              }}
              series={examData.series}
              type="bar"
              height="360"
            />
          </div>
        </Grid>
        {/* subscription card*/}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight"> Subscriptions</p>

            <Chart
              options={subscription}
              series={subscription.series}
              type="area"
              height="360"
            />
          </div>
        </Grid>
        {/* order card */}
        <Grid item xs={6}>
          <div className="card graph-container">
            <p className="chart-header fontLight"> Order</p>

            <Chart
              options={{
                ...orderDataChart.options,
                // theme: { mode: "light" },
              }}
              series={orderDataChart.series}
              type="bar"
              height="360"
            />
          </div>
        </Grid>
        {/* table 1 */}
        <Grid item xs={12}>
          <div
            style={{
              backgroundColor: "#2e4777",
              borderRadius: "10px",
              color: "#efefef",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>Top 5 Users</h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer
                sx={{ minHeight: "fit-content", overflowY: "scroll" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="fontLight">
                        Name
                      </StyledTableCell>
                      <StyledTableCell className="fontLight">
                        Email
                      </StyledTableCell>
                      <StyledTableCell className="fontLight">
                        Performance
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          style={{
                            color: "white",
                          }}
                          sx={{
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="6"
                        >
                          No data available to view
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {users?.map((row, index) => {
                      return (
                        <>
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentUser(row);
                              handleUserView();
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography sx={{ ml: 2 }}>{row.name}</Typography>
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>
                              {row.performance}
                              {"%"}
                            </TableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={() => {
                  handleClose();
                }}
              >
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    handleUserView();
                  }}
                >
                  <ListItemIcon>
                    <img src={eye} alt="View Details" />
                  </ListItemIcon>
                  <ListItemText>View Details</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
          </div>
        </Grid>
        {/* table 2 */}
        <Grid item xs={6}>
          <div
            style={{
              backgroundColor: "#2e4777",
              borderRadius: "10px",
              color: "#efefef",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>Top 5 Units</h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer sx={{ minHeight: "300px", overflowY: "scroll" }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="fontLight">
                        Unit Name
                      </StyledTableCell>
                      <StyledTableCell className="fontLight">
                        Topics
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topUnitTopic?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          style={{
                            color: "white",
                          }}
                          sx={{
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="6"
                        >
                          No data available to view
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {topUnitTopic?.map((row, index) => {
                      return (
                        <>
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCurrentUser(row);
                              handleUserView();
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography sx={{ ml: 2 }}>
                                {row.unitName}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.countTopic}</TableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              backgroundColor: "#2e4777",
              borderRadius: "10px",
              color: "#efefef",
            }}
          >
            <h4 style={{ padding: "20px 0px 0px 10px" }}>Top 5 Topics</h4>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ padding: "20px 0px" }}
            >
              <TableContainer sx={{ minHeight: "300px", overflowY: "scroll" }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="fontLight">
                        Topic Name
                      </StyledTableCell>
                      <StyledTableCell className="fontLight">
                        Questions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topTopicQuestion?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          style={{
                            color: "white",
                          }}
                          sx={{
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="6"
                        >
                          No data available to view
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {topTopicQuestion?.map((row, index) => {
                      return (
                        <>
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography sx={{ ml: 2 }}>
                                {row.topicName}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.countQuestion}</TableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
