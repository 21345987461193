import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Typography, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Controls from "../../Components/controls/Controls";
import PerformenceShelf from "./PerformenceShelf";
import { OverallPerfomance } from "../../state/actions/userDashboardAction";
import { store } from "../../state/store";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
// import expired from "../../assets/images/session-expired.png";
import { updateExpiredSession } from "../../state/actions/sessionActions";
import { loadUserApi } from "../../state/actions/authActions";

const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const Performence = (props) => {
  const [totalresultData, setTotalResultData] = useState([]);
  const [open, setOpen] = useState(false);
  const [planData, setPlanData] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    if (auth.user === null) {
      dispatch(loadUserApi());
    }
  }, [auth.user, dispatch]);

  const { expirationLeftPeriod = 0 } = auth?.user || {};

  useEffect(() => {
    if (auth.user) {
      if (expirationLeftPeriod <= 0) {
        dispatch(updateExpiredSession(true));
        setPlanData(auth?.user?.planDetail);
        // setOpenModel(true);
      } else {
        dispatch(updateExpiredSession(false));
      }
      testTotalResultData();
    }
  }, [expirationLeftPeriod, auth.user, dispatch]);

  const testTotalResultData = async () => {
    const res = await OverallPerfomance(props.userId);
    if (res && res.status == true) {
      setTotalResultData(res.data);
    }
  };

  useEffect(() => {
    testTotalResultData();
  }, []);

  return (
    <>
      {/* <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          className="fontLight"
          sx={{ fontSize: "30px", fontWeight: "bold" }}
        >
          Overall Performance
        </Typography>
      </Stack> */}
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <PerformenceShelf totalresultData={totalresultData} />
      </div>
      <Modal
        keepMounted
        open={openModel}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div style={{ marginBottom: "1rem" }}>
            {/* <img src={expired} /> */}
          </div>

          <label style={{ fontSize: "25px" }}>
            Your plan has expired, please upgrade.
          </label>

          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} className="">
              {/* <Controls.Button
                text="Buy now"
                onClick={() => Navigate("/buy-now")}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(null, { updateExpiredSession, loadUserApi })(
  Performence
);
