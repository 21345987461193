import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router";
import BackdropComponent from "../Components/Backdrop/Backdrop";
const GetEmail = () => {
  const navigate = useNavigate();
  let { email } = useParams();
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  useEffect(() => {
    if (email) {
      navigate("/register", {
        state: { emailId: email },
      });
    }
  });
  return (
    <>
      <BackdropComponent open={openBackdrop} />
    </>
  );
};

export default GetEmail;
