import React from "react";
import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import Signup from "../../pages/signup/Signup";
import UserLogin from "../../pages/userLogin/UserLogin";
import Cart from "../../pages/cart/Cart";
import Price from "../../pages/price/Price";
// import Summary from "../../pages/checkout/Checkout";
import Summary from "../../pages/checkout/Newpage";
import Payment from "../../pages/payment/Payment";

import ResetPassword from "../../pages/userLogin/ResetPassword";
import Confirmation from "../../pages/confirmation/Confirmation";
import { ThankYou } from "../../pages/thankyou/ThankYou";
import Webgl from "../Webgl/Webgl";
const SignupMain = (props) => {
  const { cartItem, setCartItem } = props;
  return (
    <Box
      component="main"
      sx={{ backgroundColor: "revert", mt: 2, }}
    >
      <Routes>
        {
          props.page === "webgl" && (
            <Route
              exact
              path="/"
              element={<Webgl />}
            />
          )
        }
        {props.page === "login" && (
          <Route exact path="/" element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><UserLogin />
          </Box>} />
        )}
        {props.page === "register" && (
          <Route exact path="/" element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><Signup /></Box>} />
        )}
        {props.page === "resetpassword" && (
          <Route exact path="/" element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><ResetPassword /></Box>} />
        )}
        {/* {props.page === "cart" && (
          <Route
            exact
            path="/"
            element={<Cart cartItem={cartItem} setCartItem={setCartItem} />}
          />
        )} */}
        {/* {props.page === "price" && <Route exact path="/" element={<Price />} />} */}
        {/* {
          props.page === "checkout" && (
            <Route
              exact
              path="/"
              element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><Summary cartItem={cartItem} setCartItem={setCartItem} /></Box>}
            />
          )
        } */}
        {/* {
          props.page === "payment" && (
            <Route
              exact
              path="/"
              element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><Payment cartItem={cartItem} setCartItem={setCartItem} /></Box>}
            />
          )
        } */}
        {
          props.page === "confirmation" && (
            <Route
              exact
              path="/"
              element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><Confirmation cartItem={cartItem} setCartItem={setCartItem} /></Box>}
            />
          )
        }
        {
          props.page === "thank-you" && (
            <Route
              exact
              path="/"
              element={<Box sx={{ padding: { xs: '0 16px', md: "0px 25px 10px" } }}><ThankYou cartItem={cartItem} setCartItem={setCartItem} /></Box>}
            />
          )
        }
      </Routes >
    </Box >
  );
};

export default SignupMain;
