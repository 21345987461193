import React, { useState, useEffect } from "react";
import { Box, Paper, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddTopic from "./AddTopic";
import Moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/system";
import { changeStatus, deleteTopic } from "../../state/actions/topicActions";
import { Tooltip } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  overflow: "scroll",
  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const TopicList = (props) => {
  const {
    topicLists,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    topicListData,
    setNotify,
    setSortBy,
    setSortFlag,
    sortFlag,
    sortBy,
  } = props;
  const [open, setOpen] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [editId, setEditId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [fieldName, setFieldName] = useState("");
  const [status, setStatus] = useState();

  const [flagId, setflagId] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [flagDate, setflagDate] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);

  const handleChangeStatus = async (e) => {
    const payload = {
      status: checked == true ? 1 : 0,
    };
    const res = await changeStatus(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "createdAt") {
        topicListData(page, fieldName, flagDate);
      } else if (fieldName == "name") {
        topicListData(page, fieldName, flagName);
      } else if (fieldName == "id") {
        topicListData(page, fieldName, flagId);
      } else {
        topicListData(page, "", "");
      }
      handleCloseSwitch();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteTopic(deleteId);
    if (res && res.status === true) {
      if (fieldName == "createdAt") {
        topicListData(page, fieldName, flagDate);
      } else if (fieldName == "name") {
        topicListData(page, fieldName, flagName);
      } else if (fieldName == "id") {
        topicListData(page, fieldName, flagId);
      } else {
        topicListData(page, "", "");
      }
      handleCloseDelete();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const dataShorting = (name) => {
    if (name == "id") {
      setflagId(!flagId);
      topicListData(page, name, !flagId);
    } else {
      setflagId(false);
      setSortFlag(false);
    }

    if (name == "name") {
      setflagName(!flagName);
      topicListData(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(false);
    }

    if (name == "createdAt") {
      setflagDate(!flagDate);
      topicListData(page, name, !flagDate);
    } else {
      setflagDate(false);
      setSortFlag(false);
    }
    setFieldName(name);
  };
  useEffect(() => {}, [sortFlag]);
  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{ backgroundColor: "var(--main-bg)", color: "#FFFFFF" }}
          >
            <TableRow>
              <TableCell
                onClick={() => dataShorting("id")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Sequence
              </TableCell>
              <TableCell
                onClick={() => dataShorting("name")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Topic Name
                {flagName ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Unit Name
              </TableCell>
              <TableCell
                onClick={() => dataShorting("createdAt")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Created Date
                {flagDate ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topicLists && topicLists.length > 0
              ? topicLists.map((topic, key) => (
                  <StyledTableRow>
                    <TableCell>{topic.sequence}</TableCell>
                    <TableCell>{topic.name}</TableCell>
                    <TableCell>{topic.unitname}</TableCell>
                    <TableCell>
                      {Moment(topic.createdAt).format("MM-DD-YYYY LT")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={topic.isActive == 1 ? true : false}
                        onChange={(e) => {
                          handleSwitchModel();
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                          setSwitchId(topic.id);
                          setChecked(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container gap={2}>
                        <Tooltip title="Edit" placement="bottom">
                          <Button
                            style={{
                              minWidth: "40px",
                              backgroundColor: "#1976d2",
                            }}
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              handleOpen();
                              setEditId(topic);
                            }}
                          >
                            <EditIcon
                              style={{ fontSize: "20px", color: "#FFF" }}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete" placement="bottom">
                          <Button
                            style={{
                              minWidth: "40px",
                              backgroundColor: "#e91e63",
                            }}
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              handleDeleteModel();
                              setDeleteId(topic.id);
                            }}
                          >
                            <DeleteIcon
                              style={{ fontSize: "20px", color: "#FFF" }}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>

        {topicLists && topicLists.length == 0 ? (
          <div className="noDataFound fontLight"> No Data Found</div>
        ) : (
          <TablePagination
            label={
              "Showing " +
              topicLists.length +
              " of " +
              totalRecords +
              " Records"
            }
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              if (fieldName == "createdAt") {
                topicListData(newPage, fieldName, flagDate);
              } else if (fieldName == "name") {
                topicListData(newPage, fieldName, flagName);
              } else if (fieldName == "id") {
                topicListData(newPage, fieldName, flagId);
              } else {
                topicListData(newPage, "", "");
              }
              setPage(newPage);
            }}
            page={page}
            setPage={setPage}
          />
        )}

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <AddTopic
              handleClose={handleClose}
              setNotify={setNotify}
              topicListData={topicListData}
              editId={editId}
              page={page}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default TopicList;
