import React, { useState, useEffect } from "react";

import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

//add date picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//date color change to white

const WhiteBorderDate = styled(DesktopDatePicker)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiSvgIcon-root {
    color: white;
  }
`;

const Date = (props) => {
  const {
    name,
    value,
    onChange,
    color = "",
    maxRows = 1,
    error = null,
    label,
    style,
    currentDate,
    ...other
  } = props;


  const [ClassName, setClassName] = useState("");

  useEffect(() => {
    if (other && other.className) {
      setClassName(ClassName + other.className);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <WhiteBorderDate
        sx={{
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "white",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-root": {
            borderColor: "white",
          },
          "& label.Mui-focused": {
            color: "white",
            borderColor: `${color != "" && color != "undefined" ? "#fc9494" : "white !important"}`,
          },
          "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: `${color != "" && color != "undefined" ? "#fc9494" : "white !important"}`,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: `${color != "" && color != "undefined" ? "#fc9494" : "white !important"}`,
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: `${color != "" && color != "undefined" ? "#fc9494" : "white !important"}`,
          },
          "  & .MuiInputLabel-root": {
            color: `${color != "" && color != "undefined" ? "#fc9494" : "white !important"}`,
          },
          mt: 1,
          borderRadius: "8px",
        }}
        label={label}
        value={value}
        onChange={onChange || ""}
        minDate={currentDate}
        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
        {...(error && { error: true, helperText: error })}
      />
    </LocalizationProvider>
  );
};

export default Date;
