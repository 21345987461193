import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
// import camera from "../../Assets/images/camera.svg";
// import { useForm } from "../../";
import { useNavigate } from "react-router-dom";
// import BackdropComponent from "../BackDrop/BackDrop";
import BackdropComponent from "../Components/Backdrop/Backdrop";
import { useForm } from "../Utils/useForm";
import camera from "../assets/images/camera.svg";
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",

  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

export default function OTPScreen(props) {
  const navigate = useNavigate();
  const { open, refreshScreen, handleClose, paymentStatus } = props;
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const reset = () => {
    resetForm();
  };

  const initialValues = {
    name: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const close = () => {
    handleClose();
    reset();
    if (!paymentStatus) {
      navigate("/buy-now");
    } else {
      navigate("/welcome");
      window.location.reload();
    }
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <Dialog open={open} onClose={close} fullWidth={true} sm={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          &nbsp;
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "auto" }}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
              padding: "20px 10px",
              textAlign: "center",
            }}
          >
            {!paymentStatus ? (
              <>
                <IconButton
                  sx={{
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                >
                  <CloseIcon sx={{ fontSize: "100px", color: "white" }} />
                </IconButton>
                <Typography sx={{ mt: 1, color: "gray" }}>
                  Your Payment has been Faild
                </Typography>
                <StyledLogin sx={{ my: 3 }} onClick={close}>
                  <p>Plese try agian</p>
                </StyledLogin>
              </>
            ) : (
              <>
                <IconButton
                  sx={{
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}
                >
                  <DoneIcon sx={{ fontSize: "100px", color: "white" }} />
                </IconButton>
                <Typography sx={{ mt: 1, color: "gray" }}>
                  Your Payment has been received successfully
                </Typography>
                <StyledLogin sx={{ my: 3 }} onClick={close}>
                  <p>Go to Dashboard</p>
                </StyledLogin>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          {/* <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#FE4646",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#FE4646",
              },
            }}
            type="submit"
            form="addNewCategory"
          >
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
