import axiosRequest from "../axios/axios";

export const DashboardData = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/dashboard` + queryString);
    return res.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, message: e.response.data.message };
  }
};

export const NewdashboardData = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/newDashboard` + queryString);
    return res.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, message: e.response.data.message };
  }
}

export const newDashboardApiDataUser = async (queryString) => {
  try {
    const res = await axiosRequest.get(`/api/v1/newUserDashboard` + queryString);
    return res.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, message: e.response.data.message };
  }
}

