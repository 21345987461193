import { Grid, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";

import InputAdornment from "@mui/material/InputAdornment";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { Search as SearchIcon } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/system";
import { faqListsUser } from "../../state/actions/faqAction";
const StyledTableRow = styled(Typography)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
    borderBottom: 2,
    borderColor: "white",
  },

  color: "#efefef",
  padding: "15px",
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  color: "white",

  backgroundColor: "var(--main-bg)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    color: "white",
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  color: "white",
  backgroundColor: "#041d496b",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FaqUser() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const [faqList, serFaqList] = useState([]);
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const columns = [
    {
      label: "Question",
    },
    {
      label: "Answer",
    },
  ];
  const faqListData = async () => {
    const res = await faqListsUser();
    if (res && res.status === true) {
      serFaqList(res.data);
    } else {
      serFaqList([]);
    }
  };

  useEffect(() => {
    faqListData();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">FAQ List</h2>
          </Grid>
          <Grid sm={6}></Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <div className="searchBar">
          <div>
            <h1> </h1>
          </div>
        </div>
        <Box>
          {faqList && faqList.length > 0
            ? faqList.map((faq, index) => (
                <>
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography>{faq.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: "#041d49e0" }}>
                      <Typography>{faq.content}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            : null}
        </Box>
      </div>
    </>
  );
}
