import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Units.css";
import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import UnitLists from "./UnitLists";
import Modal from "@mui/material/Modal";
import AddUnit from "./AddUnit";
import ToastNotification from "../../Utils/toastNotification";
import { unitList } from "../../state/actions/unitActions";
import InputAdornment from "@mui/material/InputAdornment";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { Search as SearchIcon } from "@mui/icons-material";
import { useForm, Form } from "../../Utils/useForm";
import { Stack, Typography, FormLabel, Divider, Drawer } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { getUnitDropDownList } from "../../state/actions/unitActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#2e4777",
  boxShadow: 24,
  p: 2,
};

const initialValues = {
  status: "",
};

const Units = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const statusOptions = [
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
    { id: "3", name: "Delete" },
  ];
  const [open, setOpen] = useState(false);
  const [unitLists, setUnitLists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const unitListData = async (pageNum, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    setOpenBreakdrop(true);
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}${string}&sortby=${name}&sortFlag=${flag}`;
    const res = await unitList(queryString);
    if (res && res.status === true) {
      setUnitLists(res.data);
      setTotalRecords(res.totalRecord);
      setOpenBreakdrop(false);
      setFilter(false);
    } else {
      setUnitLists([]);
      setOpenBreakdrop(false);
    }
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  useEffect(() => {
    unitListData(page, "", "");
  }, [search, filterData]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Units</h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                startIcon={<AddIcon />}
                text="Unit"
                onClick={handleOpen}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#2e4777",
          }}
        >
          <Box
            sx={{
              width: 300,
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className="fontLight" sx={{ fontWeight: "bold" }}>
                Filter
              </Typography>

              <AiOutlineCloseCircle
                className="close-button fontLight"
                onClick={closeFilterDrawer1}
              />
            </Stack>

            <Box sx={{ margin: "22px auto" }}>
              <Controls.SelectBox
                className=""
                placeholder="Select Status"
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                onChange={handleInputChange}
                options={statusOptions}
              />
            </Box>
          </Box>
          <Stack
            sx={{ margin: "5px 20px 20px 20px" }}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Controls.Button
              text="Reset"
              variant="outlined"
              onClick={closeFilterDrawer}
              className="cancel-filter"
            />

            <Controls.Button
              text="Filter"
              variant="contained"
              type="submit"
              className="order-filter-button"
            />
          </Stack>
        </form>
      </Drawer>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
            <div className="searchBar">
              <Controls.Input
                name="search"
                onChange={(e) => {
                  setTimeout(() => {
                    setSearch(e.target.value);
                  }, 800);
                }}
                icon={<SearchIcon />}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="borderLight" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{
              marginTop: "10px",
            }}
          >
            <div>
              <Controls.Button
                text="Filter"
                variant="outlined"
                startIcon={<FiFilter />}
                onClick={openFilterDrawer}
                className="filter-button"
              />
            </div>
          </Grid>
        </Grid>

        <UnitLists
          unitLists={unitLists}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          unitListData={unitListData}
          setNotify={setNotify}
          setSortBy={setSortBy}
          setSortFlag={setSortFlag}
          sortFlag={sortFlag}
          sortBy={sortBy}
        />
      </div>
      <Modal
        keepMounted
        open={open}
        aria-labelledby="create-auction-zero-step-buyer-aspect"
        aria-describedby="create-auction-zero-step-buyer-aspect-desc"
      >
        <Box sx={style}>
          <AddUnit
            handleClose={handleClose}
            setNotify={setNotify}
            unitListData={unitListData}
          />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Units;
