import React, { useState, useEffect } from "react";
import loginImage from "../../assets/images/login.png";
import CheckIcon from "@mui/icons-material/Check";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  Divider,
  styled,
  useMediaQuery,
  Stack,
} from "@mui/material";
import ToastNotification from "../../Utils/toastNotification";
import { forCartList } from "../../state/actions/planActions";
import { addPlanToCart, cartDispatch, cartRemoveDispatch } from "../../state/actions/cartActions";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./price.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SosOutlined } from "@mui/icons-material";
import { store } from "../../state/store";
import { Helmet } from "react-helmet";
import { orderRemoveDispatch } from "../../state/actions/orderActions";
import { testRunning } from "../../state/actions/exam";
import Banner from "../../Components/Common/Banner";
const steps = ["Plan", "Cart", "Checkout"];


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#48484800",
  borderColor: "white",
  boxShadow:
    "0px 2px 1px -4px white,0px 0px 1px -1px rgb(221 221 221 / 90%), 0px 1px 5px 6px rgb(255 255 255 / 12%)",
  borderRadius: "10px",
  paddingTop: "1rem",
  ...theme.typography.body2,
  overflow: "hidden",
  textAlign: "center",
  color: "white",
  Height: "100%",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "transparent",
  marginTop: "22px",
  marginBottom: "22px",
  textTransform: "capitalize",
  bottom: 0,
  fontWeight: 500,
  color: "white",
  borderColor: "white",
  borderRadius: "0.5rem",
  fontSize: "16px",
  paddingLeft: "1.25rem !important",
  paddingRight: " 1.25rem !important",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  "&:hover": {
    backgroundColor: "#136cf0",
    color: "white",
  },
}));
const Price = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const Navigate = useNavigate();
  const [planLists, setPlanLists] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const { cart } = store.getState();
  const dispatch = useDispatch();
  const planListsData = async () => {
    setOpenBreakdrop(true);
    const res = await forCartList();
    if (res && res.status === true) {
      setPlanLists(res.data);

      // setPage(pageNum);
      setOpenBreakdrop(false);
    } else {
      setPlanLists([]);
      setOpenBreakdrop(false);
    }
  };

  const addToCart = async (item) => {
    const body = {
      cartId: cart?.payload != "" ? cart?.payload : "",
    };
    const res = await addPlanToCart(item.id, body);

    if (res && res.status === true) {
      await dispatch(cartDispatch(res.data.id));

      setTimeout(() => {
        Navigate(`/cart/${item.planKey}`, {
          state: {
            cartId: res.data.id,
          },
        });
      }, 500);
    } else {
    }
  };

  useEffect(() => {
    planListsData();
    handleCart();
  }, []);

  const handleCart = async () => {
    const payload = "";
    const res = await dispatch(orderRemoveDispatch(payload));

    if (res && res == true) {
      const payload = "";
      const resData = await dispatch(cartRemoveDispatch(payload));
      dispatch(testRunning(false));
    }
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy Now Subscription For AP Biology Study Exam | BioBuddy</title>
        <meta name="description" content='12 Months Subscription. 6 Month Subscription. 3 Month Subscription. Hundreds of Practice Questions. Hundreds of Explanatory Videos. 8 Units and 65 Topics.' />
      </Helmet>
      <Banner />
      <Grid
        className="price_grid"
        container
        spacing={1}
        sx={{
          backgroundColor: "#0C0E2E",
          marginTop: { xs: "1rem", md: "2.5rem" },
          marginBottom: { xs: "5rem", md: "5rem" },
          paddingTop: { xs: "2rem", md: "2rem" },
          paddingBottom: { xs: "2rem", md: "2rem" },
          paddingLeft: { xs: "10px", md: "10px" },
          paddingRight: { xs: "20px", md: "20px" },
          width: { xs: "fit-content", md: "85%" },
          margin: 'auto',
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#1976D2", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },

                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "grey.500", // circle's number (ACTIVE)
                  },

                  "& .MuiStepIcon-root": {
                    color: "grey", // circle's number (ACTIVE)
                  },
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "2rem" }}>
          <Grid container spacing={5} sx={{ justifyContent: "center" }}>
            {planLists &&
              planLists.map((item) => {
                return (
                  <Grid item md={3} xs={12} sm={6}>
                    <Item>
                      <h1 className="font-md plan-price">
                        ${item.planPrice}/mo
                      </h1>
                      <p className=" plan-name">
                        {item.planExpirationDuration} Months Subscription
                      </p>
                      <h1 className=" plan-duration">
                        {item.dayCount} Day Access
                      </h1>

                      <ul className="plan-list">
                        {item.planDescriptions &&
                          item.planDescriptions.map((detail) => {
                            return (
                              <li className=" check ">
                                <CheckIcon
                                  sx={{
                                    fill: "green",
                                    fontSize: "17px",
                                    margin: "1px 6px -2px 0px;",
                                  }}
                                />
                                {detail.subValue}
                              </li>
                            );
                          })}
                      </ul>

                      {/* <StyledLogin
                        sx={{ border: 1 }}
                        onClick={() => addToCart(item)}
                      >
                        Purchase Now
                      </StyledLogin> */}
                    </Item>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Grid>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Price;
