import React, { useState } from "react";
import { Box } from "@mui/material";
import SignupMain from "./signupMain";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
const SignupLayout = (props) => {
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  const [cartItem, setCartItem] = useState(0);
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#131639",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {pathname != "webgl" && (
          <Header {...props} cartItem={cartItem} setCartItem={setCartItem} />
        )}
        <SignupMain {...props} cartItem={cartItem} setCartItem={setCartItem} />
        <Footer {...props} />
      </Box>
    </Box>
  );
};

export default SignupLayout;
