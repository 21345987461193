import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Main from "./Main";

const LayoutPage = (props) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth <= 1135) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        className={`sidebar-elements  ${showNavbar && "active"}`}
        sx={{
          width: !isOpen ? "100px" : "250px",
          width: {
            // md: "17%",
            // lg: "17%",
          },
          backgroundColor: "#1a1a1a",
          minHeight: "100vh",
          overflowY: "visible",
        }}
      >
        <Sidebar
          {...props}
          showNavbar={showNavbar}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
          minHeight: "100vh",
          backgroundColor: "#1a1a1a",
          overflowY: "scroll",
        }}
      >
        <Header
          {...props}
          showNavbar={showNavbar}
          setShowNavbar={setShowNavbar}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <Main
          {...props}
          showNavbar={showNavbar}
          setShowNavbar={setShowNavbar}
        />
      </Box>
    </Box>
  );
};

export default LayoutPage;
