import { USER_DATA, USER_DATA_REMOVE } from "../action-types/userAddress";
export default function userAddress(state = [], action) {
  const { type, payload } = action;
  console.log("@@@@@@@@@@@@", payload);
  switch (type) {
    case USER_DATA:
      return {
        ...state,
        payload,
      };
    case USER_DATA_REMOVE:
      return [];
    default:
      return state;
  }
}
