import React, { useState, useEffect } from "react";
import { Box, Paper, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  deleteQuestion,
  changeStatus,
} from "../../state/actions/questionAction";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",

  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const QuestionsList = (props) => {
  const {
    questionLists,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    questionListData,
    setNotify,
    setSortBy,
    setSortFlag,
    sortFlag,
  } = props;
  const [open, setOpen] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [editId, setEditId] = useState(null);
  const [flagId, setflagId] = useState(false);
  const [flagTopic, setflegTopic] = useState(false);
  const [flagUnit, setflagUnit] = useState(false);
  const [flagStatus, setflagStatus] = useState(false);
  const [flagDate, setflagData] = useState(false);

  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [fieldName, setFieldName] = useState("");

  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);

  const dataShorting = (key) => {
    if (key === "id") {
      setflagId(!flagId);
      questionListData(1, key, !flagId);
    } else {
      setflagId(false);
      setSortFlag(false);
    }
    if (key === "unitId") {
      setflagUnit(!flagUnit);
      questionListData(1, key, !flagUnit);
    } else {
      setflagUnit(false);
      setSortFlag(false);
    }
    if (key === "topicId") {
      questionListData(1, key, !flagTopic);
      setflegTopic(!flagTopic);
    } else {
      setflegTopic(false);
      setSortFlag(false);
    }
    if (key === "status") {
      questionListData(1, key, !flagStatus);

      setflagStatus(!flagStatus);
    } else {
      setflagStatus(false);
      setSortFlag(false);
    }
    if (key === "createdAt") {
      setflagData(!flagDate);
      questionListData(1, key, !flagDate);
    } else {
      setflagData(false);
      setSortFlag(false);
    }
    setSortBy(key);
    setFieldName(key);
  };

  const handleChangeStatus = async (e) => {
    const payload = {
      status: checked == true ? 1 : 0,
    };
    const res = await changeStatus(switchId, payload);

    if (res && res.status === true) {
      handleCloseSwitch();
      if (fieldName == "createdAt") {
        questionListData(page, fieldName, flagDate);
      } else if (fieldName == "status") {
        questionListData(page, fieldName, flagStatus);
      } else if (fieldName == "id") {
        questionListData(page, fieldName, flagId);
      } else if (fieldName == "unitId") {
        questionListData(page, fieldName, flagUnit);
      } else if (fieldName == "topicId") {
        questionListData(page, fieldName, flagTopic);
      } else {
        questionListData(page, "", "");
      }

      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteQuestion(deleteId);
    if (res && res.status === true) {
      handleCloseDelete();
      if (fieldName == "createdAt") {
        questionListData(page, fieldName, flagDate || false);
      } else if (fieldName == "status") {
        questionListData(page, fieldName, flagStatus || false);
      } else if (fieldName == "id") {
        questionListData(page, fieldName, flagId || false);
      } else if (fieldName == "unitId") {
        questionListData(page, fieldName, flagUnit || false);
      } else if (fieldName == "topicId") {
        questionListData(page, fieldName, flagTopic || false);
      } else {
        questionListData(page, "", "");
      }
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
      questionListData(page);
      //close delete model
      handleCloseDelete();
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{ backgroundColor: "var(--main-bg)", color: "#FFFFFF" }}
          >
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "700",
                  width: "6%",
                  fontSize: "16px",
                  color: "#efefef",
                  width: "6%",
                }}
                onClick={() => dataShorting("id")}
              >
                No
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  width: "8%",
                  color: "#efefef",
                  width: "8%",
                }}
                onClick={() => dataShorting("unitId")}
              >
                Unit
                {flagUnit ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                  width: "9%",
                }}
                onClick={() => dataShorting("topicId")}
              >
                Topic
                {flagTopic ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Question
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                  width: "15%",
                }}
                onClick={() => dataShorting("createdAt")}
              >
                Created Date
                {flagDate ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                  width: "10%",
                }}
                onClick={() => dataShorting("status")}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                  width: "12%",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionLists.map((question, key) => (
              <StyledTableRow>
                <TableCell>{question.id}</TableCell>
                <TableCell>{question.unitName}</TableCell>
                <TableCell>{question.topicName}</TableCell>
                <TableCell>{question.name}</TableCell>
                <TableCell>
                  {Moment(question.createdAt).format("MM-DD-YYYY LT")}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={question.isActive == 1 ? true : false}
                    onChange={(e) => {
                      handleSwitchModel();
                      setSwitchId(question.id);
                      setChecked(e.target.checked);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Grid container gap={2}>
                    <Button
                      style={{
                        minWidth: "40px",
                        backgroundColor: "#1976d2",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        navigate("/addquestion", {
                          state: { id: question.id, page: page },
                        });
                      }}
                    >
                      <EditIcon
                        style={{
                          fontSize: "20px",
                          color: "#FFF",
                        }}
                      />
                    </Button>
                    <Button
                      style={{
                        minWidth: "40px",
                        // borderColor: "#F05454",
                        backgroundColor: "#e91e63",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        handleDeleteModel();
                        setDeleteId(question.id);
                      }}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: "20px",
                          // color: "#F05454",
                          color: "#FFF",
                        }}
                      />
                    </Button>
                  </Grid>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {questionLists && questionLists.length == 0 ? (
          <div className="noDataFound fontLight"> No Data Found</div>
        ) : null}
        {questionLists.length != 0 && (
          <TablePagination
            label={
              "Showing " +
              questionLists.length +
              " of " +
              totalRecords +
              " Records"
            }
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              if (fieldName == "createdAt") {
                questionListData(newPage, fieldName, flagDate);
              } else if (fieldName == "status") {
                questionListData(newPage, fieldName, flagStatus);
              } else if (fieldName == "id") {
                questionListData(newPage, fieldName, flagId);
              } else if (fieldName == "unitId") {
                questionListData(newPage, fieldName, flagUnit);
              } else if (fieldName == "topicId") {
                questionListData(newPage, fieldName, flagTopic);
              } else {
                questionListData(newPage, "", "");
              }
            }}
            page={page}
            setPage={setPage}
          />
        )}

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}></Box>
        </Modal>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure want to change status?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default QuestionsList;
