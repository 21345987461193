import axiosRequest from "../axios/axios";

export const addTopic = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/addTopic`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const topicList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/topiclist${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusChangeTopic/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteTopic = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteTopic/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const editTopic = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateTopic/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

//topic dropdown
export const topicDropdown = async (id) => {
  try {
    const response = await axiosRequest.get(
      `/api/v1/unitWisetopicDropdownlist/${id}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
