import axiosRequest from "../axios/axios";

export const addCoupon = async (payload) => {
    try {
        const response = await axiosRequest.post(
            `/api/v1/addCoupon`,
            payload
        );
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
};

export const updateCoupon = async (id, payload) => {
    try {
        const response = await axiosRequest.put(
            `/api/v1/updateCoupon/${id}`,
            payload
        );
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
};

export const couponList = async (string) => {
    try {
        const response = await axiosRequest.get(
            `/api/v1/coupons${string}`
        );
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
};

export const changeStatus = async (id, payload) => {
    try {
        const response = await axiosRequest.put(
            `/api/v1/couponStatus/${id}`,
            payload
        );
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
};

export const deleteCoupon = async (id) => {
    try {
        const response = await axiosRequest.delete(`/api/v1/deleteCoupon/${id}`);
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
};

export const checkCoupon = async (payload) => {
    try {
        const response = await axiosRequest.post(`/api/v1/checkCoupon`, payload);
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
}

export const couponDropdown = async (payload) => {
    try {
        const response = await axiosRequest.get(`/api/v1/couponDropdown?${payload}`);
        return response.data;
    } catch (e) {
        if (e.request.status === 401) {
            window.location.href = "/login";
        }
        return e.response.data;
    }
}



