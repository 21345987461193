import React, { useState, useEffect, useMemo } from "react";
import "./Questions.css";
import { useForm, Form } from "../../Utils/useForm";
import Controls from "../../Components/controls/Controls";
import { styled } from "@mui/material/styles";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import camera from "../../assets/images/camera.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { convertToHTML } from "draft-convert";
import { getUnitDropDownList } from "../../state/actions/unitActions";
import {
  addQuestion,
  getQuestionDetails,
  updateQuestion,
  deleteImages,
  videoList,
} from "../../state/actions/questionAction";
import { topicDropdown } from "../../state/actions/topicActions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Close as CloseIcon } from "@mui/icons-material";
import ToastNotification from "../../Utils/toastNotification";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Paper,
  Grid,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import BackdropComponent from "../../Components/Backdrop/Backdrop";

const WhiteBorderTextField = styled(Radio)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

const WhiteBorderCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid white",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledInput = styled("input")({
  display: "none",
});

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid white",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",
  img: {
    marginTop: "30px",
  },
}));

const initialValues = {
  name: "",
  unitId: "",
  topicId: "",
  allowNumberOfQuestion: "0",
  answerType: 1,
  source: "",
  video: "",
  question: "",
  explanation: "",
  answer: "",
  questionImage: "",
  explanationImage: "",

  option1: "",
  option2: "",
  option3: "",
  option4: "",
};
const AddQuestion = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const answerTypeItems = [
    { id: "1", title: "Single" },
    { id: "2", title: "Multiple" },
  ];
  const [answerItems, setAnswerItems] = useState([
    { id: "0", name: "", answer: 0 },
    { id: "1", name: "", answer: 0 },
    { id: "2", name: "", answer: 0 },
    { id: "3", name: "", answer: 0 },
  ]);
  const [unitDropDownList, setUnitDropDownList] = useState([]);
  const [topicDropDownList, setTopicDropDownList] = useState([]);
  const [explanationFiles, setExplanationFiles] = useState([]);
  const [questionFiles, setQuestionFiles] = useState([]);
  const [questionFilesArray, setQuestionFilesArray] = useState([]);
  const [explanationContent, setExplanationContent] = useState(null);
  const [questionContent, setQuestionContent] = useState(null);
  const [editorState, setEditorState] = useState();
  const [editId, setEditId] = useState(null);
  const [explanationeditorState, setExplanationEditorState] = useState();
  const [explanationImage, setExplanationImage] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [source, setSource] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(false);

  const handelTypeChange = (e) => {
    answerItems.map((item) => {
      item.answer = 0;
      item.name = "";
    });
    setAnswerItems(answerItems);
  };

  const validate = (fieldValues = values) => {
    console.log("fieldValues", fieldValues);
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";
    }
    if ("unitId" in fieldValues) {
      temp.unitId = fieldValues.unitId ? "" : "This field is required.";
    }
    if ("topicId" in fieldValues) {
      temp.topicId = fieldValues.topicId ? "" : "This field is required.";
    }
    if ("answerType" in fieldValues) {
      temp.answerType = fieldValues.answerType ? "" : "This field is required.";
    }

    if ("option1" in fieldValues) {
      temp.option1 = fieldValues.option1 ? "" : "This field is required.";
    }
    if ("option2" in fieldValues) {
      temp.option2 = fieldValues.option2 ? "" : "This field is required.";
    }
    if ("option3" in fieldValues) {
      temp.option3 = fieldValues.option3 ? "" : "This field is required.";
    }
    if ("option4" in fieldValues) {
      temp.option4 = fieldValues.option4 ? "" : "This field is required.";
    }

    //temp

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (state && state.id) {
      setEditId(state.id);
      getQuestionDetailss(state.id);
    }
  }, []);

  const getQuestionDetailss = async (id) => {
    setOpenBreakdrop(true);
    const res = await getQuestionDetails(id);

    if (res.status == true) {
      setValues({
        ...values,
        name: res.data.question.name,
        unitId: res.data.question.unitId,
        topicId: res.data.question.topicId,
        allowNumberOfQuestion: res.data.allowNumberOfQuestion,
        answerType: res.data.question.answerType,
        source: res.data.question.videoLink ? res.data.question.videoLink : "",
        option1: res.data.answer[0].name,
        option2: res.data.answer[1].name,
        option3: res.data.answer[2].name,
        option4: res.data.answer[3].name,
      });

      setExplanationFiles(res.data.answerImage);
      setQuestionFiles(res.data.questionImage);
      setAnswerItems(res.data.answer);
      setEditorState(() =>
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(res.data.question.description)
          )
        )
      );
      setExplanationEditorState(() =>
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(res.data.question.answerExplanation)
          )
        )
      );
      setQuestionFiles(res.data.questionImage);
      setOpenBreakdrop(false);
    }
  };

  async function handelExplainationImage(e) {
    const selectedFiless = e.target.files;
    const EXPECTED_SIZE = 2e6;
    const fileSize = selectedFiless[0].size;
    if (fileSize <= EXPECTED_SIZE) {
      var localFiles = [...Array.from(selectedFiless)];
      setExplanationImage((preval) => {
        return [...preval, ...localFiles];
      });
      let newImagearr = localFiles.map((file) => ({
        file: file,
        imageName: URL.createObjectURL(file),
      }));
      setExplanationFiles([...explanationFiles, ...newImagearr]);
    } else {
      setNotify({
        isOpen: true,
        message: "File size should be less than 2MB",
        type: "error",
      });
    }
  }

  async function handelQuestionImage(e) {
    const selectedFiles = e.target.files;
    const EXPECTED_SIZE = 2e6;
    const fileSize = selectedFiles[0].size;
    if (fileSize <= EXPECTED_SIZE) {
      var localFiles = [...Array.from(selectedFiles)];

      setQuestionFilesArray((preval) => {
        return [...preval, ...localFiles];
      });
      let newImagearr = localFiles.map((file) => ({
        file: file,
        imageName: URL.createObjectURL(file),
      }));
      setQuestionFiles([...questionFiles, ...newImagearr]);
    } else {
      setNotify({
        isOpen: true,
        message: "File size should be less than 2MB",
        type: "error",
      });
    }
  }

  async function deleteImage(index, i) {
    if (i == 2) {
      if (explanationFiles[index]) {
        setExplanationFiles((preval) => {
          return preval.filter((val, id) => id !== index);
        });
        ImagesDelete(explanationFiles[index].id, 2, index);
      }
    }

    if (i == 1) {
      if (questionFiles[index]) {
        setQuestionFiles((preval) => {
          return preval.filter((val, id) => id !== index);
        });
        ImagesDelete(questionFiles[index].id, 1, index);
      }
    }
  }

  const ImagesDelete = async (id, type, index) => {
    setOpenBreakdrop(true);
    const res = await deleteImages(id, type);
    if (res.status == true) {
      setOpenBreakdrop(false);
    } else {
      if (type == 1) {
        setQuestionFilesArray((preval) => {
          return preval.filter((val, id) => id !== index);
        });
      } else {
        setExplanationImage((preval) => {
          return preval.filter((val, id) => id !== index);
        });
      }
    }
    setOpenBreakdrop(false);
  };

  const handelAnswerItem = (e, index) => {
    setAnswerStatus(true);

    const temp = answerItems.map((val, valIndex) => {
      if (valIndex === index) {
        return { ...val, answer: e.target.checked === true ? 1 : 0 };
      }
      return val;
    });
    setAnswerItems(temp);

    if (values.answerType == 1) {
      answerItems.map((val, id) => {
        if (id !== index) {
          val.answer = 0;
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setOpenBreakdrop(true);

      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("unitId", values.unitId);
      formData.append("topicId", values.topicId);
      formData.append("answerType", values.answerType);
      formData.append("options", JSON.stringify(answerItems));
      formData.append("description", questionContent); //question description
      formData.append("answerExplanation", explanationContent); //explanation description
      formData.append("videoLink", values.source);

      if (explanationImage) {
        explanationImage.map((val, id) => {
          formData.append("explanationImage", val);
        });
      }
      if (questionFilesArray) {
        questionFilesArray.map((val, id) => {
          formData.append("question_images", val);
        });
      }

      if (editId) {
        const response = await updateQuestion(formData, editId);
        if (response && response.status === true) {
          Navigate("/questions", {
            state: {
              notify: {
                isOpen: true,
                message: "Updated Successfully.",
                type: "success",
              },
            },
          });
          setOpenBreakdrop(false);
        }
        setOpenBreakdrop(false);
      } else {
        if (answerStatus == false) {
          setNotify({
            isOpen: true,
            message: "Please select answer",
            type: "error",
          });
          setOpenBreakdrop(false);
          return;
        } else {
          const response = await addQuestion(formData);
          if (response && response.status === true) {
            Navigate("/questions", {
              state: {
                notify: {
                  isOpen: true,
                  message: "Added Successfully.",
                  type: "success",
                },
              },
            });
          } else {
            setNotify({
              isOpen: true,
              message: response.message || "Something went wrong.",
              type: "error",
            });
          }
        }

        setOpenBreakdrop(false);
      }
      // }
      setOpenBreakdrop(false);
    }
  };

  const getDropDownList = async () => {
    const res = await getUnitDropDownList();
    if (res && res.status === true) {
      setUnitDropDownList(res.data);
    } else {
      setUnitDropDownList([]);
    }
  };

  const handleQuestionChange = (state) => {
    setEditorState(state);
    convertContentToHTML(2);
  };
  const convertContentToHTML = (i) => {
    if (i === 2) {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setQuestionContent(currentContentAsHTML);
    } else {
      let currentContentAsHTMLs = convertToHTML(
        explanationeditorState.getCurrentContent()
      );
      setExplanationContent(currentContentAsHTMLs);
    }
  };

  const handleEditorChange = (state) => {
    setExplanationEditorState(state);
    convertContentToHTML(1);
  };

  const handleInputChanges = (e, index) => {
    answerItems[index].name = e.target.value;
    setAnswerItems([...answerItems]);
  };

  const topicDropDownListByUnitId = async () => {
    const res = await topicDropdown(values.unitId);
    if (res && res.status === true) {
      setTopicDropDownList(res.data);
    } else {
      setTopicDropDownList([]);
    }
  };

  useEffect(() => {
    getDropDownList();
  }, []);
  useMemo(() => {
    if (values.unitId) {
      topicDropDownListByUnitId();
    }
  }, [values.unitId]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <h2 className="title">{editId ? "Edit Question" : "Add Question"}</h2>
      <Paper style={{ backgroundColor: "var(--main-bg)" }} elevation={0}>
        <div className="main-question">
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Controls.SelectBox
                  name="unitId"
                  onChange={handleInputChange}
                  value={values.unitId}
                  options={unitDropDownList}
                  error={errors.unitId}
                  label="Select Unit"
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Controls.SelectBox
                  name="topicId"
                  onChange={handleInputChange}
                  value={values.topicId}
                  options={topicDropDownList}
                  error={errors.topicId}
                  label="Select Topic"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controls.Input
                  name="name"
                  onChange={handleInputChange}
                  value={values.name}
                  error={errors.name}
                  label="Question"
                  multiline
                  rows={2}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controls.RadioGroup
                  name="answerType"
                  onChange={(e) => {
                    handleInputChange(e);
                    handelTypeChange(e);
                  }}
                  value={values.answerType}
                  error={errors.answerType}
                  label="Answer Type"
                  items={answerTypeItems}
                  defaultChecked={values.answerType}
                  isRowDir={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  className="fontLight"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <p className="answer-title">Options</p>
                  <p className="answer-title">Answer</p>
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  <FormControl className="ratio-btn-container">
                    <MuiRadioGroup
                      row={true}
                      className="ratio-btn"
                      name="Answer"
                      value={values.answerName}
                      error={errors.answerName}
                      onChange={handleInputChange}
                    >
                      <div className="description-boxs">
                        <div className="answer-radio">
                          <Controls.Input
                            name="option1"
                            placeholder="Option A"
                            key="2"
                            onChange={(e) => {
                              handleInputChanges(e, 0);
                              handleInputChange(e);
                            }}
                            value={answerItems && answerItems[0].name}
                            error={errors.option1}
                            helperText={errors.option1}
                            multiline
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            className="answer-radio"
                            key="1"
                            value="option1"
                            control={
                              values.answerType == 1 ? (
                                <WhiteBorderTextField
                                  checked={
                                    answerItems && answerItems[0].answer == 1
                                  }
                                />
                              ) : (
                                <WhiteBorderCheckbox
                                  // icon={<RadioButtonUncheckedIcon />}

                                  // checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    answerItems && answerItems[0].answer == 1
                                  }
                                />
                              )
                            }
                            onChange={(e) => {
                              handelAnswerItem(e, 0);
                            }}
                          />
                        </div>
                      </div>
                      <div className="description-boxs">
                        <div className="answer-radio">
                          <Controls.Input
                            placeholder="Option B"
                            name="option2"
                            onChange={(e) => {
                              handleInputChanges(e, 1);
                              handleInputChange(e);
                            }}
                            value={answerItems && answerItems[1].name}
                            error={errors.option2}
                            helperText={errors.option2}
                            multiline
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            className="answer-radio"
                            key="1"
                            value="option2"
                            control={
                              values.answerType == 1 ? (
                                <WhiteBorderTextField
                                  checked={
                                    answerItems && answerItems[1].answer == 1
                                  }
                                />
                              ) : (
                                <WhiteBorderCheckbox
                                  // icon={<RadioButtonUncheckedIcon />}

                                  // checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    answerItems && answerItems[1].answer == 1
                                  }
                                />
                              )
                            }
                            onChange={(e) => {
                              handelAnswerItem(e, 1);
                            }}
                          />
                        </div>
                      </div>
                      <div className="description-boxs">
                        <div className="answer-radio">
                          <Controls.Input
                            name="option3"
                            placeholder="Option C"
                            onChange={(e) => {
                              handleInputChanges(e, 2);
                              handleInputChange(e);
                            }}
                            value={answerItems && answerItems[2].name}
                            checked={answerItems && answerItems[2].answer == 1}
                            error={errors.option3}
                            helperText={errors.option3}
                            multiline
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            className="answer-radio"
                            key="1"
                            value="option3"
                            control={
                              values.answerType == 1 ? (
                                <WhiteBorderTextField
                                  checked={
                                    answerItems && answerItems[2].answer == 1
                                  }
                                />
                              ) : (
                                <WhiteBorderCheckbox
                                  // icon={<RadioButtonUncheckedIcon />}

                                  // checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    answerItems && answerItems[2].answer == 1
                                  }
                                />
                              )
                            }
                            onChange={(e) => {
                              handelAnswerItem(e, 2);
                            }}
                          />
                        </div>
                      </div>
                      <div className="description-boxs">
                        <div className="answer-radio">
                          <Controls.Input
                            name="option4"
                            placeholder="Option D"
                            onChange={(e) => {
                              handleInputChanges(e, 3);
                              handleInputChange(e);
                            }}
                            value={answerItems && answerItems[3].name}
                            error={errors.option4}
                            helperText={errors.option4}
                            multiline
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            key="1"
                            value="option4"
                            control={
                              values.answerType == 1 ? (
                                <WhiteBorderTextField
                                  checked={
                                    answerItems && answerItems[3].answer == 1
                                  }
                                />
                              ) : (
                                <WhiteBorderCheckbox
                                  // icon={<RadioButtonUncheckedIcon />}

                                  // checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={
                                    answerItems && answerItems[3].answer == 1
                                  }
                                />
                              )
                            }
                            onChange={(e) => {
                              handelAnswerItem(e, 3);
                            }}
                          />
                        </div>
                      </div>
                    </MuiRadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item={true}
                md={12}
                xs={12}
                style={{
                  marginTop: "20px",
                }}
              >
                <Divider sx={{ bgcolor: "white" }} />
              </Grid>

              <Grid item={true} md={6} xs={12}>
                <h3 className="fontLight">Question Image</h3>
                <StyledImageUploadWrapper>
                  <label htmlFor="question-image-upload">
                    <StyledInput
                      accept="image/*"
                      id="question-image-upload"
                      type="file"
                      onChange={(e) => {
                        handelQuestionImage(e);
                      }}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper>
                      {/* <img src={camera} alt="Camera" /> */}
                      <CameraAltIcon sx={{ color: "white", mt: "30px" }} />
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Add image
                  </Typography>
                  <Typography
                    sx={{
                      color: "#afafaf",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 2 MB
                  </Typography>
                </StyledImageUploadWrapper>
              </Grid>
              <Grid item={true} md={6} xs={6} className="image-container">
                {questionFiles &&
                  questionFiles.map((file, id) => {
                    return (
                      <StyledIconWrapper
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          backgroundImage: `url(${file.imageName})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundColor: "white",
                        }}
                        key={id + 1}
                      >
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "36px",
                            height: "36px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#0957DD",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage(id, 1);
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    );
                  })}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <h3
                  style={{
                    marginBottom: "1rem",
                    color: "white",
                  }}
                >
                  Question Description
                </h3>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleQuestionChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  //fontFamily remove
                  toolbar={{
                    fontFamily: {
                      //disable fontFamily dropdown in toolbar
                      options: ["Montserrat, Inter"],
                    },
                  }}
                />
              </Grid>

              <Grid
                item={true}
                md={12}
                xs={12}
                style={{
                  marginTop: "20px",
                }}
              >
                <Divider sx={{ bgcolor: "white" }} />
              </Grid>

              <Grid item={true} md={6} xs={12}>
                <h3 style={{ color: "white" }}>Answer Explanation </h3>

                <StyledImageUploadWrapper>
                  <label htmlFor="image-upload">
                    <StyledInput
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={(e) => {
                        handelExplainationImage(e);
                      }}
                      onClick={(event) => {
                        event.currentTarget.value = null;
                      }}
                    />
                    <StyledIconWrapper>
                      {/* <img
                        style={{
                          color: "white",
                        }}
                        src={camera}
                        alt="Camera"
                      /> */}
                      <CameraAltIcon sx={{ color: "white", mt: "30px" }} />
                    </StyledIconWrapper>
                  </label>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    Add Answer image
                  </Typography>
                  <Typography
                    sx={{
                      color: "#afafaf",
                      mx: 2,
                      fontSize: "12px",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, max size of 2 MB
                  </Typography>
                </StyledImageUploadWrapper>
              </Grid>
              <Grid item={true} md={6} xs={6} className="image-container">
                {explanationFiles.map((file, id) => {
                  return (
                    <StyledIconWrapper
                      sx={{
                        position: "relative",
                        margin: "25px 5px 5px 5px",
                        width: "147px",
                        height: "140px",
                        backgroundImage: `url(${file.imageName})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "white",
                      }}
                      key={id + 1}
                      // onClick={() => {
                      //   setIconFlag(id);
                      // }}
                    >
                      <IconButton
                        size="large"
                        sx={{
                          position: "absolute",
                          right: "2%",
                          top: "3%",
                          width: "36px",
                          height: "36px",
                          background: "rgba(15, 15, 15, 0.5)",
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "10px",

                          color: "white",
                          "&:hover": {
                            backgroundColor: "#0957DD",
                          },
                        }}
                      >
                        <CloseIcon
                          sx={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            deleteImage(id, 2);
                          }}
                        />
                      </IconButton>
                    </StyledIconWrapper>
                  );
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <h3 style={{ marginBottom: "1rem", color: "white" }}>
                  Answer Description{" "}
                </h3>
                <Editor
                  editorState={explanationeditorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    fontFamily: {
                      //disable fontFamily dropdown in toolbar
                      options: ["Montserrat, Inter"],
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <h3 style={{ color: "white" }}>Answer Video </h3>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4.6}
                style={{
                  paddingTop: "1rem",
                }}
              >
                <Controls.Input
                  name="source"
                  id="source"
                  label="Video Link"
                  value={values.source}
                  onChange={handleInputChange}
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {values.source &&
                values.source != "" &&
                values.source != "undefined" ? (
                  <Vimeo
                    video={values.source}
                    onReady={() => {
                      setOpenBreakdrop(false);
                    }}
                    onError={() => {
                      setOpenBreakdrop(false);
                      setNotify({
                        isOpen: true,
                        message: "Video Not Found",
                        type: "error",
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "1rem" }}>
              <Grid item xs={9}></Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Cancel"
                  className="cancel-btn"
                  onClick={() => {
                    Navigate("/questions");
                  }}
                />
                <Controls.Button text="Save" type="submit" />
              </Grid>
            </Grid>
          </Form>
        </div>
      </Paper>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default AddQuestion;
