import React, { useEffect, useState } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { addTopic, editTopic } from "../../state/actions/topicActions";
import { getUnitDropDownList } from "../../state/actions/unitActions";

const initialValues = {
  name: "",
  unitId: "",
  allowNumberOfQuestion: "0",
  sequence: "",
};

const AddTopic = (props) => {
  const [unitDropDownList, setUnitDropDownList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const { handleClose, setNotify, topicListData, editId, page } = props;
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("unitId" in fieldValues)
      temp.unitId = fieldValues.unitId ? "" : "This field is required.";
    if ("sequence" in fieldValues) {
      let regex = /^(?:\d*\.\d{1,2}|\d+)$/;

      if (fieldValues.sequence != "" && !regex.test(fieldValues.sequence)) {
        temp.sequence = "Only numbers are allowed.";
      } else {
        temp.sequence = "";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        name: values.name,
        unitId: values.unitId.toString(),
        allowNumberOfQuestion: "0",
        sequence: values.sequence || "0",
      };
      if (isEdit) {
        const res = await editTopic(editId.id, payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          topicListData(page);
          handleClose();
          resetForm();
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      } else {
        const res = await addTopic(payload);
        if (res && res.status === true) {
          handleClose();
          topicListData(1);
          resetForm();
          setNotify({
            isOpen: true,
            message: res.message || "Added Successfully.",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: res.message || "Something went wrong.",
            type: "error",
          });
        }
      }
    }
  };

  const getDropDownList = async () => {
    const res = await getUnitDropDownList();
    if (res && res.status === true) {
      setUnitDropDownList(res.data);
    } else {
      setUnitDropDownList([]);
    }
  };

  useEffect(() => {
    if (editId) {
      setIsEdit(true);
      setValues({
        ...values,
        name: editId.name,
        unitId: editId.unitId,
        sequence: editId.sequence,
      });
    }
    getDropDownList();
  }, [editId]);
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="fontLight"> {isEdit ? "Save" : "Add"} Topic</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <ClearIcon
            onClick={() => {
              handleClose();
              resetForm();
            }}
            style={{ cursor: "pointer", color: "#efefef" }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
        variant="fullWidth"
        style={{
          marginTop: "0.5rem",
          borderColor: "white",
          marginBottom: "1.5rem",
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controls.SelectBox
              name="unitId"
              onChange={handleInputChange}
              value={values.unitId}
              options={unitDropDownList}
              error={errors.unitId}
              label="Select Unit"
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="name"
              onChange={handleInputChange}
              value={values.name}
              error={errors.name}
              label="Topic Name"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="sequence"
              onChange={handleInputChange}
              value={values.sequence}
              error={errors.sequence}
              label="Sequence"
              className="seq-input"
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} className="text-end">
              <Controls.Button
                text="Cancel"
                className="cancel-btn"
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              />
              <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AddTopic;
