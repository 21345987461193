import React, { useState } from "react";
import { Box, Paper, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddCoupon from "./AddCoupon";
import Moment from "moment";
import { styled } from "@mui/system";
import {
    changeStatus,
    couponList,
    deleteCoupon,
} from "../../state/actions/couponActions";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "var(--main-bg)",
    borderRadius: "10px",
    boxShadow: 24,
    minHeight: "50px",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    maxHeight: "100%",
    p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "var(--main-table-color)",
    },
    " td, th": {
        color: "#efefef",
    },
}));
const CouponList = (props) => {
    const {
        CouponLists,
        page,
        setPage,
        totalRecords,
        rowsPerPage,
        couponListsData,
        setNotify,
    } = props;

    const [open, setOpen] = useState(false);
    const [openSwitchModel, setOpenSwitchModel] = useState(false);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [editId, setEditId] = useState(null);
    const [switchId, setSwitchId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [checked, setChecked] = useState(true);

    const handleOpen = () => setOpen(true);
    const handleSwitchModel = () => setOpenSwitchModel(true);
    const handleDeleteModel = () => setOpenDeleteModel(true);
    const handleClose = () => {
        setOpen(false);
        setEditId(null);
    };
    const handleCloseSwitch = () => setOpenSwitchModel(false);
    const handleCloseDelete = () => setOpenDeleteModel(false);
    const columns = [
        {
            label: "No",
        },
        {
            label: "Title",
        },

        {
            label: "Coupon Code",
        },
        {
            label: "Start Date",
        },
        {
            label: "End Date",
        },
        {
            label: "Created Date",
        },
        {
            label: "Status",
        },
        {
            label: "Actions",
        },
    ];

    const handleChangeStatus = async (e) => {
        const payload = {
            status: checked == true ? 1 : 0,
        };
        const res = await changeStatus(switchId, payload);

        if (res && res.status === true) {
            handleCloseSwitch();
            couponListsData(page);

            setNotify({
                isOpen: true,
                message: res.message || "Status changed Successfully.",
                type: "success",
            });
        } else {
            setNotify({
                isOpen: true,
                message: res.message || "Something went wrong.",
                type: "error",
            });
        }
    };
    const handleDelete = async (id) => {
        const res = await deleteCoupon(deleteId);

        if (res && res.status === true) {
            handleCloseDelete();
            couponListsData(page);
            setNotify({
                isOpen: true,
                message: res.message || "Status changed Successfully.",
                type: "success",
            });
        } else {
            setNotify({
                isOpen: true,
                message: res.message || "Something went wrong.",
                type: "error",
            });
        }
    };

    const numOfPages = Math.ceil(totalRecords / rowsPerPage);
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead
                        style={{
                            backgroundColor: "var(--main-bg)",
                            color: "#FFFFFF",
                        }}
                    >
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        color: "#efefef",
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {CouponLists && CouponLists.length > 0
                            ? CouponLists.map((coupon, index) => (
                                <StyledTableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{coupon.title}</TableCell>
                                    <TableCell>{coupon.couponCode}</TableCell>
                                    <TableCell>{Moment(coupon.startDate).format("MM-DD-YYYY LT")}</TableCell>
                                    <TableCell>
                                        {coupon && coupon.endDate == null ? ("-") : (Moment(coupon.endDate).format("MM-DD-YYYY LT"))}
                                    </TableCell>

                                    <TableCell>{Moment(coupon.createdAt).format("MM-DD-YYYY LT")}</TableCell>

                                    <TableCell>
                                        <Switch
                                            checked={coupon.status == 1 ? true : false}
                                            onChange={(e) => {
                                                handleSwitchModel();
                                                setSwitchId(coupon.id);
                                                setChecked(e.target.checked);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Grid container gap={2}>
                                            <Button
                                                style={{
                                                    minWidth: "40px",
                                                    backgroundColor: "#1976d2",
                                                }}
                                                size="small"
                                                variant="outlined"
                                                onClick={() => {
                                                    handleOpen();
                                                    setEditId(coupon);
                                                }}
                                            >
                                                <EditIcon
                                                    style={{ fontSize: "20px", color: "#FFF" }}
                                                />
                                            </Button>
                                            <Button
                                                style={{
                                                    minWidth: "40px",
                                                    backgroundColor: "#e91e63",
                                                }}
                                                size="small"
                                                variant="outlined"
                                                onClick={() => {
                                                    handleDeleteModel();
                                                    setDeleteId(coupon.id);
                                                }}
                                            >
                                                <DeleteIcon
                                                    style={{ fontSize: "20px", color: "#FFF" }}
                                                />
                                            </Button>
                                        </Grid>
                                    </TableCell>
                                </StyledTableRow>
                            ))
                            : null}
                    </TableBody>
                </Table>
                {CouponLists && CouponLists.length == 0 ? (
                    <div className="noDataFound"> No Data Found</div>
                ) : (
                    <TablePagination
                        label={
                            "Showing " + CouponLists.length + " of " + totalRecords + " Records"
                        }
                        numOfPages={numOfPages}
                        onChangePage={(event, newPage) => {
                            couponListsData(newPage);
                            setPage(newPage);
                        }}
                        page={page}
                        setPage={setPage}
                    />
                )}

                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        <AddCoupon
                            handleClose={handleClose}
                            setNotify={setNotify}
                            couponListsData={couponListsData}
                            editId={editId}
                            page={page}
                        />
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openSwitchModel}
                    onClose={handleCloseSwitch}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        <label className="fontLight">Are sure to change status?</label>
                        <Grid container gap={1} style={{ marginTop: "1rem" }}>
                            <Grid item xs={8} className="text-end">
                                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
                            </Grid>
                            <Grid item xs={3} className="text-end">
                                <Controls.Button
                                    text="Change"
                                    onClick={() => handleChangeStatus(switchId)}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label>Are you sure to want to delete this record?</label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal> */}

                <Modal
                    keepMounted
                    open={openDeleteModel}
                    onClose={handleCloseDelete}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        <label className="fontLight">
                            Are you sure to want to delete this record?
                        </label>
                        <Grid container gap={1} style={{ marginTop: "1rem" }}>
                            <Grid item xs={8} className="text-end">
                                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
                            </Grid>
                            <Grid item xs={3} className="text-end">
                                <Controls.Button
                                    text="Delete"
                                    style={{ backgroundColor: "red" }}
                                    onClick={() => handleDelete(deleteId)}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </TableContainer>
        </>
    );
};

export default CouponList;
