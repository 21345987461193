import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Button from "@mui/material/Button";
import { Tooltip, Grid, Chip } from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TablePagination from "../../Components/tablePagination";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Moment from "moment";
const StyledChip = styled(Chip)(() => ({
  height: "25px",
  borderRadius: "10px",
  fontSize: "14px",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));

function PriviousTestShelf(props) {
  const {
    previousTestData,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    TestData,
  } = props;
  const Navigate = useNavigate();
  const PrivioustTestList = [
    {
      id: 1,
      name: "Test 1",
      score: 80,
      status: "Completed",
      date: "12/12/2021",
      mode: "Practice",
      qs: 10,
    },
    {
      id: 2,
      name: "Test 2",
      score: 70,
      status: "Completed",
      date: "12/12/2021",
      mode: "Practice",
      qs: 10,
    },
    {
      id: 3,
      name: "Test 3",
      score: 60,
      status: "Completed",
      date: "12/12/2021",
      mode: "Practice",
      qs: 10,
    },
  ];

  const getTestStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgb(6 165 91)",
        color: "#FFF",
      },

      0: {
        backgroundColor: "rgb(6 165 91)",
        color: "#FFF",
      },
      2: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
      4: {
        backgroundColor: "#f05454cf",
        color: "#FFF;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status == 4 ? "Unfinished" : "Completed"}
      />
    );
  };
  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{ backgroundColor: "var(--main-be)", color: "#FFFFFF" }}
          >
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                SCORE(%)
              </TableCell>
              <TableCell
                // onClick={() => dataShorting("name")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                NAME
                {/* {flagName ? (
                                    <ArrowUpwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                )} */}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                DATE
              </TableCell>
              <TableCell
                // onClick={() => dataShorting("createdAt")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                MODE
                {/* {flagDate ? (
                                    <ArrowUpwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                )} */}
              </TableCell>
              <TableCell
                // onClick={() => dataShorting("createdAt")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                STATUS
                {/* {flagDate ? (
                                    <ArrowUpwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            position: "relative ",
                                            top: "2px ",
                                            left: "3px ",
                                            cursor: "pointer ",
                                        }}
                                    />
                                )} */}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                QUESTIONS
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previousTestData && previousTestData.length > 0
              ? previousTestData.map((test, key) => (
                <StyledTableRow
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    Navigate(`/previewtest`, {
                      state: {
                        testId: test.id,
                      },
                    })
                  }
                >
                  <TableCell>
                    {((100 * test.score) / test.maxQue).toFixed(2)}
                  </TableCell>
                  <TableCell>Test {totalRecords - key}</TableCell>
                  <TableCell>
                    {Moment(test.createdAt).format("MM-DD-YYYY LT")}
                  </TableCell>
                  <TableCell>
                    {test.testMode == 2 ? "Tutor" : "Timed"}
                  </TableCell>
                  <TableCell>{getTestStatus(test.status)}</TableCell>
                  <TableCell>{test.maxQue}</TableCell>
                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Grid container gap={2}>
                      {/* <Tooltip title="Resume" placement="bottom">
                                                <Button
                                                    style={{ minWidth: "40px" }}
                                                    size="small"
                                                    variant="outlined"
                                                // onClick={() => {
                                                //     handleOpen();
                                                //     setEditId(test);
                                                // }}
                                                >
                                                    <NotStartedIcon style={{ fontSize: "20px" }} />
                                                </Button>
                                            </Tooltip> */}
                      <Tooltip title="Result" placement="bottom">
                        <Button
                          sx={{
                            minWidth: "40px",
                            backgroundColor:
                              test.status == 4 ? "" : "#1976d2",
                          }}
                          disabled={test.status == 4 ? true : false}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            Navigate("/result", {
                              state: { testId: test.id, index: 0 },
                            });
                          }}
                        >
                          <AssessmentIcon
                            sx={{
                              fontSize: "20px",
                              color: test.status == 4 ? "" : "#fff",
                            }}
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Analysis" placement="bottom">
                        <Button
                          sx={{
                            minWidth: "40px",
                            backgroundColor:
                              test.status == 4 ? "" : "#1976d2",
                          }}
                          disabled={test.status == 4 ? true : false}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            Navigate("/result", {
                              state: { testId: test.id, index: 1 },
                            });
                          }}
                        >
                          <AnalyticsIcon
                            sx={{
                              fontSize: "20px",
                              color: test.status == 4 ? "" : "#fff",
                            }}
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Review test" placement="bottom">
                        <Button
                          sx={{
                            minWidth: "40px",
                            backgroundColor:
                              test.status == 4 ? "" : "#1976d2",
                          }}
                          // disabled={test.status == 4 ? true : false}
                          size="small"
                          variant="outlined"
                          onClick={() =>
                            Navigate(`/previewtest`, {
                              state: {
                                testId: test.id,
                              },
                            })
                          }
                        >
                          <VisibilityIcon
                            sx={{
                              fontSize: "20px",
                              color: "#fff",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </StyledTableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      {previousTestData && previousTestData.length == 0 ? (
        <div className="noDataFound fontLight"> No Data Found</div>
      ) : (
        <TablePagination
          label={totalRecords + " Previous Test Found"}
          numOfPages={numOfPages}
          onChangePage={(event, newPage) => {
            TestData(newPage);
            setPage(newPage);
          }}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  );
}

export default PriviousTestShelf;
