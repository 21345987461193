import { ORDER_DATA, ORDER_DATA_REMOVE } from "../action-types/order";
export default function order(state = [], action) {
  const { type, payload } = action;
  console.log("@@@@@@@@@@@@", payload);
  switch (type) {
    case ORDER_DATA:
      return {
        ...state,
        payload,
      };
    case ORDER_DATA_REMOVE:
      return [];
    default:
      return state;
  }
}
