import React from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import Layout from "./Components/Layout/Layout";
import SignupLayout from "./Components/SignupLayout/signupLayout";
import PrivateRoute from "./Routes/PrivateRoute";
import { loadUserApi } from "./state/actions/authActions";
import { useDispatch } from "react-redux";
import Exam from "./pages/addExam/Exam";
import GetCartId from "./Components/getCartId";
import GetEmail from "./Components/getEmail";
import PreviewTest from "./pages/PriviousTest/PreviewTest";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  window.onload = async () => {
    if (pathname !== "login") {
      await dispatch(loadUserApi());
    }
  };

  return (

    // <div onContextMenu={(e) => { e.preventDefault(); }} onCopy={(e) => { e.preventDefault(); }} onPaste={(e) => { e.preventDefault(); }} onCut={(e) => { e.preventDefault(); }}>
    <Routes>
      <Route exact path="/previewtest" element={<PreviewTest />} />
      <Route exact path="/addTest" element={<Exam />} />
      <Route path="/login" element={<SignupLayout page="login" />} />
      <Route
        path="/resetPassword/:token"
        element={<SignupLayout page="resetpassword" />}
      />
      <Route
        path="/checkout"
        element={
          <PrivateRoute>
            <SignupLayout page="checkout" />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoute>
            <SignupLayout page="payment" />
          </PrivateRoute>
        }
      />
      <Route
        path="/webgl"
        element={
          <PrivateRoute>
            <SignupLayout page="webgl" />
          </PrivateRoute>
        }
      />
      <Route
        path="/confirmation"
        element={
          <PrivateRoute>
            <SignupLayout page="confirmation" />
          </PrivateRoute>
        }
      />
      <Route
        path="/thank-you"
        element={
          <PrivateRoute>
            <SignupLayout page="thank-you" />
          </PrivateRoute>
        }
      />
      <Route path="/cart/:key" element={<SignupLayout page="cart" />} />
      <Route path="/buy-now" element={<SignupLayout page="price" />} />
      <Route path="/register" element={<SignupLayout page="register" />} />
      <Route path="/getEmail/:email" element={<GetEmail />} />
      <Route path="/getCart/:id" element={<GetCartId />} />
      <Route exact path="/adminlogin" element={<Login />} />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      ></Route>
    </Routes>
    // </div>

  );
};

export default App;
