import axiosRequest from "../axios/axios";
import { CART_DATA, CART_DATA_REMOVE } from "../action-types/cart";
export const addPlanToCart = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/addToCart/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const cartList = async (id) => {
  try {
    const response = await axiosRequest.post(`/api/v1/cartList/${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateCartUserId = async (payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateUserIdCart`,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const deleteCartItem = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteCart/${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const cartDispatch = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CART_DATA,
      payload: payload,
    });
  } catch (e) { }
};
export const cartRemoveDispatch = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CART_DATA_REMOVE,
      payload: payload,
    });
    return true;
  } catch (e) { }
};


export const updateCart = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `/api/v1/updateCart/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
