import React, { useState, useEffect } from "react";
import { Box, Paper, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddUnit from "./AddUnit";
import { changeStatus, deleteUnit } from "../../state/actions/unitActions";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./Units.css";
import { styled } from "@mui/system";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const UnitLists = (props) => {
  const {
    unitLists,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    unitListData,
    setNotify,
    setSortBy,
    setSortFlag,
    sortFlag,
    sortBy,
  } = props;
  const [open, setOpen] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [editId, setEditId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [fieldName, setFieldName] = useState("");
  const [flagId, setflagId] = useState(false);
  const [flagName, setflagName] = useState(false);
  const [flagDate, setflagDate] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const columns = [
    {
      label: "Id",
    },
    {
      label: "Name",
    },
    {
      label: "Date",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const handleChangeStatus = async (e) => {
    const payload = {
      status: checked == true ? 1 : 0,
    };
    const res = await changeStatus(switchId, payload);
    if (res && res.status === true) {
      handleCloseSwitch();
      if (fieldName == "createdAt") {
        unitListData(page, fieldName, flagDate);
      } else if (fieldName == "name") {
        unitListData(page, fieldName, flagName);
      } else if (fieldName == "sequence") {
        unitListData(page, fieldName, flagId);
      } else {
        unitListData(page, "", "");
      }

      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteUnit(deleteId);
    if (res && res.status === true) {
      if (fieldName == "createdAt") {
        unitListData(page, fieldName, flagDate);
      } else if (fieldName == "name") {
        unitListData(page, fieldName, flagName);
      } else if (fieldName == "sequence") {
        unitListData(page, fieldName, flagId);
      } else {
        unitListData(page, "", "");
      }
      handleCloseDelete();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const dataShorting = (name) => {
    if (name == "sequence") {
      setflagId(!flagId);
      unitListData(page, name, !flagId);
      setSortFlag(!flagId);
    } else {
      setflagId(false);
      setSortFlag(false);
    }
    if (name == "name") {
      setflagName(!flagName);
      setSortFlag(!flagName);
      unitListData(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(false);
    }
    if (name == "createdAt") {
      setflagDate(!flagDate);
      setSortFlag(!flagDate);
      unitListData(page, name, !flagDate);
    } else {
      setflagDate(false);
      setSortFlag(false);
    }
    setFieldName(name);
  };

  const numOfPages = Math.ceil(totalRecords / rowsPerPage);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "var(--main-bg)" }}>
            <TableRow>
              <TableCell
                onClick={() => dataShorting("sequence")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Sequence
                {flagId ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                onClick={() => dataShorting("name")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Name
                {flagName ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                onClick={() => dataShorting("createdAt")}
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Created Date
                {flagDate ? (
                  <ArrowUpwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1rem",
                      position: "relative ",
                      top: "2px ",
                      left: "3px ",
                      cursor: "pointer ",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#efefef",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitLists && unitLists.length > 0
              ? unitLists.map((unit, key) => (
                  <StyledTableRow
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{unit.sequence}</TableCell>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>
                      {moment(unit.createdAt).format("MM-DD-YYYY LT")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={unit.isActive == 1 ? true : false}
                        onChange={(e) => {
                          handleSwitchModel();
                          setSwitchId(unit.id);
                          setChecked(e.target.checked);
                          e.stopPropagation();
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container gap={2}>
                        <Tooltip title="Edit" placement="bottom">
                          <Button
                            style={{
                              minWidth: "40px",
                              backgroundColor: "#1976d2",
                            }}
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              handleOpen();
                              setEditId(unit);
                            }}
                          >
                            <EditIcon
                              style={{ fontSize: "20px", color: "#FFF" }}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete" placement="bottom">
                          <Button
                            style={{
                              minWidth: "40px",
                              backgroundColor: "#e91e63",
                            }}
                            size="small"
                            variant="outlined"
                            onClick={(e) => {
                              handleDeleteModel();
                              setDeleteId(unit.id);
                              e.stopPropagation();
                            }}
                          >
                            <DeleteIcon
                              style={{ fontSize: "20px", color: "#FFF" }}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {unitLists && unitLists.length == 0 ? (
          <div className="noDataFound fontLight"> No Data Found</div>
        ) : null}
        {unitLists && unitLists.length > 0 ? (
          <TablePagination
            label={
              "Showing " + unitLists.length + " of " + totalRecords + " Records"
            }
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              if (fieldName == "createdAt") {
                unitListData(newPage, fieldName, flagDate);
              } else if (fieldName == "name") {
                unitListData(newPage, fieldName, flagName);
              } else if (fieldName == "sequence") {
                unitListData(newPage, fieldName, flagId);
              } else {
                unitListData(newPage, "", "");
              }
              setPage(newPage);
            }}
            page={page}
            setPage={setPage}
          />
        ) : null}
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <AddUnit
              handleClose={handleClose}
              setNotify={setNotify}
              unitListData={unitListData}
              editId={editId}
              page={page}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default UnitLists;
