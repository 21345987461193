import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Plan.css";
import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import PlanList from "./PlanList";
import Modal from "@mui/material/Modal";
import AddPlan from "./AddPlan";
import ToastNotification from "../../Utils/toastNotification";
import { planList } from "../../state/actions/planActions";
import InputAdornment from "@mui/material/InputAdornment";
import { Search as SearchIcon } from "@mui/icons-material";
import { useForm, Form } from "../../Utils/useForm";
import { FiFilter } from "react-icons/fi";
import { Stack, Typography, FormLabel, Divider, Drawer } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import BackdropComponent from "../../Components/Backdrop/Backdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
  overflow: "auto",
};

const initialValues = {
  planName: "",
  status: "",
};
export default function Plan() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const statusOptions = [
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
    { id: "3", name: "Delete" },
  ];
  const [open, setOpen] = useState(false);
  const [planLists, setPlanLists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const planListsData = async (pageNum) => {
    setOpenBreakdrop(true);

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}${string}`;
    const res = await planList(queryString);
    if (res && res.status === true) {
      setPlanLists(res.data);
      setTotalRecords(res.totalRecord);
      setPage(pageNum);
      setOpenBreakdrop(false);
      setFilter(false);
    } else {
      setPlanLists([]);
      setOpenBreakdrop(false);
    }
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  useEffect(() => {
    planListsData(1);
  }, [search, filterData]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Plans</h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                startIcon={<AddIcon />}
                text="Plan"
                onClick={handleOpen}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
            <div className="searchBar">
              <Controls.Input
                name="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                icon={<SearchIcon />}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="borderLight" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <h1> </h1>
              </div>
            </div>
          </Grid>
        </Grid>
        <PlanList
          PlanLists={planLists}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          planListsData={planListsData}
          setNotify={setNotify}
        />
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <AddPlan
            handleClose={handleClose}
            setNotify={setNotify}
            planListsData={planListsData}
            page={page}
          />
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
