import axios from "axios";
import axiosRequest from "../axios/axios";
export const sendToAnalytics = async (payload) => {
  try {
    // const response = await axios.post(
    //   `https://www.google-analytics.com/collect`,
    //   payload
    // );
    const response = await axiosRequest.post('/api/v1/send-analytics', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}