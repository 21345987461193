import axiosRequest from "../axios/axios";

export const addVideoLibrary = async (payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/addVideoUnitWise`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateVideoLibrary = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateVideoUnit/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const videoLibraryList = async (string) => {
  try {
    const response = await axiosRequest.get(
      `/api/v1/listVideoUnitWise${string}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteVideoLibrary = async (id) => {
  try {
    const response = await axiosRequest.delete(
      `/api/v1/deleteVideoLibrary/${id}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const videoLibraryDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/detailVideoUnit/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
