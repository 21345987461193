import React from 'react'
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const BackdropComponent = (props) => {
    const { open } = props;
    return (
        <Backdrop
            id="special"
            sx={{
                color: "#fff",
                zIndex: "2222",
                display: `${open ? 'flex' : 'none'}`,
            }}

            open={open}
        >
            {/* <img src={logo} className="App-logo" alt="loader" /> */}
            <CircularProgress
            />

        </Backdrop>
    );
};
export default BackdropComponent;
