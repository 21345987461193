import React, { useState, useEffect } from 'react';

const Webgl = () => {
  // Set initial width and height based on the window size
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Update the width and height on window resize
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} >
      <iframe
        src="https://biotest.mobiginie.com/Conte_Center.html"
        width={size.width}
        height={size.height}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Webgl;
