import React, { useEffect, useState } from "react";
import { Avatar, Tooltip } from "@mui/material";
import profile_img from "../../../assets/images/profile_img.png";
import StatusCard from "./UserStatusCard";
import Infobutton from "../../infobutton/Infobutton";
import TopselectedUnitTable from "./TopselectedUnitTable";
import TopselectedTopicsTable from "./TopselectedTopicsTable";
import VideoLibrarySummaryTable from "./VideoLibrarySummaryTable";
import CreateTestsSummaryTable from "./CreateTestsSummaryTable";
import DateTimepicker from "../../../pages/date-time/DateTimepicker";
import { newDashboardApiDataUser } from "../../../state/actions/dashBoardAction";
import moment from "moment";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";
import Performence from "../../../pages/performence/Performence";
import ColumnChart from "../Charts/ColumnChart";
import Testtable from "../Testtable";
import PieChart1 from "../Charts/Piechart";
const Userdashboard = () => {
  const [filterDate, setFilterDate] = useState({
    fromDate: moment().startOf("year"),
    toDate: moment().endOf("year"),
  });
  const [dashboardData, setDashboardData] = useState({});

  const { state } = useLocation();

  console.log("state", state);
  useEffect(() => {
    getDashboardData();
  }, [filterDate]);

  const getDashboardData = async (date) => {
    try {
      const res = await newDashboardApiDataUser(
        `?fromDate=${moment(filterDate.fromDate).format(
          "YYYY-MM-DD"
        )}&toDate=${moment(filterDate.toDate).format("YYYY-MM-DD")}&userId=${state && state.userId ? state.userId : ""
        }`
      );

      if (res.status) {
        setDashboardData(res.data);
      } else {
        setDashboardData({});
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const downloadImage = () => {
    const element = document.getElementById("capture");
    //set this element background color to black
    element.style.backgroundColor = "#1A1A1A";
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "dashboard-report.png";
      link.href = canvas.toDataURL();
      link.click();
      // element.style.marginTop = "0px";
    });
  };
  const [sortConfigTest, setSortConfigTest] = useState({
    key: "",
    direction: "",
  });
  return (
    <div className="userdashboard">
      {/* header part */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          {/* <Avatar
            alt="Remy Sharp"
            src={profile_img}
            sx={{ width: 65, height: 65 }}
          />
          <div style={{ fontWeight: 700, color: "white", fontSize: "18px" }}>
            Hello, {dashboardData && dashboardData.userData && dashboardData.userData.name}
          </div> */}
        </div>

        <DateTimepicker filterDate={filterDate} setFilterDate={setFilterDate} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            color: "white",
            fontSize: "34px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          BioBuddy User Analytics Dashboard
        </div>
        <Tooltip
          title="Download Report"
          style={{
            backgroundColor: "#7e80e7",
            fontSize: "12px",
            fontWeight: "600px",
          }}
        >
          <div
            style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}
            onClick={downloadImage}
          >
            Download Report
          </div>
        </Tooltip>
      </div>
      <div id="capture">
        {/* Status card */}
        <div style={{ marginBottom: "30px" }}>
          <StatusCard
            counts={dashboardData && dashboardData.counts}
            plan={dashboardData && dashboardData.userPlanDetail}
            userData={dashboardData && dashboardData.userData}
          />
        </div>
        {/* Top Selected Unit table  */}
        {/* <div
          style={{
            backgroundColor: "#2e4777",
            borderRadius: "10px",
            color: "#efefef",
            marginBottom: "30px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Top Selected Unit
                <Infobutton text="Shows the most selected units along with the total number of tests created, started, and completed within each unit." />
              </div>
            </div>
            <TopselectedUnitTable
              data={dashboardData && dashboardData.top5UnitData}
            />
          </div>
        </div> */}
        {/* Top Selected Unit Topics  */}
        {/* <div
          style={{
            backgroundColor: "#2e4777",
            borderRadius: "10px",
            color: "#efefef",
            marginBottom: "30px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Top Selected Topics
                <Infobutton text="Displays the top selected topics along with the total number of tests created, started, and completed for each topic." />
              </div>
            </div>
            <TopselectedTopicsTable
              data={dashboardData && dashboardData.top5TopicData}
            />
          </div>
        </div> */}
        {/* Video Library SUmmary */}
        <div
          style={{
            backgroundColor: "#2e4777",
            borderRadius: "10px",
            color: "#efefef",
            marginBottom: "30px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Video Library Summary
                <Infobutton text="This table provides a summary of video library usage, including the units and topics covered, the total number of views, the number of completed videos, and the total watch time for each topic." />
              </div>
              {/* <DownloadIcon  /> */}
            </div>
            <VideoLibrarySummaryTable
              data={dashboardData && dashboardData.videoLibrarySummary}
            />
          </div>
        </div>
        {/* Create Tests Summary */}
        <div
          style={{
            backgroundColor: "#2e4777",
            borderRadius: "10px",
            color: "#efefef",
            marginBottom: "30px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Create Tests Summary
                <Infobutton text="This table provides a summary of created tests, including the units and topics, the total number of tests created, the number of tests started, and the number of tests completed for each topic & unit" />
              </div>
              {/* <DownloadIcon  /> */}
            </div>
            <CreateTestsSummaryTable
              data={dashboardData && dashboardData.top10UnitTestSummaries}
              userId={state && state.userId ? state.userId : ""}
            />
          </div>
        </div>
        {/* create test */}
        <div
          style={{
            backgroundColor: "#2e4777",
            borderRadius: "10px",
            color: "#efefef",
            marginBottom: "30px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  display: "flex",
                  gap: "10px",
                }}
              >
                Create Tests
                <Infobutton text="This table provides a summary of created tests, including the unit and topic, total questions, total correct answers, total incorrect answers, and the score percentage for each topic." />
              </div>
              {/* <DownloadIcon style={{ cursor: "pointer" }} /> */}
            </div>
            <div
              style={{
                gap: "10px",
                padding: "24px",
                display: "flex",
              }}
              className="test-chart"
            >
              <div
                style={{ color: "black", width: "100%" }}
                className="New-columnchart"
              >
                <ColumnChart
                  data={dashboardData && dashboardData.creteTestChart}
                />
              </div>
              <div style={{ color: "black", width: "100%" }}>
                <PieChart1
                  tutorModeCount={dashboardData.tutorModeCount}
                  timedModeCount={dashboardData.timedModeCount}
                />
                {/* <TypeChart data={dashboardData && dashboardData.topicChart} /> */}
              </div>
            </div>
            <div>
              <Testtable
                sortConfigTest={sortConfigTest}
                setSortConfigTest={setSortConfigTest}
                data={dashboardData && dashboardData.unitAggregatedData}
              />
            </div>
          </div>
        </div>
        <Performence userId={state && state.userId ? state.userId : ""} />
      </div>
    </div>
  );
};

export default Userdashboard;
