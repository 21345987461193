import React, { useState } from "react";
import { Box, Paper, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddPlan from "./AddPlan";
import Moment from "moment";
import { styled } from "@mui/system";
import {
  changeStatus,
  planList,
  deletePlan,
} from "../../state/actions/planActions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const PlanList = (props) => {
  const {
    PlanLists,
    page,
    setPage,
    totalRecords,
    rowsPerPage,
    planListsData,
    setNotify,
  } = props;

  const [open, setOpen] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [editId, setEditId] = useState(null);
  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checked, setChecked] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const columns = [
    {
      label: "Plan Name",
    },
    {
      label: "Base Price",
    },

    {
      label: "Plan Price",
    },
    {
      label: "Expiration Duration",
    },
    {
      label: "Plan Duration Type",
    },

    {
      label: "Status",
    },
    {
      label: "Actions",
    },
  ];

  const handleChangeStatus = async (e) => {
    const payload = {
      status: checked == true ? 1 : 0,
    };
    const res = await changeStatus(switchId, payload);

    if (res && res.status === true) {
      handleCloseSwitch();
      planListsData(page);

      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };
  const handleDelete = async (id) => {
    const res = await deletePlan(deleteId);

    if (res && res.status === true) {
      handleCloseDelete();
      planListsData(page);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const numOfPages = Math.ceil(totalRecords / rowsPerPage);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: "var(--main-bg)",
              color: "#FFFFFF",
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {PlanLists && PlanLists.length > 0
              ? PlanLists.map((plan) => (
                  <StyledTableRow>
                    <TableCell>{plan.planName}</TableCell>
                    <TableCell>{plan.planPrice}</TableCell>
                    <TableCell>{plan.finalPrice}</TableCell>
                    <TableCell>
                      {plan.planExpirationDuration} {"Months"}
                    </TableCell>
                    <TableCell>{"Month"}</TableCell>

                    <TableCell>
                      <Switch
                        checked={plan.status == 1 ? true : false}
                        onChange={(e) => {
                          handleSwitchModel();
                          setSwitchId(plan.id);
                          setChecked(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container gap={2}>
                        <Button
                          style={{
                            minWidth: "40px",
                            backgroundColor: "#1976d2",
                          }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleOpen();
                            setEditId(plan);
                          }}
                        >
                          <EditIcon
                            style={{ fontSize: "20px", color: "#FFF" }}
                          />
                        </Button>
                        <Button
                          style={{
                            minWidth: "40px",
                            backgroundColor: "#e91e63",
                          }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleDeleteModel();
                            setDeleteId(plan.id);
                          }}
                        >
                          <DeleteIcon
                            style={{ fontSize: "20px", color: "#FFF" }}
                          />
                        </Button>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {PlanLists && PlanLists.length == 0 ? (
          <div className="noDataFound"> No Data Found</div>
        ) : (
          <TablePagination
            label={
              "Showing " + PlanLists.length + " of " + totalRecords + " Records"
            }
            numOfPages={numOfPages}
            onChangePage={(event, newPage) => {
              planListsData(newPage);
              setPage(newPage);
            }}
            page={page}
            setPage={setPage}
          />
        )}

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <AddPlan
              handleClose={handleClose}
              setNotify={setNotify}
              planListsData={planListsData}
              editId={editId}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">Are sure to change status?</label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label>Are you sure to want to delete this record?</label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal> */}

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </>
  );
};

export default PlanList;
