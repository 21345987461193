import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box, Tabs, Tab, Badge, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import TestResult from "./TestResult";
import TestAnalysis from "./TestAnalysis";
import { testResult } from "../../state/actions/resultActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMemo } from "react";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import Controls from "../../Components/controls/Controls";
const StyledTab = styled(Box)(() => ({
  marginTop: "32px",
  width: "100%",
  "div > div > div > .MuiButtonBase-root": {
    color: "#ffffff52",
    fontWeight: "600",
  },
  "div > div > div > .Mui-selected": {
    color: "white !important",
    borderBottom: "2px solid #007aff",
    borderRadius: " 10px 10px 0px 0px",
  },
  "div > div > div > .MuiTabs-indicator": {
    display: "none",
  },
  "div > div > div > .MuiTabs-flexContainer": {
    height: "0px",
  },
}));
const Result = () => {
  const Navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [resultData, setResultData] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ value, index, children }) => {
    if (value === index) {
      return (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
      );
    }
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state && state.index) {
      if (state.index === 0) {
        setValue(0);
      } else if (state.index === 1) {
        setValue(1);
      } else {
        setValue(0);
      }
    } else {
      setValue(0);
    }
  }, [state]);

  const testResultData = async (id) => {
    setOpenBreakdrop(true);
    const res = await testResult(id);
    if (res && res.status == true) {
      setOpenBreakdrop(false);
      setResultData(res.data);
    }
  };

  useMemo(() => {
    if (state && state.testId) {
      testResultData(state.testId);
    }
  }, []);

  const [addUser, setAddUser] = useState(false);
  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography
          className="fontLight"
          sx={{ fontSize: "30px", fontWeight: "bold" }}
        >
          Test Result
        </Typography>
      </Stack>

      <StyledTab
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab sx={{ padding: { xs: "10px 6px", sm: "10px 15px", md: "10px 15px" } }} label="Test Result" />
            <Tab sx={{ padding: { xs: "10px 6px" } }} label={<Box>Test Analysis</Box>} />
          </Tabs>
          <Controls.Button sx={{
            marginRight: { xs: "5px", md: "10px" },
            height: { xs: "30px", md: "40px" },
            marginTop: { xs: "8px", md: "5px" },
            fontSize: { xs: "0.75em", md: "0.875em" },
            padding: { xs: "6px 5px", md: "10px 12px" }
          }} onClick={() =>
            Navigate(`/previewtest`, {
              state: {
                testId: state.testId,
              },
            })
          } text="Review Test" />
        </Box>
        <TabPanel value={value} index={0}>
          <TestResult resultData={resultData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TestAnalysis resultData={resultData} />
        </TabPanel>
      </StyledTab>




    </>
  );
};
export default Result;
