import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
// import Progressbar from 'react-linear-progressbar';
import "./result.css";
import Controls from "../../Components/controls/Controls";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LinearProgress from "@mui/material/LinearProgress";

import CircularProgress from "@mui/material/CircularProgress";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--main-table-color)",
  },
  " td, th": {
    color: "#efefef",
  },
}));

function TestResult(props) {
  const { resultData } = props;

  const rows = [
    {
      id: 1,
      name: "Frozen yoghurt",
      calories: 159,
      fat: 6.0,
      carbs: 24,
      protein: 4.0,
    },
    {
      id: 2,
      name: "Ice cream sandwich",
      calories: 237,
      fat: 9.0,
      carbs: 37,
      protein: 4.3,
    },
    {
      id: 3,
      name: "Eclair",
      calories: 262,
      fat: 16.0,
      carbs: 24,
      protein: 6.0,
    },
    {
      id: 4,
      name: "Cupcake",
      calories: 305,
      fat: 3.7,
      carbs: 67,
      protein: 4.3,
    },
    {
      id: 5,
      name: "Gingerbread",
      calories: 356,
      fat: 16.0,
      carbs: 49,
      protein: 3.9,
    },
  ];
  return (
    <div
      style={{
        marginTop: "40px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={0.2}></Grid>
        <Grid
          item
          xs={12}
          sm={5}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Typography className="resultHeader">Your Score</Typography>
          <Box sx={{ width: "90%", marginTop: "30px" }}>
            <LinearProgress
              color="success"
              style={{
                backgroundColor: "rgb(118 124 135)",
                height: "35px",
                borderRadius: "20px",
                colorPrimary: "red",
              }}
              variant="determinate"
              value={(100 * resultData.yourScore) / resultData.totalQuestions}
            />
            <Typography
              className="fontLightsForResult"
              style={{
                color: "#1976D2",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
                //placed in center
              }}
            >
              {resultData.yourScore != null
                ? (
                    (100 * resultData.yourScore) /
                    resultData.totalQuestions
                  ).toFixed(2)
                : ""}
              %
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={1}></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "10px",
          }}
        >
          <Typography className="resultHeader">Test Summary</Typography>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <p className="fontLightsForResult">Mode</p>
            <p className="fontLightsForResult">
              {resultData.testMode == 2 ? "Tutor" : "Timed"}
            </p>
          </Grid>

          <Divider className="divider" />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <p className="fontLightsForResult">Your Score</p>
            <>
              <p className=" fontLightsForResult">
                {resultData.yourScore}/{resultData.totalQuestions}
              </p>
            </>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "80px",
        }}
      >
        <TableContainer
          style={{
            marginBottom: "20px",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "var(--main-bg)" }}>
              <TableRow>
                {/* <TableCell></TableCell> */}

                <TableCell
                  // onClick={() => dataShorting("name")}
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                >
                  CORRECT
                  {/* {flagId ? (
                                        <ArrowUpwardIcon style={{ fontSize: "1rem" }} />
                                    ) : (
                                        <ArrowDownwardIcon style={{ fontSize: "1rem" }} />
                                    )} */}
                </TableCell>
                <TableCell
                  // onClick={() => dataShorting("date")}
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                >
                  INCORRECT
                  {/* {flagId ? (
                                        <ArrowUpwardIcon style={{ fontSize: "1rem" }} />
                                    ) : (
                                        <ArrowDownwardIcon style={{ fontSize: "1rem" }} />
                                    )} */}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                >
                  TOTAL QUESTIONS
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#efefef",
                  }}
                >
                  QUIZ TIME
                </TableCell>
                {/* <TableCell> </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                {/* <TableCell component="th" scope="row">
                                        <CheckIcon style={{
                                            color: "green"
                                        }} />
                                    </TableCell> */}

                <TableCell align="left">{resultData?.yourScore}</TableCell>
                <TableCell align="left">{resultData?.incorrect}</TableCell>
                <TableCell align="left">{resultData?.totalQuestions}</TableCell>
                <TableCell align="left">
                  {resultData?.totalTimeTaken?.totalTime} Second
                </TableCell>
                {/* <TableCell align="left"> <ArrowForwardIosIcon /> </TableCell> */}
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

export default TestResult;
