import React from "react";
import {
  Grid,
  Box,
  styled,
  Typography,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState, useEffect, useMemo } from "react";
import { payment } from "../../state/actions/paymentActions";
import {
  orderRemoveDispatch,
  orderCancel,
} from "../../state/actions/orderActions";
import {
  cartRemoveDispatch,
} from "../../state/actions/cartActions";
import { userDetails } from "../../state/actions/userActions";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Controls from "../../Components/controls/Controls";
import { store } from "../../state/store";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
const steps = ["Plan", "Cart", "Checkout"];
const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  overflow: "scroll",
  boxShadow: 24,
  p: 2,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#37474f",
  borderRadius: "10px",
  paddingTop: "1rem",
  ...theme.typography.body2,
  overflow: "hidden",
  textAlign: "center",
  color: "white",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));



const Payment = (props) => {
  const { cartItem, setCartItem } = props;
  const { state } = useLocation();
  const { order } = store.getState();
  const [paymentData, setPaymentData] = useState({});
  const [user, setUser] = useState({});
  const [modalState, setModalState] = useState(false);
  const [userdata, setUserData] = useState({});
  const Navigate = useNavigate();
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const { auth } = store.getState();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const [orderId, setOrderId] = useState("");
  console.log("order", paymentData);
  useEffect(() => {
    setOpenBreakdrop(true);
    if (order && order.payload?.orderdetail != null) {
      setOpenBreakdrop(false);
      setPaymentData(order.payload);

      setOrderId(order.payload?.orderdetail.id);
    }
    if (auth && auth.user != null) {
      setOpenBreakdrop(false);
      setUser(auth.user);
    }
  }, []);

  const openSuccessScreen = () => {
    setModalState(true);
  };
  const closeSuccessScreen = () => {
    setModalState(false);
  };
  const makePayment = async (token) => {
    setOpenBreakdrop(true);
    const body = {
      token,
      paymentData,
      name: user.name,
    };
    const headers = {
      "Content-Type": "application",
    };

    const res = await payment(body);
    if (res && res.status == true) {
      setOpenBreakdrop(false);

      localStorage.removeItem("cartItemId");
      setPaymentStatus(true);
      // setModalState(true);
      Navigate('/confirmation', {
        state: {
          cardData: token == 0 ? "" : res?.data?.payment_method_details?.card,
          orderId: paymentData?.orderdetail?.id
        }
      })
    } else {
      const payload = "";
      const res = await dispatch(orderRemoveDispatch(payload));

      if (res && res == true) {
        const payload = "";
        const resData = await dispatch(cartRemoveDispatch(payload));
        setOpenBreakdrop(false);
        setPaymentStatus(false);
        Navigate('/payment')

        // setModalState(true);
      }
    }
  };

  const handleDelete = async (id) => {
    const result = await orderCancel(id);
    if (result && result.status == true) {
      const payload = "";
      const res = await dispatch(orderRemoveDispatch(payload));

      if (res && res == true) {
        const payload = "";
        const resData = await dispatch(cartRemoveDispatch(payload));

        Navigate("/buy-now");
      }
    }
  };
  const getUserDetails = async () => {
    const res = await userDetails();
    if (res && res.status == true) {
      setUserData(res);
    }
  };

  useEffect(() => {
    getUserDetails();
    setCartItem();
  }, []);

  // Cancel order when click on Back button
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = async (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want cancel your payment ?")) {
        setfinishStatus(true);
        // your logic

        const result = await orderCancel(order.payload.orderdetail.id);
        if (result && result.status == true) {
          const payload = "";
          const res = await dispatch(orderRemoveDispatch(payload));

          if (res && res == true) {
            const payload = "";
            const resData = await dispatch(cartRemoveDispatch(payload));
            Navigate("/buy-now");
          }
        }
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid
        container
        spacing={3}
        sx={{
          backgroundColor: "#0C0E2E",
          marginTop: { xs: "5rem", md: "5rem" },
          marginBottom: { xs: "5rem", sm: "5rem", md: "5rem" },
          paddingTop: { xs: "2rem", md: "2rem" },
          paddingBottom: { xs: "2rem", md: "2rem" },
          paddingLeft: { xs: "1rem", md: "1rem" },
          paddingRight: { xs: "1rem", md: "1rem" },
          width: { xs: "100%", md: "85%" },
          margin: "auto",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={3} alternativeLabel>
            {steps.map((label) => (
              <Step
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#1976D2", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },

                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "grey.500", // circle's number (ACTIVE)
                  },

                  "& .MuiStepIcon-root": {
                    color: "grey", // circle's number (ACTIVE)
                  },
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            marginTop: "1rem",
            paddingLeft: {
              xs: "0px !important",
              md: "20px !important",
              lg: "24px !important",
            },
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "white",
              borderRadius: 2,
              padding: "10px",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                marginTop: "0px",
              }}
            >
              <Grid
                className="plan-title"
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "0px !important",
                  textAlign: "left",
                  paddingLeft: "25px !important",
                }}
              >
                <p className="fontLight font-sm">Profile Address </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "0px !important",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p
                  className="fontLight font-sm"
                  onClick={() => {
                    Navigate("/checkout", {
                      state: {
                        userdata: userdata,
                        cartId: state.cartId,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Edit
                </p>
              </Grid>
            </Grid>
            <p
              className="fontLight"
              style={{ fontSize: "17px", padding: "5px 0px" }}
            >
              {userdata && userdata.address ? userdata.address.name : user.name}
            </p>
            <p
              className="fontLight"
              style={{ fontSize: "15px", padding: "3px 0px" }}
            >
              {userdata && userdata.address && userdata.address.address}{" "}
              {userdata && userdata.address && userdata.address.city}{" "}
              {userdata && userdata.address && userdata.address.stateName.name}{" "}
              {userdata &&
                userdata.address &&
                userdata.address.countryName.name}{" "}
              {userdata && userdata.address && userdata.address.zipcode}{" "}
            </p>
          </Box>
          <Grid container sm={12}>

            <Grid md={6} xs={5} sm={3}>
              <StyledLogin
                sx={{
                  my: { xs: 3, sm: 3, md: 3 },
                  mx: { xs: 0, sm: 3, md: 3 },
                  backgroundColor: "transparent",
                  border: 1,
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "#0957DD",
                    color: "white",
                  },
                }}
                onClick={() => {
                  handleDeleteModel();
                }}
              >
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "16px" } }}
                >
                  {" "}
                  Cancel
                </Typography>
              </StyledLogin>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          md={1}
          sm={12}
          xs={12}
          sx={{ marginTop: "1rem" }}
        ></Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={12}
          xs={12}
          sx={{
            marginTop: "1rem",
            paddingLeft: {
              xs: "0px !important",
              md: "20px !important",
              lg: "24px !important",
            },
          }}
        >
          <Item elevation={3}>
            <h1
              className="font-md"
              style={{ textAlign: "left", paddingLeft: "10px" }}
            >
              Selected Plan
            </h1>
            <Grid
              container
              spacing={3}
              sx={{
                paddingLeft: { xs: "2.2rem", sm: "0rem", md: "0px" },
              }}
            >
              <Grid
                className="plan-title"
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                <p className="font-sm">
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.planName}
                </p>
                <p className="font-sm">Plan Duration</p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p className="font-sm">
                  $
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.planPrice}
                  /Month
                </p>
                <p className="font-sm">
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.planExpirationDuration}{" "}
                  Month
                </p>
                <span style={{ fontSize: "13px" }}>
                  ($
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.planPrice}{" "}
                  x{" "}
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.planExpirationDuration}
                  )
                </span>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                paddingLeft: { xs: "2.2rem", sm: "0rem", md: "0px" },
              }}
            >
              <Grid
                className="plan-title"
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  paddingTop: "0px !important",
                  textAlign: "left",
                }}
              >
                <h1
                  className="font-md"
                  style={{
                    textAlign: "left",
                    marginTop: "2rem",
                  }}
                >
                  Summary (USD)
                </h1>
              </Grid>
              <Grid
                className="plan-title"
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "0px !important",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                <p className="font-sm">Total Price</p>
                <p className="font-sm">Estimated Tax</p>
                {paymentData && paymentData.orderdetail && paymentData.orderdetail.discountAmount ? <p className="font-sm">Discount</p> : ""}

              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "0px !important",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p className="font-sm">
                  $
                  {paymentData &&
                    paymentData.planDetail &&
                    paymentData.planDetail.finalPrice}
                </p>
                <p className="font-sm">$0</p>
                {paymentData && paymentData.orderdetail && paymentData.orderdetail.discountAmount ? <p className="font-sm">$ {paymentData && paymentData.orderdetail && paymentData.orderdetail.discountAmount}</p> : ""}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              className="font-md cart-button"
              sx={{
                paddingLeft: { xs: "2.2rem", sm: "0rem", md: "0px" },
              }}
            >
              <Grid
                className="plan-title"
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "500" }}>
                  Order Total
                </p>
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "500" }}>
                  $
                  {paymentData &&
                    paymentData.orderdetail &&
                    (paymentData.orderdetail.planAmount).toFixed(2)}
                </p>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
      {/* <OTPScreen
        open={modalState}
        handleClose={closeSuccessScreen}
        paymentStatus={paymentStatus}
      // refreshScreen={refreshScreen}
      /> */}

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">Are you sure to cancel order?</label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Delete"
                style={{ backgroundColor: "red" }}
                onClick={() =>
                  handleDelete(
                    paymentData &&
                    paymentData.orderdetail &&
                    paymentData.orderdetail.id
                  )
                }
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Payment;