import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../Components/controls/Controls";
import "./exam.css";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { examList } from "../../state/actions/exam";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useForm } from "../../Utils/useForm";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SuccessScreen from "../../pages/SuccessScreen";
import Vimeo from "@u-wave/react-vimeo";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from 'react-redux';
import {
  AddTest,
  clearTestArray,
  testSubmit,
  suspendTest,
} from "../../state/actions/exam";
import Modal from "@mui/material/Modal";

import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { store } from "../../state/store";
import ToastNotification from "../../Utils/toastNotification";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const stylesTab = {
  tab: {
    // padding: "2px 34px",
    // width: "140px",
    // height: "72px",
    color: "#fff",
  },
  tabItemContainer: {
    background: "none",
  },
};
const initialValues = {
  name: "",
  unitId: "",
  allowNumberOfQuestion: "0",
};

const WhiteBorderTextField = styled(Radio)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

function Exam() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  // const { state } = useLocation();
  let state = {
    mode: localStorage.getItem('mode'),
    queTiming: localStorage.getItem('queTiming')
  }
  const queTiming = localStorage.getItem('queTiming');
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const [couter, setCounter] = useState(0);
  const [mode, setMode] = useState(0);
  const [position, setPosition] = useState(0);
  const [questionData, setQuestionData] = useState({});
  const [exam, setExam] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [time, setTime] = useState(0);
  const [testTime, setTestTime] = useState(0);
  const [timer, setTimer] = useState(1);
  const [video, setVideo] = useState("");
  const [reduxData, setReduxData] = useState([]);
  const [show, setShow] = useState(false);
  const [testId, setTestId] = useState("");
  const [open, setOpen] = useState(false);
  const [openSuspendModel, setOpenSuspendModel] = useState(false);
  const handleSuspendModel = () => setOpenSuspendModel(true);
  const handleCloseSuspend = () => setOpenSuspendModel(false);
  const [value, setValue] = useState(0);
  const { test } = useSelector((state) => state);

  useEffect(() => {
    if (test && test.loading == false) {
      Navigate("/test");
    }
  }, [test]);
  const handelSuspend = async () => {
    let payload = {};
    const res = await suspendTest(testId);
    if (res && res.status === true) {
      if (mode == 1) {
        payload = {
          questionId: questionData.id || "",
          answer:
            (questionData &&
              questionData.options &&
              questionData.options.filter((item) => item.answers) &&
              questionData.options.filter((item) => item.answers)[0] &&
              questionData.options.filter((item) => item.answers)[0].id) ||
            "",
          time: time || 0,
          unitId: questionData.unitId || "",
          topicId: questionData.topicId || "",
          testId: testId || 0,
        };
      } else {
        payload = {
          questionId: questionData.id || "",
          answer:
            (questionData &&
              questionData.options &&
              questionData.options.filter((item) => item.answers) &&
              questionData.options.filter((item) => item.answers)[0] &&
              questionData.options.filter((item) => item.answers)[0].id) ||
            "",
          time: time || 0,
          unitId: questionData.unitId || "",
          topicId: questionData.topicId || "",
          testId: testId || 0,
        };
      }

      await dispatch(AddTest(payload));

      const { test } = store.getState();
      let reduxData = test.test;
      const res = await testSubmit(reduxData);
      if (res && res.status === true) {
        Navigate("/welcome");
      }

      setNotify({
        isOpen: true,
        message: res.message || "Exam suspend successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleQuestionChanges = async (e) => {
    let payload = {};
    if (mode == 1) {
      payload = {
        questionId: questionData.id || "",
        answer:
          (questionData &&
            questionData.options &&
            questionData.options.filter((item) => item.answers) &&
            questionData.options.filter((item) => item.answers)[0] &&
            questionData.options.filter((item) => item.answers)[0].id) ||
          "",
        time: time || 2,
        unitId: questionData.unitId || "",
        topicId: questionData.topicId || "",
        testId: testId || 0,
      };
    } else {
      payload = {
        questionId: questionData.id || "",
        answer:
          (questionData &&
            questionData.options &&
            questionData.options.filter((item) => item.answers) &&
            questionData.options.filter((item) => item.answers)[0] &&
            questionData.options.filter((item) => item.answers)[0].id) ||
          "",
        time: time || 2,
        unitId: questionData.unitId || "",
        topicId: questionData.topicId || "",
        testId: testId || 0,
      };
    }

    // //same question available in redux then only update time not add new question
    // const { test } = store.getState();
    // let reduxData = test?.test;
    // let index = reduxData && reduxData?.findIndex((item) => item.questionId == payload.questionId);
    // if (index > -1) {
    //   reduxData[index].answer = payload.answer;
    // } else {
    //   reduxData = [...reduxData, payload];
    // }

    // console.log("reduxData", reduxData);


    await dispatch(AddTest(payload));


    // await dispatch(AddTest(payload));
  };

  const RESET_INTERVAL_S = 300; // 300s = 5m * 60s/m

  const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;

  const Timer = ({ time }) => {
    const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);

    return (
      <>
        <div
          style={{
            marginBottom: "auto",
            marginTop: "auto",
          }}
        >
          {" "}
          Time: {formatTime(time)}
        </div>
      </>
    );
  };
  let timerId = null;
  useEffect(() => {
    if (state && state.mode) {
      if (state.mode == "tutor") {
        timerId = setInterval(() => {
          setTime((t) => t + 1);
        }, 1000);
      }
      if (state.mode == "timed") {
        timerId = setInterval(() => {
          setTime((t) => t + 1);
        }, 1000);
      }
    }
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = 'Changes you made may not be saved.';
  };


  useEffect(() => {
    setOpenBreakdrop(true);
    // if (state && state.queTiming) {
    //   if (mode == 2) {
    //     setTestTime(Number(state.queTiming));
    //   }
    // }
    setTimeout(() => {
      setShow(true);
      setOpenBreakdrop(false);
    }, 500);
  }, []);

  // useEffect(() => {
  //   if (state && time == 0) {
  //     clearInterval(timerId);
  //     if (position < exam.length - 1) {
  //       handleQuestionChanges(1);
  //       setCounter(couter + 1);
  //       const newPosition = position + 1;
  //       setPosition(newPosition);
  //       setQuestionData(exam[position + 1]);
  //     } else {
  //       handelSubmit();
  //     }
  //     setTime(timer);
  //   }
  // }, [time]);

  useEffect(() => {
    if (state && state.mode) {
      setMode(state.mode == "tutor" ? 1 : 2);
    }
    window.scrollTo(0, 0);
  }, [mode]);

  const closeSuccessScreen = () => {
    setModalState(false);
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    setOpenBreakdrop(true);
    const res = await examList();
    if (res && res.status === true) {
      setExam(res.data);
      setQuestionData(res.data[position]);
      setTestId(res.testId.id);
      setTimer(Number(res.timeDuration.value));
      // if (mode == 2) {
      //   setTime(Number(res.timeDuration.value));
      // }
      setOpenBreakdrop(false);
    } else {
      setOpenBreakdrop(false);
    }
  };

  const handelSubmit = async () => {
    setOpenBreakdrop(true);
    let payload = {};
    if (mode == 1) {
      payload = {
        questionId: questionData.id || "",
        answer:
          (questionData &&
            questionData.options &&
            questionData.options.filter((item) => item.answers) &&
            questionData.options.filter((item) => item.answers)[0] &&
            questionData.options.filter((item) => item.answers)[0].id) ||
          "",
        time: time || 0,
        unitId: questionData.unitId || "",
        topicId: questionData.topicId || "",
        testId: testId || 0,
      };
    } else {
      payload = {
        questionId: questionData.id || "",
        answer:
          (questionData &&
            questionData.options &&
            questionData.options.filter((item) => item.answers) &&
            questionData.options.filter((item) => item.answers)[0] &&
            questionData.options.filter((item) => item.answers)[0].id) ||
          "",
        time: time || 0,
        unitId: questionData.unitId || "",
        topicId: questionData.topicId || "",
        testId: testId || 0,
      };
    }

    await dispatch(AddTest(payload));

    const { test } = store.getState();
    let reduxData = test.test;
    const res = await testSubmit(reduxData);

    if (res && res.status === true) {
      // openSuccessScreen()
      setOpenBreakdrop(false);
      //then redux data clear
      dispatch(clearTestArray());
      Navigate("/result", { state: { testId: testId } });
    } else {
      setOpenBreakdrop(false);
    }
  };

  const handleRadioButtonChange = (e, id) => {
    const data =
      questionData &&
      questionData.options &&
      questionData.options.map((item) => {
        if (item.id == id) {
          item.answers = true;
        } else {
          item.answers = false;
        }
        return item;
      });
    setQuestionData({ ...questionData, options: data });
  };

  const clickOnNext = () => {
    setQuestionData(exam[position + 1]);
    setSubmit(false);
    window.scrollTo(0, 0);
    setPosition(position + 1);
    setOpenBreakdrop(true);
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 800);
    // if (mode == 1) {
    //   setTime(0);
    // }
    // if (mode == 2) {
    //   setTime(0);
    // }
    const { test } = store.getState();
    let reduxData = test.test;
    let index = reduxData && reduxData?.findIndex((item) => item.questionId == exam[position + 1].id);
    if (index > -1) {
      setTime(reduxData[index].time);
    } else {
      setTime(0);
    }
    handleQuestionChanges(1);
    setValue(0);
  };

  const clickOnPre = () => {
    handleQuestionChanges(1);

    setQuestionData(exam[position - 1]);
    setSubmit(false);
    window.scrollTo(0, 0);
    setPosition(position - 1);
    setOpenBreakdrop(true);
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 800);

    const { test } = store.getState();
    let reduxData = test.test;
    let index = reduxData && reduxData?.findIndex((item) => item.questionId == exam[position - 1].id);
    if (index > -1) {
      setTime(reduxData[index].time);
    } else {
      setTime(0);
    }
    // if (mode == 1) {
    //   setTime(0);
    // }
    // if (mode == 2) {
    //   setTime(0);
    // }
    // handleQuestionChanges(1);
    setValue(0);
  };

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
  return (
    <>
      <BackdropComponent open={openBackdrop} />

      {show == true && (
        <>
          <Grid
            container
            spacing={2}
            style={{
              height: "10vh",
              backgroundColor: "black",
              position: "fixed",
              top: "0",
            }}
          >
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={2} className="margin">
              <h3 className="fontLight testQue">
                {position + 1}/{exam.length}
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3.8}
              className="margin"
              sx={{ paddingTop: { xs: "1px !important" } }}
            >
              {mode == 2 ?
                (<div className="row testTimer">
                  <AccessTimeIcon />
                  &nbsp;
                  <Timer time={time} />
                </div>)
                : ""
              }

            </Grid>
            <Grid item xs={12} sm={0.2}></Grid>
          </Grid>

          <div
            className="testSection"
            style={{
              marginTop: "10vh",
              backgroundColor: "#1a1a1a",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={mode == "1" ? 6 : 8}>
                {questionData &&
                  questionData?.QuestionImage &&
                  questionData?.QuestionImage.map((item, index) => {
                    return (
                      <div className="questionImage">
                        <img
                          src={item.imageName}
                          style={{ backgroundColor: "white" }}
                        />
                      </div>
                    );
                  })}

                <div className="question">
                  {questionData?.description != "null" &&
                    questionData?.description != "" && (
                      <div
                        className="fontLight"
                        dangerouslySetInnerHTML={{
                          __html: questionData?.description,
                        }}
                      ></div>
                    )}
                </div>

                <div className="question">
                  <p
                    style={{
                      color: "white",
                      lineHeight: '24px'
                    }}
                  >
                    {position + 1}. {questionData && questionData?.name}
                  </p>
                </div>

                <div className="questionAnswer">
                  <FormControl className="ratio-btn-container" sx={{ gap: '15px' }}>
                    <MuiRadioGroup
                      row={false}
                      className="ratio-btn"
                      name="answer"
                      value={1}
                      onChange={handleInputChange}
                    >
                      {questionData &&
                        questionData.options &&
                        questionData.options.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {mode == "1" && (
                                <>
                                  {item.answer == 1 && submit == true && (
                                    <CheckIcon
                                      style={{
                                        color: "white",
                                        marginBottom: "auto",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  )}

                                  {item.answers == 1 &&
                                    item.answer != 1 &&
                                    submit == true && (
                                      <ClearIcon
                                        style={{
                                          color: "red",
                                          marginBottom: "auto",
                                        }}
                                      />
                                    )}

                                  {item.answers != 1 &&
                                    item.answer != 1 &&
                                    submit == true && (
                                      <ClearIcon
                                        style={{
                                          color: "red",
                                          marginBottom: "auto",
                                          visibility: "hidden",
                                        }}
                                      />
                                    )}
                                </>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: '20px',
                                  marginLeft: '12px',
                                }}
                              >

                                <FormControlLabel
                                  // key={1}
                                  value="answers"
                                  onChange={(e) =>
                                    handleRadioButtonChange(e, item.id)
                                  }
                                  control={<WhiteBorderTextField />}
                                  disabled={submit}
                                  sx={{ gap: '10px', alignItems: 'start' }}
                                  label={
                                    <p
                                      style={{
                                        color: "white",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  }
                                  checked={item.answers == 1 ? true : false}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </MuiRadioGroup>
                  </FormControl>
                </div>

                {mode == "1" && questionData && questionData.options ? (
                  <div className="subbtn">
                    <Controls.Button
                      text="See Explanation"
                      disabled={
                        submit ||
                        questionData.options.filter((item) => item.answers == 1)
                          .length == 0
                      }
                      onClick={() => {
                        setSubmit(true);
                      }}
                      className="submitBtn"
                    />
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={0.1}></Grid>

              <Grid item xs={12} sm={0.3} sx={{ display: { xs: "none" } }}>
                <div className="hr"></div>
              </Grid>

              {mode == "1" && submit == true ? (
                <>
                  <Grid item xs={12} sm={5.3} style={{}}>
                    <Tabs
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                    >
                      <Tab style={stylesTab.tab} label="Explanation" />
                      {questionData.videoLink != "" ? (
                        <Tab style={stylesTab.tab} label="Video Explanation" />
                      ) : (
                        ""
                      )}
                    </Tabs>
                    {value === 0 &&
                      questionData.answerImage != "null" &&
                      questionData.answerImage != "undefined" &&
                      questionData.answerImage != "" &&
                      questionData.answerImage.map((item, index) => {
                        return (
                          <>
                            <div className="questionExplainationImage">
                              <img src={item.imageName} alt="Avatar" />
                            </div>
                          </>
                        );
                      })}
                    {value === 1 &&
                      questionData.videoLink != "null" &&
                      questionData.videoLink != "" && (
                        <>
                          <div className="questionExplainationImage video-lg">
                            <Vimeo video={questionData.videoLink} width={480}
                              onError={() => {
                                setOpenBreakdrop(false);
                                setNotify({
                                  isOpen: true,
                                  message: "Video Not Found",
                                  type: "error",
                                });
                              }}
                            />
                          </div>

                          <div className="questionExplainationImage video-sm">
                            <Vimeo video={questionData.videoLink} width={350}
                              onError={() => {
                                setOpenBreakdrop(false);
                                setNotify({
                                  isOpen: true,
                                  message: "Video Not Found",
                                  type: "error",
                                });
                              }}
                            />
                          </div>
                        </>
                      )}

                    <div className="explainationContent">
                      {questionData.answerExplanation != "null" &&
                        questionData.answerExplanation != "" && (
                          <div
                            className="fontLight"
                            dangerouslySetInnerHTML={{
                              __html: questionData.answerExplanation,
                            }}
                          ></div>
                        )}
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "var(--main-color)",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />

                    <Grid
                      container
                      sx={{ paddingBottom: { xs: "5rem" } }}
                      spacing={2}
                      xs={12}
                      sm={12}
                    >
                      <Grid item xs={6} sm={6}>
                        <div>
                          <p
                            style={{
                              color: "white",
                              fontSize: "16px",
                            }}
                          >
                            {questionData.unitName}{" "}
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        sx={{ textAlign: { xs: "right" } }}
                      >
                        <div>
                          <p
                            style={{
                              color: "white",
                              fontSize: "16px",
                            }}
                          >
                            {questionData.topicName}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={5}></Grid>
              )}
            </Grid>
          </div>

          <div
            className="container"
            style={{
              display: "flex",
              height: "7vh",
              flexDirection: "row",
              backgroundColor: "black",
              position: "fixed",
              width: "100%",
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <div
              style={{ cursor: "pointer", paddingLeft: "10px" }}
              className="row1"
              onClick={() => handleSuspendModel()}
            >
              <StopCircleIcon className="icon" />
              &nbsp;
              <p className="icon"> End</p>
            </div>
            {position < exam.length - 1 ? (
              <>
                <div className="row">
                  {position != 0 ? (
                    <Controls.Button
                      text="Previous"
                      onClick={() => clickOnPre()}
                      startIcon={<ArrowBackIcon />}
                      className="nextButton"
                      sx={{ marginRight: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                  <Controls.Button
                    text="Next"
                    onClick={() => clickOnNext()}
                    endIcon={<ArrowForwardIcon />}
                    className="nextButton"
                  />
                </div>
              </>
            ) : (
              <div className="row">
                {position != 0 ? (
                  <Controls.Button
                    text="Previous"
                    onClick={() => clickOnPre()}
                    startIcon={<ArrowBackIcon />}
                    className="nextButton"
                    sx={{ marginRight: "10px" }}
                  />
                ) : (
                  ""
                )}
                <Controls.Button
                  text="Submit"
                  onClick={() => {
                    handelSubmit();
                  }}
                  endIcon={<ArrowForwardIcon />}
                  className="nextButton"
                />
              </div>
            )}
          </div>

          <SuccessScreen open={modalState} handleClose={closeSuccessScreen} />
        </>
      )}
      <Modal
        keepMounted
        open={openSuspendModel}
        onClose={handleCloseSuspend}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Are you sure want to end this test?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseSuspend} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="End"
                style={{ backgroundColor: "red" }}
                onClick={() => handelSuspend()}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default Exam;
