import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../Components/controls/Controls";
import "../addExam/exam.css";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { examList } from "../../state/actions/exam";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useForm } from "../../Utils/useForm";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import SuccessScreen from "../../pages/SuccessScreen";
import Vimeo from "@u-wave/react-vimeo";
import { previewTest } from "../../state/actions/previousTestActions";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  AddTest,
  clearTestArray,
  testSubmit,
  suspendTest,
} from "../../state/actions/exam";
import {
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { store } from "../../state/store";
import ToastNotification from "../../Utils/toastNotification";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const initialValues = {
  name: "",
  unitId: "",
  allowNumberOfQuestion: "0",
};
const stylesTab = {
  tab: {
    color: "#fff",
  },
  tabItemContainer: {
    background: "none",
  },
};
const WhiteBorderTextField = styled(Radio)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

function PreviewTest() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const [couter, setCounter] = useState(0);
  const [mode, setMode] = useState(0);
  const [position, setPosition] = useState(0);
  const [value, setValue] = useState(0);
  console.log("out side position ", position);
  const [questionData, setQuestionData] = useState({});
  const [exam, setExam] = useState([]);
  const [submit, setSubmit] = useState(true);
  const [attend, setattend] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [video, setVideo] = useState("");
  const [reduxData, setReduxData] = useState([]);
  const [show, setShow] = useState(true);
  const [testId, setTestId] = useState("");
  const [open, setOpen] = useState(false);
  const [openSuspendModel, setOpenSuspendModel] = useState(false);
  const handleSuspendModel = () => setOpenSuspendModel(true);
  const handleCloseSuspend = () => setOpenSuspendModel(false);
  const handelSuspend = async () => {
    const res = await suspendTest(testId);
    if (res && res.status === true) {
      Navigate("/welcome");
      setNotify({
        isOpen: true,
        message: res.message || "Exam suspend successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (state && state.mode) {
      setMode(state.mode == "tutor" ? 1 : 2);
    }
    window.scrollTo(0, 0);
  }, [mode]);

  const openSuccessScreen = () => {
    setModalState(true);
  };
  const closeSuccessScreen = () => {
    setModalState(false);
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    setOpenBreakdrop(true);
    const res = await previewTest(state.testId);

    if (res && res.status === true) {
      setExam(res.data);
      setQuestionData(res.data[position]);
      setOpenBreakdrop(false);
      setTestId(res?.testId?.id);
    }
  };

  const handleRadioButtonChange = (e, id) => {
    const data =
      questionData &&
      questionData.options &&
      questionData.options.map((item) => {
        if (item.id == id) {
          item.answers = true;
        } else {
          item.answers = false;
        }
        return item;
      });
    setQuestionData({ ...questionData, options: data });
  };

  const clickOnNext = () => {
    setQuestionData(exam[position + 1]);
    setSubmit(false);
    window.scrollTo(0, 0);
    setPosition(position + 1);
    setOpenBreakdrop(true);
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 800);
    setValue(0);
  };
  const clickOnPre = () => {
    setQuestionData(exam[position - 1]);
    setSubmit(false);
    window.scrollTo(0, 0);
    setPosition(position - 1);
    setOpenBreakdrop(true);
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 800);
    setValue(0);
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <>
        <Grid
          container
          spacing={2}
          style={{
            height: "10vh",
            backgroundColor: "black",
            position: "fixed",
            // width: "100%",
            top: "0",
            zIndex: 99999,
          }}
        >
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={2} className="margin">
            <h3 className="fontLight testQue">
              {position + 1}/{exam.length}
              {/* {/ {mode === 2 ? position : position + 1}/{exam.length} /} */}
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3.8}
            className="margin"
            sx={{ paddingTop: { xs: "1px !important" } }}
          ></Grid>
          <Grid item xs={12} sm={0.2}></Grid>
        </Grid>

        <div
          className="testSection"
          style={{
            marginTop: "10vh",
            backgroundColor: "#41434e",

            // height: "fit-content",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {questionData &&
                questionData?.QuestionImage &&
                questionData?.QuestionImage.map((item, index) => {
                  return (
                    <div className="questionImage">
                      <img
                        src={item.imageName}
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  );
                })}
              {questionData &&
                questionData?.questionData &&
                questionData?.questionData.map((item, index) => {
                  return (
                    <>
                      <div className="question">
                        {item.description != "null" &&
                          item.description != "" && (
                            <div
                              className="fontLight"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          )}
                      </div>
                      <div className="question">
                        <p
                          style={{
                            color: "white",
                            lineHeight: '24px'
                          }}
                        >
                          {position + 1}. {item && item?.name}
                        </p>
                      </div>
                      <div className="questionAnswer">
                        <FormControl className="ratio-btn-container" sx={{ gap: '15px' }}>
                          <MuiRadioGroup
                            row={false}
                            className="ratio-btn"
                            name="answer"
                            value={1}
                            onChange={handleInputChange}
                          >
                            {item &&
                              item.option &&
                              item.option.map((option, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <>
                                      {option.answer == 1 && (
                                        <CheckIcon
                                          style={{
                                            color: "white",
                                            marginBottom: "auto",
                                            fontWeight: "bold",
                                          }}
                                        />
                                      )}

                                      {option.answers == 1 &&
                                        option.answer != 1 && (
                                          <ClearIcon
                                            style={{
                                              color: "red",
                                              marginBottom: "auto",
                                            }}
                                          />
                                        )}

                                      {option.answers != 1 &&
                                        option.answer != 1 && (
                                          <ClearIcon
                                            style={{
                                              color: "red",
                                              marginBottom: "auto",
                                              visibility: "hidden",
                                            }}
                                          />
                                        )}
                                    </>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: '20px',
                                        marginLeft: '12px',
                                      }}
                                    >
                                      <FormControlLabel
                                        // key={1}
                                        value="answers"
                                        onChange={(e) =>
                                          handleRadioButtonChange(e, option.id)
                                        }
                                        control={<WhiteBorderTextField />}
                                        disabled={submit}
                                        sx={{ gap: '10px', alignItems: 'start' }}
                                        label={
                                          <p
                                            style={{
                                              color: "white",
                                            }}
                                          >
                                            {option.name}
                                          </p>
                                        }
                                        checked={
                                          questionData.ansId == option.id
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </MuiRadioGroup>
                        </FormControl>
                      </div>
                    </>
                  );
                })}
              <Box
                sx={{
                  marginLeft: "4rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <p className="fontLight">
                  {questionData.attendQue == 1
                    ? "* Selected answer is your answer."
                    : "* Unanswered question."}
                </p>
              </Box>
            </Grid>

            <Grid item xs={12} sm={0.1}></Grid>

            <Grid item xs={12} sm={0.3} sx={{ display: { xs: "none" } }}>
              <div className="hr"></div>
            </Grid>

            <Grid item xs={12} sm={5.3} style={{}}>
              <Tabs
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
              >
                <Tab style={stylesTab.tab} label="Explanation" />
                {questionData &&
                  questionData?.questionData &&
                  questionData?.questionData[position - position].videoLink !=
                  "" ? (
                  <Tab style={stylesTab.tab} label="Video Explanation" />
                ) : (
                  ""
                )}
              </Tabs>
              {value === 0 &&
                questionData != "" &&
                questionData?.answerImage &&
                questionData?.answerImage != "null" &&
                questionData?.answerImage != "undefined" &&
                questionData?.answerImage != "" &&
                questionData?.answerImage.map((item, index) => {
                  return (
                    <>
                      <div className="questionExplainationImage">
                        <img src={item.imageName} alt="Avatar" />
                      </div>
                    </>
                  );
                })}
              {value === 1 &&
                questionData?.questionData &&
                questionData?.questionData[position - position].videoLink !=
                "null" &&
                questionData?.questionData[position - position].videoLink !=
                "" && (
                  <>
                    <div className="questionExplainationImage  video-lg">
                      <Vimeo
                        video={
                          questionData?.questionData[position - position]
                            .videoLink
                        }
                        width={480}
                        onError={() => {
                          setOpenBreakdrop(false);
                          setNotify({
                            isOpen: true,
                            message: "Video Not Found",
                            type: "error",
                          });
                        }}
                      />
                    </div>
                    <div className="questionExplainationImage  video-sm">
                      <Vimeo
                        video={
                          questionData?.questionData[position - position]
                            .videoLink
                        }
                        width={350}
                        onError={() => {
                          setOpenBreakdrop(false);
                          setNotify({
                            isOpen: true,
                            message: "Video Not Found",
                            type: "error",
                          });
                        }}
                      />
                    </div>
                  </>
                )}

              <div className="explainationContent">
                {questionData.questionData &&
                  questionData.questionData.answerExplanation != "null" &&
                  questionData.questionData.answerExplanation != "" && (
                    <div
                      className="fontLight"
                      dangerouslySetInnerHTML={{
                        __html: questionData?.questionData[0].answerExplanation,
                      }}
                    ></div>
                  )}
              </div>
              <Divider
                style={{
                  backgroundColor: "black",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />

              <Grid
                container
                sx={{ paddingBottom: { xs: "5rem" } }}
                spacing={2}
                xs={12}
                sm={12}
              >
                <Grid item xs={6} sm={6}>
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      {questionData.unitName}{" "}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} sx={{ textAlign: { xs: "right" } }}>
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      {questionData.topicName}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          className="container"
          style={{
            display: "flex",
            height: "7vh",
            flexDirection: "row",
            backgroundColor: "black",
            position: "fixed",
            width: "100%",
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              paddingLeft: "10px",
              width: "100%",
            }}
            className="row1"
            onClick={() => Navigate("/previoustest")}
          >
            &nbsp;
            <p className="icon"> Back to menu</p>
          </div>
          {position < exam.length - 1 ? (
            <>
              <div className="row">
                {position != 0 ? (
                  <Controls.Button
                    text="Previous"
                    onClick={() => clickOnPre()}
                    startIcon={<ArrowBackIcon />}
                    className="nextButton"
                    sx={{ marginRight: "10px" }}
                  />
                ) : (
                  ""
                )}
                <Controls.Button
                  text="Next"
                  onClick={() => clickOnNext()}
                  endIcon={<ArrowForwardIcon />}
                  className="nextButton"
                />
              </div>
            </>
          ) : (
            <div className="row">
              {position != 0 ? (
                <Controls.Button
                  text="Previous"
                  onClick={() => clickOnPre()}
                  startIcon={<ArrowBackIcon />}
                  className="nextButton"
                  sx={{ marginRight: "10px" }}
                />
              ) : (
                ""
              )}
              <Controls.Button
                text="Close"
                onClick={() => {
                  // setQuestionData(exam[position + 1]);
                  // setPosition(position + 1);
                  Navigate("/previoustest");

                  // openSuccessScreen(true)
                }}
                endIcon={<ArrowForwardIcon />}
                className="nextButton"
              />
            </div>
          )}
        </div>

        <SuccessScreen
          open={modalState}
          handleClose={closeSuccessScreen}
        // refreshScreen={refreshScreen}
        />
      </>
      <Modal
        keepMounted
        open={openSuspendModel}
        onClose={handleCloseSuspend}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Are you sure to want to Suspend this Test?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseSuspend} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Suspend"
                style={{ backgroundColor: "red" }}
                onClick={() => handelSuspend()}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default PreviewTest;
