import axiosRequest from "../axios/axios";

export const videoWiseUnitList = async (queryString) => {
    try {
        const res = await axiosRequest.get(`/api/v1/dashboarApiDataUserVideo`);
        return res.data;
    } catch (e) {
        return { success: false, message: e.response.data.message };
    }
};

export const videoListUnitWise = async (id) => {
    try {
        const res = await axiosRequest.get(`/api/v1/listVideoUnitWiseDashboard/${id}`);
        return res.data;
    } catch (e) {
        return { success: false, message: e.response.data.message };
    }
};


export const updateWatchTime = async (data) => {
    try {
        const res = await axiosRequest.post(`/api/v1/storeUserVideoWatchTime`, data);
        return res.data;
    } catch (e) {
        return { success: false, message: e.response.data.message };
    }
}
