// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import {
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Box,
//   Collapse,
// } from "@mui/material";
// import './sidebar.css';

// import { ExpandMore, ExpandLess, Person, Dashboard } from "@mui/icons-material";
// import { IoHomeOutline } from "react-icons/io5";
// import { TiShoppingCart } from "react-icons/ti";
// import BookIcon from "@mui/icons-material/Book";
// import SettingsIcon from "@mui/icons-material/Settings";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
// import PeopleIcon from "@mui/icons-material/People";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import ReportIcon from "@mui/icons-material/Report";
// import ControlCameraIcon from "@mui/icons-material/ControlCamera";
// import SupportIcon from "@mui/icons-material/Support";
// import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
// import { setTimeout } from "core-js";
// import { store } from "../../state/store";
// import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import TopicIcon from "@mui/icons-material/Topic";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// import Logo from "../../assets/images/biobuddy-logo-white.png";
// import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
// import NoteAltIcon from "@mui/icons-material/NoteAlt";
// import SpeedIcon from "@mui/icons-material/Speed";
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
// import dashboard_icon from "../../assets/images/sidebar_icon/dashboard_icon.png";
// import "./sidebar.css";
// const SelectedStyles = {
//   ".MuiListItemButton-root": {
//     backgroundColor: "#1976d2",
//     ".MuiListItemIcon-root img": {
//       color: "white",
//       filter: " brightness(0) invert(1)",
//     },

//     ".MuiSvgIcon-root": {
//       color: "white",
//     },
//     ".MuiListItemText-root": {
//       color: "white",
//     },
//   },
// };

// const Sidebar = () => {
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const pathname = location.pathname.substr(1);
// //   const [settingMenu, setSettingMenu] = useState(false);

// //   const [isExpanded, setIsExpanded] = useState(false);

// //   const handleMouseEnter = () => {
// //     setIsExpanded(true);
// //   };

// //   const handleMouseLeave = () => {
// //     setIsExpanded(false);
// //   };
// //   //  if unknown route is found then redirect it to dashboard page
// //   const [active, setActive] = useState(pathname);
// //   const [authUser, setAuthUser] = useState({});
// //   //redux in find the user role and permission

// //   //  to find out current selected tab
// //   const menus = [
// //     { menu: "dashboard", subMenu: [] },
// //     {
// //       menu: "users",
// //       subMenu: [],
// //     },
// //     { menu: "exam", subMenu: [] },
// //     { menu: "billing", subMenu: [] },
// //     { menu: "reports", subMenu: [] },
// //     { menu: "rto", subMenu: [] },
// //     { menu: "support", subMenu: [] },
// //     { menu: "setting", subMenu: [] },
// //   ];

// //   //  after refreshing the page select the respective tab
// //   const getCurrentTab = () => {
// //     var flag = false;
// //     //  matching at menu level
// //     for (let index = 0; index < menus.length; index++) {
// //       const element = menus[index];
// //       if (
// //         //  checking alternate path
// //         element.menu === pathname ||
// //         (element.menu !== "" && element?.alternatePath?.includes(pathname))
// //       ) {
// //         setActive(pathname);
// //         flag = true;
// //         break;
// //       } else {
// //         if (
// //           element.subMenu.includes(pathname) ||
// //           element?.alternatePath?.includes(pathname)
// //         ) {
// //           element.toggle();
// //           setActive(pathname);
// //           flag = true;
// //           break;
// //         }
// //       }
// //     }
// //   };

//   useEffect(() => {
//     // check if authUser is present in local storage
//     const storedAuthUser = localStorage.getItem("authUser");
//     if (storedAuthUser) {
//       setAuthUser(JSON.parse(storedAuthUser));
//     } else {
//       setTimeout(() => {
//         const { auth } = store.getState();
//         setAuthUser(auth.user);
//         localStorage.setItem("authUser", JSON.stringify(auth.user));
//       }, 500);
//     }
//   }, []);

// //   useEffect(() => {
// //     getCurrentTab();
// //   }, []);

// //   useEffect(() => {
// //     setActive(pathname);
// //   }, [pathname]);

// //   //  we have to close all sub menus when we click on other menu items
// //   const closeAllMenus = (key) => {
// //     const subMenusList = [{ key: "settingMenu", close: closeSettingMenu }];

// //     subMenusList.forEach((value) => {
// //       if (key === value.key) {
// //       } else {
// //         value.close();
// //       }
// //     });
// //   };
//   let MenusList;
//   const toggleSettingMenu = () => {
//     setSettingMenu(!settingMenu);
//   };
//   const closeSettingMenu = () => {
//     setSettingMenu(false);
//   };
//   if (authUser && authUser?.role === 1) {
//     MenusList = [
//       {
//         name: "Dashboard",
//         icon: Dashboard,
//         path: "",
//         subMenu: [],
//       },
//       {
//         name: "Users",
//         icon: PeopleIcon,
//         path: "users",
//         subMenu: [],
//       },
//       {
//         name: "Units",
//         icon: MenuBookIcon,
//         path: "units",
//         subMenu: [],
//       },
//       {
//         name: "Topics",
//         icon: TopicIcon,
//         path: "topics",
//         subMenu: [],
//       },
//       {
//         name: "Questions",
//         icon: QuestionMarkIcon,
//         path: "questions",
//         subMenu: [],
//       },

//       {
//         name: "FAQs / Help",
//         icon: QuestionAnswerIcon,
//         path: "support",

//         subMenu: [],
//       },
//       {
//         name: "Plans",
//         icon: RequestQuoteIcon,
//         path: "plans",
//         subMenu: [],
//       },
//       {
//         name: "Video Library",
//         icon: VideoLibraryIcon,
//         path: "videos",
//         subMenu: [],
//       },
//       {
//         name: "Orders",
//         icon: ShoppingCartIcon,
//         path: "orders",
//         subMenu: [],
//       },
//       {
//         name: "Coupons",
//         icon: LocalOfferIcon,
//         path: "coupons",
//         subMenu: [],
//       },
//       {
//         name: "Settings",
//         icon: SettingsIcon,
//         path: "settings",
//         isSubMenu: true,

//         subMenu: [
//           {
//             name: "Global Time",
//             icon: AccessTimeIcon,
//             path: "globalTime",
//           },
//           {
//             name: "Profile",
//             icon: Person,
//             path: "profile",
//           },
//         ],
//         key: "settingMenu",
//         state: settingMenu,
//         toggle: toggleSettingMenu,
//       },
//       {
//         name: "Log out",
//         icon: PowerSettingsNewIcon ,
//         path: "orders",
//         subMenu: [],
//       },
//     ];
//   } else {
//     MenusList = [
//       {
//         name: "Welcome",
//         icon: Dashboard,
//         path: "welcome",
//       },
//       {
//         name: "Video Library",
//         icon: VideoLibraryIcon,
//         path: "videolibrary",
//       },
//       {
//         name: "Create Test",
//         icon: NoteAltIcon,
//         path: "test",
//       },
//       {
//         name: "Previous Tests",
//         icon: SkipPreviousIcon,
//         path: "previoustest",
//       },
//       {
//         name: "Performance",
//         icon: SpeedIcon,
//         path: "performance",
//       },

//       {
//         name: "FAQ",
//         icon: QuestionAnswerIcon,
//         path: "faq",
//       },
//     ];
//   }

// const backToHome = () => {
//   window.location = process.env.REACT_APP_FRONT_URL;
// };

//   return (
//     <>
//       <div className={`sidebar ${isExpanded ? 'expanded' : ''}`} >
//         <Box sx={{ textAlign: "center", color: "white", marginTop: "25px" }}>
//           <img
//             src={Logo}
//             width="150"
//             style={{ cursor: "pointer" }}
//             onClick={() => backToHome()}
//           />
//         </Box>
// <Box className="PE_sidebar" sx={{ height: "80%", overflowY: "auto" }}>
//   <List
//     sx={{
//       ".MuiListItemButton-root": {
//         borderRadius: "10px",
//         color: "white",
//         "&:hover": {
//           backgroundColor: "#1976d2",
//           ".MuiListItemIcon-root img": {
//             filter: " brightness(0) invert(1)",
//           },
//           ".MuiSvgIcon-root": {
//             color: "white",
//           },
//           ".MuiListItemText-root": {
//             color: "white",
//           },
//         },
//         ".MuiSvgIcon-root": {
//           color: "white",
//         },
//       },
//     }}
//   >
//     {MenusList.map((value, i) => {
//       const flag = value.isSubMenu;
//       //  if it is not submenu then it is only menu no sub menus
//       if (!flag) {
//         return (
//           <ListItem
//             key={i}
//             sx={
//               active === value.path ||
//                 value?.alternatePath?.includes(active)
//                 ? SelectedStyles
//                 : {}
//             }
//             onClick={() => {
//               navigate("/" + value.path);
//               setActive(value.path);
//               closeAllMenus();
//             }}
//           >
//             <ListItemButton>
//               <ListItemIcon>
//                 {value.img ? (
//                   <img src={value.img} alt={value.name} />
//                 ) : (
//                   <value.icon />
//                 )}
//               </ListItemIcon>
//               <ListItemText primary={value.name} />
//             </ListItemButton>
//           </ListItem>
//         );
//       }
//       //  it means it is a submenus
//       if (flag) {
//         return (
//           <>
//             <ListItem
//               key={i}
//               onClick={() => {
//                 value.toggle();
//               }}
//             >
//               <ListItemButton>
//                 <ListItemIcon>
//                   {value.img ? (
//                     <img src={value.img} alt={value.name} />
//                   ) : (
//                     <value.icon />
//                   )}
//                 </ListItemIcon>
//                 <ListItemText primary={value.name} />
//                 {value.state ? <ExpandLess /> : <ExpandMore />}
//               </ListItemButton>
//             </ListItem>
//             {/* our submenus */}
// <Collapse in={value.state} timeout="auto" unmountOnExit>
//   <List component="div" sx={{ margin: "0px 5px 0px 20px" }}>
//     {/*  our submenus list */}
//     {value.subMenu.map((subMenuButton, j) => {
//       console.log("object", subMenuButton);
//       return (
//         <ListItem
//           sx={
//             active === subMenuButton.path ||
//               subMenuButton?.alternatePath?.includes(active)
//               ? SelectedStyles
//               : {}
//           }
//           onClick={() => {
//             navigate("/" + subMenuButton.path);
//             setActive(subMenuButton.path);
//             closeAllMenus(value.key);
//           }}
//         >
//           <ListItemButton>
//             <ListItemIcon>
//               {subMenuButton.img ? (
//                 <img
//                   src={subMenuButton.img}
//                   alt={subMenuButton.name}
//                 />
//               ) : (
//                 <subMenuButton.icon />
//               )}
//             </ListItemIcon>
//             <ListItemText primary={subMenuButton.name} />
//           </ListItemButton>
//         </ListItem>
//       );
//     })}
//   </List>
// </Collapse>
//           </>
//         );
//       }
//     })}
//   </List>
// </Box>
//       </div>
//     </>
//   );
// };

// export default Sidebar;
// src/Sidebar.js

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";
import Logo from "../../assets/images/biobuddy-logo-white.png";
import dashboard_icon from "../../assets/images/sidebar_icon/dashboard_icon.png";
import chart from "../../assets/images/sidebar_icon/support.png";
import home_icon from "../../assets/images/sidebar_icon/home.svg";
import unit_icon from "../../assets/images/sidebar_icon/unit.png";
import topics_icon from "../../assets/images/sidebar_icon/topic.png";
import order_icon from "../../assets/images/sidebar_icon/order.svg";
import support_icon from "../../assets/images/sidebar_icon/support.png";
import que_icon from "../../assets/images/sidebar_icon/question-icon.png";
import plane_icon from "../../assets/images/sidebar_icon/plane.png";
import logout_icon from "../../assets/images/sidebar_icon/logout.png";
import video_icon from "../../assets/images/sidebar_icon/video.png";
import coupon_icon from "../../assets/images/sidebar_icon/cuppon.png";
import setting_icon from "../../assets/images/sidebar_icon/setting.svg";
import users_icon from "../../assets/images/sidebar_icon/user.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { userSessionMentaion } from "../../state/actions/headerAction";
import { useDispatch } from "react-redux";
import { logoutApi } from "../../state/actions/authActions";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { store } from "../../state/store";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ExpandLess, ExpandMore, Person } from "@mui/icons-material";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState({});
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation(); // Get the current path
  const logoutAdmin = async () => {
    const res = await userSessionMentaion();
    if (res.status === true) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("token");
      dispatch(logoutApi());
      navigate("/");
    }
  };
  useEffect(() => {
    // check if authUser is present in local storage
    const storedAuthUser = localStorage.getItem("authUser");
    if (storedAuthUser) {
      setAuthUser(JSON.parse(storedAuthUser));
    } else {
      setTimeout(() => {
        const { auth } = store.getState();
        setAuthUser(auth.user);
        // localStorage.setItem("authUser", JSON.stringify(auth.user));
      }, 500);
    }
  }, []);
  let menuItems = [];
  const backToHome = () => {
    // window.location = process.env.REACT_APP_FRONT_URL;
    authUser?.role === 1 ? navigate("/") : navigate("/welcome");
  };
  const [user, setUser] = useState("");
  // const location = useLocativideoson();
  // console.log(location.pathname);
  // if(location.pathname==="/")

  if (authUser && authUser?.role === 1) {
    menuItems = [
      { path: "/", icon: dashboard_icon, label: "Dashboard" },
      // { path: "/newDashboard", icon: dashboard_icon, label: "New Dashboard" },
      { path: "/users", icon: users_icon, label: "Users" },
      { path: "/units", icon: unit_icon, label: "Units" },
      { path: "/topics", icon: topics_icon, label: "Topics" },
      { path: "/questions", icon: que_icon, label: "Questions" },
      { path: "/faq", icon: support_icon, label: "Support" },
      { path: "/plans", icon: plane_icon, label: "Plans" },
      { path: "/videos", icon: video_icon, label: "Video Library" },
      { path: "/orders", icon: order_icon, label: "Orders" },
      {
        path: "/coupons",
        icon: coupon_icon,
        label: "Coupons",
        style: { rotate: "275deg" },
      },
      {
        path: "#",
        icon: setting_icon,
        label: "Settings",
        subMenu: [
          { path: "/globalTime", icon: <AccessTimeIcon />, label: "Global Time" },
          { path: "/profile", icon: <Person />, label: "Profile" },
        ],
      },
    ];
  } else {
    menuItems = [
      // { path: "/userdashboard", icon: home_icon, label: "Home" },
      { path: "/welcome", icon: dashboard_icon, label: "Welcome" },
      { path: "/videolibrary", icon: video_icon, label: "Video Library" },
      { path: "/test", icon: users_icon, label: "Create Test" },
      { path: "/previoustest", icon: unit_icon, label: "Previous Tests" },
      { path: "/performance", icon: topics_icon, label: "Performance" },
      { path: "/faq", icon: support_icon, label: "FAQ" },
    ];
  }
  const handleMenuClick = (label) => {
    if (label === "Settings") {
      setIsSubMenuOpen(!isSubMenuOpen);
    }
  };
  return (
    <>
      <div className={`sidebar ${isOpen ? "" : "open"}`}>
        <nav className="menu">
          <Box
            sx={{
              textAlign: "center",
              color: "white",
              marginTop: "50px",
              marginBottom: "25px",
            }}
          >
            <img
              src={Logo}
              width="150"
              style={{ cursor: "pointer" }}
              onClick={backToHome}
            />
          </Box>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "start",
              color: "white",
            }}
          >
            {menuItems.map((item, index) => (
              <Link to={item.path} className="sidebarlink">
                <React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={() => handleMenuClick(item.label)}
                    selected={location.pathname === item.path}
                    sx={{
                      backgroundColor: location.pathname === item.path ? 'blue' : 'transparent',
                      '&.Mui-selected': {
                        backgroundColor: '#63a2f5 !important', // Ensure the selected background is blue
                      },
                      '&:hover': {
                        backgroundColor: location.pathname === item.path ? '#63a2f5' : '#63a2f5', // Slightly lighter blue on hover if not selected
                      },
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={item.icon}
                        className="sidebar_icon"
                        style={item.style || {}}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {item.subMenu &&
                      (isSubMenuOpen ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                  {item.subMenu && (
                    <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.subMenu.map((subItem, subIndex) => (
                          <Link to={subItem.path} className="sidebarlink">
                            <ListItem
                              button
                              key={subIndex}
                              selected={location.pathname === subItem.path}
                              sx={{
                                backgroundColor: location.pathname === item.path ? 'blue' : 'transparent',
                                '&.Mui-selected': {
                                  backgroundColor: '#63a2f5 !important', // Ensure the selected background is blue
                                },
                                '&:hover': {
                                  backgroundColor: location.pathname === item.path ? '#63a2f5' : '#63a2f5', // Slightly lighter blue on hover if not selected
                                },
                                pl: 6
                              }}
                            >
                              <ListItemIcon sx={{ color: "white", minWidth: "24px", height: "24px" }}>{subItem.icon}</ListItemIcon>
                              <ListItemText primary={subItem.label} sx={{ pl: 2 }} />
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              </Link>
            ))}
            <ListItem
              button
              onClick={logoutAdmin}
              sx={{
                marginTop: "70px",
                position: "sticky",
                top: "0",
                display: "flex",
              }}
            >
              <ListItemIcon>
                <img src={logout_icon} className="sidebar_icon" />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;

