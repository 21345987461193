import React, { useState } from "react";
import "./style/TestsSummaryTable.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const data = [
  {
    unit: "Unit 1: Chemistry of Life",
    totalVideoViews: "88",
    totalVideoCompleted: "77",
    totalVideoWatchTime: "12:38:01",
    subdata: [
      {
        unit: "1.1 Structure of water and Hydrogen Bonding",
        totalVideoViews: "2",
        totalVideoCompleted: "0",
        totalVideoWatchTime: "00:40:30",
        avgVideoWatchTime: "00:00:00",
      },
      {
        unit: "1.2 Elements of Life",
        totalVideoViews: "5",
        totalVideoCompleted: "5",
        totalVideoWatchTime: "00:40:30",
        avgVideoWatchTime: "00:08:06",
      },
      {
        unit: "1.3 Introduction to Biological Macromolecules",
        totalVideoViews: "1",
        totalVideoCompleted: "0",
        totalVideoWatchTime: "00:00:00",
        avgVideoWatchTime: "00:00:00",
      },
      {
        unit: "1.4 Properties of Biological Macromolecules",
        totalVideoViews: "1",
        totalVideoCompleted: "0",
        totalVideoWatchTime: "00:00:00",
        avgVideoWatchTime: "00:00:00",
      },
      {
        unit: "1.5 Structure and Function of Biological Macromolecules",
        totalVideoViews: "1",
        totalVideoCompleted: "0",
        totalVideoWatchTime: "00:00:00",
        avgVideoWatchTime: "00:00:00",
      },
      {
        unit: "1.6 Nucleic Acids",
        totalVideoViews: "3",
        totalVideoCompleted: "0",
        totalVideoWatchTime: "00:00:00",
        avgVideoWatchTime: "00:00:00",
      },
    ],
  },
  {
    unit: "Unit 2: Cell Structure & Function",
    totalVideoViews: "62",
    totalVideoCompleted: "30",
    totalVideoWatchTime: "11",
    subdata: [
      {
        unit: "2.1 Cell Structure: Subcellular Components",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.2 Cell Structure and Function",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.3 Cell Size",
        totalVideoViews: "4",
        totalVideoCompleted: "2",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.4 Plasma Membranes",
        totalVideoViews: "4",
        totalVideoCompleted: "2",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.5 Membrane Permeability",
        totalVideoViews: "11",
        totalVideoCompleted: "3",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.6 Membrane Transport",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.7 Facilitated Diffusion",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.8 Tonicity and Osmoregulation",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.9 Mechanisms of Transport",
        totalVideoViews: "11",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "2.10 Cell Compartmentalization",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
    ],
  },
  {
    unit: "Unit 3: Cellular Energetics",
    totalVideoViews: "22",
    totalVideoCompleted: "12",
    totalVideoWatchTime: "7",
    subdata: [
      {
        unit: "3.1 Enzyme Structure",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.2 Enzyme Catalysis",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.3 Eniviromental Imapcts on Enzme Function",
        totalVideoViews: "4",
        totalVideoCompleted: "3",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.4 Cellular Energy",
        totalVideoViews: "4",
        totalVideoCompleted: "3",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.5 Photosynthesis",
        totalVideoViews: "11",
        totalVideoCompleted: "3",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.6 Cellular Respiration",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
      {
        unit: "3.7 Fitness",
        totalVideoViews: "4",
        totalVideoCompleted: "1",
        totalVideoWatchTime: "1",
      },
    ],
  },
  {
    unit: "Unit 4: Cell Communication & Cell Cycle",
    totalVideoViews: "33",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "NN",
    subdata: [],
  },
  {
    unit: "Unit 5: Heredity",
    totalVideoViews: "29",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "NN",
    subdata: [],
  },
  {
    unit: "Unit 6: Gene Expression & Regulation",
    totalVideoViews: "11",
    totalVideoCompleted: "22",
    totalVideoWatchTime: "NN",
    subdata: [],
  },
  {
    unit: "Unit 7: Natural Selection",
    totalVideoViews: "30",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "NN",
    subdata: [],
  },
  {
    unit: "Unit 8: Ecology",
    totalVideoViews: "36",
    totalVideoCompleted: "NN",
    totalVideoWatchTime: "NN",
    subdata: [],
  },
];

const CreateTestsSummaryTable = ({ data, userId }) => {
  const navigate = useNavigate();
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleRowClick = (unitName) => {
    setSelectedUnit(selectedUnit === unitName ? null : unitName);
  };

  return (
    <div className="table-container" style={{ padding: "0px" }}>
      <div style={{ overflowX: "auto" }}>
        <table className="newtable">
          <thead>
            <tr>
              <th className="tableheader">Unit & Topic</th>
              <th className="tableheader">Total Created Tests</th>
              <th className="tableheader">Total Tests Started</th>
              <th className="tableheader">Total Completed Tests</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => handleRowClick(item.unitName)}
                  style={{
                    backgroundColor:
                      (item.completedCount == 0 && item.startedCount == 0) ? "#ac5ac3" : selectedUnit === item.unitName ? "" : "#191e26",
                  }}
                  className={selectedUnit === item.unitName ? "selected" : ""}
                >
                  <td style={{ textAlign: "left" }} className="tablebody">
                    {item.unitName}
                  </td>
                  <td className="tablebody">{item.createdCount}</td>
                  <td className="tablebody">{item.startedCount}</td>
                  <td className="tablebody">{item.completedCount}</td>
                </tr>
                {selectedUnit === item.unitName &&
                  item && item.topics && item.topics.length > 0 && item.topics.map((subItem, subIndex) => (
                    <tr
                      key={`${index}-${subIndex}`}
                      className="subdata selected"
                      style={{ backgroundColor: "#0d1216" }}
                    >
                      <td style={{ textAlign: "left" }} className="tablebody">
                        {subItem.topicName}
                      </td>
                      <td className="tablebody">{subItem.createdCount}</td>
                      <td className="tablebody">{subItem.startedCount}</td>
                      <td className="tablebody">{subItem.completedCount}</td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Link to="/previoustest"> */}
      <button className="see-all-users" style={{ marginTop: "20px" }} onClick={() => {
        navigate("/previoustest", {
          state: {
            userId: userId
          }
        });
      }
      }>
        See Tests Created
      </button>
      {/* </Link> */}
    </div>
  );
};

export default CreateTestsSummaryTable;
