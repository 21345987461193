import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import { MenuItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import {
  Logout as LogoutIcon,
  AccountCircle,
  Person,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Logo from "../../assets/images/biobuddy-logo-white.png";
import "./navbar.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userSessionMentaion } from "../../state/actions/headerAction";
import { logoutApi } from "../../state/actions/authActions";
import { store } from "../../state/store";
import { useDispatch } from "react-redux";
import Modal from "../../pages/checkout/LeaveModal";
import {
  orderCancel,
  orderRemoveDispatch,
} from "../../state/actions/orderActions";
import { cartRemoveDispatch } from "../../state/actions/cartActions";
import { testRunning } from "../../state/actions/exam";
import BackdropComponent from "../Backdrop/Backdrop";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [cartBadge, setCartBadge] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const Navigate = useNavigate();
  let { id } = useParams();
  const [admin, setAdmin] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { auth } = store.getState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const { order } = store.getState();

  const logoutAdmin = async () => {
    const res = await userSessionMentaion();
    if (res.status == true) {
      dispatch(logoutApi());
      Navigate("/");
    }
  };

  useEffect(() => {
    let timeout = setInterval(() => {
      let { auth } = store.getState();
      if (auth.user) {
        // if data is on store then we have to update the state of user with userdata and stop the timeout
        setAdmin(auth.user);
        stoptimeout();
      }
    }, 500);
    function stoptimeout() {
      clearInterval(timeout);
    }
  }, []);
  useEffect(() => {
    try {
      const cartCount = localStorage.getItem("cartItemId");
      setCartBadge(cartCount);
    } catch (error) {
      setCartBadge(null);
    }
  }, [id]);

  const [modalOpen, setModalOpen] = useState({
    isOpen: false,
    path: "",
  });

  const handleCancel = () => {
    setModalOpen({
      isOpen: false,
      path: "",
    });
  };

  const handleLeave = async () => {
    setOpenBreakdrop(true);
    const result = await orderCancel(order.payload?.orderdetail.id);
    if (result && result.status == true) {
      const payload = "";
      const res = await dispatch(orderRemoveDispatch(payload));

      if (res && res == true) {
        const payload = "";
        const resData = await dispatch(cartRemoveDispatch(payload));
        dispatch(testRunning(false));
        if (modalOpen.path == "buy-now" || modalOpen.path == "profile") {
          Navigate(`/${modalOpen.path}`);
          setModalOpen({
            isOpen: false,
            path: "",
          });
        } else if (modalOpen.path == "logout") {
          logoutAdmin();
        } else {
          window.location.href = `${process.env.REACT_APP_FRONT_URL}${modalOpen.path}`;
          setModalOpen({
            isOpen: false,
            path: "",
          });
        }
        setOpenBreakdrop(false);
      } else {
        setOpenBreakdrop(false);
      }
    } else {
      setOpenBreakdrop(false);
    }
  };

  const handelOrderCancel = async (path) => {
    console.log("path", path);
    if (pathname == "checkout") {
      setModalOpen({
        isOpen: true,
        path: path,
      });
    } else if (path == "buy-now") {
      Navigate(`/buy-now`);
    } else if (path == "profile") {
      Navigate(`/profile`);
    } else if (path == "logout") {
      logoutAdmin();
    } else {
      window.location.href = `${process.env.REACT_APP_FRONT_URL}${path}`;
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpen.isOpen}
        onCancel={handleCancel}
        onLeave={handleLeave}
      />

      <AppBar
        sx={{
          backgroundColor: "#000",
        }}
        position="fixed"
        onClick={handleShowNavbar}
      >
        <nav className="navbar">
          <div className="navContainer">
            <div className="menu-icon" onClick={handleShowNavbar}>
              <MenuIcon sx={{ xs: { height: "1rem" } }} />
              <span style={{ paddingTop: "2px", marginLeft: "5px" }}>
                {" "}
                MENU
              </span>
            </div>
            <div
              onClick={() => {
                handelOrderCancel("");
              }}
            >
              <a className="logobar" href={process.env.REACT_APP_FRONT_URL}>
                <img className="logo" src={Logo} />
              </a>
            </div>
            <div className="menu-item">
              <div className={`nav-elements  ${showNavbar && "active"}`}>
                <ClearIcon
                  className="clear-btn"
                  onClick={handleShowNavbar}
                  style={{
                    cursor: "pointer",
                    color: "#efefef",
                    position: "absolute",
                    left: "14.5rem",
                    height: "32px",
                    width: "32px",
                  }}
                />
                <ul>
                  <li
                    className="logo-mbl"
                    // onClick={() => Navigate(`/buy-now`)}
                  >
                    <a
                      className="logobar"
                      href={process.env.REACT_APP_FRONT_URL}
                    >
                      <img className="logo-side" src={Logo} />
                    </a>
                  </li>
                  {/* <li onClick={() =>
                    handelOrderCancel('buy-now')
                  }>
                    <a className={!modalOpen.isOpen ? "animation-hover" : ""}
                    >BUY NOW</a>
                  </li> */}

                  {/* <li onClick={() => handelOrderCancel("ap-biology")}>
                    <a
                      className={!modalOpen.isOpen ? "animation-hover" : ""}
                      // href={`${process.env.REACT_APP_FRONT_URL}ap-biology`}
                    >
                      CURRICULUM
                    </a>
                  </li> */}
                  <li
                  // onClick={() => handelOrderCancel("about-biobuddy")}
                  >
                    <a
                      className={!modalOpen.isOpen ? "animation-hover" : ""}
                      // href={`${process.env.REACT_APP_FRONT_URL}why-biobuddy`}
                      href="https://biobuddy.com/for-educators/"
                      target="_blank"
                    >
                      FOR EDUCATORS
                    </a>
                  </li>
                  <li
                  // onClick={() => handelOrderCancel("faq")}
                  >
                    <a
                      className={!modalOpen.isOpen ? "animation-hover" : ""}
                      // href={`${process.env.REACT_APP_FRONT_URL}faq`}
                      href="https://biobuddy.com/faq/"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </li>
                  <li
                  // onClick={() => handelOrderCancel("contact-us")}
                  >
                    <a
                      className={!modalOpen.isOpen ? "animation-hover" : ""}
                      // href={`${process.env.REACT_APP_FRONT_URL}contact-us`}
                      href="https://biobuddy.com/contact-us/"
                      target="_blank"
                    >
                      CONTACT US
                    </a>
                  </li>

                  {auth.token == null ? (
                    <li onClick={() => Navigate(`/login`)}>
                      <a className={!modalOpen.isOpen ? "animation-hover" : ""}>
                        LOGIN
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li style={{ marginRight: "0px", cursor: "pointer" }}>
                    <button
                      onClick={() => Navigate(`/register`)}
                      className="trailbtn"
                    >
                      {/* 7-Day Free Trial */}
                      {/* Register FREE */}
                      Sign Up
                    </button>
                  </li>
                </ul>
              </div>

              {auth.isAuthenticated ? (
                <Toolbar sx={{ paddingLeft: "0px !important" }}>
                  <Box />
                  <Box sx={{}}>
                    <Button
                      sx={{
                        borderRadius: "10px",
                        padding: "0",
                        color: "black",
                        alignItems: "center",
                      }}
                      onClick={handleClick}
                    >
                      <Avatar src={<AccountCircle />} /> &nbsp;
                    </Button>
                    <Menu
                      sx={{
                        mt: 0.3,
                        "&#user-profile .MuiPaper-root ": {
                          width: "190px",
                          borderRadius: "10px",
                        },
                      }}
                      id="user-profile"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        sx={{ mt: 1 }}
                        onClick={() => {
                          // Navigate("/profile");
                          handelOrderCancel("profile");
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        <ListItemText>Profile</ListItemText>
                      </MenuItem>
                      <MenuItem
                        sx={{ mt: 1 }}
                        onClick={() => handelOrderCancel("logout")}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Toolbar>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
      </AppBar>
      <BackdropComponent open={openBackdrop} />
    </>
  );
};

export default Header;
