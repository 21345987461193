import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
const WhiteBorderTextField = styled(Radio)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
export default function RadioGroup(props) {

  const { name, label, value, onChange, items, isRowDir } = props;
  return (
    <FormControl className="ratio-btn-container">
      <FormLabel style={{ color: "white" }}>{label}</FormLabel>
      <MuiRadioGroup
        row={isRowDir}
        className="ratio-btn"
        name={name}
        value={value}
        onChange={onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<WhiteBorderTextField />}
            label={<Typography color="white">{item.title}</Typography>}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
