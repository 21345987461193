import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TablePagination from "../../Components/tablePagination";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/controls/Controls";
import AddVideoLibrary from "./AddVideoLibrary";
import Divider from "@mui/material/Divider";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import Moment from "moment";
import { styled } from "@mui/system";
import { deleteFaq, changeStatus } from "../../state/actions/faqAction";
import { deleteVideoLibrary } from "../../state/actions/videoLibraryActions";
import Vimeo from "@u-wave/react-vimeo";
import { Tooltip } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",

  boxShadow: 24,
  p: 2,
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0957dd0a",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#404250",
  },
  " td, th": {
    color: "#efefef",
  },
}));
const VideoLibraryList = (props) => {
  const {
    videolist,
    VideoLibraryListData,
    setNotify,
    open,
    setOpen,
    editId,
    setEditId,
  } = props;
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [videoUrlLink, setVideoUrlLink] = useState("");
  const handleOpen = () => setOpen(true);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleDelete = async (id) => {
    const res = await deleteVideoLibrary(deleteId);
    if (res && res.status === true) {
      handleCloseDelete();
      VideoLibraryListData();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  useEffect(() => { }, [editId]);

  const setEditData = (video) => {
    setOpen(true);
    setEditId(video);
  };

  return (
    <>
      <Grid container style={{ marginTop: "1rem" }}>
        {videolist.length > 0 &&
          videolist.map((item, index) => {
            return (
              <>
                <Grid item xs={12}>
                  <Typography
                    key={index}
                    className="fontLight"
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1 }}
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                  >
                    {item.name}
                  </Typography>
                  <Divider sx={{ bgcolor: "white", mt: 1 }} />
                </Grid>

                {item.video &&
                  item.video.length > 0 &&
                  item.video.map((video, index) => {
                    let videoUrl = ""
                    if (video.videoLink.startsWith("http://") || video.videoLink.startsWith("https://")) {
                      videoUrl = new URL(video?.videoLink)?.pathname.split("/");
                    }
                    // new URL(video?.videoLink)?.pathname.split("/");
                    let topic = video.topicId.split(",");
                    return (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            key={index}
                            className="fontLight"
                            component="div"
                            sx={{ flexGrow: 1 }}
                            style={{
                              marginTop: "20px",
                              marginLeft: "10px",
                              fontSize: "18px",
                            }}
                          >
                            {topic.join(", ")}{" "}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          gap={0.5}
                          xs={5.6}
                          md={3}
                          lg={3}
                          style={{
                            marginTop: "1rem",
                            marginLeft: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",

                              marginBottom: "10px",
                            }}
                          >
                            <Tooltip title={video.title} placement="bottom">
                              <Typography
                                className="fontLight"
                                sx={{ fontSize: "14px" }}
                              >
                                {video && video.title && video.title.length > 25
                                  ? video.title.substring(0, 25) + "..."
                                  : video.title}
                              </Typography>
                            </Tooltip>
                            <div>
                              <Button
                                style={{
                                  minWidth: "40px",
                                  marginRight: "5px",
                                  backgroundColor: "#1976d2",
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() => setEditData(video)}
                              >
                                <EditIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFF",
                                  }}
                                />
                              </Button>
                              <Button
                                style={{
                                  minWidth: "40px",
                                  backgroundColor: "#e91e63",
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  handleDeleteModel();
                                  setDeleteId(video.id);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFF",
                                  }}
                                />
                              </Button>
                            </div>
                          </div>

                          <img
                            onClick={() => {
                              setIsOpen(true);
                              setVideoUrlLink(videoUrl[1]);
                            }}
                            src={video.thumbnail}
                            width="280"
                            height="160"
                            style={{ cursor: "pointer" }}
                          />
                        </Grid>
                      </>
                    );
                  })}
              </>
            );
          })}
      </Grid>
      {videolist && videolist.length == 0 ? (
        <div className="noDataFound fontLight "> No Data Found</div>
      ) : null}

      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        videoId={videoUrlLink}
        onClose={() => setIsOpen(false)}
      />
      <Modal
        keepMounted
        open={open}
        // onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <AddVideoLibrary
            handleClose={handleClose}
            open={open}
            setOpen={setOpen}
            setNotify={setNotify}
            VideoLibraryListData={VideoLibraryListData}
            editId={editId}
          />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <label className="fontLight">
            Are you sure to want to delete this Video?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Delete"
                style={{ backgroundColor: "red" }}
                onClick={() => handleDelete(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default VideoLibraryList;
