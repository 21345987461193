import axiosRequest from "../axios/axios";

export const addPlan = async (payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/addsubscriptionplan`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateplan = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `/api/v1/updateplan/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const planList = async (string) => {
  try {
    const response = await axiosRequest.get(
      `/api/v1/adminListSubscriptionPlan${string}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const forCartList = async (string) => {
  try {
    const response = await axiosRequest.get(
      `/api/v1/Subplans`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.put(
      `/api/v1/statusChange/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deletePlan = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deletePlan/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const planDescriptionRemove = async (id) => {
  try {
    const response = await axiosRequest.delete(
      `/api/v1/deleteDescription/${id}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
