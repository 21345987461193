import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  Box,
  TextField,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Controls from "../../Components/controls/Controls";
import Modal from "@mui/material/Modal";
import ToastNotification from "../../Utils/toastNotification";
import { useForm, Form } from "../../Utils/useForm";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { store } from "../../state/store";
import {
  getUserDetails,
  updateProfile,
  planDropdown,
} from "../../state/actions/userActions";
import { useNavigate } from "react-router-dom";
import {
  getCountryDropDownList,
  stateDropdown,
  updateUserByAdmin,
  addUserData,
} from "../../state/actions/userActions";

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const initialValues = {
  name: "",
  email: "",
  address: "",
  countryId: "1",
  stateId: "",
  zipcode: "",
  mobile: "",
  planId: "",
};

export default function AddUser(props) {
  const {
    handleClose,
    setNotify,
    userListData,
    editId,
    page,
    viewUser,
    addUsers,
    selectedId,
    handleOpen,
    setViewUser,
    setEditId,
    setSelectedId,
    idEditable,
  } = props;

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [state, setState] = useState("");
  const [userPlanData, setUserPlanData] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [ResetPasswordOpen, setResetPasswordOpen] = useState(null);
  const [planDropDownList, setPlanDropDownList] = useState([]);
  const [stateDropDownList, setStateDropDownList] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("zipcode" in fieldValues) {
      let regex = /^[0-9]{5}$/;
      if (fieldValues.zipcode)
        if (!regex.test(fieldValues.zipcode)) {
          temp.zipcode = "Please enter valid zipcode";
        } else {
          temp.zipcode = "";
        }
    }
    if ("mobile" in fieldValues) {
      let regex = /^[0-9]{10}$/;
      if (fieldValues.mobile)
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = "Please enter valid mobile number";
        } else {
          temp.mobile = "";
        }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const stateListByCountryId = async () => {
    const res = await stateDropdown(values.countryId);
    if (res && res.status === true) {
      setStateDropDownList(res.data);
    } else {
      setStateDropDownList([]);
    }
  };
  const getPlanDropDownList = async () => {
    const res = await planDropdown();
    if (res && res.status === true) {
      setPlanDropDownList(res.data);
    } else {
      setPlanDropDownList([]);
    }
  };
  const getuserData = async (id) => {
    setOpenBreakdrop(true);
    const res = await getUserDetails(id);
    if (res && res.status === true) {
      setUserPlanData(res.data.planData);
      setState(res.data.stateName.name);
      setValues({
        ...values,
        name: res.data.name,
        email: res.data.email,
        address: res.data.userAddress.address,
        stateId: res.data.stateName.id,
        zipcode: res.data.userAddress.zipcode,
        mobile: res.data.mobile,
        planId: res.data.planId,
      });
      setOpenBreakdrop(false);
    } else {
      setStateDropDownList([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        name: values.name,
        email: values.email,
        address: {
          address: values.address,
          country: 2,
          state: values.stateId,

          zipcode: values.zipcode,
        },
        mobile: values.mobile,
        planId: values.planId,
      };
      if (isEdit) {
        const res = await updateUserByAdmin(editId ?? selectedId, payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          resetForm();
          userListData(1);
          handleClose();
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      } else {
        const res = await addUserData(payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          resetForm();
          userListData(page);
          handleClose();
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (selectedId) {
      if (viewUser) {
        getuserData(selectedId);
        setIsEdit(false);
        setIsAdd(false);
      } else {
        getuserData(selectedId);
        setIsEdit(true);
        setIsAdd(false);
      }
    } else if (addUsers) {
      setIsAdd(true);
    }
  }, [selectedId, viewUser, addUsers, handleOpen]);

  useEffect(() => {
    getPlanDropDownList();
    stateListByCountryId();
  }, []);

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title" style={{ marginBottom: "0px" }}>
              User details
            </h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                text="SEE USER PERFORMANCE"
                style={{
                  marginRight: "30px",
                }}
                onClick={() => {
                  navigate("/performance", {
                    state: { userId: selectedId },

                  });
                }}


              ></Controls.Button>
              {viewUser && (
                <Controls.Button
                  text="Edit"
                  // disabled={idEditable}
                  onClick={() => {
                    setViewUser(false);
                    handleOpen();
                  }}
                />
              )}

              <ClearIcon
                onClick={() => {
                  setSelectedId(null);
                  setIsEdit(false);

                  handleClose();
                  resetForm();
                }}
                style={{
                  cursor: "pointer",
                  color: "#efefef",
                  marginLeft: "10px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            container
            spacing={1}
            style={{
              marginTop: "10px",
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              style={{
                textAlign: "center",
                padding: "20px",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow key={"name"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        width: "15%",
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      Name
                    </TableCell>
                    {isEdit || isAdd ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.name}
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {values.name || ""}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow key={"email"}>
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      Email
                    </TableCell>

                    {isEdit || isAdd ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.email}
                          error={Boolean(errors.email)}
                          helperText={errors.email}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {values.email || ""}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow key={"mobile"}>
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      Mobile
                    </TableCell>
                    {isEdit || isAdd ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="mobile"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.mobile}
                          error={Boolean(errors.mobile)}
                          helperText={errors.mobile}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {values.mobile || ""}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{
                textAlign: "center",
                padding: "20px",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow key={"address"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        width: "15%",
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      Address
                    </TableCell>
                    {isEdit || isAdd ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="address"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.address}
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(errors.address)}
                          helperText={errors.address}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {values.address || ""}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow key={"coutry"}>
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      Country
                    </TableCell>

                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      United States
                    </TableCell>
                  </TableRow>
                  <TableRow key={"state"}>
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      State
                    </TableCell>
                    {isEdit || isAdd ? (
                      <TableCell>
                        <Controls.SelectBox
                          name="stateId"
                          onChange={handleInputChange}
                          value={values.stateId}
                          options={stateDropDownList}
                          error={errors.stateId}
                          label={values && values.stateId ? "" : "Select State"}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {state || ""}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow key={"zipcode"}>
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      Zipcode
                    </TableCell>
                    {isEdit || isAdd ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="zipcode"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.zipcode}
                          error={Boolean(errors.zipcode)}
                          helperText={errors.zipcode}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ pl: 2, color: "white", fontSize: "16px" }}
                      >
                        {values.zipcode || ""}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <div style={{ padding: "20px" }}>
            <h3 className="fontLight">Subscription Details</h3>
            <Table>
              <TableBody>
                <TableRow key={"name"}>
                  <TableCell
                    sx={{
                      pl: 2,
                      width: "15%",
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    Plan Type
                  </TableCell>

                  {isEdit || isAdd ? (
                    <TableCell>
                      <Controls.SelectBox
                        name="planId"
                        onChange={handleInputChange}
                        value={values.planId}
                        options={planDropDownList}
                        error={errors.planId}
                        label={
                          userPlanData && userPlanData.finalPrice != "0.00"
                            ? ""
                            : "Select Plan"
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                      {userPlanData?.planName || "Trail"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow key={"email"}>
                  <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                    Duration
                  </TableCell>

                  <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                    {userPlanData?.planExpirationDuration}{" "}
                    {addUsers
                      ? ""
                      : userPlanData?.planDurationType == 3
                        ? "Day"
                        : "Month"}
                  </TableCell>
                </TableRow>

                <TableRow key={"email"}>
                  <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                    Price
                  </TableCell>
                  <TableCell sx={{ pl: 2, color: "white", fontSize: "16px" }}>
                    {addUsers
                      ? ""
                      : userPlanData?.planDurationType == 3
                        ? "Free"
                        : `$${userPlanData?.planPrice}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {!viewUser ? (
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6} className="text-end">
                  <Controls.Button
                    text="Cancel"
                    className="cancel-btn"
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);

                      resetForm();
                    }}
                  />
                  <Controls.Button
                    text={isEdit ? "SAVE" : "ADD"}
                    type="submit"
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </div>
        </Form>
      </div>
    </>
  );
}
