import React, { useState } from "react";
import "./login.css";
import QuizImage from "../../assets/images/quiz.jpg";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../Utils/useForm";
import ToastNotification from "../../Utils/toastNotification";
import { loginUserApi } from "../../state/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const initialValues = { email: "", password: "" };
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "50vh",
  position: "relative",
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "50vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));
const Login = () => {
  const Navigate = useNavigate();
  const xs = useMediaQuery("@media (max-width:600px)");
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [auth] = useSelector((store) => store.auth.isAuthenticated);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  const getauthUser = JSON.parse(localStorage.getItem("authUser"))

  // if (isAuthenticated) {
  //   if (auth?.user?.role == 1) {
  //     Navigate("/");
  //   } else {
  //     Navigate("/welcome");
  //   }
  // }

  if (getauthUser) {
    console.log("getauthUser", getauthUser.role)

    if (getauthUser?.role == 1) {
      Navigate("/");
    } else {
      Navigate("/welcome");
    }
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 characters";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const LoginResponse = await dispatch(
        loginUserApi({
          email: values.email,
          password: values.password,
        })
      );
      if (LoginResponse && LoginResponse.status === true) {
        // toastNotification(1);
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Successfully.",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Fail.",
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#021759",
          width: "70%",
          margin: "auto",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{}}>
          <StyledLeftSide>
            <img
              src={QuizImage}
              style={{
                width: "100%",
                height: "100%",
              }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Login
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  Login to manage your account
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel
                    sx={{
                      color: "white",
                    }}
                  >
                    Email
                  </StyledLabel>
                  <WhiteBorderTextField
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },

                      mt: 1,
                      mr: 3,
                      borderRadius: "8px",
                    }}
                    size="small"
                    fullWidth
                  />
                  {Boolean(errors.email) ? (
                    <p style={{ color: "red", marginTop: "2px" }}>
                      {errors.email}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel
                    sx={{
                      color: "white",
                    }}
                  >
                    Password
                  </StyledLabel>
                  <WhiteBorderTextField
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },

                      mt: 1,
                      mr: 3,
                      borderRadius: "8px",
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton
                              sx={{ color: "white" }}
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {Boolean(errors.password) ? (
                    <p style={{ color: "red", marginTop: "2px" }}>
                      {errors.password}
                    </p>
                  ) : (
                    ""
                  )}
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      className="fontLight"
                      label="Remember me"
                      control={
                        <Checkbox
                          sx={{ color: "white" }}
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                    />
                  </FormGroup>
                  <Button
                    // onClick={() => {
                    //   openForgotPasswordModal();
                    // }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Forgot Password?
                  </Button>
                </Stack>
                <StyledLogin sx={{ mt: 3 }} type="submit">
                  Login
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
      <ToastNotification notify={notify} setNotify={setNotify} />;
    </>
  );
};
export default Login;
