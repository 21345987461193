import React from 'react';
import './Modal.css';
import { Grid } from '@mui/material';
import Controls from '../../Components/controls/Controls';
function Modal({ isOpen, onCancel, onLeave }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        {/* <p style={{
          color: "black",
          fontSize: "1.2rem",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "0.5rem",
        }} >Leave page?</p> */}
        <p className='fontLight'
        >Are you sure you want to cancel your order?</p>
        <div className="button-group">

          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={onCancel} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Yes"
                onClick={onLeave}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Modal;
