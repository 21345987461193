import { Grid, Box, Drawer, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import Controls from "../../Components/controls/Controls";
import AddIcon from "@mui/icons-material/Add";
import OrderList from "./OrderList";
import InputAdornment from "@mui/material/InputAdornment";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { Search as SearchIcon } from "@mui/icons-material";
import ToastNotification from "../../Utils/toastNotification";
import { OrderLists } from "../../state/actions/orderActions";
import { useForm, Form } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
const initialValues = {
  status: "",
};
function Order() {
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [orderList, setorderList] = useState([]);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [topicDropDownList, setTopicDropDownList] = useState([]);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const OrderListData = async (pageNum, name, flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}${string}&sortby=${
      name || "id"
    }&sortFlag=${flag || false}`;
    const res = await OrderLists(queryString);
    if (res && res.status === true) {
      setorderList(res.data);
      setFilter(false);
      setTotalRecords(res.totalRecord);
      setPage(pageNum);
      setOpenBreakdrop(false);
    } else {
      setorderList([]);
      setOpenBreakdrop(false);
      setFilter(false);
    }
  };
  const statusOptions = [
    { id: "1", name: "Paid" },
    { id: "0", name: "Unpaid" },
    { id: "3", name: "Failed" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  useEffect(() => {
    OrderListData(1, "", "");
  }, [rowsPerPage, search, filterData]);

  return (
    <div>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Orders</h2>
          </Grid>
          <Grid sm={6}></Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
            <div className="searchBar">
              <Controls.Input
                name="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                icon={<SearchIcon />}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="borderLight" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <h1> </h1>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{
              marginTop: "10px",
            }}
          >
            <div>
              <Controls.Button
                text="Filter"
                variant="outlined"
                startIcon={<FiFilter />}
                onClick={openFilterDrawer}
                className="filter-button"
              />
            </div>
          </Grid>
        </Grid>
        <OrderList
          orderList={orderList}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          OrderListData={OrderListData}
          setNotify={setNotify}
        />
      </div>
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "var(--main-bg)",
          }}
        >
          <Box
            sx={{
              width: 300,
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className="fontLight" sx={{ fontWeight: "bold" }}>
                Filter
              </Typography>

              <AiOutlineCloseCircle
                className="close-button fontLight"
                onClick={closeFilterDrawer1}
              />
            </Stack>

            <Box sx={{ margin: "22px auto" }}>
              <Controls.SelectBox
                className=""
                placeholder="Select Status"
                fullWidth
                id="status"
                name="status"
                label="Payment Status"
                value={values.status}
                onChange={handleInputChange}
                options={statusOptions}
              />
            </Box>
          </Box>

          <Stack
            sx={{ margin: "5px 20px 20px 20px" }}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Controls.Button
              text="Reset"
              variant="outlined"
              onClick={closeFilterDrawer}
              className="cancel-filter"
            />

            <Controls.Button
              text="Filter"
              variant="contained"
              type="submit"
              className="order-filter-button"
            />
          </Stack>
        </form>
      </Drawer>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default Order;
