import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Units from "../../pages/units/Units";
import Topics from "../../pages/topics/Topics";
import Plan from "../../pages/plans/Plan";
import Coupons from "../../pages/coupon/Coupons";
import User from "../../pages/users/User";
import AddUser from "../../pages/users/AddUser";
import Questions from "../../pages/questions/Questions";
import AddQuestion from "../../pages/questions/AddQuestion";
import Test from "../../pages/Test/Test";
import Faq from "../../pages/faq/Faq";
import FaqUser from "../../pages/faq/FaqUser";
import Result from "../../pages/result/Result";
import Setting from "../../pages/globalTime/GlobalTime";
import Profile from "../../pages/profile/Profile";
import Video from "../../pages/videoLibrary/VideoLibrary";
import UserVideoLibrary from "../../pages/userVideoLibrary/UserVideoLibrary";
import Performance from "../../pages/performence/Performence";
import Welcome from "../../pages/welcome/Welcome";
import PriviousTest from "../../pages/PriviousTest/PriviousTest";
import Userdashboard from "../Dashboard/UserDashboard/Userdashboard";
import Newdashboard from "../Dashboard/NewDashboard";

import Order from "../../pages/Order/Order";
const Main = (props) => {
  const { showNavbar, setShowNavbar } = props;
  return (
    <Box
      component="main"
      sx={{ backgroundColor: "revert", mt: 2, padding: "0px 25px 10px 25px" }}
      onClick={() => setShowNavbar(false)}
    >
      <Routes>
        <Route exact path="/" element={< Newdashboard />} />
        <Route exact path="/welcome" element={<Welcome />} />
        <Route exact path="/units" element={<Units />} />
        <Route exact path="/topics" element={<Topics />} />
        <Route exact path="/plans" element={<Plan />} />
        <Route exact path="/coupons" element={<Coupons />} />
        <Route exact path="/test" element={<Test />} />
        <Route path="/profile" element={<Profile />} />
        <Route exact path="/users" element={<User />} />
        <Route exact path="/adduser" element={<AddUser />} />
        <Route exact path="/questions" element={<Questions />} />
        <Route exact path="/addquestion" element={<AddQuestion />} />

        <Route exact path="/support" element={<Faq />} />
        <Route exact path="/faq" element={<FaqUser />} />
        <Route exact path="/performance" element={<Userdashboard />} />
        <Route exact path="/previoustest" element={<PriviousTest />} />

        {/* <Route exact path="/tools" element={<Result />} /> */}
        <Route exact path="/result" element={<Result />} />
        <Route exact path="/globalTime" element={<Setting />} />
        <Route exact path="/videos" element={<Video />} />
        <Route exact path="/orders" element={<Order />} />
        <Route exact path="/videolibrary" element={<UserVideoLibrary />} />
        {/* <Route exact path="/userdashboard" element={<Userdashboard />} /> */}
        {/* <Route exact path="/newDashboard" element={<Dashboard />} /> */}

      </Routes>
    </Box>
  );
};

export default Main;
