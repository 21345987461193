import axiosRequest from "../axios/axios";

export const addTest = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/createTest`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const testList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/testlist${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const questionCount = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/questionCount`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const getUnitTopicList = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/topicUnitForTest`);

    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
