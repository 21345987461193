import React, { useEffect } from 'react'
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from "@mui/material/styles";
import { useDispatch } from 'react-redux';

const StyledLogin = styled(Button)(() => ({
    backgroundColor: "#007aff",
    color: "white",
    fontSize: "14px",
    padding: "6px 16px",
    "&:hover": {
        backgroundColor: "#0957DD",
        color: "white",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#0c0e2e",
    borderColor: "white",
    boxShadow:
        "0px 2px 1px -4px white,0px 0px 1px -1px rgb(221 221 221 / 90%), 0px 1px 5px 6px rgb(255 255 255 / 12%)",
    borderRadius: "10px",
    padding: "1.5rem",
    ...theme.typography.body2,
    overflow: "hidden",
    // textAlign: "center",
    color: "white",
    Height: "100%",
    width: '100%'
}));


export const ThankYou = () => {
    const navigate = useNavigate()


    useEffect(() => {
        // Redirect after a delay (e.g., 3 seconds)
        const redirectTimeout = setTimeout(() => {
            navigate('/welcome'); // Replace '/dashboard' with the desired destination
        }, 3000);

        // Clear the timeout when the component is unmounted
        return () => clearTimeout(redirectTimeout);
    }, [navigate]);


    return (
        <Grid
            container
            sx={{
                // backgroundColor: "#0c0e2e",
                marginTop: { xs: "5rem", md: "5rem" },
                marginBottom: { xs: "5rem", sm: "5rem", md: "5rem" },
                width: { xs: "100%", md: "550px" },
                margin: "auto",
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
            }}
        >
            <Item>
                <Grid item sm={12} xs={12}>
                    <Stack gap={1} paddingBottom={2} flexDirection={'row'} justifyContent={'center'}>
                        <Typography fontWeight={'500'} fontSize={'25px'} sx={{ color: '#fff' }} textAlign={'center'}>Thank You for Registering!</Typography>
                    </Stack>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Stack paddingBottom={2} flexDirection={'row'} gap={1} justifyContent={'center'} alignItems={'center'}>
                        {/* <Typography fontWeight={'600'} fontSize={'18px'} sx={{ color: '#fff' }} textAlign={'center'}>Your registration was successful. Enjoy a 7-day free trial of our service.</Typography> */}
                        <Typography fontWeight={'600'} fontSize={'18px'} sx={{ color: '#fff' }} textAlign={'center'}>Your registration was successful.
                            Enjoy our service.</Typography>

                    </Stack>
                </Grid>
            </Item>
        </Grid>

    )
}
