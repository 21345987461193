import {
  Grid, Box, Typography, Divider
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "./Welcome.css";
import Controls from "../../Components/controls/Controls";
import Modal from "@mui/material/Modal";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import VideoThumb from "../../assets/images/video-thumbnail-biobuddy.jpg";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideoImage from "../../assets/images/video.png"
import { videoWiseUnitList, videoListUnitWise, updateWatchTime } from "../../state/actions/welcomeAction";
import 'react-modal-video/scss/modal-video.scss';
import CloseIcon from '@mui/icons-material/Close';
import Vimeo from "@u-wave/react-vimeo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalVideo from "react-modal-video";

const style = {
  border: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '400px', sm: '560px', md: '800px' },
  bgcolor: "var(--main-bg)",
  // minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: { xs: '37px', md: '20px' },
  boxShadow: "none",
  backgroundColor: "transparent",
  outline: "none",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  boxShadow: 24,
  p: 2,
};
export default function Welcome() {
  const { auth } = useSelector((state) => state);
  const Navigate = useNavigate();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [unitName, setUnitName] = useState("");
  const [unit, setUnit] = useState([]);
  const [videoListTopicWise, setVideoListTopicWise] = useState([]);
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrlLink, setVideoUrlLink] = useState("");
  const [videoModal, setVideoModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [topicId, setTopicId] = useState("");
  const [openErrorModel, setOpenErrorModel] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [videoWatchTime, setVideoWatchTime] = useState(0);
  const handleCloseError = () => setOpenErrorModel(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    handleCloseVideo();
    setVideoModal(false);
  };

  useEffect(() => {
    unitList();
  }, []);

  useEffect(() => {
    if (isActive) {
      videoList(isActive);
    }
  }, [isActive]);

  const openVideo = (allowInTest, videoLink, topicId) => {
    setOpenBreakdrop(true);

    if (auth?.user?.planDetail?.planPrice == "0.00") {
      if (allowInTest == 1) {
        setIsOpen(true);
        setVideoModal(true)
        setVideoUrlLink(videoLink);
        setTopicId(topicId)
      } else {
        setOpenBreakdrop(false);
        setOpenErrorModel(true);
        setErrMsg(true);
      }
    } else {
      setIsOpen(true);
      setVideoModal(true)
      setVideoUrlLink(videoLink);
      setTopicId(topicId)
    }
  };

  const unitList = async () => {
    setOpenBreakdrop(true);
    const res = await videoWiseUnitList();
    if (res && res.status) {
      setOpenBreakdrop(false);
      setUnit(res.data);
      setIsActive(res.data[0].id);
      setUnitName(res.data[0].name);
    } else {
      setOpenBreakdrop(false);
    }
  };

  const handleCloseVideo = async () => {
    setOpenBreakdrop(true);
    let percent = (currentTime?.percent * 100)
    let sec = currentTime?.seconds;
    const payload = {
      watchTime: Math.ceil(percent),
      watchTimeSec: sec,
      unitId: isActive,
      videoId: videoUrlLink,
      topicId: topicId,
    };
    const res = await updateWatchTime(payload);

    if (res && res.status) {
      videoList(isActive);
    }
  }

  const videoList = async (id) => {
    setOpenBreakdrop(true);
    const res = await videoListUnitWise(id);
    if (res && res.status) {
      setOpenBreakdrop(false);
      setVideoListTopicWise(res.data);
    } else {
      setOpenBreakdrop(false);
    }
  };

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  const handleReady = (player) => {
    player.getDuration().then((duration) => {
      // setDuration(duration);
    });
    setTimeout(() => {
      setOpenBreakdrop(false);
    }, 1000);
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title mb_2">BioBuddy Tutorial</h2>
          </Grid>

        </Grid>
        <Grid item container xs={12} sx={{ marginBottom: '30px' }}>
          <Grid sm={6}>
            <span className="title">Check out this video to learn how to navigate the site.</span>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          // backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            color: "black",
          }}
        >
          <Grid
            container
            style={{
              marginTop: "0.5rem",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Grid xs={12} md={4} sm={4} lg={4} xl={4}>
              <div
                className=""
                onClick={() => { setOpen(true) }}
              >
                <img
                  className="videoThumb"
                  src={VideoThumb}
                  style={{ cursor: "pointer", objectFit: "cover", borderRadius: "10px" }}
                />
                <a></a>
              </div>
            </Grid>
          </Grid>

        </div>
        <ModalVideo
          channel="vimeo"
          autoplay={true}
          isOpen={open}
          videoId={900663842}
          onClose={() => setOpen(false)}
        />

      </Box >

      <Grid container sx={{ marginTop: "30px" }}>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Course curriculum progress</h2>
          </Grid>

        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "var(--main-bg)",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h5" sx={{ paddingX: { xs: "20px", md: "30px" }, paddingTop: "16px", color: "white" }}>Select unit to check progress</Typography>
        <Grid container sx={{ padding: "30px" }}>
          <Grid item container xs={12} sx={{
            gap: {
              xs: '10px',
              sm: '0px',
            }
          }} >

            {unit && unit.map((item, i) => {
              return (
                <Grid sm={3} key={i}>
                  <Typography onClick={() => {
                    setIsActive(item.id)
                    setIndex(i)
                    setUnitName(item.name)
                  }} className={isActive == item.id ? "active-tab" : "fade"}
                  > Unit {i + 1}</Typography>
                </Grid>
              )
            })}

          </Grid>
        </Grid>

        <Box className={isActive == "" ? "hide" : "show"} sx={{ paddingX: "30px", }}>
          <Typography variant="h5" sx={{ color: "white" }}>
            {unitName}
          </Typography >
          {/* <Typography sx={{ color: "white" }}>Atoms and Elements</Typography> */}
          <Divider sx={{ marginY: "10px", borderColor: "white !important" }}></Divider>
          <Box sx={{ paddingY: "10px" }}>

            {videoListTopicWise && videoListTopicWise.map((item, i) => {
              let topic = item.topicId.split(",");

              return (
                <>
                  {item && item.watchTime == 100 ? (
                    <Box sx={{ display: "flex", color: "white", paddingY: "15px", gap: "10px", alignItems: "center" }} >
                      <Box style={{
                        cursor: "pointer"
                      }}
                        onClick={() => {
                          openVideo(item.allowInTest, item.videoLink, item.topicId);
                          setVideoWatchTime(Number(item.watchTimeSec));

                        }} >
                        <img src={VideoImage} className="videoTic" />
                      </Box>

                      <Typography>{topic.join(", ")}{" "}</Typography>
                    </Box>
                  ) : (

                    <Box sx={{ display: "flex", color: "white", paddingY: "15px", gap: "15px", alignItems: "center" }}>
                      <Box sx={{ border: "1px solid grey", marginLeft: "2px", width: "35px", textAlign: "center" }}
                        onClick={() => {
                          openVideo(item.allowInTest, item.videoLink, item.topicId);
                          setVideoWatchTime(Number(item.watchTimeSec));
                        }}
                      >

                        <PlayArrowIcon sx={{ fontSize: "25px", paddingTop: "5px", }} />
                        {item.watchTime == 0 ? (null) : (<Divider sx={{ borderColor: "#008900!important", borderWidth: "3px", width: `${item.watchTime}%` }}></Divider>)}

                      </Box>

                      <Typography>{topic.join(", ")}{" "}</Typography>
                    </Box>
                  )}
                </>
              )
            })}
          </Box>
        </Box>
      </div>

      <Modal
        open={videoModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        onHide={() => { setOpenBreakdrop(false) }}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!openBackdrop && (<CloseIcon onClick={handleClose} sx={{ fontSize: "30px", color: "white", cursor: "pointer" }} />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Box sx={{ width: "100%", height: "100%" }} className='vimeo_video'>
              <Vimeo
                video={videoUrlLink}
                onReady={handleReady}
                onTimeUpdate={handleTimeUpdate}
                autoplay
                speed={true}
                onEnd={handleCloseVideo}
                onError={() => {
                  setOpenBreakdrop(false);
                  setNotify({
                    isOpen: true,
                    message: "Video Not Found",
                    type: "error",
                  });
                }}
                start={videoWatchTime}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* <ModalVideo
        channel="vimeo"
        autoplay={true}
        onReady={handleReady}
        onTimeUpdate={handleTimeUpdate}
        isOpen={videoModal}
        videoId={videoUrlLink}
        onEnd={handleCloseVideo}
        onError={() => {
          setOpenBreakdrop(false);
          setNotify({
            isOpen: true,
            message: "Video Not Found",
            type: "error",
          });
        }}
        onClose={handleClose}
      /> */}
      <Modal
        keepMounted
        open={openErrorModel}
        onClose={handleCloseError}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <label className="fontLight">
            Upgrade your plan to watch more videos.
          </label>
          <Grid container style={{ marginTop: "1rem", justifyContent: "end" }}>
            <Grid item xs={7} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseError} />
            </Grid>
            <Grid item xs={4} className="text-end">
              {/* <Controls.Button
                text="Buy-now"
                onClick={() => Navigate("/buy-now")}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>

    </>
  );
}
