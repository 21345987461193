import axiosRequest from "../axios/axios";

export const addUnit = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/addUnit`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const unitList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/unitlist${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const getUnitDropDownList = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/unitDropdownList`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusChangeUnit/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteUnit = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteUnit/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const editUnit = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateUnit/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
