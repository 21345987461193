import React from "react";
import { useSelector } from "react-redux";
import loginImage from "../../assets/images/login.png";
import {
  Grid,
  Box,
  Divider,
  styled,
  useMediaQuery,
  Stack,
  Button,
  TextField,
  Typography
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./cart.css";
import Controls from "../../Components/controls/Controls";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { useState, useEffect } from "react";
import ToastNotification from "../../Utils/toastNotification";

import {
  cartList,
  deleteCartItem,
  updateCartUserId,
  cartRemoveDispatch,
  updateCart
} from "../../state/actions/cartActions";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { placeOrder } from "../../state/actions/orderActions";
import { store } from "../../state/store";
import { useDispatch } from "react-redux";
import { orderDispatch } from "../../state/actions/orderActions";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { checkCoupon, couponDropdown } from "../../state/actions/couponActions";
import { testRunning } from "../../state/actions/exam";
import Banner from "../../Components/Common/Banner";
const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  overflow: "scroll",
  boxShadow: 24,
  p: 2,
};
const ViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  overflow: "scroll",
  height: "80%",
  boxShadow: 24,
  p: 2,
};
const steps = ["Plan", "Cart", "Checkout"];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#37474f",
  borderRadius: "10px",
  paddingTop: "1rem",
  ...theme.typography.body2,

  textAlign: "center",
  color: "white",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white; 
  } 
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
    border-color: white;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const ModelTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
     padding: 5px 10px;
  } 
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: white;
    top:-8px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
    border-color: white;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const Cart = (props) => {
  const token = useSelector((state) => state.auth.token);

  const { cartItem, setCartItem } = props;
  const Navigate = useNavigate();
  const { state } = useLocation();
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();
  let { id } = useParams();
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openViewModel, setOpenViewModel] = useState(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const handleViewModel = () => setOpenViewModel(true);
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleCloseView = () => setOpenViewModel(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [applyButton, setApplyButton] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState("");
  const [coupenListdata, setCoupenListdata] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const ViewButtonPop = () => (

    <Button sx={{ backgroundColor: "transparent", left: "13px", textTransform: "capitalize", height: "40px", width: "100px", }} variant="contained">Apply</Button>

  )
  const ViewButton = () => (

    <Button onClick={() => {
      handleViewModel();
    }} sx={{ left: "13px", height: "55px", width: "190px", textTransform: "capitalize" }} variant="contained">View Offer</Button>

  )
  const ApplyButton = () => (

    <Button sx={{ left: "13px", height: "55px", width: "190px", textTransform: "capitalize" }} variant="contained"
      onClick={() => {
        ApllyCoupon(applyButton);
      }}

    >Apply</Button>

  )
  const cartData = async () => {
    setOpenBreakdrop(true);
    const queryString = `${state.cartId}`;
    const res = await cartList(queryString);
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      setCart(res.data);
    } else {
    }
  };



  const ApllyCoupon = async (code) => {
    setOpenBreakdrop(true);
    const payload = {
      couponCode: code,
      amount: cart.finalPrice
    }

    const res = await checkCoupon(payload);
    if (res && res.status === true && res.isValid === true) {
      setCouponCode(code);
      setOpenBreakdrop(false);
      setApplyButton("");
      setCouponData(res);
      handleCloseView();
      cartCoupenUpdate(state.cartId, res);
      setNotify({
        isOpen: true,
        message: "Coupon has been Applied Successfully",
        type: "success",
      });

    } else {
      setOpenBreakdrop(false);
      setCouponCode("");
      setCouponData("");
      setApplyButton("");
      handleCloseView();
      setNotify({
        isOpen: true,
        message: res.message,
        type: "error",
      });
    }
    setOpenBreakdrop(false);
  };


  const handleDelete = async () => {
    setOpenBreakdrop(true);
    const res = await deleteCartItem(state.cartId);
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      const payload = "";
      const result = await dispatch(cartRemoveDispatch(payload));
      if (result && result == true) {
        setCartItem(0);
        setCart([]);
        handleCloseDelete();
        Navigate("/buy-now");
      }
    } else {
    }
  };

  const cartCoupenUpdate = async (id, ress) => {
    const payload = {
      couponId: ress?.data?.id,
      discountAmount: ress?.discount,
    }

    const res = await updateCart(id, payload);
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      const payload = "";
    }
  };


  // const result = await dispatch(cartRemoveDispatch(payload));


  const getAllCoupon = async () => {
    let queryString = `search=${applyButton}`;
    const res = await couponDropdown(queryString);
    if (res && res.status == true) {
      setCoupenListdata(res.data);
    } else {
      setCoupenListdata([])
    }
  };


  useEffect(() => {
    cartData();
    if (id) {
      setCartItem(id);
    }
  }, []);

  useEffect(() => {
    getAllCoupon();
  }, [applyButton]);

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Banner />
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#0C0E2E",
          marginTop: { xs: "1rem", md: "1rem" },
          marginBottom: { xs: "5rem", md: "5rem" },
          paddingTop: { xs: "2rem", md: "2rem" },
          paddingBottom: { xs: "2rem", md: "2rem" },
          paddingLeft: { xs: "10px", md: "10px" },
          paddingRight: { xs: "20px", md: "20px" },
          width: { xs: "fit-content", md: "85%" },
          margin: 'auto',
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#1976D2", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },

                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "grey.500", // circle's number (ACTIVE)
                  },

                  "& .MuiStepIcon-root": {
                    color: "grey", // circle's number (ACTIVE)
                  },
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Grid
          className="plan_text"
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ marginTop: "2rem", paddingLeft: "0" }}
        >

          <h1 className="fontLight font-md">Plan</h1>
          <Divider
            sx={{ border: 0.5, borderColor: "white", bgcolor: "white" }}
            variant="fullWidth"
            style={{
              marginTop: "0.5rem",

              marginBottom: "0.5rem",
            }}
          />
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <h1 className="fontLight font-md ">
                {cart != "" ? cart.planName : "Cart is Empty"}
              </h1>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
              {cart && cart != ""}
              <h1 className="fontLight font-md ">
                ${cart.planPrice}/Month
                <ClearIcon
                  onClick={() => {
                    handleDeleteModel();
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#efefef",
                    backgroundColor: "#1976D2",
                    borderRadius: "20px",
                    fontSize: { xs: "18px", sm: "20px", md: "20px" },
                    padding: "1px",
                    paddingLeft: "1px",
                    marginLeft: { xs: "4px", sm: "6px", md: "6px" },
                  }}
                />
              </h1>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: { sm: "0px", md: "28%" } }}>
            <WhiteBorderTextField fullWidth id="outlined-basic" label="Enter coupon code" variant="outlined" sx={{
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  borderColor: "white",
                },
              },
              "&.Mui-focused .MuiOutlinedInput-root": {
                borderColor: "white",
              },
              mt: 1,
              color: "white",
            }}
              name="couponCode"
              value={applyButton}
              InputProps={{ endAdornment: applyButton != "" ? <ApplyButton /> : <ViewButton /> }}
              onChange={(e) => {
                setApplyButton(e.target.value)
              }}
            />
          </Box>
          {couponCode != "" && couponData && couponData !== "" && couponData.status == true && (<Box
            sx={{
              marginTop: { sm: "0px", md: "1%" },
              display: "flex",
              backgroundColor: "#1976D2",
              borderRadius: "10px",
              width: "100%",
              padding: "10px 20px",
              justifyContent: "space-between"
            }}>

            <Box sx={{}}>
              <Typography sx={{ color: "white", fontSize: "20px" }}>
                You saved  <b> $ {couponData?.discount}</b>
              </Typography>
              <Typography sx={{ color: "white", fontSize: "15px" }}>
                <b>{couponCode}</b> applied
              </Typography>
            </Box>
            <Box>
              <ClearIcon
                onClick={() => {
                  setCouponCode("");
                  setCouponData("");

                }}
                sx={{
                  cursor: "pointer",
                  color: "#efefef",
                  backgroundColor: "#000",
                  borderRadius: "20px",
                  fontSize: { xs: "18px", sm: "20px", md: "20px" },
                  padding: "1px",
                  paddingLeft: "1px",
                  marginTop: "15px",
                  marginLeft: { xs: "4px", sm: "6px", md: "6px" },
                }}
              />
            </Box>
          </Box>)}

        </Grid>
        <Grid
          item
          lg={1}
          md={1}
          sm={12}
          xs={12}
          sx={{ marginTop: "1rem" }}
        ></Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ marginTop: "2rem" }}>
          <Item elevation={3}>
            <h1 className="font-md">{cart && cart.planName}</h1>
            <h3 className="font-md">
              {cart && cart.planExpirationDuration} Month Subscription
            </h3>
            <Divider
              sx={{
                border: 0.5,
                borderColor: "white",
                bgcolor: "white",
                marginTop: "1rem",
              }}
              variant="fullWidth"
            />
            <Grid
              container
              spacing={{ xs: 1, md: 3 }}
              sx={{
                paddingLeft: "12px",
                paddingRight: "10px",
              }}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  marginTop: "1rem",
                  textAlign: "left",
                }}
              >
                <p className="font-sm">Plan Price</p>
                <p className="font-sm">Plan Duration</p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  marginTop: "1rem",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p className="font-sm">${cart && cart.planPrice}/Month</p>
                <p className="font-sm">
                  {cart && cart.planExpirationDuration} Month
                </p>
                <span style={{ fontSize: "13px" }}>
                  ($
                  {cart && cart.planPrice} x{" "}
                  {cart && cart.planExpirationDuration})
                </span>
              </Grid>
            </Grid>
            <Divider
              sx={{
                border: 0.5,
                borderColor: "white",
                bgcolor: "white",
                marginTop: "1rem",
                marginBottom: { xs: "10px", md: "20px", lg: "20px" },
              }}
              variant="fullWidth"
            />
            {couponData && couponData?.discount && (
              <>
                <Grid
                  container
                  spacing={{ xs: 1, md: 3 }}
                  sx={{
                    paddingLeft: "12px",
                    paddingRight: "10px",
                  }}
                >

                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{
                      marginTop: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <p className="font-sm">Discount</p>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{
                      marginTop: "1rem",
                      textAlign: "right",
                      paddingRight: "15px",
                    }}
                  >
                    <p className="font-sm">- ${couponData && couponData?.discount ? couponData?.discount : "00.00"}</p>

                  </Grid>

                </Grid>

                <Divider
                  sx={{
                    border: 0.5,
                    borderColor: "white",
                    bgcolor: "white",
                    marginTop: "1rem",
                    marginBottom: { xs: "10px", md: "20px", lg: "20px" },
                  }}
                  variant="fullWidth"
                />
              </>
            )}

            <Grid
              container
              spacing={{ sm: 1, md: 1 }}
              sx={{ marginTop: "0.2rem" }}
            >
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "500" }}>Total</p>
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={6}
                sx={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "500" }}>
                  ${!couponData?.discount ? (cart && cart.finalPrice) : (cart && cart.finalPrice - couponData?.discount).toFixed(2)}
                </p>
              </Grid>
            </Grid>
            <h1
              onClick={() => {
                dispatch(testRunning(true));

                token
                  ? Navigate("/checkout", {
                    state: {
                      cartId: state.cartId,
                      plan: cart.planName,
                      planExpirationDuration: cart.planExpirationDuration,
                      planPrice: cart.planPrice,
                    },
                  })
                  : Navigate("/register", {
                    state: {
                      id: cart.planKey,
                      cartId: state.cartId,
                      plan: cart.planName,
                      planExpirationDuration: cart.planExpirationDuration,
                      planPrice: cart.planPrice,
                    },
                  })
              }
              }
              className="font-md checkout-button"
            >
              Checkout
            </h1>
          </Item>
          <p
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              textAlign: "center",
              cursor: "pointer",
            }}
            className="fontLight font-sm"
            onClick={() =>
              Navigate(`/buy-now`, {
                state: {
                  cartId: state.cartId,
                },
              })
            }
          >
            See Other Plans
          </p>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={openViewModel}
        onClose={handleCloseView}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={ViewStyle}>

          <Box>
            <Box>
              <ModelTextField fullWidth id="outlined-basic" label="Enter coupon code" variant="outlined" sx={{
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "white",
                  },
                },
                "&.Mui-focused .MuiOutlinedInput-root": {
                  borderColor: "white",
                },
                mt: 1,
                color: "white",
              }}
                InputProps={{ endAdornment: <ViewButtonPop /> }}
                onChange={(e) => {
                  setApplyButton(e.target.value)
                }}
              />
            </Box>
            {coupenListdata && coupenListdata.map((item, index) => {
              return (
                <>
                  <Grid container gap={1} style={{ marginTop: "2rem", justifyContent: "space-between" }}>
                    <Grid item xs={8} >
                      <Typography sx={{ color: "white" }}>
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "end" }}>
                      <Controls.Button
                        text="Apply"
                        style={{ padding: "5px 10px", textTransform: "capitalize" }}
                        onClick={() => {
                          setApplyButton(item.couponCode);
                          ApllyCoupon(item.couponCode);
                        }}
                        type="submit"
                      />
                    </Grid>
                  </Grid>
                  <Typography sx={{ color: "white", fontSize: "12px" }}>
                    {/* Save 20% instantly on total amount of order */}
                    {item?.shortDescription || ""}
                  </Typography>
                  <Divider
                    sx={{
                      border: 0.1,
                      borderWidth: 0.2,
                      borderColor: "white",
                      bgcolor: "white",
                      marginTop: "10px",
                      marginBottom: { xs: "10px", md: "20px", lg: "20px" },
                    }}
                    variant="fullWidth"
                  />
                </>
              )
            })}

          </Box>

          {/* <Box>
            <Grid container gap={1} style={{ marginTop: "1rem", justifyContent: "space-between" }}>
              <Grid item xs={8} >
                <Typography sx={{ color: "white" }}>
                  FLAT20%
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "end" }}  >
                <Controls.Button
                  text="Apply"
                  style={{ padding: "5px 10px", textTransform: "capitalize" }}
                  onClick={() => handleDelete()}
                  type="submit"
                />
              </Grid>
            </Grid>
            <Typography sx={{ color: "white", fontSize: "12px" }}>
              Save 20% instantly on total amount of order
            </Typography>
            <Divider
              sx={{
                border: 0.1,
                borderWidth: 0.2,
                borderColor: "white",
                bgcolor: "white",
                marginTop: "10px",
                marginBottom: { xs: "10px", md: "20px", lg: "20px" },
              }}
              variant="fullWidth"
            />
          </Box> */}
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Are you sure to remove this plan from cart?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Delete"
                style={{ backgroundColor: "red" }}
                onClick={() => handleDelete()}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ToastNotification notify={notify} setNotify={setNotify} />

    </>
  );
};

export default Cart;
