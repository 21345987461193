import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Controls from "../../Components/controls/Controls";
import InputAdornment from "@mui/material/InputAdornment";
import { Search as SearchIcon } from "@mui/icons-material";
import { userList } from "../../state/actions/userActions";
import UserLists from "./userList";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import "./User.css";
import AddIcon from "@mui/icons-material/Add";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Form } from "../../Utils/useForm";
import { styled } from "@mui/material/styles";
import { FiFilter } from "react-icons/fi";
import { Stack, Typography, FormLabel, Divider, Drawer } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import dayjs from "dayjs";
import ToastNotification from "../../Utils/toastNotification";
import Modal from "@mui/material/Modal";
import AddUser from "./AddUser";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "var(--main-bg)",
  boxShadow: 24,
  p: 2,
};

const initialValues = {
  name: "",
  email: "",
  status: "",
  startDate: "",
  endDate: "",
};

const p = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginBottom: "10px",
}));
const User = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [open, setOpen] = useState(false);
  const [sortFlag, setSortFlag] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedId, setSelectedId] = useState(null);

  const [filter, setFilter] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const userListData = async (pageNum, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    setOpenBreakdrop(true);
    const queryString = `?page=${pageNum}&size=${rowsPerPage}&search=${search}${string}&sortby=${name}&sortFlag=${flag}`;
    const res = await userList(queryString);
    if (res && res.status === true) {
      setSortBy(name);
      setUserLists(res.data);
      setTotalRecords(res.totalRecord);
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setUserLists([]);
      setOpenBreakdrop(false);
      setSortFlag(!sortFlag);
    }
    setFilter(false);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);

    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  useEffect(() => {
    userListData(1, "", "");
  }, [filterData, search]);

  const statusOptions = [
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
    { id: "3", name: "Delete" },
  ];

  return (
    <>
      <BackdropComponent open={openBackdrop} />
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6}>
            <h2 className="title">Users</h2>
          </Grid>
          <Grid sm={6}>
            <Box style={{ float: "right" }}>
              <Controls.Button
                startIcon={<AddIcon />}
                text="User"
                onClick={() => {
                  setAddUsers(true);
                  handleOpen();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{
          backgroundColor: "#2e4777",
          borderRadius: "10px",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <div className="searchBar borderLight" style={{ width: "33%" }}>
              <Controls.Input
                name="search"
                className="borderLight"
                icon={<SearchIcon />}
                placeholder="Search"
                // chage placeholder color
                style={{ color: "red" }}
                onChange={(e) => {
                  setTimeout(() => {
                    setSearch(e.target.value);
                  }, 800);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="borderLight" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{
              marginTop: "10px",
              textAlign: "end",
            }}
          >
            <Controls.Button
              text="Export"
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/export-users`;
              }}
              className="filter-button"
              sx={{ marginRight: "5px" }}
            />

            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<FiFilter />}
              onClick={openFilterDrawer}
              className="filter-button"
              sx={{ marginRight: "10px" }}
            />
          </Grid>
        </Grid>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#404250",
            }}
          >
            <Box
              sx={{
                width: 300,
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography className="fontLight" sx={{ fontWeight: "bold" }}>
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button fontLight"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{ margin: "8px auto" }}
                style={{
                  backgroundColor: "#404250",
                }}
              >
                <Divider sx={{ mt: 2, color: "white" }} />

                <Controls.Input
                  className=""
                  placeholder="Enter Name"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                sx={{ margin: "22px auto" }}
                style={{
                  backgroundColor: "#404250",
                }}
              >
                <Controls.Input
                  className=""
                  placeholder="Enter Email"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </Box>

              <Box sx={{ margin: "22px auto" }}>
                <Controls.SelectBox
                  className=""
                  placeholder="Select Status"
                  fullWidth
                  id="status"
                  name="status"
                  label="Status"
                  value={values.status}
                  onChange={handleInputChange}
                  options={statusOptions}
                />
              </Box>

              <Box sx={{ margin: "22px auto" }}>
                <Controls.Date
                  className=""
                  placeholder="Select From Date"
                  fullWidth
                  id="date"
                  name="startDate"
                  label="From Date"
                  value={values.startDate}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      startDate: dayjs(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </Box>

              <Box sx={{ margin: "22px auto" }}>
                <Controls.Date
                  className=""
                  placeholder="Select To Date"
                  fullWidth
                  id="date"
                  name="endDate"
                  label="To Date"
                  value={values.endDate}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      endDate: dayjs(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </Box>
            </Box>
            <Stack
              sx={{ margin: "5px 20px 20px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Controls.Button
                text="Reset"
                variant="outlined"
                onClick={closeFilterDrawer}
                className="cancel-filter"
              />

              <Controls.Button
                text="Filter"
                variant="contained"
                type="submit"
                className="order-filter-button"
              />
            </Stack>
          </form>
        </Drawer>

        <UserLists
          userLists={userLists}
          page={page}
          setPage={setPage}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          userListData={userListData}
          setNotify={setNotify}
          setSortBy={setSortBy}
          setSortFlag={setSortFlag}
          sortFlag={sortFlag}
          sortBy={sortBy}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
        />
        <Modal
          keepMounted
          open={open}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <AddUser
              handleClose={handleClose}
              setNotify={setNotify}
              userListData={userListData}
              page={page}
              addUsers={addUsers}
              setSelectedId={setSelectedId}
              idEditable={false}
            />
          </Box>
        </Modal>
      </div>

      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default User;
