import React, { useState, useEffect } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { forgotPassword } from "../../state/actions/authActions";
const initialValues = { email: "" };

const ForgotPassword = (props) => {
  const { handleClose, setNotify } = props;
  const [open, setOpen] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "") {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        email: values.email,
      };

      const res = await forgotPassword(payload);
      if (res && res.status === true) {
        handleClose();

        setNotify({
          isOpen: true,
          message:
            res.message ||
            "Reset password link has been sent successfully.Please check mail",
          type: "success",
        });
        resetForm();
      } else {
        setNotify({
          isOpen: true,
          message: res.message || "Something went wrong.",
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="fontLight"> Forgot Password</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <ClearIcon
            onClick={() => {
              handleClose();
              resetForm();
            }}
            style={{ cursor: "pointer", color: "#efefef" }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
        variant="fullWidth"
        style={{
          marginTop: "0.5rem",
          borderColor: "white",
          marginBottom: "1.5rem",
        }}
      />
      <Form onSubmit={handleSubmit}>
        {/* <StyledLabel className="fontLight">Email</StyledLabel> */}
        <Controls.Input
          name="email"
          value={values.email}
          onChange={handleInputChange}
          sx={{
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: "white",
              },
            },

            mt: 1,
            mr: 3,
            borderRadius: "8px",
          }}
          size="small"
          label="Email"
          fullWidth
        />
        {Boolean(errors.email) ? (
          <p
            style={{
              color: "red",
              marginTop: "4px",
              fontSize: "14px",
            }}
          >
            {errors.email}
          </p>
        ) : (
          ""
        )}
        <p className="fontLight font-sm " style={{ marginTop: "1rem" }}>
          Lost your Password? Please enter your email address. You will receive
          a link to create a new password via email.
        </p>
        <Grid container style={{ marginTop: "1rem" }}>
          <Grid item xs={5}></Grid>
          <Grid item xs={7} className="text-end">
            <Controls.Button
              text="Cancel"
              className="cancel-btn"
              onClick={() => {
                handleClose();
                resetForm();
              }}
            />
            <Controls.Button text="Submit" type="submit" />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default ForgotPassword;
