import React, { useState, useEffect } from "react";
import { useForm, Form } from "../../Utils/useForm";
import ToastNotification from "../../Utils/toastNotification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  useMediaQuery,
  Stack,
} from "@mui/material";
import loginImage from "../../assets/images/forgot.png";
import { loginUserApi } from "../../state/actions/authActions";
import Controls from "../../Components/controls/Controls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Modal from "@mui/material/Modal";
import { store } from "../../state/store";
import { resetPassword } from "../../state/actions/authActions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1b3065",
  boxShadow: 24,
  p: 2,
};

const initialValues = { password: "", confirmpassword: "" };
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  position: "relative",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD",
    color: "white",
  },
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  marginTop: "1rem",
}));

const ResetPassword = () => {
  const xs = useMediaQuery("@media (max-width:600px)");
  const Navigate = useNavigate();
  const { auth } = store.getState();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  let { token } = useParams();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  const passwordValidation = () => {
    if (values.password !== values.confirmpassword) {
      setNotify({
        isOpen: true,
        message: "Password and confirm password does not match",
        type: "error",
      });
      return false;
    } else {
      return true;
    }
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    if ("confirmpassword" in fieldValues) {
      if (fieldValues.confirmpassword !== "") {
        if (fieldValues.confirmpassword.length < 8) {
          temp.confirmpassword = "Password  must be 8 character";
        } else {
          if (!/[0-9]/.test(fieldValues.confirmpassword)) {
            temp.confirmpassword = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.confirmpassword)) {
              temp.confirmpassword = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.confirmpassword)) {
                temp.confirmpassword = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.confirmpassword = "";
              }
            }
          }
        }
      } else {
        temp.confirmpassword = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate() && passwordValidation()) {
      const payload = {
        token: token,
        password: values.password,
      };

      const res = await resetPassword(payload);
      if (res && res.status === true) {
        handleClose();

        setNotify({
          isOpen: true,
          message: res.message || "Password has been reset successfully",
          type: "success",
        });
        Navigate("/login");
        resetForm();
      } else {
        setNotify({
          isOpen: true,
          message: res.message || "Something went wrong.",
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#0C0E2E",
          marginTop: { xs: "5rem", md: "5rem" },
          width: { xs: "100%", md: "80%" },
          margin: "auto",
        }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ backgroundColor: "#171D53" }}
        >
          <StyledLeftSide
            sx={{
              height: { xs: "95vh", md: "88vh", lg: "80vh" },
            }}
          >
            <Container sx={xs ? {} : { pt: "10%" }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <img
                    src={loginImage}
                    style={{
                      width: "300px",
                      height: "300px",
                    }}
                    alt="Login Background"
                  />
                </Box>
                <Typography
                  component={"h4"}
                  className="fontLight"
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Reset Password
                </Typography>
                <br />
                <Typography
                  className="fontLight"
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                    maxWidth:'370px'
                  }}
                >
                  Sign in using your BioBuddy account login or{" "}
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                    className="mt-3"
                    onClick={() =>
                      Navigate("/register", {
                        state: {
                          id: state && state.id != null ? state.id : null,
                        },
                      })
                    }
                  >
                    register a new account.
                  </Typography>
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 2 }}>
                  <Grid item xs={12} md={12}>
                    <StyledLabel className="fontLight">Password</StyledLabel>
                    <WhiteBorderTextField
                      name="password"
                      value={values.password}
                      onChange={handleInputChange}
                      type={showPassword ? "text" : "password"}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mt: 1,
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      fullWidth
                      error={errors.password}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ m: 0 }}>
                            <Tooltip
                              title={
                                showPassword ? "Hide Password" : "Show Password"
                              }
                            >
                              <IconButton
                                sx={{ color: "white" }}
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Grid item xs={12} md={12}>
                    <StyledLabel className="fontLight">
                      Confirm Password
                    </StyledLabel>
                    <WhiteBorderTextField
                      name="confirmpassword"
                      value={values.confirmpassword}
                      onChange={handleInputChange}
                      type={showCPassword ? "text" : "password"}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mt: 1,
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      error={errors.confirmpassword}
                      helperText={errors.confirmpassword}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ m: 0 }}>
                            <Tooltip
                              title={
                                showCPassword
                                  ? "Hide Password"
                                  : "Show Password"
                              }
                            >
                              <IconButton
                                sx={{ color: "white" }}
                                onClick={handleClickShowCPassword}
                              >
                                {showCPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Box>

                <StyledLogin sx={{ mt: 3 }} type="submit">
                  Reset
                </StyledLogin>
              </form>
            </Container>
          </StyledLeftSide>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: { xs: "none", sm: "block", md: "block", lg: "block" },
          }}
        >
          <StyledRightSide>
            <img
              src={loginImage}
              style={{
                width: "500px",
                height: "500px",
              }}
              alt="Login Background"
            />
          </StyledRightSide>
        </Grid>
      </Grid>

      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default ResetPassword;
