import React, { useEffect, useState } from "react";
import Controls from "../../Components/controls/Controls";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Divider } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import {
  addPlan,
  updateplan,
  planDescriptionRemove,
} from "../../state/actions/planActions";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { styled } from "@mui/material/styles";
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;
const initialValues = {
  planName: "",
  planPrice: "",
  planExpirationDuration: "",
  planDescription: "",
  planStatus: "",
  planDurationType: "",
  planDescriptionId: "",
};

const AddPlan = (props) => {
  const planDurationType = [{ id: 1, name: "Month" }];

  const [isEdit, setIsEdit] = useState(false);
  const [descriptionData, setDescriptionData] = useState([]);
  const [descriptionIndex, setDescriptionDataIndex] = useState("");
  const { handleClose, setNotify, planListsData, editId, page } = props;
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("planName" in fieldValues) {
      temp.planName = fieldValues.planName ? "" : "This field is required.";
    }

    if ("planPrice" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.planPrice)) {
        temp.planPrice = "";
      } else {
        temp.planPrice = "Only number allowed";
      }
    }

    if ("planExpirationDuration" in fieldValues) {
      let reg = /^[0-9\b]+$/;
      if (fieldValues.planExpirationDuration === "") {
        temp.planExpirationDuration = "This field is required.";
      } else if (reg.test(fieldValues.planExpirationDuration)) {
        temp.planExpirationDuration = "";
      } else {
        temp.planExpirationDuration = "Only number allowed";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        planName: values.planName,
        planPrice: values.planPrice,
        planDurationType: values.planDurationType,
        planExpirationDuration: values.planExpirationDuration,
        planDescription: descriptionData,
        dayCount: values.planExpirationDuration * 30,
        finalPrice: values.planPrice * values.planExpirationDuration,
      };
      if (descriptionData.length > 0) {
        if (isEdit) {
          const res = await updateplan(editId.id, payload);
          if (res && res.status === true) {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "success",
            });
            planListsData(1);
            handleClose();
            resetForm();
            setDescriptionData([]);
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "error",
            });
          }
        } else {
          const res = await addPlan(payload);
          if (res && res.status === true) {
            handleClose();
            planListsData(1);
            resetForm();
            setDescriptionData([]);

            setNotify({
              isOpen: true,
              message: res.message || "Added Successfully.",
              type: "success",
            });
          } else {
            setNotify({
              isOpen: true,
              message: res.message || "Something went wrong.",
              type: "error",
            });
          }
        }
      } else {
        setNotify({
          isOpen: true,
          message: "Please add plan description",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (editId) {
      setIsEdit(true);
      setValues({
        ...values,
        planName: editId.planName,
        planPrice: editId.planPrice,
        planDurationType: editId.planDurationType,
        planExpirationDuration: editId.planExpirationDuration,
        finalPrice: editId.planPrice * editId.planExpirationDuration,
      });
      setDescriptionData(editId.planDescriptions);
    }
  }, [editId]);

  const manageDescription = (e) => {
    const check = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");

    if (check !== "") {
      if (e.key === "Enter") {
        if (descriptionIndex === "") {
          e.target.value = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");
          setDescriptionData([
            ...descriptionData,
            {
              id: values.planDescriptionId,
              planId: editId?.id ? editId?.id : "",
              subValue: e.target.value,
            },
          ]);
          e.target.value = "";
          values.planDescription = "";
          values.planDescriptionId = "";
          setDescriptionDataIndex("");
        } else {
          e.target.value = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");

          descriptionData[descriptionIndex].subValue = e.target.value;
          values.planDescription = "";
          values.planDescriptionId = "";
          setDescriptionDataIndex("");
          // setDescriptionDataIndex("")
        }
      }
    } else {
      e.target.value = "";
      values.planDescription = "";
      values.planDescriptionId = "";
    }
  };

  const handelDescriptionEdit = async (index) => {
    setDescriptionDataIndex(index);
  };

  const planDescriptions = async (id) => {
    const res = await planDescriptionRemove(id);
    if (res && res.status === true) {
      setNotify({
        isOpen: true,
        message: res.message,
        type: "success",
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <p className="fontLight"> {isEdit ? "Save" : "Add"} Plan</p>
        </Grid>
        <Grid item xs={3} className="text-end">
          <ClearIcon
            onClick={() => handleClose()}
            style={{ cursor: "pointer", color: "#efefef" }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{ border: 1, borderColor: "white", bgcolor: "white" }}
        variant="fullWidth"
        style={{
          marginTop: "0.5rem",

          marginBottom: "1.5rem",
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controls.Input
              name="planName"
              className="planExpirationDuration"
              onChange={handleInputChange}
              value={values.planName}
              error={errors.planName}
              label="Plan Name"
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="planPrice"
              className="planExpirationDuration"
              onChange={handleInputChange}
              value={values.planPrice}
              error={errors.planPrice}
              helperText={errors.planPrice}
              label="Base Price"
            />
          </Grid>

          <Grid item xs={12}>
            <Controls.SelectBox
              name="planDurationType"
              onChange={handleInputChange}
              value={values.planDurationType}
              options={planDurationType}
              error={errors.planDurationType}
              label="Select Plan Duration Type"
            />
          </Grid>

          <Grid item xs={12}>
            <Controls.Input
              className="planExpirationDuration"
              name="planExpirationDuration"
              onChange={handleInputChange}
              value={values.planExpirationDuration}
              error={errors.planExpirationDuration}
              label="Expiration Duration In months"
              inputProps={{
                maxLength: 2,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
              name="finalPrice"
              className="planExpirationDuration"
              // onChange={handleInputChange}
              value={values.planPrice * values.planExpirationDuration}
              error={errors.planPrice}
              helperText={errors.planPrice}
              label="Plan Price"
            />
          </Grid>
          <Grid item xs={12}>
            <WhiteBorderTextField
              label="Plan Description"
              variant="outlined"
              value={values.planDescription}
              onKeyUp={manageDescription}
              onChange={handleInputChange}
              name="planDescription"
              style={{
                width: "100%",
              }}
              sx={{
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "white",
                  },
                },
                "&.Mui-focused .MuiOutlinedInput-root": {
                  borderColor: "white",
                },

                mt: 1,
                color: "white",
                borderRadius: "8px",
              }}
              multiline
              rows={2}
            />
            <h5 className="fontLight" style={{ marginTop: "10px" }}>
              * Hit Enter For Add Description
            </h5>
            <div>
              {descriptionData.map((item, index) => {
                return (
                  <div className="description-box">
                    <p className="fontLight">{item.subValue}</p>
                    <div className="description-item">
                      <EditIcon
                        style={{
                          fill: "white",
                          cursor: "pointer",
                          fontSize: "20px",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          // let temp = [...descriptionData];
                          handelDescriptionEdit(index);
                          setValues({
                            ...values,
                            planDescription: item.subValue,
                            planDescriptionId: item.id,
                          });
                        }}
                      />
                      <DeleteIcon
                        style={{
                          fill: "white",
                          cursor: "pointer",
                          fontSize: "20px",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          let temp = [...descriptionData];
                          temp.splice(index, 1);
                          setDescriptionData(temp);
                          planDescriptions(item.id);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid container style={{ marginTop: "22px" }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} className="text-end">
              <Controls.Button
                text="Cancel"
                className="cancel-btn"
                onClick={() => {
                  handleClose();
                  planListsData(1);
                  resetForm();
                }}
              />
              <Controls.Button text={isEdit ? "SAVE" : "ADD"} type="submit" />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AddPlan;
