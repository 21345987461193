import Input from "./Input";
import Button from "./Button";
import SelectBox from "./SelectBox";
import RadioGroup from "./RadioGroup";
import Switch from "./Switch";
import Checkbox from "./Checkbox";
import Date from "./Date";
import SelectBox1 from "./SelectBox1";
const Controls = {
  Input,
  Button,
  SelectBox,
  SelectBox1,
  RadioGroup,
  Switch,
  Checkbox,
  Date,
};

export default Controls;
