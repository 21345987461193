import axiosRequest from "../axios/axios";

export const GlobalTimeperQue = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/timeConfig`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const GlobalTimeperDetails = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/timeDetail`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
