import React, { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const PieChart = ({ tutorModeCount, timedModeCount }) => {
  const data = {
    labels: ['Tutor Mode Count', 'Timed'],
    datasets: [
      {
        data: [tutorModeCount, timedModeCount],
        backgroundColor: ['#B5BC1A', '#C84D17'],
        hoverBackgroundColor: ['#B5BC1A', '#C84D17'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#FFFFFF', // Set font color for legend labels
          boxWidth: 13, // Set the width of the legend box
          padding: 20, // Add padding between legend items,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1) + '%';
            return `${label}: ${percentage}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '400px' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;


