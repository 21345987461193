import axiosRequest from "../axios/axios";

export const addFaq = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/addFaq`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateFaq = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateFaq/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const faqListsAdmin = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/faqListAdmin${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const faqLists = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/faqList${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const faqListsUser = async () => {
  try {
    const response = await axiosRequest.get(`/api/v1/faqListUserAuthenticate`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusChangeFaq/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changeLocation = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusChangeFaqUserLogin/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteFaq = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteFaq/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
