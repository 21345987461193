import React from "react";

//create common checkbox component
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

const WhiteBorderTextField = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill: white;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
`;

export default function Checkboxs(props) {
  const { name, label, value, onChange, checked, disabled } = props;
  return (
    <FormControlLabel
      control={
        <WhiteBorderTextField
          checked={checked}
          onChange={onChange}
          name={name}
          color="primary"
          disabled={disabled}
          sx={{ paddingTop: "2px" }}
        />
      }
      sx={{ alignItems: "flex-start" }}
      label={label}
    />
  );
}
