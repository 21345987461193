import axiosRequest from "../axios/axios";

export const testResult = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/testSubmitResult/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
