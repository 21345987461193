import axiosRequest from "../axios/axios";
export const addQuestion = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/addQuestion`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const questionsList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/questionList${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const getQuestionDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/questionDetail/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateQuestion = async (payload, id) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/updateQuestion/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const deleteQuestion = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/deleteQuestion/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

//delete question image
export const deleteImages = async (id, type) => {
  try {
    const response = await axiosRequest.post(`/api/v1/deleteImage`, {
      id: id,
      imageType: type,
    });
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const videoList = async (id) => {
  try {
    const response = await axiosRequest.get(`/api/v1/videoList/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

//change stattus
export const changeStatus = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/api/v1/statusChangeQuestion/${id}`,
      payload
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
