import axiosRequest from "../axios/axios";

export const previousTestList = async (string) => {
  try {
    const response = await axiosRequest.get(`/api/v1/previousTest${string}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
export const previewTest = async (id) => {
  try {
    const response = await axiosRequest.get(
      `/api/v1/previewPreviousTest/${id}`
    );
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
