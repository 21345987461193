import React from "react";
import "./signUp.css";
import loginImage from "../../assets/images/biobuddy-register-update.png";
import { useState, useEffect } from "react";
import { useForm } from "../../Utils/useForm";
import ToastNotification from "../../Utils/toastNotification";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  useMediaQuery,
  Stack,
  Modal,
  FormLabel,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BackdropComponent from "../../Components/Backdrop/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import { loginUserApi } from "../../state/actions/authActions";
import { userSinup } from "../../state/actions/sinupAction";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { testRunning } from "../../state/actions/exam";
import { placeOrder } from "../../state/actions/orderActions";
import { createSubscriber } from "../../state/actions/flodeskAction";
import Controls from "../../Components/controls/Controls";

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }

  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: white;
    border-color: white;
  }
  & .MuiOutlinedInput-root:focused {
    border-color: white;
  }
`;
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  padding: "30px 20px",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
}));

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  position: "relative",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
}));

const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#007aff",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#0957DD ",
    color: "white",
  },
}));

const style = {
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  minHeight: "50px",
  maxHeight: "90vh",
  bgcolor: "#FFFFFF",
  padding: "20px",
  borderRadius: "10px",
};

const initialValues = {
  email: "",
  password: "",
  showPassword: false,
  rememberMe: false,
  fname: "",
  lname: "",
  password: "",
  confirmpassword: "",
  roleType: "",
};

const roleDropdownList = [
  { id: "High School Student (AP)", name: "High School Student (AP)" },
  { id: "High School Teacher", name: "High School Teacher" },
  { id: "College Student (Pre-Health)", name: "College Student (Pre-Health)" },
  { id: "College Student (Other)", name: "College Student (Other)" },
  { id: "Educators (TA/Professor)", name: "Educators (TA/Professor)" },
  { id: "Parent", name: "Parent" },
];

const Signup = () => {
  const Navigate = useNavigate();
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [submitHandel, setSubmitHandel] = useState(false);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const getauthUser = JSON.parse(localStorage.getItem("authUser"));

  if (getauthUser && !submitHandel) {
    console.log("getauthUser", getauthUser);

    if (getauthUser?.role == 1) {
      setTimeout(() => {
        Navigate("/");
      }, 100);
    } else {
      setTimeout(() => {
        Navigate("/welcome");
      }, 100);
    }
  }

  useEffect(() => {
    if (state && state.emailId != null && state.emailId) {
      setValues({ ...values, email: state.emailId });
    }
  }, [state]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      temp.email = fieldValues.email
        ? regex.test(fieldValues.email)
          ? ""
          : "Invalid Email"
        : "This field is required.";
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "") {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 characters";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    if ("fname" in fieldValues) {
      temp.fname = fieldValues.fname ? "" : "This field is required.";
    }
    if ("lname" in fieldValues) {
      temp.lname = fieldValues.lname ? "" : "This field is required.";
    }
    if ("roleType" in fieldValues) {
      temp.roleType = fieldValues.roleType ? "" : "This field is required.";
    }

    if ("confirmpassword" in fieldValues) {
      if (fieldValues.confirmpassword !== "") {
        if (fieldValues.confirmpassword.length < 8) {
          temp.confirmpassword = "Password  must be 8 characters";
        } else {
          if (!/[0-9]/.test(fieldValues.confirmpassword)) {
            temp.confirmpassword = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.confirmpassword)) {
              temp.confirmpassword = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.confirmpassword)) {
                temp.confirmpassword = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.confirmpassword = "";
              }
            }
          }
        }
      } else {
        temp.confirmpassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const passwordValidation = () => {
    if (values.password !== values.confirmpassword) {
      setNotify({
        isOpen: true,
        message: "Password and confirm password does not match",
        type: "error",
      });
      setOpenBreakdrop(false);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitHandel(true);
    if (validate() && passwordValidation()) {
      setOpenBreakdrop(true);
      const data = {
        email: values.email,
        password: values.password,
        name: values.fname + " " + values.lname,
        roleType: values.roleType,
      };

      const res = await userSinup(data);

      if (res.status == true) {
        const subscriber = await createSubscriber({
          email: values.email,
          firstName: values.fname,
          lastName: values.lname,
          id: res.data.id,
          roleType: values.roleType,
        });
        const LoginResponse = await dispatch(
          loginUserApi(
            {
              email: values.email,
              password: values.password,
            },
            rememberMe
          )
        );

        console.log("LoginResponse", LoginResponse);

        if (LoginResponse && LoginResponse.status === true) {
          setOpenBreakdrop(false);
          dispatch(testRunning(true));
          await placeOrder();

          setTimeout(() => {
            state && state.id != null && state.id
              ? Navigate(`/checkout`, {
                state: {
                  cartId: state.cartId,
                  plan: state.planName,
                  planExpirationDuration: state.planExpirationDuration,
                  planPrice: state.planPrice,
                },
              })
              : Navigate("/thank-you");
          }, 2000);
        } else {
          setNotify({
            isOpen: true,
            message: LoginResponse.message || "Login Fail.",
            type: "error",
          });
          setOpenBreakdrop(false);
        }

        setNotify({
          isOpen: true,
          message: res.message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "error",
        });
        setOpenBreakdrop(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register For New Account | BioBuddy</title>
        {/* <meta name="description" content='Please complete registration for a FREE 7 Day trial or login to continue. Get started today with BioBuddy.' /> */}
        <meta
          name="description"
          content="Please complete registration to continue. Get started today with BioBuddy."
        />
      </Helmet>
      <BackdropComponent open={openBackdrop} />
      <Grid
        container
        className="login-grid"
        spacing={1}
        sx={{
          backgroundColor: "#0C0E2E",
        }}
      >
        {/* <ToastContainer limit={1} /> */}
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: { sm: "block", md: "block", lg: "block" },
          }}
        >
          <StyledLeftSide
            style={{
              padding: "20px 20px",
              textAlign: "center",
            }}
          >
            <img
              src={loginImage}
              style={{
                width: "85%",
                margin: "auto",
              }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ backgroundColor: "#171D53" }}
        >
          <StyledRightSide>
            <Container>
              <Box>
                <Typography
                  className="fontLight"
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Enter the Portal
                </Typography>
                <br />
                <Typography
                  className="fontLight"
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Create your account below to explore the portal{" "}
                  {/* for a FREE 7 Day trial */}
                  or
                  <span
                    style={{
                      // textDecoration: "underline",
                      cursor: "pointer",
                      color: "#1864ca",
                      fontWeight: "600",
                    }}
                    className="mt-3"
                    onClick={() => {
                      state && state.id != null && state.cartId
                        ? Navigate(`/login`, {
                          state: {
                            cartId: state.cartId,
                            plan: state.plan,
                            planExpirationDuration:
                              state.planExpirationDuration,
                            planPrice: state.planPrice,
                          },
                        })
                        : Navigate("/login");
                    }}
                  >
                    {" "}
                    login{" "}
                  </span>{" "}
                  here.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginTop: "-4px" }}>
                  <Grid item xs={12} md={6}>
                    <WhiteBorderTextField
                      placeholder="First Name"
                      name="fname"
                      value={values.fname}
                      onChange={handleInputChange}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      fullWidth
                      error={errors.fname}
                      helperText={errors.fname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <WhiteBorderTextField
                      placeholder="Last Name"
                      name="lname"
                      value={values.lname}
                      onChange={handleInputChange}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },
                        padding: "0px",
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      fullWidth
                      error={errors.lname}
                      helperText={errors.lname}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBorderTextField
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mt: 1,
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      fullWidth
                      error={errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.SelectBox1
                      name="roleType"
                      onChange={handleInputChange}
                      value={values.roleType}
                      options={roleDropdownList}
                      error={errors.roleType}
                      label="Tell us more about yourself"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <WhiteBorderTextField
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleInputChange}
                      type={showPassword ? "text" : "password"}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mt: 1,
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      fullWidth
                      error={errors.password}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ m: 0 }}>
                            <Tooltip
                              title={
                                showPassword ? "Hide Password" : "Show Password"
                              }
                            >
                              <IconButton
                                sx={{ color: "white" }}
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <WhiteBorderTextField
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      value={values.confirmpassword}
                      onChange={handleInputChange}
                      type={showCPassword ? "text" : "password"}
                      sx={{
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "white",
                          },
                        },

                        mt: 1,
                        mr: 3,
                        borderRadius: "8px",
                      }}
                      size="small"
                      error={errors.confirmpassword}
                      helperText={errors.confirmpassword}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ m: 0 }}>
                            <Tooltip
                              title={
                                showCPassword
                                  ? "Hide Password"
                                  : "Show Password"
                              }
                            >
                              <IconButton
                                sx={{ color: "white" }}
                                onClick={handleClickShowCPassword}
                              >
                                {showCPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className="fontLight"
                  sx={{
                    mt: 3,
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                >
                  By creating an account you agree to the{" "}
                  <a
                    style={{ color: "var(--main-color)" }}
                    // href={`${process.env.REACT_APP_FRONT_URL}terms-conditions`}
                    href="https://biobuddy.com/faqs/"
                    target="_blank"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: "var(--main-color)" }}
                    // href={`${process.env.REACT_APP_FRONT_URL}privacy-policy`}
                    href="https://biobuddy.com/faqs/"
                    target="_blank"
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </Typography>

                <StyledLogin sx={{ my: 3 }} type="submit">
                  Create Account
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
      <ToastNotification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default Signup;
