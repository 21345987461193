import { CART_DATA, CART_DATA_REMOVE } from "../action-types/cart";
export default function cart(state = [], action) {
  const { type, payload } = action;
  console.log("@@@@@@@@@@@@", payload);
  switch (type) {
    case CART_DATA:
      return {
        ...state,
        payload,
      };
    case CART_DATA_REMOVE:
      return [];
    default:
      return state;
  }
}
