import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "../styles/ColumnChart.css";

const ColumnChart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-column",
      toolbar: {
        show: false,
      },
    },
    colors: ["#c769ec", "#00cadc", "rgb(101, 166, 250)", "rgb(83, 193, 247)"],
    plotOptions: {
      bar: {
        dataLabels: {
          style: {
            colors: ['#000000']
          }
        },
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: data?.libraryChartLabels,
      axisBorder: {
        show: true,
        color: '#fff'
      },
      axisTicks: {
        show: false,
        color: '#fff'
      },
      labels: {
        style: {
          colors: '#fff'
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: '#fff'
      },
      axisTicks: {
        show: false,
        color: '#fff'
      },
      labels: {
        style: {
          colors: '#fff'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    legend: {
      position: "top"
    }
  });

  const [series, setSeries] = useState([
    {
      name: "Total Video Views",
      data: data?.videoViewsValues,
      backgroundColor: "rgb(172, 90, 195)",
      borderColor: "rgb(172, 90, 195)",
      borderWidth: 1,
    },
    {
      name: "Total Video Completed",
      data: data?.completedVideosValues,
      backgroundColor: 'rgb(0, 202, 220)',
      borderColor: 'rgb(0, 202, 220)',
      borderWidth: 1,
    },
    {
      name: 'Total Video Watchtime',
      data: data?.watchTimeSecSumValues,
      backgroundColor: 'rgb(101, 166, 250)',
      borderColor: 'rgb(101, 166, 250)',
      borderWidth: 1,
    },
    {
      name: 'Avg. Video Watchtime',
      data: data?.avergageWatchTimeValues,
      backgroundColor: 'rgb(83, 193, 247)',
      borderColor: 'rgb(83, 193, 247)',
      borderWidth: 1,
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "Total Video Views",
        data: data?.videoViewsValues,
        backgroundColor: "rgb(172, 90, 195)",
        borderColor: "rgb(172, 90, 195)",
        borderWidth: 1,
      },
      {
        name: "Total Video Completed",
        data: data?.completedVideosValues,
        backgroundColor: 'rgb(0, 202, 220)',
        borderColor: 'rgb(0, 202, 220)',
        borderWidth: 1,
      },
      {
        name: 'Total Video Watchtime',
        data: data?.watchTimeSecSumValues,
        backgroundColor: 'rgb(101, 166, 250)',
        borderColor: 'rgb(101, 166, 250)',
        borderWidth: 1,
      },
      {
        name: 'Avg. Video Watchtime',
        data: data?.avergageWatchTimeValues,
        backgroundColor: 'rgb(83, 193, 247)',
        borderColor: 'rgb(83, 193, 247)',
        borderWidth: 1,
      },
    ]);

    setOptions({
      chart: {
        id: "basic-column",
        toolbar: {
          show: false,
        },
      },
      colors: ["#c769ec", "#00cadc", "rgb(101, 166, 250)", "rgb(83, 193, 247)"],
      plotOptions: {
        bar: {
          dataLabels: {
            style: {
              colors: ['#000000']
            }
          },
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: data?.libraryChartLabels,
        axisBorder: {
          show: true,
          color: '#fff'
        },
        axisTicks: {
          show: false,
          color: '#fff'
        },
        labels: {
          style: {
            colors: '#fff'
          }
        }
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: '#fff'
        },
        axisTicks: {
          show: false,
          color: '#fff'
        },
        labels: {
          style: {
            colors: '#fff'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      legend: {
        position: "top"
      }
    });
  }
    , [data]);

  return (
    <div className="chart-container">
      <div className="chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default ColumnChart;
