import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router";
import BackdropComponent from "../Components/Backdrop/Backdrop";
import { cartList } from "../state/actions/cartActions";
const GetCartId = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const cartData = async () => {
    setOpenBreakdrop(true);
    const queryString = id;
    const res = await cartList(queryString);
    console.log("-----------------", res);
    if (res && res.status === true) {
      navigate(`/cart/${res.data.planKey}`, {
        state: {
          cartId: id,
        },
      });
    } else {
    }
  };
  useEffect(() => {
    cartData();
  }, []);
  return (
    <>
      <BackdropComponent open={openBackdrop} />
    </>
  );
};

export default GetCartId;
