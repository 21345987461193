import { UPDATETESTRESULT, STARTUPDATING, TEST_RUNNING } from '../action-types/exam';
const initialState = {
    test: [],
    loading: false,
};

export default function questionReducer(state = initialState, action, loading = false) {

    const { type, payload } = action;

    switch (type) {
        case STARTUPDATING:
            return {
                loading: true,
            };
        case UPDATETESTRESULT:
            return {
                ...state,
                test: payload,
            };

        case "CLEARTESTARRAY":
            return {
                ...state,
                test: [],
            };
        case TEST_RUNNING:
            return {
                ...state,
                loading: payload
            };


        default:
            return state;


    }

}