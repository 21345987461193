// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
// import "../styles/ColumnChart.css";

// const ColumnChart = ({ data }) => {
//   const [options, setOptions] = useState({
//     chart: {
//       id: "basic-column",
//       width: 380,
//       background: "transparent",
//     },
//     colors: ["#c769ec", "#00cadc"],
//     plotOptions: {
//       bar: {
//         borderRadius: 2,
//       },
//     },
//     xaxis: {
//       categories: data?.testDates,
//       axisBorder: {
//         show: true,
//         color: "#fff",
//       },
//       axisTicks: {
//         show: false,
//         color: "#fff",
//       },
//       labels: {
//         style: {
//           colors: "#fff",
//         },
//       },
//     },
//     yaxis: {
//       axisBorder: {
//         show: true,
//         color: "#fff",
//       },
//       axisTicks: {
//         show: false,
//         color: "#fff",
//       },
//       labels: {
//         style: {
//           colors: "#fff",
//         },
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "straight",
//     },
//     legend: {
//       position: "top",
//       fontSize: "20px",
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: 200,
//           },
//           legend: {
//             position: "bottom",
//           },
//         },
//       },
//     ],
//   });

//   const [series, setSeries] = useState([
//     {
//       name: "Total Tests Completed",
//       data: data?.completedCountValues || [],
//     },
//     {
//       name: "Total Tests Started",
//       data: data?.startedCountValues || [],
//     },
//   ]);

//   useEffect(() => {
//     if (data) {
//       setOptions((prevOptions) => ({
//         ...prevOptions,
//         xaxis: {
//           ...prevOptions.xaxis,
//           categories: data.testDates,
//         },
//       }));

//       setSeries([
//         {
//           name: "Total Tests Completed",
//           data: data.completedCountValues || [],
//         },
//         {
//           name: "Total Tests Started",
//           data: data.startedCountValues || [],
//         },
//       ]);
//     }
//   }, [data]);

//   return (
//     <div className="chart-container">
//       <div className="chart">
//         <Chart
//           options={options}
//           series={series}
//           type="bar"
//           height="400"
//         />
//       </div>
//     </div>
//   );
// };

// export default ColumnChart;

// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
// import "../styles/ColumnChart.css";

// const ColumnChart = ({ data }) => {
//   const [options, setOptions] = useState({
//     chart: {
//       id: "basic-column",
//       toolbar: {
//         show: false,
//       },
//     },
//     colors: ["#c769ec", "#00cadc", "rgb(101, 166, 250)", "rgb(83, 193, 247)"],
//     plotOptions: {
//       bar: {
//         dataLabels: {
//           style: {
//             colors: ['#000000']
//           }
//         },
//         borderRadius: 2,
//       },
//     },
//     xaxis: {
//       categories: data?.testDates,
//       axisBorder: {
//         show: true,
//         color: '#fff'
//       },
//       axisTicks: {
//         show: false,
//         color: '#fff'
//       },
//       labels: {
//         style: {
//           colors: '#fff'
//         }
//       }
//     },
//     yaxis: {
//       axisBorder: {
//         show: true,
//         color: '#fff'
//       },
//       axisTicks: {
//         show: false,
//         color: '#fff'
//       },
//       labels: {
//         style: {
//           colors: '#fff'
//         }
//       }
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'straight'
//     },
//     legend: {
//       position: "top"
//     }
//   });

//   const [series, setSeries] = useState([
//     {
//       name: "Total Tests Completed",
//       data: data?.completedCountValues || [],
//       backgroundColor: "rgb(172, 90, 195)",
//       borderColor: "rgb(172, 90, 195)",
//       borderWidth: 1,
//     },
//     {
//       name: "Total Tests Started",
//       data: data?.startedCountValues || [],
//       backgroundColor: 'rgb(0, 202, 220)',
//       borderColor: 'rgb(0, 202, 220)',
//       borderWidth: 1,
//     },

//   ]);

//   useEffect(() => {
//     setSeries([
//       {
//         name: "Total Tests Completed",
//         data: data?.completedCountValues || [],
//         backgroundColor: "rgb(172, 90, 195)",
//         borderColor: "rgb(172, 90, 195)",
//         borderWidth: 1,
//       },
//       {
//         name: "Total Tests Started",
//         data: data?.startedCountValues || [],
//         backgroundColor: 'rgb(0, 202, 220)',
//         borderColor: 'rgb(0, 202, 220)',
//         borderWidth: 1,
//       },
//     ]);

//     setOptions({
//       chart: {
//         id: "basic-column",
//         toolbar: {
//           show: false,
//         },
//       },
//       colors: ["#c769ec", "#00cadc", "rgb(101, 166, 250)", "rgb(83, 193, 247)"],
//       plotOptions: {
//         bar: {
//           dataLabels: {
//             style: {
//               colors: ['#000000']
//             }
//           },
//           borderRadius: 2,
//         },
//       },
//       xaxis: {
//         categories: data?.testDates,
//         axisBorder: {
//           show: true,
//           color: '#fff'
//         },
//         axisTicks: {
//           show: false,
//           color: '#fff'
//         },
//         labels: {
//           style: {
//             colors: '#fff'
//           }
//         }
//       },
//       yaxis: {
//         axisBorder: {
//           show: true,
//           color: '#fff'
//         },
//         axisTicks: {
//           show: false,
//           color: '#fff'
//         },
//         labels: {
//           style: {
//             colors: '#fff'
//           }
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         curve: 'straight'
//       },
//       legend: {
//         position: "top"
//       }
//     });
//   }
//     , [data]);

//   return (
//     <div className="chart-container">
//       <div className="chart">
//         <Chart
//           options={options}
//           series={series}
//           type="bar"
//           height="400"
//         />
//       </div>
//     </div>
//   );
// };

// export default ColumnChart;


import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../styles/ColumnChart.css";

const ColumnChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      const newData = {
        labels: data.testDates,
        datasets: [
          {
            label: "Total Tests Completed",
            backgroundColor: "rgb(172, 90, 195)",
            borderColor: "rgb(172, 90, 195)",
            borderWidth: 1,
            data: data.completedCountValues,
          },
          {
            label: "Total Tests Started",
            backgroundColor: "rgb(0, 202, 220)",
            borderColor: "rgb(0, 202, 220)",
            borderWidth: 1,
            data: data.startedCountValues,
          },
        ],
      };
      setChartData(newData);
    }
  }, [data]);

  return (
    <div className="chart-container">
      <div className="chart">
        {chartData && (
          <Bar
            data={chartData}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    color: '#ffffff',
                  },
                  grid: {
                    display: false,
                  },
                  border: {
                    color: '#ffffff',
                  },
                },
                y: {
                  ticks: {
                    color: '#ffffff',
                  },
                  grid: {
                    display: true,
                    color: '#8793AB',
                  },
                  border: {
                    color: '#ffffff', // Set y-axis line color here
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    color: '#ffffff',
                    padding: 20,
                    boxWidth: 13,
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );


};

export default ColumnChart;



